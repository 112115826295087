import { Document, DocumentType } from "./types";
import { X } from "lucide-react";
import { getFileIcon } from "utils/getFileIcon";

const SelectedDocumentListItem: React.FC<{
  item: DocumentType;
  onDeleteSelectedDocument: (id: Document) => void;
}> = ({ item, onDeleteSelectedDocument }) => {
  return (
    <div
      key={item.file_id}
      className="group w-full px-4 py-3 flex items-center justify-between rounded hover:bg-action-lightest"
    >
      <div className="flex overflow-hidden items-center gap-1">
        <img
          className={`h-[24px] w-[28px] pr-2 object-contain`}
          src={getFileIcon(item.file_extension_type)}
          alt="file-icon"
        />

        <p className="text-sm text-gray-700 truncate max-w-full w-max text-left">{item.file_name}</p>
      </div>
      <button
        onClick={() => onDeleteSelectedDocument({ id: item.file_id })}
        className="text-[14px] font-medium text-blue-900 ml-2 rounded opacity-0 p-1 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto hover:bg-action-light"
      >
        <X size={14} />
      </button>
    </div>
  );
};

export default SelectedDocumentListItem;
