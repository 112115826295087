import { Section, Volume } from "components/copilot/CopilotSchemaTypes";
import { LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";

export const getSectionsMap = (volumes?: ToImmutable<LiveList<LiveObject<Volume>>>) => {
    return (
        volumes?.reduce<Record<string, ToImmutable<Section>>>((acc, draft) => {
            const reducedSections = draft.sections.reduce<Record<string, ToImmutable<Section>>>(
                (acc, section) => ({ ...acc, [section.id]: section }),
                {}
            );
            return { ...acc, ...reducedSections };
        }, {}) || {}
    );
};
