import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { TabSlug } from "components/copilot/Framework/types";

type State = {
    activeTab: TabSlug;
    activeSheet: Sheet | null;
    config: {
        animateReqs?: boolean;
    };
};

const initialState: State = {
    activeTab: TabSlug.Unassigned,
    activeSheet: null,
    config: {},
};

const requirementsDrawerReducer = createSlice({
    name: "requirementsDrawerReducer",
    initialState,
    reducers: {
        resetRequirementsDrawerState: () => {
            return initialState;
        },
        setRequirementsDrawerState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
        setRequirementsDrawerConfig: (state: State, action: PayloadAction<Partial<State["config"]>>) => {
            state.config = { ...state.config, ...action.payload };
        },
    },
});

export const { setRequirementsDrawerState, resetRequirementsDrawerState, setRequirementsDrawerConfig } =
    requirementsDrawerReducer.actions;

export default requirementsDrawerReducer.reducer;
