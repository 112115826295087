import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
// util
import { containsAllRoles } from "../utils/helpers";
//
import { useSelector } from "react-redux";
// auth pages
import WelcomePage from "../pages/auth/WelcomePage.jsx";
import ContinueWithEmail from "../pages/auth/ContinueWithEmail.jsx";
import LoginPage from "../pages/auth/LoginPage.jsx";
import ConfirmEmail from "../pages/auth/ConfirmEmailPage.jsx";
import CheckLoginTokenPage from "../pages/auth/CheckLoginTokenPage.jsx";
import SelectWorkSpacePage from "../pages/auth/SelectWorkSpacePage.jsx";
import CreateWorkSpace from "../pages/auth/CreateWorkSpace.jsx";
// dashboard pages
import Dashboard from "../pages/dashboard/Dashboard.jsx";
import SearchPage from "../pages/search-pages/SearchPage.jsx";
import ContractManagement from "../pages/contracts-management/ContractManagement.jsx";
import ProposalAssistant from "../pages/proposal-assistant/index.jsx";
import DrivePage, { MediaContent } from "pages/drive";
import FolderPage from "pages/drive/FolderPage";
import CreateDocument from "pages/drive/CreateDocument";
import CuratedOpportunities from "../pages/Notifications/index.jsx";
import Settings from "../pages/Settings";
// ****************
// loader/spinner
import ScreenSpinner from "../utils/Spinner";
import ProjectDetails from "pages/project-details";
import DraftSection, { DraftSectionEmpty, DraftSectionProposal, DraftSectionRequirements } from "pages/draft-section";
import { ControlItemSlug } from "pages/draft-section/hooks";
import DocumentsContent from "pages/drive/documents/DocumentsContent";
import axios from "axios";
import { useEffect } from "react";
import useLogout from "hook/useLogout";
import AIAssistantRoom from "pages/ai-assistant/room";
import AIAssistant from "pages/ai-assistant";
import ExtractDocViewerModal from "components/copilot/extract-v2/doc-viewer/ExtractDocViewerModal";
import ContentLibrary from "components/copilot/ProposalPlan/content-library/ContentLibrary";
import ProjectSpecific from "components/copilot/ProposalPlan/project-specific/ProjectSpecific";
import WinThemesContent from "components/organisms/win-themes-content";
import CaptureForm from "components/organisms/capture-form";

// --------------- main wrapper for all routing within the web app -------------------
const AppRouter = () => {
  const currentWorkspace = useSelector((store) => store.auth.currentWorkspace);

  return (
    <Routes>
      {/* UnAuthenticated routes */}
      <Route path="/" element={<Navigate to="/auth" replace />} />
      <Route path="auth" element={<WelcomePage />}>
        <Route index element={<ContinueWithEmail />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="confirm-email" element={<ConfirmEmail />} />
        <Route path="redirect" element={<CheckLoginTokenPage />} />
      </Route>
      {/* Authenticated routes */}
      <Route
        path="select-workspace"
        element={
          <ProtectedRoute redirectPath="/auth">
            <SelectWorkSpacePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="create-workspace"
        element={
          <ProtectedRoute redirectPath="/auth">
            <CreateWorkSpace />
          </ProtectedRoute>
        }
      />
      <Route
        path="dashboard"
        exact
        element={
          <ProtectedRoute redirectPath="/auth">
            <Dashboard />
          </ProtectedRoute>
        }
      >
        <Route
          index
          element={
            <Navigate to={currentWorkspace.enable_search ? "/dashboard/search" : "/dashboard/contracts"} replace />
          }
        />
        <Route path="search" element={<SearchPage />} />
        <Route path="search/contracts-details" element={<SearchPage />} />

        <Route path="recommended" element={<CuratedOpportunities />} />
        <Route path="contracts" element={<ContractManagement />} />

        <Route path="contracts/details" element={<ProjectDetails />} />
        <Route path="contracts/details/capture" element={<ProjectDetails />}>
          <Route index element={<CaptureForm />} />
          <Route path="win-themes" element={<WinThemesContent />} />
          <Route path=":schemaType" element={<CaptureForm />} />
        </Route>
        <Route path="contracts/details/documents" element={<ProjectDetails />}>
          <Route index element={<Navigate to="project-specific" replace />} />
          <Route path="project-specific" element={<ProjectSpecific />} />
          <Route path="content-library" element={<ContentLibrary />} />
        </Route>
        <Route path="contracts/details/extractions" element={<ProjectDetails />}>
          <Route path=":extractionId" element={<ExtractDocViewerModal />} />
        </Route>
        <Route path="contracts/details/volumes/:volumeId" element={<ProjectDetails />}>
          <Route index element={<DraftSectionEmpty />} />
          <Route path="sections/:sectionId" element={<DraftSection />}>
            <Route path={ControlItemSlug.requirements} element={<DraftSectionRequirements />} />
            <Route path={ControlItemSlug.proposal} element={<DraftSectionProposal />} />
          </Route>
        </Route>

        <Route path="ai-assistant" element={<AIAssistantRoom />}>
          <Route index element={<AIAssistant />} />
          <Route path=":currentSessionId" element={<AIAssistant />} />
        </Route>
        <Route path="proposal-assistant" element={<ProposalAssistant />} />
        <Route path="library" element={<DrivePage />}>
          <Route index element={<Navigate to="documents" replace />} />
          <Route path="documents" element={<DocumentsContent />} />
          <Route path="documents/folder/:folderId" element={<FolderPage />} />
          <Route path="media" element={<MediaContent />} />
          <Route path="media/folder/:folderId" element={<MediaContent />} />
        </Route>
        <Route path="create-document" element={<CreateDocument />} />
        <Route path="settings" element={<Settings />} />
        <Route path="create-workspace" element={<CreateWorkSpace />} />
        <Route path="select-workspace" element={<SelectWorkSpacePage />} />
        {/* <Route path="help" element={<Help />} /> */}
      </Route>
      {/* 404 page */}
      <Route path="*" element={<h1>404 not found</h1>} />
    </Routes>
  );
};

// This is wrapper component for protected routes & it will also validate role based routing.
const ProtectedRoute = ({ redirectPath = "/", children, roles }) => {
  const { isAuthenticated, isLoading, data: user } = useSelector((store) => store.auth);
  let location = useLocation();
  const logout = useLogout();

  useEffect(() => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 403) {
          logout();
        }
        return Promise.reject(error);
      }
    );
  }, [logout]);

  // if auth loading
  if (isLoading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <ScreenSpinner />
      </div>
    );
  }
  // if user is not authenticated
  if (!isAuthenticated) {
    window.Intercom("shutdown");
    return <Navigate to={redirectPath} replace state={{ from: location }} />;
  }
  // if user is authenticated but not contains provided roles
  if (roles && !containsAllRoles(roles, user.roles)) {
    return <Navigate to={"/select-workspace"} replace state={{ from: location }} />;
  }
  return children ? children : <Outlet />;
};

export default AppRouter;
