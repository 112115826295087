import BasicSidebarItem from "components/atoms/basic-sidebar-item";
import { CAPTURE_SCHEMA_TYPE_PREFIX } from "const-values/Capture";
import { useSearchParams } from "react-router-dom";
import { CaptureSchema } from "types/Capture";

type Props = {
  schema: CaptureSchema;
};

const CaptureSchemaItem = ({ schema }: Props) => {
  const [searchParams] = useSearchParams();

  return (
    <BasicSidebarItem
      to={{ pathname: schema.type.replace(`${CAPTURE_SCHEMA_TYPE_PREFIX}:`, ""), search: searchParams.toString() }}
    >
      {schema.title}
    </BasicSidebarItem>
  );
};

export default CaptureSchemaItem;
