import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { contractSearch } from "api/api";

export const getAllSearchData = createAsyncThunk("searchSlice/getAllSearchData", async (query) => {
    return contractSearch(query).then((res) => {
        let data = res.data || { result: [] };
        return data.result || [];
    });
});

const initialState = {
    data: [],
    isLoadingSearchData: false,
    mainOffset: 0,
    mainHasMore: true,
};

export const searchReducerSlice = createSlice({
    name: "searchSlice",
    initialState,
    reducers: {
        setSearch: (state, action) => {
            state.data = action.payload.data;
            state.isLoadingSearchData = action.payload.loading;
        },
        setMainOffset: (state, action) => {
            state.mainOffset = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllSearchData.pending, (state) => {
                state.isLoadingSearchData = true;
            })
            .addCase(getAllSearchData.fulfilled, (state, action) => {
                let newList = action.payload || [];
                state.mainHasMore = newList.length > 49;
                state.data = [...state.data, ...newList];
                state.isLoadingSearchData = false;
            })
            .addCase(getAllSearchData.rejected, (state) => {
                state.isLoadingSearchData = false;
            });
    },
});

export const { setSearch, setMainOffset } = searchReducerSlice.actions;

export default searchReducerSlice.reducer;
