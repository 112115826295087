import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export enum SourceType {
    ContentDrive = "content_drive",
    AI = "vultron_ai",
}

export type SearchContent = {
    date: string;
    reference_id: string;
    source_content: string;
    source_extension_type: string;
    source_name: string;
    source_type: SourceType;
    content_id: string;
};

type State = {
    query: string;
    results: SearchContent[];
    selectedFiles: string[];
    isLoading: boolean;
};

const initialState: State = {
    query: "",
    results: [],
    selectedFiles: [],
    isLoading: false,
};

const contentSearchReducer = createSlice({
    name: "contentSearchReducer",
    initialState,
    reducers: {
        resetContentSearchState: () => {
            return initialState;
        },
        setContentSearchState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setContentSearchState, resetContentSearchState } = contentSearchReducer.actions;

export default contentSearchReducer.reducer;
