/** @jsxImportSource @emotion/react */

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { getMinMaxMap } from "./utils";
import { getSectionsMap } from "utils/Liveblocks/Framework";
import { setActiveDocument } from "store/reducers/extract/CurrentExtractionReducer";
import { Bounds, GroupedBlock, Item, MergedRequirement, Row, StepValue } from "../types";
import { ComplianceMatrixRow, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { Minus, Plus, ZoomIn } from "lucide-react";
import { memo, useCallback, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import BottomRightActions from "./bottom-right-actions";
import DocumentContainer from "./DocumentContainer";
import GenerationBanner from "../../GenerationBanner";
import Tooltip from "components/atoms/tooltip/Tooltip";
import tw from "twin.macro";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { compact, keyBy } from "lodash";
import { decrementScale, incrementScale } from "utils/scale";

const DocumentDisplay = () => {
  const scrollArea = useRef<HTMLDivElement | null>(null);
  const [scale, setScale] = useState(1.25);
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const coordinates = useAppSelector((store) => store.currentExtractionState.coordinates);
  const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);
  const dispatch = useAppDispatch();

  const isAssignStep = extraction?.step === StepValue.Assign;
  const sectionsMap = useMemo(() => getSectionsMap(extraction?.framework?.volumes), [extraction?.framework?.volumes]);
  const keysOfSectionMap = useMemo(() => Object.keys(sectionsMap), [sectionsMap]);
  const filteredRows = useMemo(() => {
    return extraction?.compliance_matrix?.filter((row) => {
      return (
        row.document?.id === activeDocument?.id &&
        !row.requirement.soft_deleted &&
        (!isAssignStep || (isAssignStep && !row.requirement.skipped))
      );
    });
  }, [activeDocument?.id, extraction?.compliance_matrix, isAssignStep]);

  const groupedRequirements = useMemo(() => keyBy(mergedRequirements, "id"), [mergedRequirements]);
  const groupedMergedRequirements = useMemo(() => {
    return keyBy(
      compact(mergedRequirements.map(({ id }) => filteredRows?.find((row) => row.requirement.id === id))),
      "requirement.id"
    );
  }, [filteredRows, mergedRequirements]);

  const elementIdToRequirementMap = useMemo(() => {
    return (
      filteredRows?.reduce<Record<string, ToImmutable<ComplianceMatrixRow>>>((acc, row) => {
        return Object.assign(acc, { [row.requirement.element_id || ""]: row });
      }, {}) || {}
    );
  }, [filteredRows]);

  const validateMergedBlock = useCallback(
    (
      acc: {
        allFilteredBlocks: GroupedBlock[];
        pageGroups: Record<number, GroupedBlock[]>;
      },
      mergedRequirement: MergedRequirement
    ) => {
      const mergedYjsRequirement = groupedMergedRequirements[mergedRequirement.id];

      if (mergedYjsRequirement) {
        mergedRequirement.bounds.forEach((mergedBlock) => {
          const transformedBlock = {
            page: mergedBlock.page_number,
            bounds: mergedBlock.bounds,
            requirement: mergedYjsRequirement,
            id: mergedYjsRequirement?.requirement.element_id || "",
            isMergedRequirement: true,
          };
          acc.pageGroups[mergedBlock.page_number] = [
            ...(acc.pageGroups[mergedBlock.page_number] || []),
            transformedBlock,
          ];
          acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
        });
      }
    },
    [groupedMergedRequirements]
  );

  const groupedBlocks = useMemo(() => {
    const grouped = coordinates.reduce<{
      allFilteredBlocks: GroupedBlock[];
      pageGroups: Record<number, GroupedBlock[]>;
    }>(
      (acc, block) => {
        const blockItems = block.items || [];
        const blockParts = block.parts || [];
        const parentBlockToRequirement = elementIdToRequirementMap[block.id];

        const appendChildBlocks = (childBlocks: (Item | Row)[]) => {
          childBlocks.forEach((innerBlock) => {
            const requirement = elementIdToRequirementMap[parentBlockToRequirement ? block.id : innerBlock.id];
            if (!requirement) return;
            const mergedRequirement = groupedRequirements[requirement.requirement.id];
            if (mergedRequirement) {
              validateMergedBlock(acc, mergedRequirement);
            } else {
              const transformedBlock = {
                ...innerBlock,
                requirement,
              };

              if (!!innerBlock.text.trim()) {
                if (isAssignStep) {
                  if (!requirement?.requirement?.skipped) {
                    acc.pageGroups[innerBlock.page] = [...(acc.pageGroups[innerBlock.page] || []), transformedBlock];
                    acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                  }
                } else {
                  acc.pageGroups[innerBlock.page] = [...(acc.pageGroups[innerBlock.page] || []), transformedBlock];
                  acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                }
              }
            }
          });
        };

        if (!!block.bounds && !!parentBlockToRequirement) {
          if (typeof block.page === "number") {
            const requirement = elementIdToRequirementMap[block.id];
            const mergedRequirement = groupedRequirements[requirement.requirement.id];
            if (mergedRequirement) {
              validateMergedBlock(acc, mergedRequirement);
            } else {
              const transformedBlock = {
                ...block,
                requirement,
              };
              if (isAssignStep) {
                if (!requirement?.requirement?.skipped) {
                  acc.pageGroups[block.page] = [...(acc.pageGroups[block.page] || []), transformedBlock];
                  acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                }
              } else {
                acc.pageGroups[block.page] = [...(acc.pageGroups[block.page] || []), transformedBlock];
                acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
              }
            }
          } else {
            if (blockParts.length) {
              const childRows = blockParts
                .map((part) => {
                  if (!part.rows?.length) return [];
                  return part.rows.flat();
                })
                .flat();
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(childRows);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...block,
                    requirement: parentBlockToRequirement,
                  };

                  if (isAssignStep) {
                    if (!parentBlockToRequirement?.requirement?.skipped) {
                      acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                      acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                    }
                  } else {
                    acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                    acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                  }
                }
              } else {
                appendChildBlocks(childRows);
              }
            }

            if (blockItems.length) {
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(blockItems);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...block,
                    requirement: parentBlockToRequirement,
                  };

                  if (isAssignStep) {
                    if (!parentBlockToRequirement?.requirement?.skipped) {
                      acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                      acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                    }
                  } else {
                    acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                    acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                  }
                }
              } else {
                appendChildBlocks(blockItems);
              }
            }
          }
        } else if (blockItems.length || blockParts.length) {
          if (blockItems.length) {
            if (parentBlockToRequirement) {
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(blockItems);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              const parentCalculatedBounds = minMaxMap.bounds;
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...{ ...block, bounds: parentCalculatedBounds as Bounds },
                    requirement: parentBlockToRequirement,
                  };

                  if (isAssignStep) {
                    if (!parentBlockToRequirement?.requirement?.skipped) {
                      acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                      acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                    }
                  } else {
                    acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                    acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                  }
                }
              } else {
                appendChildBlocks(blockItems);
              }
            } else {
              appendChildBlocks(blockItems);
            }
          }

          if (blockParts.length) {
            const childRows = blockParts
              .map((part) => {
                if (!part.rows?.length) return [];
                return part.rows.flat();
              })
              .flat();
            if (parentBlockToRequirement) {
              const { minMaxMap, isValidAndSamePage } = getMinMaxMap(childRows);
              const { min, max } = minMaxMap;
              const hasMinMax = typeof max === "number" && typeof min === "number";
              const parentCalculatedBounds = minMaxMap.bounds;
              if (isValidAndSamePage && hasMinMax) {
                const mergedRequirement = groupedRequirements[parentBlockToRequirement.requirement.id];
                if (mergedRequirement) {
                  validateMergedBlock(acc, mergedRequirement);
                } else {
                  const transformedBlock = {
                    ...{ ...block, bounds: parentCalculatedBounds as Bounds },
                    requirement: parentBlockToRequirement,
                  };

                  if (isAssignStep) {
                    if (!parentBlockToRequirement?.requirement?.skipped) {
                      acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                      acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                    }
                  } else {
                    acc.pageGroups[min] = [...(acc.pageGroups[min] || []), transformedBlock];
                    acc.allFilteredBlocks = [...acc.allFilteredBlocks, transformedBlock];
                  }
                }
              } else {
                appendChildBlocks(childRows);
              }
            } else {
              appendChildBlocks(childRows);
            }
          }
        }

        return acc;
      },
      { allFilteredBlocks: [], pageGroups: {} }
    );

    return grouped;
  }, [coordinates, elementIdToRequirementMap, groupedRequirements, isAssignStep, validateMergedBlock]);

  const isReadOnly = extraction?.status === ExtractionStatus.Completed;

  return (
    <>
      <div className="flex flex-col gap-2 absolute z-[4] top-2 left-2 right-[190px] w-fit pointer-events-none">
        <div className="flex flex-wrap gap-2 pointer-events-auto">
          {documents.map((doc) => (
            <Tooltip key={doc.id} content={doc.file_name}>
              <button
                key={doc.id}
                className="border truncate text-left text-slate-900 border-transparent max-w-[330px] backdrop-blur-lg shadow-sharp-thin bg-gray-300/70 rounded-md text-xs font-medium px-2.5 py-2 duration-150 hover:text-black hover:bg-gray-300"
                title={doc.file_name}
                onClick={() => {
                  dispatch(setActiveDocument(doc));
                }}
                css={[doc.id === activeDocument?.id && tw`border-slate-900 bg-gray-300 border-1.5`]}
              >
                {doc.file_name}
              </button>
            </Tooltip>
          ))}
        </div>
        {!isReadOnly && <GenerationBanner />}
      </div>
      <div className="absolute z-[3] top-2 right-2 flex flex-row items-center gap-2">
        <div className="shadow backdrop-blur-lg rounded-md p-1.5 pl-3 flex gap-4 flex-row items-center bg-gray-300/60">
          <div className="text-xs flex items-center gap-2">
            <ZoomIn size={16} className="stroke-[0.5]" />
            <div className="text-xs font-semibold">{Math.trunc(scale * 100)}%</div>
          </div>
          <div className="flex items-center gap-1">
            <button
              className="w-7 h-7 flex justify-center items-center rounded-md bg-black/[0.12] hover:bg-black/[0.2] active:bg-black/[0.24]"
              onClick={() => setScale(incrementScale)}
            >
              <Plus size={14} />
            </button>
            <button
              className="w-7 h-7 flex justify-center items-center rounded-md bg-black/[0.12] hover:bg-black/[0.2] active:bg-black/[0.24]"
              onClick={() => setScale(decrementScale)}
            >
              <Minus size={14} />
            </button>
          </div>
        </div>
      </div>
      <DocumentContainer
        ref={scrollArea}
        groupedBlocks={groupedBlocks}
        isReadOnly={isReadOnly}
        keysOfSectionMap={keysOfSectionMap}
        sectionsMap={sectionsMap}
        scale={scale}
      />
      <BottomRightActions isAssignStep={isAssignStep} allFilteredBlocks={groupedBlocks.allFilteredBlocks} />
    </>
  );
};

export default memo(DocumentDisplay);
