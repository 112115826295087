/** @jsxImportSource @emotion/react */

import { useEffect, useState } from "react";
import { RoomProvider } from "utils/yjs-configs/ai-assistant/yjs.config";
import { ScreenSpinner } from "utils/icons";
import { useAppSelector } from "store/storeTypes";
import { useLocalStorage } from "hook/useLocalStorage";
import { Outlet } from "react-router-dom";

const AIAssistantRoom = () => {
  const { currentUser } = useAppSelector((store) => store.auth);
  const { localValue: currentWorkspaceId } = useLocalStorage("vultron_workspace_id", "");

  if (!currentUser?.id)
    return (
      <div className="p-20 w-full my-auto flex items-center justify-center">
        <LoadingText />
      </div>
    );

  const aiAssistantRoom = `ai_assistant_${currentUser?.id}_${currentWorkspaceId}`;

  return (
    <RoomProvider id={aiAssistantRoom} initialPresence={{}} fallback={<LoadingText />}>
      <Outlet />
    </RoomProvider>
  );
};

const LoadingText = () => {
  const [dots, setDots] = useState(".");

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((currentDots) => {
        if (currentDots.length < 3) {
          return currentDots + ".";
        }
        return ".";
      });
    }, 500);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="p-20 w-full h-full my-auto flex flex-col items-center justify-center gap-3 bg-white">
      <ScreenSpinner />
      <div className="relative text-gray-400">
        <span>Establishing secure connection</span>
        <span className="absolute left-full top-0">{dots}</span>
      </div>
    </div>
  );
};

export default AIAssistantRoom;
