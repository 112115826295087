const SwitchBtn = ({ handleChange, value }) => {
    return (
        <>
            <label className="relative inline-flex items-center cursor-pointer">
                <input
                    type="checkbox"
                    name=""
                    id=""
                    className="sr-only peer"
                    onChange={handleChange}
                    checked={typeof value === "string" ? (value.includes("true") ? true : false) : value}
                    value={value}
                />
                <div
                    className="w-9 h-5 bg-gray-200 peer-focus:outline-none  
                                rounded-full peer dark:bg-gray-500 
                                peer-checked:after:translate-x-full 
                                after:content-[''] after:absolute 
                                after:top-[2px] 
                                after:left-[2px] 
                                after:bg-white 
                                after:rounded-full 
                                after:h-4 
                                after:w-4 after:transition-all 
                                dark:border-gray-600 peer-checked:bg-action"
                ></div>
            </label>
        </>
    );
};

export default SwitchBtn;
