import {
  RequirementStatus,
  RequirementCompliance,
  ResponseSource,
  WritingPrompt,
  UserInstruction,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { Section, Storage, ComplianceMatrixRow } from "components/copilot/CopilotSchemaTypes";
import { addRequirementToSection } from "components/copilot/Framework/utils";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import useRequirementOperations from "hook/useRequirementOperations";
import { useCallback, useMemo } from "react";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  createComplianceMatrixRow,
  createComplianceMatrixRowRequirement,
  createUserInstruction,
  createWritingPrompt,
} from "utils/complianceMatrix";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { useTrackUserMetric } from "utils/metrics";
import { useSearchParams } from "react-router-dom";
import { getWordCount } from "utils/getWordCount";
import { useMutation } from "YJSProvider/createYJSContext";
import { findIndex, LiveList, LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import { uniq } from "lodash";

export const useBulkUpdateOperation = () => {
  const { checkedState, activeSheet } = useAppSelector((root) => root.requirements);
  const { deleteRequirementRow } = useRequirementOperations();
  const { generateRequirementHeading } = useGenerateRequirementHeading();
  const dispatch = useAppDispatch();
  const trackUserEvent = useTrackUserMetric();
  const [searchParams] = useSearchParams();
  const tabSlug = searchParams.get("tab")?.toLocaleLowerCase();
  const checkedReqIds = useMemo(() => Object.keys(checkedState).filter((reqId) => checkedState[reqId]), [checkedState]);

  const removeRows = useCallback(() => {
    checkedReqIds.forEach((id) => {
      deleteRequirementRow(id);
    });
    dispatch(setCheckedState({}));
  }, [checkedReqIds, deleteRequirementRow, dispatch]);

  const assignToSection = useMutation(
    ({ storage }, section: ToImmutable<Section>) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const volumes = (storage.get("framework") as Storage["framework"])?.get("volumes");
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));
      const requirementsIds = liveRequirements
        ?.filter(
          (row) =>
            !!(
              row.get("written_content") ||
              row.get("requirement").get("content") ||
              row.get("requirement").get("summarized_content")
            )
        )
        ?.map((row) => row.get("requirement").get("id"));

      liveRequirements.forEach((complianceMatrixRow) => {
        addRequirementToSection({
          complianceMatrix,
          activeRow: complianceMatrixRow,
          volumeList: volumes,
          destinationSectionId: section.id,
        });
      });

      generateRequirementHeading({ requirement_ids: requirementsIds });
      trackUserEvent("Requirements: Requirement Bulk Added to Section", {
        number_requirements: liveRequirements.length,
        section_id: String(section.id),
      });
    },
    [checkedReqIds]
  );

  const setRequirementStatus = useMutation(
    ({ storage }, status: ToImmutable<ComplianceMatrixRow>["requirement_status"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        complianceMatrixRow.set("requirement_status", status);
      });

      trackUserEvent("Requirements: Requirement Status Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_requirement_status: status
          ? REQUIREMENT_STATUS_TO_META[status]?.label
          : REQUIREMENT_STATUS_TO_META[RequirementStatus.Todo].label,
      });
    },
    [checkedReqIds]
  );

  const setComplianceStatus = useMutation(
    ({ storage }, status: ToImmutable<ComplianceMatrixRow>["compliance_status"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        complianceMatrixRow.set("compliance_status", status);
      });

      trackUserEvent("Requirements: Compliance Status Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_compliance_status: status
          ? COMPLIANCE_TO_META[status]?.label
          : COMPLIANCE_TO_META[RequirementCompliance.Empty].label,
      });
    },
    [checkedReqIds]
  );

  const setResponseTolerance = useMutation(
    ({ storage }, tolerance: ToImmutable<ComplianceMatrixRow>["response_tolerance"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        complianceMatrixRow.set("response_tolerance", tolerance);
      });

      trackUserEvent("Requirements: Response Tolerance Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_response_tolerance: tolerance,
      });
    },
    [checkedReqIds]
  );

  const setResponseSpeed = useMutation(
    ({ storage }, speed: ToImmutable<ComplianceMatrixRow>["response_speed"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        complianceMatrixRow.set("response_speed", speed);
      });

      trackUserEvent("Requirements: Response Speed Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_response_speed: speed,
      });
    },
    [checkedReqIds]
  );

  const setWritingPrompts = useMutation(
    ({ storage }, writingPrompt: Partial<WritingPrompt>) => {
      const newLiveWritingPrompt = createWritingPrompt(writingPrompt);

      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        const liveWritingPrompts = complianceMatrixRow.get("writing_prompts") || [];
        if (complianceMatrixRow.get("locked")) return;

        if (!liveWritingPrompts?.length) {
          complianceMatrixRow?.set("writing_prompts", new LiveList([newLiveWritingPrompt]));
        } else complianceMatrixRow?.get("writing_prompts")?.push([newLiveWritingPrompt]);
      });

      trackUserEvent("Requirements: Writing Prompt Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_writing_prompt: writingPrompt,
      });
    },
    [checkedReqIds]
  );

  const setUserInstructions = useMutation(
    ({ storage }, guideline: Partial<UserInstruction>) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const newUserInstructions = createUserInstruction(guideline.content || "");

      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        const userInstructions = complianceMatrixRow.get("user_instructions");
        if (complianceMatrixRow.get("locked")) return;

        if (!userInstructions?.length) {
          complianceMatrixRow?.set("user_instructions", new LiveList([newUserInstructions]));
        } else complianceMatrixRow?.get("user_instructions")?.push([newUserInstructions]);
      });

      trackUserEvent("Requirements: User Instructions Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_user_instructions: guideline,
      });
    },
    [checkedReqIds]
  );

  const setSkipped = useMutation(
    ({ storage }, skipped: ToImmutable<ComplianceMatrixRow>["requirement"]["skipped"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        complianceMatrixRow.get("requirement").set("skipped", skipped);
      });
    },
    [checkedReqIds]
  );

  const setAssignees = useMutation(
    ({ storage }, assignees: ToImmutable<ComplianceMatrixRow>["assigned_user_ids"]) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));

      liveRequirements.forEach((complianceMatrixRow) => {
        complianceMatrixRow.set("assigned_user_ids", assignees);
      });

      trackUserEvent("Requirements: Assignees Bulk Updated", {
        number_requirements: liveRequirements.length,
        new_assignees_count: assignees?.length ?? 0,
      });
    },
    [checkedReqIds]
  );

  const mergeRequirements = useMutation(
    ({ storage }, retainSection?: boolean) => {
      const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
      const liveRequirements = complianceMatrix
        ?.toArray()
        .filter((row) => checkedReqIds.includes(row.get("requirement").get("id")));
      const retainSectionOrder = liveRequirements.every(
        (req) => typeof req.get("requirement").get("section_order") === "number"
      );

      let minIndex = complianceMatrix.length;
      const mergedContent = liveRequirements.map((row) => row.get("requirement")?.get("content")).join("\n\n");
      const sortedRequirements = liveRequirements
        .map((req) => req.toJSON() as ToImmutable<ComplianceMatrixRow>)
        .sort((a, b) => (a.requirement.section_order || 0) - (b.requirement.section_order || 0));
      const mergeAndAdjustSources = mergeAndAdjustSourcesAndContent(sortedRequirements);
      const createdRequirement = createComplianceMatrixRowRequirement({
        extraction_id: activeSheet?.id,
        content: mergedContent,
        ...(retainSection && {
          section_order: liveRequirements.at(-1)?.get("requirement").get("section_order"),
        }),
      });
      const newRow = createComplianceMatrixRow({
        requirement: createdRequirement,
        written_content: mergeAndAdjustSources.transformedWrittenContent,
        response_sources: new LiveList(mergeAndAdjustSources.adjustedSources.map((source) => new LiveObject(source))),
        ...(retainSection && {
          proposal_reference: new LiveObject(liveRequirements[0]?.toJSON().proposal_reference),
        }),
      });

      liveRequirements.forEach((complianceMatrixRow) => {
        const idx = findIndex(
          complianceMatrix,
          (row) => row.get("requirement")?.get("id") === complianceMatrixRow.get("requirement")?.get("id")
        );

        if (idx >= 0) minIndex = Math.min(minIndex, idx);

        if (retainSectionOrder) {
          const sectionOrder = complianceMatrixRow.get("requirement").get("section_order");
          const minSectionOrder = Math.min(
            createdRequirement.get("section_order") || minIndex,
            sectionOrder || minIndex
          );
          createdRequirement.set("section_order", minSectionOrder);
        }

        complianceMatrix.delete(idx);
      });
      complianceMatrix.insert(minIndex, [newRow]);
      dispatch(setCheckedState({}));

      trackUserEvent("Requirements: Requirements Merged", {
        tab: tabSlug,
        number_requirements: liveRequirements.length,
        word_count: getWordCount(mergedContent),
      });
    },
    [checkedReqIds, dispatch, activeSheet?.id, tabSlug]
  );

  return {
    checkedReqIds,
    removeRows,
    setRequirementStatus,
    setComplianceStatus,
    setSkipped,
    assignToSection,
    setAssignees,
    setResponseTolerance,
    setResponseSpeed,
    setUserInstructions,
    setWritingPrompts,
    mergeRequirements,
  };
};

function findAndSortSources(inputString: string) {
  const regex = /\[Source-\d+(\.\d+)?\]/g;
  const matches = inputString.match(regex);

  if (!matches) return []; // No matches found

  const sortedMatches = matches.sort((a, b) => {
    const aMatch = a.match(/\d+(\.\d+)?/);
    const bMatch = b.match(/\d+(\.\d+)?/);
    if (!aMatch || !bMatch) return 0;
    const numA = parseFloat(aMatch[0]);
    const numB = parseFloat(bMatch[0]);
    return numA - numB;
  });

  return sortedMatches;
}

const mergeAndAdjustSourcesAndContent = (requirements: ToImmutable<ComplianceMatrixRow>[]) => {
  const regex = /\b\d+(?:.\d+)?/;
  const responseSources = requirements.reduce<ToImmutable<LiveObject<ResponseSource & { reqId: string }>>[]>(
    (acc, row) => [...acc, ...(row.response_sources?.map((s) => ({ ...s, reqId: row.requirement.id })) || [])],
    []
  );
  let sources = 0;
  const citationMap = requirements.reduce<Record<string, Record<number, number>>>((acc, req) => {
    let reqCitationMap = {};

    req.response_sources?.forEach((source) => {
      const appendedCitationMap = source.used_file_contents.reduce<Record<number, number>>((acc2, contentObj) => {
        const currentExtractedSourceMatch = contentObj.requirement_source_citations.match(regex) || [];
        const currentExtractedFloat = currentExtractedSourceMatch[0] ? parseFloat(currentExtractedSourceMatch[0]) : 0;
        const builtSource = currentExtractedFloat - Math.floor(currentExtractedFloat) + sources + 1;
        acc2[currentExtractedFloat] = builtSource;
        return acc2;
      }, {});

      reqCitationMap = { ...reqCitationMap, ...appendedCitationMap };
      sources += 1;
    });
    return { ...acc, [req.requirement.id]: reqCitationMap };
  }, {});

  const adjustedSources = responseSources.reduce<NonNullable<ToImmutable<ComplianceMatrixRow>["response_sources"]>>(
    (acc, { reqId, ...source }) => {
      const regex = /\b\d+(?:.\d+)?/;

      const adjustedFileContents = source.used_file_contents.map((contentObj) => {
        const currentExtractedSourceMatch = contentObj.requirement_source_citations.match(regex) || [];
        const currentExtractedFloat = currentExtractedSourceMatch[0] ? parseFloat(currentExtractedSourceMatch[0]) : 0;

        const builtSource = citationMap[reqId][currentExtractedFloat];
        return {
          ...contentObj,
          requirement_source_citations: `[Source-${builtSource}]`,
        };
      });

      return [...acc, { ...source, used_file_contents: adjustedFileContents }];
    },
    []
  );

  const transformedWrittenContent = () => {
    let transformedMergedContent = "";
    requirements.forEach((req, i) => {
      let writtenContent = req.written_content || "";
      const foundAllCitations = uniq(findAndSortSources(writtenContent).reverse());

      foundAllCitations.forEach((cit) => {
        const currentExtractedSourceMatch = cit.match(regex) || [];
        const currentExtractedFloat = currentExtractedSourceMatch[0] ? parseFloat(currentExtractedSourceMatch[0]) : 0;
        const builtSource = citationMap[req.requirement.id][currentExtractedFloat];

        writtenContent = writtenContent.replaceAll(cit, `[Source-${builtSource}]`);
      });
      if (!!writtenContent.trim())
        transformedMergedContent += `${writtenContent} ${i === requirements.length - 1 ? "" : "\n\n"}`;
    });

    return transformedMergedContent;
  };

  return { transformedWrittenContent: transformedWrittenContent(), adjustedSources };
};
