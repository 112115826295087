/** @jsxImportSource @emotion/react */

import Input from "components/molecules/input";
import { Checkbox } from "components/atoms/checkbox";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import Icon from "components/atoms/icons/Icon";
import { NewSectionConfig, TypeToMove } from "./MoveRequirementOrSectionModal";
import tw from "twin.macro";
import { Dispatch, SetStateAction, useState } from "react";
import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";

type Props = {
  newSectionConfig: NewSectionConfig;
  setNewSectionConfig: Dispatch<SetStateAction<NewSectionConfig | null>>;
  draftSelectionItems: MenuItem<string>[];
  isSectionsDisabled: boolean;
  type: TypeToMove;
};

const CreateSectionAndAssign = ({
  newSectionConfig,
  setNewSectionConfig,
  draftSelectionItems,
  isSectionsDisabled,
  type,
}: Props) => {
  const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(null);

  return (
    <div ref={setContainerRef} className="flex flex-col gap-7 flex-1 pl-6 pr-4 w-full mt-7">
      <div className="flex flex-col gap-5">
        <div className="text-slate-700 font-medium">New Draft {!isSectionsDisabled && "and Section"}</div>
        <div className="flex flex-col gap-4">
          <div className="flex items-center gap-2 text-sm text-slate-600">
            <Checkbox
              checked={newSectionConfig.existingDraft}
              onCheck={(checked) =>
                setNewSectionConfig(
                  (prev) =>
                    ({
                      ...prev,
                      existingDraft: checked,
                      draft: { title: "" },
                    } as NewSectionConfig)
                )
              }
            />
            Existing Draft
          </div>
          {newSectionConfig.existingDraft ? (
            <div className="flex flex-col gap-1">
              <label className="text-slate-500 font-medium text-xs">Draft</label>
              <DropdownMenu
                contentProps={{ css: tw`max-h-[200px] min-w-[760px]` }}
                portalProps={{ container: containerRef }}
                items={draftSelectionItems}
              >
                <div
                  className="flex justify-between text-sm items-center gap-4 rounded-lg border border-gray-light px-2 py-[7px]"
                  css={[!newSectionConfig.draft?.title && tw`text-slate-400`]}
                >
                  {newSectionConfig.draft?.title || "Select from current drafts"} <Icon name="CarrotDown" />
                </div>
              </DropdownMenu>
            </div>
          ) : (
            <div className="flex flex-col gap-1">
              <label className="text-slate-500 font-medium text-xs">Draft Title</label>
              <Input
                placeholder="Add draft title"
                value={newSectionConfig.draft?.title}
                onChange={(e) =>
                  setNewSectionConfig(
                    (prev) =>
                      ({
                        ...prev,
                        draft: { title: e.target.value },
                      } as NewSectionConfig)
                  )
                }
              />
            </div>
          )}
          {!isSectionsDisabled && (
            <div className="flex flex-col gap-1">
              <label className="text-slate-500 font-medium text-xs">
                Section Title{" "}
                {TypeToMove.Section === type && (
                  <span className="font-normal text-gray-400 text-xs ml-0.5">(Optional)</span>
                )}
              </label>
              <Input
                placeholder="Add section title"
                value={newSectionConfig.section?.title}
                onChange={(e) =>
                  setNewSectionConfig(
                    (prev) =>
                      ({
                        ...prev,
                        section: { title: e.target.value },
                      } as NewSectionConfig)
                  )
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateSectionAndAssign;
