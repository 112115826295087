import {
  DragEndEvent,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import Icon from "components/atoms/icons/Icon";
import { Framework, Storage, Volume } from "components/copilot/CopilotSchemaTypes";
import { Volume as ImmutableVolume, Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { styles } from "components/molecules/dropdown-menu/styles";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useNotification } from "context/notificationContext";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ChevronRight, Link, Pencil, RotateCcwSquare, Trash2 } from "lucide-react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  DisplayOption,
  PreviewConfig,
  SectionFormat,
  setPreviewProposalState,
} from "store/reducers/draft/previewProposalReducer";
import { setSectionState } from "store/reducers/draft/sectionReducer";
import { togglePreviewProposalModal } from "store/reducers/modalsSlice";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import copyText from "utils/copyText";
import { useTrackUserMetric } from "utils/metrics";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { find, findIndex, moveItem } from "YJSProvider/LiveObjects";

export const useDrag = () => {
  const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveDragId(event.active.id);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveDragId(null);
  }, []);

  const handleDragEnd = useMutation(({ storage }, event: DragEndEvent, volumeId?: string) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const framework = storage.get("framework") as Storage["framework"] | undefined;
      const volumes = framework?.get("volumes") as Framework["volumes"];
      if (!volumes || !framework) return;
      const sections = find(volumes, (row) => row.get("id") === volumeId)?.get("sections") as
        | Volume["sections"]
        | undefined;

      if (!sections) return;

      const sourceIndex = findIndex(sections, (row) => row.get("id") === active.id);
      const destinationIndex = findIndex(sections, (row) => row.get("id") === over.id);
      if (sourceIndex === -1 || destinationIndex === -1) return;
      moveItem(sections, sourceIndex, destinationIndex);
    }

    setActiveDragId(null);
  }, []);

  return { sensors, handleDragStart, handleDragEnd, handleDragCancel, activeDragId };
};

export const useSectionNavigation = (volume?: ImmutableVolume) => {
  const [searchParams] = useSearchParams();
  const { sections } = volume || {};
  const { volumeId, sectionId } = useParams();
  const navigate = useNavigate();

  const sectionExists = useMemo(() => sections?.find((sec) => sec.id === sectionId), [sectionId, sections]);

  useEffect(() => {
    if (volumeId && !volume) {
      const redirectionPath = `/dashboard/contracts/details?${searchParams.toString()}`;
      navigate(redirectionPath, { replace: true });
    } else if ((sectionId && !sectionExists) || (!!sections?.length && !sectionId)) {
      const firstParentSection = sections?.find((sec) => !sec.parent_id);
      const defaultSectionPath = firstParentSection?.id ? `sections/${firstParentSection.id}/requirements` : ".";
      const redirectionPath = `${defaultSectionPath}?${searchParams.toString()}`;
      navigate(redirectionPath, { replace: true });
    }
  }, [navigate, searchParams, sectionExists, sectionId, sections, volume, volumeId]);
};

export const useDropdownItems = (directionPath: string, isSubsection: boolean, sectionId?: string) => {
  const { setToast } = useNotification();
  const link = `${window.location.origin}/dashboard/contracts/details/${directionPath}`;
  const dispatch = useAppDispatch();
  const trackUserEvent = useTrackUserMetric();
  const { volumeId } = useParams();
  const { convertToSection, moveSectionToDraft, deleteSection } = useFrameworkOperations();
  const drafts = useStorage((root) => (root.framework as ImmutableStorage["framework"])?.volumes);

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Pencil size={16} /> Edit
          </div>
        ),
        onSelect: () => dispatch(setSectionState({ sectionEditable: sectionId })),
      },
      {
        key: 2,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Link size={16} className="w-4" /> Copy link
          </div>
        ),
        onSelect: () =>
          copyText(link, () => {
            setToast.success({
              msg: "Copied",
            });
            trackUserEvent("Drafts: Section Link Copied", {
              section_id: String(sectionId),
            });
          }),
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Icon name="Swap" tw="w-4" /> Move to
          </div>
        ),
        subs: (
          <DropdownMenu.Sub key={sectionId}>
            <DropdownMenu.SubTrigger className={styles.item}>
              <div className="flex items-center gap-2 text-xs">
                <Icon name="Swap" tw="w-4" />
                Move to
              </div>
              <div className={styles.chevronRight}>
                <ChevronRight size={14} />
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                {drafts.map((draft) => (
                  <DropdownMenu.Item
                    key={draft.id}
                    className={styles.item}
                    onSelect={() => {
                      if (volumeId && sectionId) moveSectionToDraft(volumeId, sectionId, draft.id);
                    }}
                  >
                    {draft.title || <span className="text-slate-400">Draft name...</span>}
                  </DropdownMenu.Item>
                ))}
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
        ),
        onSelect: () => {},
      },
      ...(isSubsection
        ? [
            {
              key: 4,
              label: (
                <div className="flex items-center gap-2 text-xs">
                  <RotateCcwSquare size={16} className="w-4" /> Convert to section
                </div>
              ),
              onSelect: () => volumeId && sectionId && convertToSection(volumeId, sectionId),
            },
          ]
        : []),
      {
        key: 5,
        label: (
          <div className="flex gap-2 text-xs items-center text-red-500">
            <Trash2 size={14} className="w-4" /> Delete
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            proceedLabel: "Delete",
            header: "Are you sure you want to delete this section?",
            body: (
              <div>
                This action is irreversible and will delete all progress associated with the section.
                <br />
                <br />
                Please confirm this action.
              </div>
            ),
          }).then((proceed) => {
            if (proceed && volumeId && sectionId) {
              deleteSection(volumeId, sectionId);
            }
          });
        },
      },
    ],
    [
      drafts,
      isSubsection,
      dispatch,
      sectionId,
      link,
      setToast,
      trackUserEvent,
      volumeId,
      moveSectionToDraft,
      convertToSection,
      deleteSection,
    ]
  );

  return { items };
};

export const usePreview = (draft?: ImmutableVolume) => {
  const { generateRequirementHeading } = useGenerateRequirementHeading();
  const headingsInProgress = useAppSelector((store) => store.previewProposalState.headingsInProgress);
  const initialConfig = useMemo(
    () => ({
      includeSourceDocuments: false,
      sectionFormats:
        draft?.sections?.map((section) => ({
          id: section.id,
          displayOption: DisplayOption.FullRequirement,
          headingStyle: {
            bold: true,
            underline: false,
            italic: false,
          },
        })) || [],
    }),
    [draft?.sections]
  );
  const dispatch = useAppDispatch();
  const { volumeId } = useParams();
  const [previewProposalConfig, setPreviewProposalConfig] = usePersistedStorage<PreviewConfig>(
    `previewProposalConfig-${volumeId}`,
    initialConfig
  );

  const isInSync = useMemo(
    () =>
      draft?.sections.every((section, idx) => {
        const foundIndex = previewProposalConfig?.sectionFormats.findIndex((format) => format.id === section.id);
        return idx === foundIndex;
      }),
    [draft?.sections, previewProposalConfig?.sectionFormats]
  );

  useEffect(() => {
    if (!isInSync) {
      const sections = draft?.sections;

      const newConfigFormats = sections?.reduce<SectionFormat[]>((acc, section) => {
        const foundFormat = previewProposalConfig?.sectionFormats.find((format) => format.id === section.id);

        if (foundFormat) {
          return [...acc, foundFormat];
        }

        return [
          ...acc,
          {
            id: section.id,
            displayOption: DisplayOption.FullRequirement,
            headingStyle: {
              bold: true,
              underline: false,
              italic: false,
            },
          },
        ];
      }, []);

      if (!!previewProposalConfig && !!newConfigFormats?.length)
        setPreviewProposalConfig({ ...previewProposalConfig, sectionFormats: newConfigFormats });
    }
  }, [draft, isInSync, previewProposalConfig, previewProposalConfig?.sectionFormats, setPreviewProposalConfig]);

  const triggerPreview = useMutation(
    ({ storage }) => {
      const immutableRequirements = (
        storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined
      )?.toJSON();
      const validRequirementsWithoutHeading = immutableRequirements?.filter(
        (row) =>
          !!(row.written_content || row.requirement.content || row.requirement.summarized_content) &&
          !headingsInProgress.includes(row.requirement.id) &&
          !row.requirement.skipped &&
          !row.requirement.generated_heading &&
          row.proposal_reference.volume_id === volumeId
      );

      if (!!validRequirementsWithoutHeading?.length) {
        generateRequirementHeading({
          requirement_ids: validRequirementsWithoutHeading.map(({ requirement }) => requirement.id),
        });
      }

      dispatch(setPreviewProposalState({ activeDraftId: volumeId }));
      dispatch(togglePreviewProposalModal({ open: true }));
    },
    [dispatch, volumeId, generateRequirementHeading]
  );

  return {
    triggerPreview,
  };
};
