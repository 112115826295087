/** @jsxImportSource @emotion/react */

import { CopilotPresencePage } from "types/Presence";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { ChevronDown, ChevronUp, LucideIcon } from "lucide-react";

import tw from "twin.macro";

interface Tab {
  name: string;
  slug?: string;
  tabIcon: LucideIcon;
}

interface SubItem<T> {
  label: string;
  to: string;
  isActive?: boolean;
  value?: T;
}

interface SideNavigationNavigationItemProps<T> {
  tab: Tab;
  id: string | undefined;
  navigationIsExpanded: boolean | undefined;
  subItems?: SubItem<T>[];
  currentlyExpandedTab: CopilotPresencePage | string;
  setCurrentlyExpandedTab: Dispatch<SetStateAction<CopilotPresencePage | string>>;
  openLinksInNewTab?: boolean;
  to?: string;
  handleSubItemClick?: (item?: T) => void;
}

const SideNavigationNavigationItem = <T extends { id?: string }>({
  tab,
  id,
  navigationIsExpanded,
  subItems,
  currentlyExpandedTab,
  setCurrentlyExpandedTab,
  openLinksInNewTab,
  to,
  handleSubItemClick,
}: SideNavigationNavigationItemProps<T>) => {
  const [searchParams] = useSearchParams();
  const tabSlug = searchParams.get("tab")?.toLocaleLowerCase() || CopilotPresencePage.Project;
  const [showAllItems, setShowAllItems] = useState(false);

  const firstFiveSubItems = subItems?.slice(0, 5);
  const displayItems = showAllItems ? subItems : firstFiveSubItems;

  const navigationItemIsExpanded = currentlyExpandedTab === tab.name;

  useEffect(() => {
    setShowAllItems(false);
  }, [navigationItemIsExpanded]);

  return (
    <div className="flex flex-col mb-1">
      <div className="flex justify-between">
        <NavLink
          target={openLinksInNewTab ? "_blank" : ""}
          key={tab.slug}
          to={to ? to : `/dashboard/contracts/details?tab=${tab.slug}&id=${id}`}
          className="flex items-center p-2.5 text-md rounded-md hover:bg-hover-default h-[44px] w-[44px]"
          css={[
            tabSlug === tab.slug && tw`bg-[#F6F6F6] text-black`,
            !navigationIsExpanded && tw`justify-center`,
            navigationIsExpanded && tw`min-w-[200px] w-[200px]`,
          ]}
        >
          <tab.tabIcon size={16} />
          {navigationIsExpanded && <div className="ml-2.5 text-sm">{tab.name}</div>}
        </NavLink>
        {navigationIsExpanded && !!subItems && !!subItems.length && (
          <div
            className="relative flex justify-center items-center left-[-30px] top-[10px] p-1 hover:outline hover:outline-1 hover:outline-gray-300 rounded-md cursor-pointer h-6"
            onClick={() => setCurrentlyExpandedTab(navigationItemIsExpanded ? "" : tab.name)}
          >
            {navigationItemIsExpanded ? <ChevronUp size={14} /> : <ChevronDown size={14} />}
          </div>
        )}
      </div>
      {navigationIsExpanded && currentlyExpandedTab === tab.name && !!subItems?.length && (
        <div className="flex flex-col gap-1 border-l-2 mb-1 ml-4 mt-2 border-[#D9D9D9]">
          {displayItems?.map((subItem, idx) => (
            <NavLink
              target={openLinksInNewTab ? "_blank" : ""}
              key={`${subItem.label}_${idx}`}
              to={subItem.to}
              onClick={() => handleSubItemClick?.(subItem.value)}
            >
              <div
                css={[subItem.isActive && tabSlug === tab.slug && tw`bg-[#F6F6F6] text-black`]}
                className="cursor-pointer flex items-center pl-4 p-2 ml-1 rounded-md text-xs text-[#757575] hover:bg-hover-default"
              >
                <div className="truncate">{subItem.label}</div>
              </div>
            </NavLink>
          ))}
          {subItems.length > 5 && !showAllItems && (
            <div
              className="cursor-pointer flex items-center pl-4 p-2 ml-1 rounded-md text-xs text-[#1987EA] hover:bg-hover-default"
              onClick={() => setShowAllItems(!showAllItems)}
            >
              Show {subItems.length - 5} more
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default SideNavigationNavigationItem;
