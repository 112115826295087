import { useRef, useMemo } from "react";
import { clearDraft } from "../CommentsDraftPlugin";
import { useDraft } from "./useDraft";
import { Editor } from "@tiptap/react";
import { CommentEditor } from "./CommentEditor/CommentEditor";
import ClickAwayListener from "helpers/ClickAwayListener";
import { useCommentOperations } from "../../../api/comments/useCommentOperations";
import { v4 } from "uuid";
import { ThreadContext } from "../types";

interface CommentDraftProps {
  editor: Editor;
  internalContractId: string;
  context: ThreadContext;
  referenceId?: string;
  editorContainerRef?: React.RefObject<HTMLDivElement>;
}

export const CommentDraft = ({
  editor,
  internalContractId,
  referenceId,
  context,
  editorContainerRef,
}: CommentDraftProps) => {
  const draft = useDraft(editor);
  const draftContainerRef = useRef<HTMLDivElement>(null);
  const { createCommentMutation } = useCommentOperations(internalContractId, referenceId);

  const top = useMemo(() => {
    if (!draft || !draft.domNode || !(draft.domNode instanceof HTMLElement)) return 0;

    if (!editorContainerRef?.current) return draft.domNode.offsetTop;

    const nodeRect = draft.domNode.getBoundingClientRect().top;
    const containerRect = editorContainerRef.current.getBoundingClientRect().top;
    const scrollTop = editorContainerRef.current.scrollTop;
    return nodeRect - containerRect + scrollTop;
  }, [draft, editorContainerRef]);

  if (!draft) return null;

  return (
    <div className="absolute h-full w-[350px] top-0 right-0">
      <ClickAwayListener
        onClickAway={() => {
          clearDraft(editor);
        }}
      >
        <div
          className={`absolute z-20 flex flex-col items-center bg-white p-2 border border-gray-300 rounded-lg shadow-md min-w-[300px] max-w-[300px]`}
          style={{
            top: `${top}px`,
            right: 15,
          }}
          ref={draftContainerRef}
        >
          <CommentEditor
            readonly={false}
            onAddComment={(content) => {
              createCommentMutation.mutate({
                content,
                editor,
                new_thread_id: v4(),
                new_comment_id: v4(),
                quote_text: draft.quote_text,
                context,
              });
              return true;
            }}
            autofocus
          />
        </div>
      </ClickAwayListener>
    </div>
  );
};
