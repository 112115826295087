import { ComponentProps } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import SectionRequirement from "./SectionRequirement";

const SortableItem = (props: ComponentProps<typeof SectionRequirement>) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.id || "",
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition: transition || undefined,
    };

    return (
        <SectionRequirement
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            dragProps={{ attributes, listeners }}
        />
    );
};

export default SortableItem;
