/** @jsxImportSource @emotion/react */

import { PopoverClose } from "@radix-ui/react-popover";
import Icon from "components/atoms/icons/Icon";
import { useMemo } from "react";
import { SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { SECTION_STATUS_TO_META } from "const-values/Draft";

type Props = {
    selectedStatus: SectionStatus;
    onStatusSelect: (id: SectionStatus) => void;
};

const SectionStatusPopoverContent = ({ selectedStatus, onStatusSelect, ...props }: Props) => {
    const statusOptions = useMemo(() => {
        return (Object.values(SectionStatus).filter((status) => typeof status === "number") as SectionStatus[]).map(
            (status) => ({ status, ...SECTION_STATUS_TO_META[status] })
        );
    }, []);

    return (
        <div className="w-max max-w-[425px] overflow-hidden bg-white" {...props}>
            <div className="flex flex-col max-h-[180px] overflow-y-auto">
                {statusOptions?.map(({ label, status, color }) => (
                    <PopoverClose key={status}>
                        <button
                            onClick={() => onStatusSelect(status)}
                            className="flex items-center justify-between gap-3 w-full py-2 px-2 duration-100 bg-transparent hover:bg-slate-100"
                        >
                            <div className="flex items-center gap-2 line-clamp-1">
                                {SECTION_STATUS_TO_META[status || SectionStatus.Todo].icon}
                                {label}
                            </div>
                            {selectedStatus === status && <Icon name="Check" className="w-5 h-5 min-w-[20px]" />}
                        </button>
                    </PopoverClose>
                ))}
            </div>
        </div>
    );
};

export default SectionStatusPopoverContent;
