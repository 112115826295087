/** @jsxImportSource @emotion/react */

import { RESPONSE_TOLERANCE_OPTIONS } from "components/copilot/ComplianceMatrix/constants";
import { Modal } from "../../organisms/modal";
import tw from "twin.macro";
import { Button } from "components/editor/components";
import { ComponentProps, useEffect, useState } from "react";
import { useAppDispatch } from "store/storeTypes";
import { toggleStrictnessModal } from "store/reducers/modalsSlice";
import { ResponseTolerance } from "types/Requirement";

export interface StrictnessModalProps extends Pick<ComponentProps<typeof Modal>, "open"> {
    initialProps?: Partial<{
        strictness: ResponseTolerance;
        onStrictnessSubmit: (val: ResponseTolerance) => void;
    }>;
}

const StrictnessModal = ({ initialProps, ...props }: StrictnessModalProps) => {
    const { strictness, onStrictnessSubmit } = initialProps || {};
    const dispatch = useAppDispatch();
    const [localStrictness, setLocalStrictness] = useState(strictness || ResponseTolerance.Strict);

    useEffect(() => {
        if (props.open) setLocalStrictness(strictness || ResponseTolerance.Strict);
    }, [props.open, strictness]);

    return (
        <Modal
            header="Sensitivity"
            onOpenChange={(openState) => {
                dispatch(toggleStrictnessModal({ open: openState }));
            }}
            body={
                <div className="flex flex-col gap-2.5 px-4">
                    {RESPONSE_TOLERANCE_OPTIONS.map(({ icon: ResponseIcon, value, text, name }) => (
                        <button
                            key={name}
                            className="rounded-md border border-zinc-300 px-4 py-3 w-[420px] text-left duration-100 hover:bg-action-lightest"
                            css={[
                                value === localStrictness &&
                                    tw`border-action bg-action-lightest [outline-style: solid] outline-[0.5px] outline-action`,
                            ]}
                            onClick={() => setLocalStrictness(value)}
                        >
                            <div className="flex flex-col gap-2">
                                <div
                                    className="flex items-center gap-1"
                                    css={[value === localStrictness && tw`text-action`]}
                                >
                                    <ResponseIcon />
                                    <div className="text-md font-medium">{name}</div>
                                </div>
                                <div className="text-gray-mid text-xs pr-4">{text}</div>
                            </div>
                        </button>
                    ))}
                </div>
            }
            footer={
                <>
                    <Button
                        size="md"
                        variant="outline"
                        className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                        onClick={() => dispatch(toggleStrictnessModal({ open: false }))}
                    >
                        Cancel
                    </Button>
                    <Button
                        size="md"
                        variant="primary"
                        css={[!localStrictness && tw`pointer-events-none`]}
                        disabled={!localStrictness}
                        onClick={() => {
                            dispatch(toggleStrictnessModal({ open: false }));
                            onStrictnessSubmit?.(localStrictness);
                        }}
                    >
                        Apply
                    </Button>
                </>
            }
            {...props}
        />
    );
};

export default StrictnessModal;
