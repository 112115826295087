import { EMPTY_SHEET } from "const-values/Sheets";
import DropdownMenu, { DropdownMenuProps } from "../dropdown-menu";
import tw from "twin.macro";
import { PropsWithChildren, useMemo, useState } from "react";
import { Sheet, Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";
import { Plus } from "lucide-react";
import NewSheetModal from "components/copilot/ComplianceMatrix/sheets-tabs/NewSheetModal";
import { useStorage } from "YJSProvider/createYJSContext";

interface Props extends Omit<DropdownMenuProps<Sheet>, "items" | "multiselect"> {
  selectedSheet?: string;
  onSelect: (value: Sheet) => void;
}

const SheetsDropdown = ({ selectedSheet, onSelect, children, ...props }: PropsWithChildren<Props>) => {
  const [newSheetOpen, setNewSheetOpen] = useState(false);
  const shouldShowDefault = useStorage((storage) => {
    const defaultSheetExists = (storage.sheets || []).some((sheet) => sheet.name === EMPTY_SHEET.name);
    return !defaultSheetExists && storage.compliance_matrix.some((row) => !row.requirement?.extraction_id);
  });
  const sheets = useStorage((storage) => [
    ...(shouldShowDefault ? [EMPTY_SHEET] : []),
    ...((storage.sheets as ImmutableStorage["sheets"]) || []),
  ]);
  const availableSheetOptions = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex items-center gap-1.5 text-action">
            <Plus size={14} />
            New sheet
          </div>
        ),
        onSelect: () => setNewSheetOpen(true),
      },
      ...(sheets || [])?.map((sheet) => ({
        key: sheet.id,
        label: sheet.name,
        value: sheet.id,
        selected: selectedSheet === sheet.id,
        onSelect: () => onSelect(sheet),
      })),
    ],
    [onSelect, selectedSheet, sheets]
  );

  return (
    <>
      <DropdownMenu label="Select Sheet" triggerProps={{ css: tw`w-fit` }} items={availableSheetOptions} {...props}>
        {children}
      </DropdownMenu>
      <NewSheetModal sheets={sheets} open={newSheetOpen} onOpenChange={setNewSheetOpen} />
    </>
  );
};

export default SheetsDropdown;
