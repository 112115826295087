/** @jsxImportSource @emotion/react */

import { EXTRACTING_LOADING_MSGS } from "../document-display/constants";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useAppSelector } from "store/storeTypes";
import { useState } from "react";

import GenerationLottie from "./generation-lottie.json";
import Lottie from "lottie-react";

const LoadingDisplay = () => {
  const [loadingStep, setLoadingStep] = useState(EXTRACTING_LOADING_MSGS[0]);
  const isExtractingDocument = useAppSelector((state) => state.currentExtractionState.isExtractingDocument);
  const timeRemaining = useAppSelector((state) => state.currentExtractionState.timeRemaining);

  useAnimateLoadingMsg(isExtractingDocument, 15000, EXTRACTING_LOADING_MSGS, setLoadingStep);

  return (
    <>
      <div className="h-full w-full flex flex-col justify-center items-center px-8">
        <Lottie className="h-[400px] w-[400px]" animationData={GenerationLottie} loop />
        <div className="text-2xl font-semibold mb-4 mr-4">
          {loadingStep}
          <span className="loading-ellipsis" />
        </div>
        <div className="text-sm text-gray-500">Grab some water and take a moment to unwind.</div>
        {!!timeRemaining ? (
          <div className="text-sm text-gray-500">
            Preparing this document will take approximately {timeRemaining}{" "}
            {timeRemaining && timeRemaining > 1 ? "minutes" : "minute"}.
          </div>
        ) : (
          <div className="text-sm text-gray-500">
            This document is queued for processing and will begin preparing shortly.
          </div>
        )}
        <br />
        <div className="text-sm text-gray-500">You can leave the page and come back later.</div>
      </div>
    </>
  );
};

export default LoadingDisplay;
