/** @jsxImportSource @emotion/react */

import { useAppSelector } from "store/storeTypes";
import TemplateManager from "./template-manager";
import { StepValue } from "../types";
import RequirementsFilter from "./requirements-filter";

const DocumentSidebar = () => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);

  if (!extraction) return null;

  return (
    <div className="flex flex-col h-full">
      {extraction?.step === StepValue.Requirements && <RequirementsFilter />}
      {extraction?.step === StepValue.Assign && <TemplateManager />}
    </div>
  );
};

export default DocumentSidebar;
