import { SPEED_TO_META } from "components/molecules/response-quality-dropdown/constants";
import { ResponseSpeed } from "components/molecules/response-quality-dropdown/types";

type Props = {
  onGenerationModeSelect: (tolerance: ResponseSpeed) => void;
};

const GenerationModePopoverContent = ({ onGenerationModeSelect, ...props }: Props) => {
  return (
    <div className="w-max max-w-[425px] overflow-hidden bg-white" {...props}>
      <div className="flex flex-col max-h-[180px] overflow-y-auto">
        {Object.values(SPEED_TO_META).map(({ icon: ResponseIcon, value, label }) => (
          <button
            key={label}
            className="rounded-md px-2 py-3 text-left duration-100 hover:bg-action-lightest"
            onClick={() => onGenerationModeSelect(value)}
          >
            <div className="flex gap-2 items-center">
              <ResponseIcon size={14} />
              {label}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default GenerationModePopoverContent;
