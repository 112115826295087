import { TemplateExtractionModal } from "components/modals/template-extraction/template-extraction-modal";
import TemplatesModal from "../Framework/TemplatesModal";
import { TemplateExtractionFromRequirementsModal } from "components/modals/template-extraction/template-extraction-from-requirements-modal";
import { useAppSelector } from "store/storeTypes";
import { RequirementExtractionModal } from "components/organisms/requirement-extraction-modal";
import WritingPromptIdeationModal from "components/modals/writing-prompt-ideation-modal/WritingPromptIdeationModal";
import StrictnessModal from "components/modals/strictness-modal";
import EvaluationModal from "../ComplianceMatrix/EvaluationModal";
import PreviewProposalModal from "components/modals/preview-proposal-modal/PreviewProposalModal";
import { memo } from "react";
import SaveTemplateModal from "components/modals/save-template-modal";

const CopilotModals = () => {
  const {
    templateExtractionModal,
    requirementExtractionModal,
    templateExtractionFromRequirementsModal,
    templatesModal,
    writingPromptIdeationModal,
    strictnessModal,
    evaluationModal,
    previewProposalModal,
    saveTemplateModal,
  } = useAppSelector((store) => store.modals);

  return (
    <>
      <WritingPromptIdeationModal {...writingPromptIdeationModal} />
      <TemplatesModal {...templatesModal} />
      <SaveTemplateModal {...saveTemplateModal} />
      <TemplateExtractionModal {...templateExtractionModal} />
      <TemplateExtractionFromRequirementsModal {...templateExtractionFromRequirementsModal} />
      <RequirementExtractionModal {...requirementExtractionModal} />
      <StrictnessModal {...strictnessModal} />
      <EvaluationModal {...evaluationModal} />
      <PreviewProposalModal {...previewProposalModal} />
    </>
  );
};

export default memo(CopilotModals);
