import * as RadixDropdownMenu from "@radix-ui/react-dropdown-menu";
import classNames from "classnames";
import { ComponentProps } from "react";

interface DropdownMenuLabelProps extends ComponentProps<typeof RadixDropdownMenu.Label> {
  label: string;
}

const DropdownMenuLabel = ({ key, label, className }: DropdownMenuLabelProps) => {
  return (
    <RadixDropdownMenu.Label
      key={key}
      className={classNames(["mb-1 pl-1 pb-1 pt-2.5 text-xs text-gray-light", className])}
    >
      {label}
    </RadixDropdownMenu.Label>
  );
};

export default DropdownMenuLabel;
