import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { discoverWorkspace, getWorkspaceMembers } from "api/api";
import { User, WorkspaceMember } from "types/User";
import { MyWorkspaces, Workspace } from "types/Workspace";
import { colorList } from "utils/colors";
export const getWorkspaceUsers = createAsyncThunk("userAuthSlice/getWorkspaceUsers", async () => {
  // let userToken = localStorage.getItem("vultron_user_token");
  // userToken = JSON.parse(userToken);
  // let workspace_id = localStorage.getItem("vultron_workspace_id");
  // workspace_id = JSON.parse(workspace_id);
  return getWorkspaceMembers().then((res) => {
    let data: User[] = res.data;
    return data;
  });
});

export const getWorkSpaces = createAsyncThunk<MyWorkspaces>(
  "userAuthSlice/getWorkSpaces",
  async (token, { dispatch }) => {
    return discoverWorkspace()
      .then((res) => {
        let workspace_id = localStorage.getItem("vultron_workspace_id");
        if (workspace_id) {
          workspace_id = JSON.parse(workspace_id);
          dispatch(getWorkspaceUsers());
        }
        let data = res.data;
        return data;
      })
      .catch((e) => {
        if (e?.response?.status === 403) {
          localStorage.removeItem("vultron_workspace_id");
          localStorage.removeItem("vultron_user_token");
          localStorage.removeItem("vultron_user_sso");
          // @ts-ignore
          window?.Intercom("shutdown");
          dispatch(resetAuth());
          window.location.href = "/auth";
        }
      });
  }
);

type InitialState = {
  myWorkSpaces: Partial<MyWorkspaces>;
  isLoading: boolean;
  isAuthenticated: boolean;
  isLoadingWorkSpaces: boolean;
  workspaceMembers: WorkspaceMember[];
  isLoadingMembers: boolean;
  currentUser?: Partial<User>;
  currentWorkspace: Workspace | {};
};

const initialState: InitialState = {
  myWorkSpaces: {},
  isLoading: true,
  isAuthenticated: false,
  isLoadingWorkSpaces: false,
  workspaceMembers: [],
  isLoadingMembers: false,
  currentUser: {},
  currentWorkspace: {},
};

export const authReducerSlice = createSlice({
  name: "userAuthSlice",
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
      state.isLoading = action.payload.isLoading;
    },
    setMyWorkSpaces: (state, action) => {
      state.myWorkSpaces = action.payload.data;
      state.isLoadingWorkSpaces = action.payload.loading;
    },
    resetAuth: (state) => {
      state.myWorkSpaces = {};
      state.isLoadingWorkSpaces = false;
      state.isAuthenticated = false;
      state.isLoading = false;
      state.workspaceMembers = [];
      state.isLoadingMembers = false;
      state.currentUser = {};
    },
    setWorkSpacesMembers: (state, action) => {
      state.workspaceMembers = action.payload.data;
      state.isLoadingMembers = action.payload.loading;
    },
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkSpaces.pending, (state) => {
        // state.myWorkSpaces = {};
        state.isLoadingWorkSpaces = true;
      })
      .addCase(getWorkSpaces.fulfilled, (state, action) => {
        state.myWorkSpaces = action.payload || {};
        state.isLoadingWorkSpaces = false;

        try {
          const workspaceId = JSON.parse(localStorage.getItem("vultron_workspace_id") || "");

          const currentWorkspace = action.payload?.workspaces?.find((workspace) => workspace?.id === workspaceId) || {};
          state.currentWorkspace = currentWorkspace;
        } catch {}
      })
      .addCase(getWorkSpaces.rejected, (state) => {
        // state.myWorkSpaces = {};
        state.isLoadingWorkSpaces = false;
        state.currentWorkspace = {};
      });
    //
    builder
      .addCase(getWorkspaceUsers.pending, (state) => {
        // state.workspaceMembers = [];
        state.isLoadingMembers = true;
        // state.currentUser = {}
      })
      .addCase(getWorkspaceUsers.fulfilled, (state, action) => {
        const usersArray = action.payload || [];
        const updatedUsersArray = usersArray.map((user, ind) => ({
          ...user,
          color: colorList[ind].bg,
        }));
        state.workspaceMembers = updatedUsersArray;
        state.isLoadingMembers = false;
        let list = action.payload || [];
        state.currentUser = list.find((l) => l.email === state.myWorkSpaces.email);
      })
      .addCase(getWorkspaceUsers.rejected, (state) => {
        // state.workspaceMembers = [];
        state.isLoadingMembers = false;
        // state.currentUser = {}
      });
  },
});

export const { setAuth, setMyWorkSpaces, resetAuth, setWorkSpacesMembers, setCurrentUser } = authReducerSlice.actions;

export default authReducerSlice.reducer;
