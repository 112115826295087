import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { useMemo } from "react";
import { ArchiveRestore, Pencil, Trash2 } from "lucide-react";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useNavigate } from "react-router-dom";
import { setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";

export const useRowActions = (session: ToImmutable<AIAssistantSession>): MenuItem<void>[] => {
  const { deleteSession } = useAIAssistantOperations();
  const activeSession = useAppSelector((store) => store.aiAssistantState.activeSession);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  return useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex flex-row items-center gap-2">
            <Pencil size={14} />
            Rename
          </div>
        ),
        onSelect: () => dispatch(setAiAssistantState({ sessionEditable: session.id })),
      },
      {
        key: 2,
        label: (
          <div className="text-red-500 flex flex-row items-center gap-2">
            <Trash2 size={14} />
            Delete
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            variant: "error",
            proceedLabel: "Delete",
            header: "Delete chat?",
            body: (
              <div>
                This will delete <span className="font-semibold">{session.name}</span>
              </div>
            ),
          }).then((proceed) => {
            if (proceed) {
              deleteSession(session.id);
              if (session.id === activeSession?.id) navigate("/dashboard/ai-assistant");
            }
          });
        },
      },
    ],
    [activeSession?.id, deleteSession, dispatch, navigate, session.id, session.name]
  );
};

export const useArchivedRowActions = (session: ToImmutable<AIAssistantSession>): MenuItem<void>[] => {
  const { deleteArchivedSession, unarchiveSession } = useAIAssistantOperations();

  return useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex flex-row items-center gap-2">
            <ArchiveRestore size={14} />
            Unarchive
          </div>
        ),
        onSelect: () => unarchiveSession(session.id),
      },
      {
        key: 2,
        label: (
          <div className="text-red-500 flex flex-row items-center gap-2">
            <Trash2 size={14} />
            Delete
          </div>
        ),
        onSelect: () => {
          triggerConfirm({
            variant: "error",
            proceedLabel: "Delete",
            header: "Delete chat?",
            body: (
              <div>
                This will delete <span className="font-semibold">{session.name}</span>
              </div>
            ),
          }).then((proceed) => {
            if (proceed) {
              deleteArchivedSession(session.id);
            }
          });
        },
      },
    ],
    [deleteArchivedSession, session.id, session.name, unarchiveSession]
  );
};
