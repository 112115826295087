import { nanoid } from "nanoid";
import { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const createAIAssistantSession = (properties?: Partial<AIAssistantSession>): LiveObject<AIAssistantSession> => {
    const newID = nanoid();
    return new LiveObject({
        id: newID,
        name: "",
        updated_at: new Date().toISOString(),
        conversation: new LiveList([]),
        ...properties,
    });
};
