/** @jsxImportSource @emotion/react */

import {
  ComplianceMatrixRow as ImmutableComplianceMatrixRow,
  Storage as ImmutableStorage,
  Volume as ImmutableVolume,
} from "components/copilot/CopilotSchemaImmutableTypes";
import Tooltip from "components/atoms/tooltip";
import Popover from "components/atoms/popover";
import RequirementStatusPopoverContent from "../requirement-status-popover-content";
import { REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import AssigneesPopoverContent from "../assignees-popover-content";
import tw from "twin.macro";
import { isEqual, xor } from "lodash";
import { AvatarGroup } from "components/molecules/avatar-group";
import { Avatar } from "components/molecules/avatar";
import RequirementOptionsDropdown from "components/molecules/requirement-options-dropdown";
import EditableContent from "components/molecules/editable-content";
import WritingPrompts from "pages/draft-section/draft-section-requirements/writing-prompts";
import UserInstructions from "pages/draft-section/draft-section-requirements/user-instructions";
import RequirementResponse from "../requirement-response";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import useRequirementOperations from "hook/useRequirementOperations";
import useGetAvatarGroup from "hook/draft/useAvatarGroup";
import { useMemo, useState } from "react";
import { RequirementStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import { useParams, useSearchParams } from "react-router-dom";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import Icon from "components/atoms/icons/Icon";
import { useStorage } from "YJSProvider/createYJSContext";
import GenerateResponseActions from "pages/draft-section/draft-section-requirements/GenerateResponseActions";
import { EllipsisVertical, Minimize2, WandSparkles } from "lucide-react";

type Props = {
  requirementId: string;
};

const FloatingRequirement = ({ requirementId }: Props) => {
  const { volumeId } = useParams();
  const [searchParams] = useSearchParams();
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false);
  const complianceMatrixRow = useStorage<ImmutableComplianceMatrixRow | undefined>(
    (storage) =>
      (storage.compliance_matrix as ImmutableStorage["compliance_matrix"]).find(
        (row) => row.requirement.id === requirementId
      ),
    isEqual
  );
  const volume = useStorage<ImmutableVolume | undefined>(
    (storage) => (storage.framework as ImmutableStorage["framework"]).volumes.find((draft) => draft.id === volumeId),
    isEqual
  );
  const {
    requirement,
    auto_response_actor,
    is_response_generating,
    is_response_in_queue,
    requirement_status,
    assigned_user_ids,
    locked,
  } = complianceMatrixRow || {};
  const { workspaceMembers } = useAppSelector((store) => store.auth);
  const actionsPanelOpen = useAppSelector((store) => store.copilotDrawer.open);
  const sidebarSize = useAppSelector((store) => store.sectionState.sidebarSize);
  const { setAssignees, setRequirementStatus, setRequirementContent } = useRequirementOperations();
  const avatars = useGetAvatarGroup(assigned_user_ids, { size: 18, className: "!text-xs" });
  const dispatch = useAppDispatch();
  const content = requirement?.content || requirement?.summarized_content || "";

  const sortedSections = useMemo(() => {
    return (
      volume?.sections?.reduce<FormattedSection[]>((acc, section) => {
        if (!section.parent_id) {
          const subsections = volume?.sections?.filter(({ parent_id }) => parent_id === section.id);
          return [...acc, { ...section, subsections }];
        }

        return acc;
      }, []) || []
    );
  }, [volume?.sections]);

  const generatedOrQueuedBy = useMemo(
    () => workspaceMembers.find((member) => member.id === auto_response_actor)?.username,
    [auto_response_actor, workspaceMembers]
  );

  const internalContractId = searchParams.get("id");
  const requirementContent = requirement?.content || requirement?.summarized_content || "";

  const canGenerate = !!internalContractId && !!requirementContent && !locked;

  if (!complianceMatrixRow || !requirement) {
    return null;
  }

  return (
    <div className="z-[4] absolute top-0 right-0 left-0 bottom-0 bg-white">
      <div
        className="flex flex-row items-baseline gap-1.5 p-5 h-full overflow-y-auto cursor-default"
        style={{ animation: "200ms fade-in forwards" }}
      >
        <div className="flex-1">
          <div className="flex shadow-sharp-thin flex-col gap-2 p-2 pb-3 bg-white border border-gray-light rounded-md flex-1">
            <div className="flex items-center justify-between">
              <div className="flex gap-1 items-center justify-between">
                <div className="text-xs pl-1 font-medium text-slate-600 text-start mr-1">Requirement</div>
                {(is_response_generating || is_response_in_queue) && (
                  <Tooltip
                    disabled={!generatedOrQueuedBy}
                    disableHoverableContent
                    content={
                      <div>
                        {is_response_generating ? "Generated by " : "Queued by "}{" "}
                        <span className="font-semibold">{generatedOrQueuedBy}</span>
                      </div>
                    }
                  >
                    <Icon
                      name={is_response_generating ? "Generate" : "NoteQueue"}
                      className="px-1 min-w-5 min-h-4 h-4 w-5"
                      css={[
                        is_response_generating && {
                          animation: "rotateAnimation 0.5s infinite linear",
                        },
                        !is_response_generating && tw`text-amber-400 px-0 min-h-[18px] min-w-[22px]`,
                      ]}
                    />
                  </Tooltip>
                )}
              </div>
              <div className="flex items-center gap-1.5">
                <Popover
                  contentProps={{ align: "end", css: tw`mx-0` }}
                  content={
                    <RequirementStatusPopoverContent
                      selectedStatus={requirement_status || RequirementStatus.Todo}
                      onStatusSelect={(newStatus) => {
                        setRequirementStatus(requirement.id, newStatus);
                      }}
                      tw="p-1"
                    />
                  }
                >
                  <button className="z-[1] h-7 flex items-center max-w-full text-gray-700 py-1 px-1.5 rounded bg-slate-100 duration-150 hover:bg-slate-200">
                    <div className="flex text-xs items-center gap-1.5 text-gray-500 truncate">
                      {REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo].smallerIcon}
                      {REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo].label}
                    </div>
                  </button>
                </Popover>
                <Popover
                  contentProps={{ align: "end", css: tw`mx-0` }}
                  content={
                    <AssigneesPopoverContent
                      selectedUsers={assigned_user_ids || []}
                      onUserSelect={(userId) => {
                        const newAssignees = xor(assigned_user_ids || [], [userId]);
                        setAssignees(requirement.id, newAssignees);
                      }}
                      onClearAll={() => setAssignees(requirement.id, [])}
                      tw="p-1"
                    />
                  }
                >
                  <button className="z-[1] h-7 max-w-full flex items-center text-gray-700 py-1 px-1.5 rounded bg-slate-100 duration-150 hover:bg-slate-200">
                    {!!avatars.length ? (
                      <div className="flex text-xs items-center gap-2 text-gray-500">
                        <AvatarGroup maxCount={3} size={18} avatars={avatars} />
                        <span className="max-w-[125px] truncate">{`${avatars[0].id} ${
                          avatars.length > 1 ? `(+${avatars.length - 1})` : ""
                        }`}</span>
                      </div>
                    ) : (
                      <span className="text-xs text-slate-500">
                        <Avatar size={18} empty />
                      </span>
                    )}
                  </button>
                </Popover>
                <button
                  className="text-lg h-7 w-7 flex justify-center items-center rounded bg-transparent font-medium text-slate-600 text-start hover:bg-slate-100"
                  onClick={() => dispatch(setRequirementsState({ requirementRouted: "" }))}
                >
                  <Minimize2 size={18} />
                </button>
                <RequirementOptionsDropdown complianceMatrixRow={complianceMatrixRow} sections={sortedSections || []}>
                  <div className="p-1 pl-0 text-base text-slate-500 duration-100 z-[1] hover:text-black">
                    <EllipsisVertical size={14} />
                  </div>
                </RequirementOptionsDropdown>
              </div>
            </div>
            <EditableContent
              content={content || ""}
              onMouseDown={() => dispatch(setRequirementsState({ expandedRequirementIds: [requirement.id] }))}
              onContentUpdate={(val) => setRequirementContent(requirement?.id, val)}
              css={tw`text-sm p-1.5 flex-1`}
              textareaProps={{
                placeholder: "Add requirement content...",
                ...(sidebarSize && {
                  forceResetProps: [sidebarSize, actionsPanelOpen],
                  forceResetDelay: 150,
                }),
              }}
            />
            <div className="flex flex-col gap-4">
              <div className="flex flex-col gap-3 border border-light rounded-md bg-slate-50 px-4 py-3 mx-1.5">
                <div className="flex items-center justify-between">
                  <div className="text-xs font-semibold"> Generate Response</div>
                  <div className="flex items-center justify-end">
                    <GenerateResponseActions
                      showSelectFileButton
                      row={complianceMatrixRow}
                      disabled={!canGenerate || showAdvancedSettings}
                    />
                    <div className="bg-slate-300 w-[1px] mx-2 h-6" />
                    <button
                      className="flex items-center gap-1 bg-slate-200 text-gray-mid rounded border-[1px] border-transparent shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-gray-mid disabled:hover:bg-slate-200"
                      onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}
                      css={[showAdvancedSettings && tw`border-slate-500`]}
                    >
                      <WandSparkles size={12} />
                      Advanced
                    </button>
                  </div>
                </div>
                {showAdvancedSettings && (
                  <>
                    <WritingPrompts row={complianceMatrixRow} />
                    <UserInstructions row={complianceMatrixRow} />
                    <GenerateResponseActions
                      showSpeedDropdown
                      showStrictnessDropdown
                      row={complianceMatrixRow}
                      disabled={!canGenerate}
                    />
                  </>
                )}
              </div>
              <RequirementResponse complianceMatrixRow={complianceMatrixRow} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloatingRequirement;
