/** @jsxImportSource @emotion/react */

import { useEffect, useMemo } from "react";
import { DEFAULT_FILTERS, REQUIREMENT_SECTION_TO_META, RequirementSection } from "./constants";
import { Checkbox } from "components/atoms/checkbox";
import Input from "components/molecules/input";
import { CircleCheck, Search, X } from "lucide-react";
import tw from "twin.macro";
import { Button } from "components/editor/components";
import { xor } from "lodash";
import { useGetDynamicSections, useRequirementFilters } from "./hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import useExtractionOperations from "hook/useExtractionOperations";
import { useDocViewNotification } from "components/copilot/extract-v2/context/doc-view-notification-context/docViewNotificationContext";
import {
  setFilteredRequirements,
  setHighlightedElementId,
  setRequirementFilters,
} from "store/reducers/extract/CurrentExtractionReducer";
import { SolicitationType } from "components/copilot/CopilotSchemaTypes";
import { Skeleton } from "components/molecules/skeleton";

const RequirementsFilter = () => {
  const complianceMatrix = useAppSelector((store) => store.currentExtractionState.currentExtraction?.compliance_matrix);
  const filteredRequirements = useAppSelector((store) => store.currentExtractionState.filteredRequirements);
  const extractionId = useAppSelector((store) => store.currentExtractionState.currentExtraction?.id);
  const solicitationType = useAppSelector((store) => {
    const currentExtraction = store.currentExtractionState.currentExtraction;
    return currentExtraction?.solicitation_type || currentExtraction?.soliciation_type;
  });
  const isDynamicSectionsActivated =
    solicitationType === SolicitationType.RequestForInformation || solicitationType === SolicitationType.SourcesSought;
  const { setBulkExtractionRequirementsSkipped } = useExtractionOperations();
  const dispatch = useAppDispatch();
  const { isLoading, filters, isOpen, setIsOpen, query, setQuery } = useRequirementFilters(isDynamicSectionsActivated);
  const { setToast } = useDocViewNotification();
  const { dynamicSections, isLoadingDynamicSections } = useGetDynamicSections(isDynamicSectionsActivated, extractionId);

  useEffect(
    () => () => {
      dispatch(setFilteredRequirements([]));
      setIsOpen(false);
    },
    [dispatch, setIsOpen]
  );
  const sections = useMemo(
    () => (isDynamicSectionsActivated ? dynamicSections : Object.values(RequirementSection)),
    [dynamicSections, isDynamicSectionsActivated]
  );

  const selectedRequirements = useMemo(() => {
    const addedMergedRequirementsSet = new Set();

    return (
      complianceMatrix
        ?.filter((row) =>
          filteredRequirements.some((filteredRequirement) => {
            const isValidMergedRequirement =
              filteredRequirement.group_id === row.requirement.id &&
              !addedMergedRequirementsSet.has(filteredRequirement.group_id);

            const isValid =
              !row.requirement.soft_deleted &&
              (filteredRequirement.id === row.requirement.id || isValidMergedRequirement) &&
              !row.requirement.skipped;

            if (isValid && isValidMergedRequirement) {
              addedMergedRequirementsSet.add(filteredRequirement.group_id);
            }

            return isValid;
          })
        )
        ?.map(({ requirement }) => requirement.id) || []
    );
  }, [complianceMatrix, filteredRequirements]);
  const notSelectedRequirements = useMemo(() => {
    const addedMergedRequirementsSet = new Set();

    return (
      complianceMatrix
        ?.filter((row) =>
          filteredRequirements.some((filteredRequirement) => {
            const isValidMergedRequirement =
              filteredRequirement.group_id === row.requirement.id &&
              !addedMergedRequirementsSet.has(filteredRequirement.group_id);

            const isValid =
              !row.requirement.soft_deleted &&
              (filteredRequirement.id === row.requirement.id || isValidMergedRequirement) &&
              !!row.requirement.skipped;

            if (isValid && isValidMergedRequirement) addedMergedRequirementsSet.add(filteredRequirement.group_id);

            return isValid;
          })
        )
        ?.map(({ requirement }) => requirement.id) || []
    );
  }, [complianceMatrix, filteredRequirements]);

  return (
    <>
      <div className="border-b border-gray-light p-3 font-semibold text-base h-[55px] flex items-center">Search</div>
      <div className="flex flex-1 h-full flex-col overflow-hidden">
        <div className="flex flex-col flex-1 overflow-y-auto" css={[isOpen && tw`pb-[144px]`]}>
          <div className="flex flex-col gap-2 p-5 pr-6">
            <label className="font-semibold text-gray-darkest text-sm">Keywords</label>
            <div className="relative flex items-center flex-1">
              <Search size={14} className="absolute text-gray-400 ml-2 mr-1.5" />
              <Input
                value={query}
                onChange={(e) => setQuery(e.target.value)}
                placeholder="Separate keywords with commas"
                styles={tw`pl-[30px] flex-1`}
              />
            </div>
          </div>
          <hr className="mx-5" />
          <div className="flex flex-col gap-4 p-5 pr-6">
            <label className="font-semibold text-gray-darkest text-sm">Sections</label>
            <div className="flex flex-col gap-3">
              {isLoadingDynamicSections &&
                new Array(7).fill(0).map((_, idx) => (
                  <div key={idx} className="flex justify-between items-center text-slate-500 text-sm">
                    <Skeleton borderRadius={4} height={20} width={174} />
                    <Checkbox checkedStyles={tw`bg-gray-darkest !border-gray-darkest`} disabled onCheck={() => {}} />
                  </div>
                ))}
              {!isLoadingDynamicSections && !sections.length && (
                <div className="text-gray-500 text-sm">No sections found</div>
              )}
              {!isLoadingDynamicSections &&
                sections.map((section) => (
                  <div key={section} className="flex justify-between items-center text-slate-500 text-sm">
                    {isDynamicSectionsActivated
                      ? section
                      : REQUIREMENT_SECTION_TO_META[section as RequirementSection].label}
                    <Checkbox
                      checkedStyles={tw`bg-gray-darkest !border-gray-darkest`}
                      checked={filters.sections.includes(section)}
                      onCheck={() => {
                        dispatch(setRequirementFilters({ ...filters, sections: xor(filters.sections, [section]) }));
                      }}
                    />
                  </div>
                ))}
            </div>
          </div>
          {/* <hr className="mx-5" />
        <div className="flex flex-col gap-4 p-5 pr-6">
          <label className="font-semibold text-gray-darkest text-sm">Type</label>
          <div className="flex flex-col gap-3">
            {types.map((type) => (
              <div key={type} className="flex justify-between items-center text-slate-500 text-sm">
                {REQUIREMENT_TYPE_TO_META[type].label}
                <Checkbox
                  checked={filters.types.includes(type)}
                  onCheck={() => {
                    setFilters((prev) => ({ ...prev, types: xor(prev.types, [type]) }))
                  }}
                />
              </div>
            ))}
          </div>
        </div> */}
        </div>
        <div className="relative">
          <div
            className="flex flex-col gap-3 absolute duration-150 transition-transform translate-y-0 p-5 bottom-full bg-gray-darkest rounded-t left-0 right-0 shadow-[0px_-1px_14px_0px_rgba(0,0,0,0.3)] text-white"
            css={[!isOpen && tw`translate-y-[130%]`]}
          >
            <button
              className="absolute top-4 right-4 p-1 rounded duration-100 hover:bg-zinc-700"
              onClick={() => {
                setIsOpen(false);
                dispatch(setHighlightedElementId(""));
              }}
            >
              <X size={14} />
            </button>
            <label className="font-semibold text-base">
              {notSelectedRequirements.length + selectedRequirements.length} Matched
            </label>
            <div className="flex flex-col gap-3">
              <div className="flex items-center justify-between">
                <div className="text-sm text-white">
                  Selected: <span className="font-semibold">{selectedRequirements.length}</span>
                </div>
                <button
                  onClick={() => {
                    // TODO: don't unselect reqs that are merged
                    if (extractionId) setBulkExtractionRequirementsSkipped(extractionId, selectedRequirements, true);
                  }}
                  className="w-[90px] flex items-center justify-center text-gray-100 bg-zinc-600 h-7 rounded-md px-1 py-2 text-xs font-medium duration-100 hover:text-white hover:bg-zinc-700 disabled:bg-zinc-700 disabled:text-gray-500"
                  disabled={isLoading || !selectedRequirements.length}
                >
                  Deselect All
                </button>
              </div>
              <div className="flex items-center justify-between">
                <div className="text-sm text-white">
                  Not Selected: <span className="font-semibold">{notSelectedRequirements.length}</span>
                </div>
                <button
                  onClick={() => {
                    // TODO: don't select reqs that are merged
                    if (extractionId)
                      setBulkExtractionRequirementsSkipped(extractionId, notSelectedRequirements, false);
                    setToast.success({
                      msg: (
                        <div className="flex flex-row gap-2 items-center text-sm text-white">
                          <CircleCheck size={14} />
                          <span>
                            {notSelectedRequirements.length} requirement{notSelectedRequirements.length !== 1 && "s"}{" "}
                            added.
                          </span>
                        </div>
                      ),
                    });
                  }}
                  className="w-[90px] flex items-center justify-center text-gray-100 bg-zinc-600 h-7 rounded-md px-1 py-2 text-xs font-medium duration-100 hover:text-white hover:bg-zinc-700 disabled:bg-zinc-700 disabled:text-gray-500"
                  disabled={isLoading || !notSelectedRequirements.length}
                >
                  Select All
                </button>
              </div>
            </div>
          </div>
          <div className="relative bg-white px-5 py-3 border-t border-gray-light flex justify-between items-center">
            <button
              onClick={() => {
                dispatch(setRequirementFilters(DEFAULT_FILTERS));

                if (filteredRequirements.length) dispatch(setFilteredRequirements([]));
                dispatch(setHighlightedElementId(""));
                setIsOpen(false);
              }}
              className="text-sm font-medium text-gray-500 hover:text-gray-700 hover:underline"
            >
              Clear
            </button>
            <Button
              className="!bg-gray-darkest hover:!bg-zinc-700"
              disabled={isLoading}
              loading={isLoading}
              onClick={() => {
                setIsOpen(true);
                dispatch(setHighlightedElementId(filteredRequirements[0]?.element_id || ""));
              }}
              size="sm/md"
              variant="primary"
            >
              View {notSelectedRequirements.length + selectedRequirements.length} requirements
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default RequirementsFilter;
