import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export enum AssistantBlockType {
  CoreBlock = 1,
  VultronBlock,
}

export type BlockSource = {
  name: string;
  extension_type: string;
  content: string;
  reference_id: string;
  date: string;
  citation_reference?: string;
};

export type BaseBlock = {
  id: string;
  updated_at: string;
  body: string;
};

export type CoreBlock = {
  type: AssistantBlockType.CoreBlock;
  sources?: LiveList<LiveObject<{ id: string; name: string; extension_type: string }>>;
} & BaseBlock;

export type VultronBlock = {
  type: AssistantBlockType.VultronBlock;
  prompt?: string;
  promptSources?: string[];
  sources: LiveList<LiveObject<BlockSource>>;
  error?: boolean;
  enableInternet?: boolean;
} & BaseBlock;

export type AIAssistantBlock = CoreBlock | VultronBlock;

export type AIAssistantSession = {
  id: string;
  name: string;
  updated_at?: string;
  conversation: LiveList<LiveObject<AIAssistantBlock>>;
};

export type AIAssistant = {
  sessions: LiveList<LiveObject<AIAssistantSession>>;
  archived_sessions: LiveList<LiveObject<AIAssistantSession>>;
};

export type Storage = {
  ai_assistant: LiveObject<AIAssistant>;
};

export type UserMeta = {
  presence: {};
  info: {
    id: string;
  };
};
