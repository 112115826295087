/** @jsxImportSource @emotion/react */

import { DraggableAttributes } from "@dnd-kit/core";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { ArrowUpDown, ChevronRight, EllipsisVertical } from "lucide-react";
import { StepValue } from "../../../types";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { useSectionDropdownItems } from "../hooks";
import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { HTMLAttributes, forwardRef, memo, useState } from "react";
import {
  setActiveSection,
  setEditableTemplateRowState,
  setSectionToMove,
  setTemplateOpenState,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";

import Chip from "components/atoms/chip";
import Icon from "components/atoms/icons/Icon";
import RequirementList from "../RequirementList";
import tw, { theme } from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import * as Accordion from "@radix-ui/react-accordion";
import Tooltip from "components/atoms/tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
  volumeId: string;
  groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>>;
  section: FormattedSection;
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const SubsectionRow = forwardRef<HTMLDivElement, Props>(
  ({ dragProps, isDragging, withOpacity, style, volumeId, groupedComplianceMatrix, section }, ref) => {
    const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
    const requirements = groupedComplianceMatrix[section.id] || [];
    const hasRequirements = !!requirements.length;
    const { setSectionName } = useExtractionOperations();
    const dispatch = useAppDispatch();
    const activeSection = useAppSelector((store) => store.currentExtractionState.activeSection);
    const editableTemplateRowState = useAppSelector((store) => store.currentExtractionState.editableTemplateRowState);
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const openState = useAppSelector((store) => store.currentExtractionState.templateOpenState);
    const { items } = useSectionDropdownItems(section, volumeId, extraction?.id);

    const isReadOnly = extraction?.status === ExtractionStatus.Completed;
    const isImportStep = extraction?.step === StepValue.Import;
    const isAssignStep = extraction?.step === StepValue.Assign;
    const isEditing = editableTemplateRowState.id === section.id;
    const isActive = activeSection?.id === section.id;
    const shouldShowRequirementsAccordion = (isAssignStep || isImportStep) && hasRequirements;

    return (
      <div
        ref={ref}
        className="ml-[36px] rounded-md"
        css={[
          shouldShowRequirementsAccordion && tw`ml-[18px]`,
          isDragging && tw`!m-0 bg-white`,
          isDragging && isImportStep && tw`bg-layout-gray-light`,
          {
            boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
            margin: isDragging ? theme`boxShadow.expanded` : "none",
            zIndex: isDragging ? "4" : "auto",
            opacity: withOpacity ? "0.3" : "1",
            pointerEvents: isEditing ? "none" : "auto",
            ...style,
          },
        ]}
        {...dragProps?.attributes}
      >
        <Accordion.Item key={section.id} value={section.id}>
          <Accordion.Header>
            <div ref={setPortalRef} className="flex flex-col gap-1.5 select-none">
              <div
                className="cursor-default relative overflow-hidden group text-gray-darkest rounded-md"
                css={[
                  isAssignStep && !isReadOnly && tw`cursor-pointer`,
                  !isEditing && !isReadOnly && tw`hover:bg-gray-200`,
                  isActive && tw`!bg-gray-darkest !text-white`,
                ]}
              >
                <div className="flex flex-row pr-4 pl-2 gap-0.5 items-center">
                  {!isReadOnly && (
                    <div
                      className="z-[1] bg-transparent rounded p-1 opacity-0 text-slate-500 hover:text-slate-900 hover:bg-gray-300 group-hover:opacity-100"
                      css={[
                        { cursor: isDragging ? "grabbing" : "grab" },
                        isActive && tw`text-white hover:text-white hover:bg-zinc-700`,
                        isDragging && tw`opacity-100`,
                        !hasRequirements && tw`mr-1`,
                      ]}
                      {...dragProps?.listeners}
                    >
                      <Icon name="Draggable" className="w-3 h-3" />
                    </div>
                  )}
                  <div
                    className="text-xs flex flex-row gap-1.5 items-center w-[calc(100%-22px)]"
                    onClick={() => {
                      if (!isReadOnly && extraction?.step === StepValue.Assign) {
                        if (!isActive) dispatch(setActiveSection(section));
                        if (isActive) dispatch(setActiveSection(undefined));

                        if (requirements.length) dispatch(setTemplateOpenState([...openState, section.id]));
                      }
                    }}
                  >
                    {shouldShowRequirementsAccordion && (
                      <Accordion.Trigger
                        title={section.title}
                        className="rounded group duration-100"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ChevronRight
                          size={14}
                          className="flex-shrink-0 transition-transform mr-0.5 duration-200 group-data-[state=open]:rotate-90"
                        />
                      </Accordion.Trigger>
                    )}
                    {isEditing ? (
                      <>
                        <Icon name="Section" className="min-w-3" />
                        <input
                          placeholder="Section title..."
                          autoFocus
                          onChange={(e) => {
                            dispatch(setEditableTemplateRowState({ localValue: e.target.value }));
                          }}
                          onKeyDown={(e) => {
                            if (e.code === "Enter") {
                              if (extraction?.id) {
                                setSectionName(
                                  extraction.id,
                                  volumeId,
                                  section.id,
                                  editableTemplateRowState.localValue
                                );
                              }
                              setTimeout(
                                () =>
                                  dispatch(
                                    setEditableTemplateRowState({
                                      id: "",
                                      localValue: "",
                                    })
                                  ),
                                100
                              );
                            }
                          }}
                          onBlur={() => {
                            if (extraction?.id) {
                              setSectionName(extraction.id, volumeId, section.id, editableTemplateRowState.localValue);
                            }
                            setTimeout(
                              () =>
                                dispatch(
                                  setEditableTemplateRowState({
                                    id: "",
                                    localValue: "",
                                  })
                                ),
                              100
                            );
                          }}
                          value={editableTemplateRowState.localValue}
                          className="text-[13px] font-semibold py-2 outline-none text-gray-darkest w-full bg-transparent"
                          css={[isActive && tw`bg-gray-darkest text-white`]}
                        />
                      </>
                    ) : (
                      <>
                        <Icon name="Section" className="min-w-3" />
                        <div className="text-[13px] py-2 w-full min-w-0">
                          <div className="flex flex-row justify-between items-center">
                            {/* Here is a luchevron right and when you click on it  */}

                            <span
                              className="font-semibold whitespace-nowrap"
                              css={[
                                shouldShowRequirementsAccordion && tw`truncate`,
                                !section.title.trim() && tw`text-gray-400`,
                              ]}
                            >
                              {section.title || "Section title..."}
                            </span>
                            {hasRequirements && (
                              <Chip
                                variant="primary"
                                className="ml-1 !h-[16px] min-w-[16px] !px-1"
                                colors={{
                                  primaryColor: isActive ? theme`colors.gray.100` : theme`colors.gray.700`,
                                  secondaryColor: isActive ? theme`colors.gray.700` : theme`colors.gray.100`,
                                }}
                              >
                                <div className="text-[0.5rem]">{requirements.length}</div>
                              </Chip>
                            )}
                          </div>
                          {!isEditing && !isReadOnly && (
                            <div
                              className="absolute bottom-0 right-0 top-0 to-transparent bg-gradient-to-l w-14 from-white group-hover:w-28 group-hover:from-50% group-hover:from-gray-200"
                              css={[
                                shouldShowRequirementsAccordion && tw`from-transparent`,
                                isActive && tw`!from-gray-darkest from-50% w-24`,
                                isActive && tw`w-16`,
                                isImportStep && tw`from-layout-gray-light group-hover:w-24`,
                              ]}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!isReadOnly && (
                  <div
                    className="opacity-0 text-slate-700 text-sm flex items-center gap-1.5 absolute bottom-0 right-0 top-0 pl-2 pr-2 bg-gray-200 group-hover:opacity-100"
                    css={[isEditing && tw`hidden`, isActive && tw`!bg-gray-darkest !text-white opacity-100`]}
                  >
                    <Tooltip content="Move">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(setSectionToMove(section));
                        }}
                      >
                        <ArrowUpDown size={16} />
                      </button>
                    </Tooltip>
                    <DropdownMenu
                      portalProps={{ container: portalRef }}
                      contentProps={{ align: "end", css: tw`min-w-[120px]` }}
                      items={items}
                    >
                      <div className="">
                        <EllipsisVertical size={14} />
                      </div>
                    </DropdownMenu>
                  </div>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Content className="collapsibleContent">
            {shouldShowRequirementsAccordion && <RequirementList requirements={requirements} />}
          </Accordion.Content>
        </Accordion.Item>
      </div>
    );
  }
);

export default memo(SubsectionRow);
