const CFWButton = ({ title, onClick, type, disabled, className }) => {
    return (
        <button
            className={`w-full py-3 rounded-md text-[12.5px] bg-action text-[#f1f1f1] active:bg-[#304aa5de] ${className}`}
            onClick={onClick}
            type={type}
            disabled={disabled}
        >
            {title}
        </button>
    );
};

export default CFWButton;
