import { SolicitationType } from "components/copilot/CopilotSchemaTypes";

export const SOLICITATION_TYPE_TO_META: Record<SolicitationType, { label: string; fileString: string }> = {
    [SolicitationType.RequestForProposal]: {
        label: "RFP",
        fileString: "RFP",
    },
    [SolicitationType.RequestForInformation]: {
        label: "RFI",
        fileString: "RFI",
    },
    [SolicitationType.SourcesSought]: {
        label: "Sources Sought",
        fileString: "SourcesSought",
    },
    [SolicitationType.SmallBusinessInnovationResearch]: {
        label: "SBIR",
        fileString: "SBIR",
    },
};
