import { DisplayOption } from "store/reducers/draft/previewProposalReducer";

export const DISPLAY_OPTIONS_TO_META = {
    [DisplayOption.Heading]: {
        label: "Heading",
    },
    [DisplayOption.DontInclude]: {
        label: "Don't Include",
    },
    [DisplayOption.FullRequirement]: {
        label: "Full Requirement",
    },
};
