import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";

export enum ExtractMode {
  DocumentUpload,
  CopyPaste,
  None,
}

export enum ExtractionType {
  Requirements = 1,
  Template,
}

export enum TemplateDocumentType {
  RFI = "RFI",
  RFP = "RFP",
  SBIR = "SBIR",
  Outline = "Outline",
}

type State = {
  mode?: ExtractMode;
  selectedDocument: string | File;
  content: string;
  extractionTypes?: ExtractionType[];

  // Requirement ExtractionType
  keywords: string[];
  aiDetection: boolean;
  splitType: "sentence" | "paragraph";
  requirementExtractionSelectedSheet?: Sheet;

  // Template ExtractionType
  templateDocumentType: TemplateDocumentType | string;
  shouldGenerateTemplateWithRequirements: boolean;
  templateExtractionSelectedSheet?: Sheet;

  // FAR and DFARS Keywords
  farKeywords: string[];
  dfarsKeywords: string[];
};

const initialState: State = {
  keywords: ["shall", "must", "will", "require"],
  aiDetection: false,
  splitType: "paragraph",
  mode: undefined,
  selectedDocument: "",
  content: "",
  extractionTypes: [],
  templateDocumentType: "",
  shouldGenerateTemplateWithRequirements: false,
  farKeywords: [],
  dfarsKeywords: [],
};

const extractReducer = createSlice({
  name: "extractReducer",
  initialState,
  reducers: {
    setExtractState: (state: State, action: PayloadAction<Partial<State>>) => {
      return { ...state, ...action.payload };
    },
    resetExtractState: (state: State) => {
      return { ...initialState, mode: state.mode };
    },
    clearExtractState: () => {
      return initialState;
    },
  },
});

export const { setExtractState, resetExtractState, clearExtractState } = extractReducer.actions;

export default extractReducer.reducer;
