import { AssistantBlockType, WritingAssistantBlock } from "../CopilotSchemaImmutableTypes";
import CoreBlock from "./CoreBlock";
import VultronBlock from "./VultronBlock";

export const renderBlock = (block: WritingAssistantBlock) => {
    switch (block.type) {
        case AssistantBlockType.CoreBlock:
            return <CoreBlock key={block.id} block={block} />;
        case AssistantBlockType.VultronBlock:
            return <VultronBlock key={block.id} block={block} />;

        default:
            return <div />;
    }
};
