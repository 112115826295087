import { DropdownMenu } from "components/molecules/dropdown-menu";
import { MenuItem } from "components/molecules/dropdown-menu/DropdownMenu";
import { EllipsisVertical, Link, Pencil, Trash2 } from "lucide-react";

const CommentOptions = ({
  isCommenter,
  onDelete,
  onEdit,
  onCopyLink,
  isThread = false,
}: {
  isCommenter: boolean;
  onEdit: () => void;
  onDelete: () => void;
  onCopyLink: () => void;
  isThread?: boolean;
}) => {
  const commenterOnlyItems: MenuItem<void>[] = [
    {
      key: "edit",
      label: (
        <div className="flex items-center gap-2 text-sm">
          <Pencil size={16} /> Edit
        </div>
      ),
      onSelect: onEdit,
    },
    {
      key: "delete",
      label: (
        <div className="flex gap-2 text-sm items-center text-red-500">
          <Trash2 size={16} /> {isThread ? "Delete Thread" : "Delete"}
        </div>
      ),
      onSelect: onDelete,
    },
  ];

  const sharedItems: MenuItem<void>[] = [
    {
      key: "link",
      label: (
        <div className="flex gap-2 text-sm items-center">
          <Link size={16} /> {isThread ? "Copy Link to Thread" : "Copy Link to Comment"}
        </div>
      ),
      onSelect: onCopyLink,
    },
  ];
  const dropdownItems = [...sharedItems, ...(isCommenter ? commenterOnlyItems : [])];
  return (
    <DropdownMenu items={dropdownItems}>
      <EllipsisVertical size={18} className="text-gray-light" />
    </DropdownMenu>
  );
};

export default CommentOptions;
