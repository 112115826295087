/** @jsxImportSource @emotion/react */

import { DropdownMenu } from "components/molecules/dropdown-menu";
import { getFileIcon } from "utils/getFileIcon";
import { ChevronDown, Download, EllipsisVertical, ExternalLink, File, Info, Trash2 } from "lucide-react";
import { INTERNAL_DOCUMENT_CLASSIFICATIONS } from "./constants";
import { InternalDocument } from "types/Project";
import { Spinner } from "utils/icons";
import { useMemo } from "react";

import { useGetDocumentClassificationOptions } from "./hooks";
import tw from "twin.macro";
import { toggleDocumentViewerModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import Tooltip from "components/atoms/tooltip";

type ProjectSpecificDocumentsTableRowProps = {
  doc: InternalDocument;
  deletingDocs: string[];
  setDeletingDocs: React.Dispatch<React.SetStateAction<string[]>>;
  deleteInternalDoc: (docId: string) => void;
};

const ProjectSpecificDocumentsTableRow = ({
  doc,
  deletingDocs,
  setDeletingDocs,
  deleteInternalDoc,
}: ProjectSpecificDocumentsTableRowProps) => {
  const dispatch = useAppDispatch();
  const documentIsProcessed = useMemo(
    () => (doc: InternalDocument) => (new Date().valueOf() - new Date(doc?.created_at).valueOf()) / 60000 > 20,
    []
  );

  const documentOptions = useMemo(
    () => (doc: InternalDocument) => {
      return [
        {
          key: 1,
          label: (
            <div className="flex items-center gap-2">
              <Download size={14} />
              Download
            </div>
          ),
          onSelect: () => {
            if (doc?.ext_access?.toLowerCase() === "controlled") return;
            var a = document.createElement("a");
            a.href = doc?.download_url;
            a.click();
          },
        },

        {
          key: 2,
          label: (
            <div className="flex items-center gap-2 text-red-500">
              {!!deletingDocs?.includes(doc?.id) ? <Spinner width={13} height={13} /> : <Trash2 size={14} />}
              Delete
            </div>
          ),
          disabled: !!deletingDocs?.includes(doc?.id),
          onSelect: () => {
            setDeletingDocs([...deletingDocs, doc?.id]);
            deleteInternalDoc(doc?.id);
          },
        },
      ];
    },
    [deleteInternalDoc, deletingDocs, setDeletingDocs]
  );

  const documentClassificationOptions = useGetDocumentClassificationOptions(doc);

  return (
    <tr key={doc.id} className="border-b border-gray-200 text-sm group">
      <td className="px-6 py-5 relative">
        <div className="flex items-center gap-4 min-w-0 max-w-[300px] group">
          <img src={getFileIcon(doc.file_type)} alt="" className="w-4 h-5" />
          <div className="truncate flex items-center gap-2">
            {doc.name}
            {doc.file_type === "pdf" && (
              <Tooltip content="Open">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(toggleDocumentViewerModal({ open: true, initialProps: { url: doc.download_url } }));
                  }}
                  className="text-xs pb-0.5 text-action duration-150 opacity-0 group-hover:opacity-100 hover:text-action-hover"
                >
                  <ExternalLink size={16} className="shrink-0" />
                </button>
              </Tooltip>
            )}
          </div>
        </div>
      </td>
      <td className="px-4 py-5">{new Date(doc.upload_date).toLocaleDateString()}</td>
      <td className="px-4 py-5">
        <DropdownMenu modal items={documentClassificationOptions}>
          <div className="flex items-center gap-1 bg-[#F2F5F7] rounded-md px-2 py-1.5">
            {doc.classification && <File className="text-[#737D86] h-[14px] w-[14px]" />}
            <div className="truncate" css={[!doc.classification && tw`text-gray-500`]}>
              {doc.classification ? INTERNAL_DOCUMENT_CLASSIFICATIONS[doc.classification] : "Select Type"}
            </div>
            <ChevronDown size={15} className="text-[#737D86]" />
          </div>
        </DropdownMenu>
      </td>
      <td className="px-4 py-5 min-w-[120px]">{doc.file_size_formatted}</td>
      <td className="pl-4 pr-6 py-5">
        <div className="flex items-center justify-between">
          {documentIsProcessed(doc) ? (
            <div className="text-sm text-[#4cb592]">Processed</div>
          ) : (
            <div className="text-sm text-[#4680FF] mr-1 flex items-center justify-center gap-1">
              <Info size={14} />
              <span>Pending</span>
            </div>
          )}
          <DropdownMenu modal items={documentOptions(doc)}>
            <div className="w-full flex justify-end pl-1 pr-2">
              {deletingDocs?.includes(doc?.id) ? (
                <Spinner classes="!text-black ml-auto w-5" width={13} height={13} />
              ) : (
                <EllipsisVertical size={14} />
              )}
            </div>
          </DropdownMenu>
        </div>
      </td>
    </tr>
  );
};

export default ProjectSpecificDocumentsTableRow;
