/** @jsxImportSource @emotion/react */

import { setProposalState } from "store/reducers/draft/sectionReducer";
import Icon from "components/atoms/icons/Icon";
import { Orbit, Square } from "lucide-react";
import { Button } from "components/editor/components";
import Tooltip from "components/atoms/tooltip";
import Switch from "components/atoms/switch";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Section, Storage } from "components/copilot/CopilotSchemaImmutableTypes";
import SegmentedControl from "components/molecules/segmented-control";
import { useSegmentedControl } from "./hooks";
import { useOthersMapped, useStorage } from "YJSProvider/createYJSContext";
import { isEqual } from "lodash";

type Props = {
  section?: Section;
  abortConnection: () => void;
};

const BottomBar = ({ section, abortConnection }: Props) => {
  const { controlItems, activeControlItem, onControlSelect } = useSegmentedControl();
  const requirements = useStorage(
    (storage) =>
      (storage.compliance_matrix as Storage["compliance_matrix"]).filter(
        (row) => row.proposal_reference.section_id === section?.id && !row.requirement.skipped
      ),
    isEqual
  );
  const [includeRequirement, setIncludeRequirement] = useState(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const presence = useOthersMapped((other) => other.presence.selectedId)?.[0];
  const isSectionInUse = useMemo(() => presence?.includes(`${section?.id}-proposal-section`), [presence, section?.id]);
  const { sectionInProgress } = useAppSelector((root) => root.sectionState.proposalState);

  const isProposalGenerating = sectionInProgress?.id === section?.id;

  const canGenerate = useMemo(() => {
    const hasValidReqs =
      !!requirements.length &&
      requirements.every(
        ({ requirement, written_content }) =>
          !!(requirement.content?.trim() || requirement.summarized_content?.trim()) && !!written_content?.trim()
      );

    return !isSectionInUse && hasValidReqs;
  }, [isSectionInUse, requirements]);

  return (
    <div className="p-2 flex items-center justify-between gap-2 border-t border-gray-light">
      <SegmentedControl
        layoutId="SegmentedControlActiveDraftSection"
        activeItem={activeControlItem}
        onItemSelect={onControlSelect}
        items={controlItems}
      />
      <div className="flex flex-row gap-2 items-center justify-end">
        <Tooltip
          delayDuration={700}
          content="Vultron will list the requirement before each response in the generated full section draft."
        >
          <div className="bg-slate-100 rounded-md text-xs text-slate-500 py-[9px] px-2.5 flex items-center gap-2">
            Include requirement
            <Switch checked={includeRequirement} onCheckedChange={setIncludeRequirement} size="sm" />
          </div>
        </Tooltip>
        <Tooltip
          disabled={canGenerate}
          content={
            <div className="flex gap-2 items-center">
              <Icon name="WarningCircle" className="text-red-500 w-10 h-10" />
              All requirements in this section must not be empty and have a response to generate a full section draft.
            </div>
          }
        >
          <Button
            disabled={!canGenerate}
            onClick={() => {
              if (isProposalGenerating) {
                abortConnection?.();
                return;
              }

              if (!canGenerate || !section) return;
              dispatch(
                setProposalState({
                  sectionInProgress: { ...section, full_requirement_title: includeRequirement },
                })
              );
              navigate(`proposal?${searchParams.toString()}`);
            }}
            variant="primary"
            size="sm/md"
            className="gap-2"
          >
            {isProposalGenerating ? (
              <div className="relative w-5 flex items-center justify-center">
                <Orbit
                  className="absolute"
                  css={[
                    isProposalGenerating && {
                      animation: "rotateAnimation 3s infinite linear",
                    },
                  ]}
                  size={24}
                />
                <Square className="absolute fill-current" size={12} />
              </div>
            ) : (
              <Icon name="Generate" className="stroke-[1.5]" />
            )}
            <div>{isProposalGenerating ? "Stop Generation" : "Generate Section"}</div>
          </Button>
        </Tooltip>
      </div>
    </div>
  );
};

export default BottomBar;
