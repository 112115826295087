import { useEffect } from "react";
import axios from "axios";
import { jwtDecode } from "jwt-decode";

export const useValidateUserSession = () => {
    // add interceptor to axios requests
    // check if their request has an Authorization header, if not pass
    // check if using SSO, if so, pass
    // check that session token exists and not expired
    // if expired, do an axios request to refresh token
    // if that fails, force relogin

    const tokenRefreshPath = "/users/auth0/token/refresh";
    const callbackPath = "/users/auth0/callback";
    const loginPath = "/users/auth0/login";
    const logoutPath = "/users/auth0/logout";
    const ignoreInterceptorPath = [loginPath, callbackPath, tokenRefreshPath, logoutPath];

    const isJWTExpired = (token: string) => {
        if (!token) return true;
        try {
            const decoded: any = jwtDecode(token);
            const currentTime = Date.now() / 1000;
            return decoded.exp < currentTime;
        } catch (error) {
            return true;
        }
    };
    const setAxiosInterceptor = () => {
        axios.interceptors.request.use(
            async (config) => {
                // Bypass interceptor for certain paths (login, token refresh, etc.)
                if (ignoreInterceptorPath.some((path) => config.url?.includes(path))) {
                    return config;
                }

                if (!config.headers.Authorization) return config;

                const useSSO = localStorage.getItem("vultron_user_sso") === "true";
                const userToken = localStorage.getItem("vultron_user_token") || "";
                const syntheticToken = localStorage.getItem("synthetic_test_secret") || "";
                if (useSSO) return config;
                if (syntheticToken) return config;
                if (isJWTExpired(userToken)) {
                    try {
                        // Call the backend endpoint to refresh the token (include cookies)
                        const response = await axios.post(tokenRefreshPath, {}, { withCredentials: true });
                        const token = response.data.session_token;
                        localStorage.setItem("vultron_user_token", token);
                        config.headers.Authorization = `Bearer ${token}`;
                    } catch (error) {
                        console.error("Token refresh failed", error);
                        // Handle the error
                        throw error;
                    }
                }
                return config;
            },
            (error) => Promise.reject(error)
        );
    };

    useEffect(() => {
        setAxiosInterceptor();
        // eslint-disable-next-line
    }, []);
};
