/** @jsxImportSource @emotion/react */

import { Button } from "components/editor/components";
import { useMemo } from "react";
import { useGenerateTemplate } from "./hooks";
import { useObserveSseController } from "hook/useObserveSseController";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import {
    INITIAL_TEMPLATE,
    setCopyPasteContent,
    toggleExtractError,
    toggleLoading,
    updateTemplate,
} from "store/reducers/templates/templateExtractionReducer";
import tw from "twin.macro";
import { getWordCount, sliceByWordCount } from "utils/getWordCount";
import { Volume } from "types/Templates";
import { v4 } from "uuid";
import { useNotification } from "context/notificationContext";

const MAX_WORDS = 5000;

const CopyPasteExtraction = () => {
    const { setToast } = useNotification();
    const dispatch = useAppDispatch();
    const { isExtractLoading, copyPasteContent } = useAppSelector((root) => root.templateExtraction);

    let templateString = "";
    const { generateTemplate, abortConnection } = useGenerateTemplate({
        onmessage(msg) {
            if (msg.event === "FatalError") {
            }

            if (!!msg.data?.length) {
                templateString += msg.data;
            }
        },
        async onopen(response) {
            templateString = "";
            dispatch(updateTemplate(INITIAL_TEMPLATE));
            if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                return; // everything's good
            } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                setToast.error({
                    title: "Unable to generate template",
                    msg: (
                        <div>
                            We could not generate the template due to a technical issue on our end. Please refresh and
                            try again. If the issue persists,{" "}
                            <a
                                className="font-medium text-action duration-150 hover:text-action-hover"
                                href="mailto:support@vultron.ai"
                            >
                                contact us
                            </a>{" "}
                            for assistance.
                        </div>
                    ),
                });
                dispatch(toggleLoading(false));
            } else {
            }
        },
        onclose() {
            try {
                const strippedTemplate = templateString.substring(
                    templateString.indexOf("{"),
                    templateString.lastIndexOf("}") + 1
                );
                const parsedTemplate: Record<"volumes", Volume[]> = JSON.parse(strippedTemplate);

                if (parsedTemplate?.volumes?.length) {
                    const draftTemplate = parsedTemplate.volumes.map((vol) => ({
                        ...vol,
                        id: v4(),
                        sections: vol.sections.map((sec) => ({ ...sec, id: v4(), subsections: [] })),
                    }));
                    dispatch(updateTemplate({ ...parsedTemplate, volumes: draftTemplate }));
                } else throw new Error();
            } catch (e) {
                dispatch(toggleExtractError(true));
            }
            dispatch(toggleLoading(false));
        },
        onerror(err) {
            setToast.error({
                title: "Unable to generate template",
                msg: (
                    <div>
                        We could not generate the template due to a technical issue on our end. Please refresh and try
                        again. If the issue persists,{" "}
                        <a
                            className="font-medium text-action duration-150 hover:text-action-hover"
                            href="mailto:support@vultron.ai"
                        >
                            contact us
                        </a>{" "}
                        for assistance.
                    </div>
                ),
            });
            dispatch(toggleLoading(false));
            if (err instanceof Error) {
                throw err; // rethrow to stop the operation
            } else {
            }
        },
    });

    useObserveSseController(abortConnection, () => dispatch(toggleLoading(false)));

    const wordCount = useMemo(() => getWordCount(copyPasteContent), [copyPasteContent]);

    return (
        <div className="flex flex-col gap-2 flex-1 p-2 rounded-md shadow-soft relative border-1.5 border-gray-lightest max-w-[43%]">
            <button
                disabled={isExtractLoading || !copyPasteContent}
                onClick={() => dispatch(setCopyPasteContent(""))}
                className="absolute -top-[20px] right-2.5 text-xs text-red-500 duration-150 hover:text-red-400 disabled:!text-[#C6CBD0]"
            >
                Clear
            </button>
            <span
                className="text-xxs font-normal text-gray-lightest absolute top-4 right-5"
                css={wordCount >= MAX_WORDS && tw`text-red-500`}
            >
                {wordCount}/5000 words
            </span>
            <textarea
                placeholder="Write or paste template here..."
                value={copyPasteContent}
                onChange={(e) => {
                    const count = getWordCount(e.target.value);
                    if (count > MAX_WORDS) {
                        if (
                            // @ts-ignore
                            e.nativeEvent.inputType === "insertFromPaste" &&
                            wordCount < MAX_WORDS
                        )
                            dispatch(setCopyPasteContent(sliceByWordCount(e.target.value, MAX_WORDS)));
                        return;
                    }
                    dispatch(setCopyPasteContent(e.target.value));
                }}
                className="flex-1 text-xs resize-none border border-light rounded-md bg-gray-light outline-none p-4 pt-5 w-full"
            />
            <div className="flex gap-4 items-center">
                <Button
                    disabled={!copyPasteContent.trim() || isExtractLoading || wordCount > MAX_WORDS}
                    variant="primary"
                    onClick={() => {
                        if (isExtractLoading || !copyPasteContent.trim()) return;
                        dispatch(toggleLoading(true));
                        dispatch(toggleExtractError(false));
                        generateTemplate({ text: copyPasteContent.trim() });
                    }}
                    className="w-full"
                    size="md"
                >
                    Generate
                </Button>
            </div>
        </div>
    );
};

export default CopyPasteExtraction;
