import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";

export enum TemplateSectionMode {
    Generate,
    Extract,
}
export const MODE_TO_LABEL = {
    [TemplateSectionMode.Generate]: "Generate",
    [TemplateSectionMode.Extract]: "Extract",
};

export type TemplateSectionState = {
    mode: TemplateSectionMode;
    prompt: string;
    sheet?: Sheet;
};

export const DEFAULT_TEMPLATE_SECTION_STATE: TemplateSectionState = {
    mode: TemplateSectionMode.Generate,
    prompt: "",
};
