import { memo, useMemo } from "react";

interface HighligtWordsProps {
    text: string;
    highlight: string;
}
const HighlightWords = ({ text, highlight }: HighligtWordsProps) => {
    const splitText = useMemo(() => {
        try {
            const regexp = new RegExp(`(${highlight})`, "gi");
            return text.split(regexp);
        } catch {
            return [text];
        }
    }, [text, highlight]);

    return (
        <span>
            {splitText.map((part, i) =>
                part.toLowerCase() === highlight.toLowerCase() ? (
                    <strong className="bg-gray-200 text-action" key={i}>
                        {part}
                    </strong>
                ) : (
                    part
                )
            )}
        </span>
    );
};

export default memo(HighlightWords);
