/** @jsxImportSource @emotion/react */

import { Modal } from "components/organisms/modal";
import FeedbackAccordion from "components/custom/FeedbackAccordion";
import Icon from "components/atoms/icons/Icon";
import tw from "twin.macro";
import { ComponentProps, useState } from "react";
import { toggleEvaluationModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import loadingImg from "../../../Assets/scoring2.gif";

type Evaluation = {
    createdAt: string;
    createdBy: string;
    needsEvaluation: boolean;
    categories: {
        category: string;
        score: string;
        feedback: string[];
    }[];
    totalGrade: string;
};

export interface EvaluationModalProps extends Pick<ComponentProps<typeof Modal>, "open"> {
    initialProps?: Partial<{
        evaluation: Evaluation;
        setEvaluation: (val: any) => void;
        calculateDaysInThePast: (date: string) => string;
        onEvaluationBtn: () => void;
        showBtnLoading: boolean;
        writtenContent: string;
    }>;
}

const LOADING_MSGS = ["Analyzing response", "Scoring response", "Generating feedback"];
const INITIAL_LOADING_MSG = LOADING_MSGS[0];

// ---------------- Add new Link Modal handler --------------------------
const EvaluationModal = ({ initialProps, ...props }: EvaluationModalProps) => {
    const dispatch = useAppDispatch();
    const { evaluation, setEvaluation, calculateDaysInThePast, onEvaluationBtn, showBtnLoading } = initialProps || {};
    const needsEvaluation = evaluation && evaluation.needsEvaluation;
    const SUBTITLE_MAP: Record<string, string> = {
        Comprehensiveness: "Does it clearly explain outcomes, benefits, and the how?",
        Quality: "Is it clear, concise, active, and free from repetition and buzzwords?",
        Responsiveness: "Does it relate to the content being requested in the requirement?",
    };
    const COLOR_MAP: Record<string, string> = {
        Satisfactory: "text-green-700",
        Adequate: "text-[#4680FF]",
        "Needs Improvement": "text-red-400",
    };
    const writtenContent = initialProps?.writtenContent;

    const gradingTooltip = (): string => {
        if (showBtnLoading) return "Scoring...";
        if (!writtenContent?.trim()) return "Unable to provide a score without a response.";
        return "";
    };

    const [loadingMsg, setLoadingMsg] = useState<string>(INITIAL_LOADING_MSG);

    useAnimateLoadingMsg(!!showBtnLoading, 4000, LOADING_MSGS, (msg) => setLoadingMsg(msg));

    return (
        <Modal
            header="Response Score"
            onOpenChange={(openState) => dispatch(toggleEvaluationModal({ open: openState }))}
            contentProps={{ css: tw`max-w-[866px] min-w-[866px] max-h-[650px] min-h-[650px] overflow-y-auto` }}
            body={
                <div className="px-6 overflow-y-auto">
                    <div className="">
                        <div className="sticky -top-1 z-10 bg-white w-full -mt-3 pb-3">
                            {showBtnLoading ? (
                                <div className="mt-2">
                                    <div className="pb-2 text-gray-lightest text-sm">
                                        Last Updated:{" "}
                                        <span className="text-gray-lightest font-semibold mt-0.5">
                                            Updating
                                            <span className="loading-ellipsis" />
                                        </span>
                                    </div>
                                </div>
                            ) : (
                                <div className="pb-2 text-gray-lightest text-sm">
                                    Last Updated: {calculateDaysInThePast?.(evaluation?.createdAt || "")} ago | By:{" "}
                                    {evaluation?.createdBy}{" "}
                                </div>
                            )}
                            <div className="flex justify-between">
                                <div className="flex text-md text-black mt-2">
                                    Overall Score:{" "}
                                    {showBtnLoading ? (
                                        <span className="text-gray-500 pl-2 font-semibold">
                                            Updating
                                            <span className="loading-ellipsis" />
                                        </span>
                                    ) : (
                                        <div
                                            className={`${
                                                COLOR_MAP[evaluation?.totalGrade || ""] || "text-gray-500"
                                            } pl-2 font-semibold cursor-default`}
                                        >
                                            {evaluation?.totalGrade}
                                        </div>
                                    )}
                                </div>
                                <button
                                    className="bg-action py-2 px-4 flex justify-end gap-1 items-center text-white text-sm rounded-md disabled:opacity-75 disabled:cursor-not-allowed"
                                    onClick={onEvaluationBtn}
                                    disabled={showBtnLoading || !writtenContent?.trim()}
                                >
                                    <Icon
                                        name="Generate"
                                        className="mr-1"
                                        css={[
                                            showBtnLoading && {
                                                animation: "rotateAnimation 0.5s infinite linear",
                                            },
                                        ]}
                                    />{" "}
                                    {showBtnLoading ? (
                                        <span>
                                            Scoring
                                            <span className="loading-ellipsis" />
                                        </span>
                                    ) : needsEvaluation ? (
                                        "Score"
                                    ) : (
                                        "Re-score"
                                    )}
                                </button>
                            </div>
                        </div>
                        <div>
                            {showBtnLoading && (
                                <div className="flex flex-col justify-center items-center h-full">
                                    <div className="flex flex-col justify-center items-center w-[60%] mx-auto mt-[152px]">
                                        <img src={loadingImg} alt="icon" className="h-48 w-48" />
                                        <div className="text-sm text-[#96A4AF] absolute mt-[152px]">
                                            {loadingMsg}
                                            <span className="loading-ellipsis" />
                                        </div>
                                    </div>
                                </div>
                            )}
                            {!showBtnLoading && (
                                <div className="border border-gray rounded-lg my-4" style={{ borderWidth: "1.5px" }}>
                                    <FeedbackAccordion
                                        key="rubrick"
                                        title="Scoring Framework"
                                        subtitleContent={"Criteria used to score your response."}
                                        grade={""}
                                        gradeColor={""}
                                        bgColor={"bg-slate-50"}
                                        content={
                                            <div className="text-xs pl-4 pr-4">
                                                <div className="-mt-2">
                                                    <div className="mb-1.5">
                                                        <span className="font-semibold cursor-default">Adequate:</span>{" "}
                                                        <span className="text-gray-500">
                                                            All evaluation factors are fulfilled
                                                        </span>
                                                    </div>
                                                    <div className="mb-1.5">
                                                        <span className="font-semibold cursor-default">
                                                            Needs Improvement:
                                                        </span>{" "}
                                                        <span className="text-gray-500">
                                                            At least one evaluation factor is unfulfilled
                                                        </span>
                                                    </div>
                                                </div>
                                                <span className="font-semibold mb-1.5">
                                                    {" "}
                                                    Scoring Framework Factors:
                                                </span>
                                                <span className="text-gray-500">
                                                    {" "}
                                                    Your response should address all elements and deliverables of the
                                                    requirement. Response should include a description of your approach
                                                    (what you will do), an overview of the process (how), detailed steps
                                                    of the process or workflow, evidence to substantiate (experience
                                                    and/or past performance), and value (expected ROI/outcomes,
                                                    benefits, results).
                                                </span>
                                            </div>
                                        }
                                        defaultOpen={false}
                                    />
                                </div>
                            )}
                            {!showBtnLoading &&
                                evaluation?.categories.map((category, index) => (
                                    <div
                                        className="border border-gray rounded-lg my-4"
                                        style={{ borderWidth: "1.5px" }}
                                    >
                                        <FeedbackAccordion
                                            key={index}
                                            title={`${category.category}`}
                                            subtitleContent={SUBTITLE_MAP[category.category]}
                                            grade={category.score}
                                            gradeColor={COLOR_MAP[category.score] || "text-gray-500"}
                                            content={
                                                <div className="text-xs pl-4 pr-4">
                                                    <div className="text-black -mt-2 mb-1"> Feedback: </div>
                                                    <ul className="pl-4">
                                                        {category.feedback.map((item, feedbackIndex) => (
                                                            <li
                                                                className="list-disc text-xs text-gray-500 mb-1"
                                                                key={feedbackIndex}
                                                            >
                                                                {item}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            }
                                            defaultOpen={false}
                                        />
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            }
            {...props}
        />
    );
};

export default EvaluationModal;
