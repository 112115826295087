import { createInternalContractMilestone } from "api/api";
import CInputWithLabel from "components/custom/CInputWithLabel";
import { Button } from "components/editor/components";
import { Modal } from "components/organisms/modal";
import { useState } from "react";
import tw from "twin.macro";

const AddMilestoneModal = ({ isOpen, setIsOpen, internalContractId, setInternalContractDetails }) => {
    const [milestone, setMilestone] = useState({
        name: "",
        deadline: "",
    });
    const [loading, setLoading] = useState(false);

    const changeHandler = (e) => {
        const { name, value } = e.target;
        setMilestone((pS) => ({
            ...pS,
            [name]: value,
        }));
    };

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await createInternalContractMilestone(internalContractId, milestone);

            setInternalContractDetails((pS) => ({
                ...pS,
                milestone: pS.milestones.push({ ...response.data }),
            }));
            setIsOpen(false);
        } catch (err) {
            console.log(err);
        } finally {
            setLoading(false);
        }
    };
    return (
        <Modal
            open={isOpen}
            onOpenChange={setIsOpen}
            contentProps={{ css: tw`!max-w-[600px]` }}
            header="Add Milestone"
            body={
                <div className="mt-[10px]">
                    <form onSubmit={submitHandler} className="w-full px-[24px] ">
                        <CInputWithLabel
                            label="Title"
                            onChange={changeHandler}
                            name="name"
                            type="text"
                            className="mt-[8px]"
                            required={true}
                        />
                        <CInputWithLabel
                            label="Date"
                            onChange={changeHandler}
                            name="deadline"
                            type="date"
                            className="mt-[8px]"
                            required={true}
                        />

                        <div className="w-full flex justify-end mt-8">
                            <div className="flex gap-[4px]">
                                <Button
                                    variant="outline"
                                    size="md"
                                    onClick={() => setIsOpen(false)}
                                    className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                                >
                                    Cancel
                                </Button>
                                <Button size="md" variant="primary" type="submit" loading={loading}>
                                    Add
                                </Button>
                            </div>
                        </div>
                    </form>
                </div>
            }
        />
    );
};

const styles = {
    submitBtn: { width: "66px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
    cancelBtn: { width: "80px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
    deleteBtn: { width: "146px", display: "flex", justifyContent: "center", height: "40px", alignItems: "center" },
};

export default AddMilestoneModal;
