import Icon from "components/atoms/icons/Icon";
import { PencilRuler } from "lucide-react";
import { toggleTemplatesModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import { setVolumeState } from "store/reducers/draft/volumeReducer";
import { useFrameworkOperations } from "hook/useFrameworkOperations";

const buttonStyle =
  "flex items-center gap-2 justify-center border-[1.5px] rounded-lg text-lg font-medium border-gray-light shadow-expanded bg-white text-action py-3 w-full duration-150 hover:border-action hover:bg-action-lightest disabled:bg-gray-200 disabled:text-slate-400 disabled:border-gray-200 disabled:shadow-none";

const EmptyFramework = () => {
  const { addNewSection, addNewVolume } = useFrameworkOperations();
  const dispatch = useAppDispatch();

  return (
    <div className="px-5 flex-1 h-full flex flex-col justify-center items-center relative gap-8 pb-5">
      <div className="flex flex-col gap-4 w-1/3 min-w-[380px]">
        <button className={buttonStyle} onClick={() => dispatch(toggleTemplatesModal({ open: true }))}>
          <Icon name="TaskSquare" className="w-[18px] h-[18px] stroke-current stroke-[0.3]" />
          Import
        </button>
        <button
          className={buttonStyle}
          onClick={() => {
            const createdVolume = addNewVolume();
            addNewSection(createdVolume.id);
            dispatch(setVolumeState({ volumeEditable: createdVolume.id }));
          }}
        >
          <PencilRuler size={14} />
          New
        </button>
      </div>
    </div>
  );
};

export default EmptyFramework;
