import { memo, useMemo } from "react";
import Selection from "./Selection/Selection";
import { getUserColorSet } from "utils/getUserColor";
import { useOthers } from "YJSProvider/createYJSContext";

const COLORS = ["#FF0221", "#D97706", "#059669", "#7C3AED", "#DB2777"];

const Selections = memo(
    ({
        id,
        isFirstRow,
        containerProps,
        nameProps,
        hasActiveOthers,
    }: {
        id: string;
        isFirstRow?: boolean;
        containerProps?: any;
        nameProps?: any;
        hasActiveOthers?: (hasOthers: boolean) => void;
    }) => {
        const users = useOthers();
        const filteredUsers = useMemo(
            () =>
                users
                    .filter(({ presence }) => presence.selectedId === id)
                    // @ts-ignore
                    ?.map((user) => ({ ...user, colorSet: getUserColorSet(user?.presence?.name) })),
            [id, users]
        );

        hasActiveOthers?.(!!filteredUsers.length);

        return (
            <>
                {filteredUsers.map(
                    ({
                        connectionId,
                        presence,
                        colorSet,
                    }: {
                        connectionId: number;
                        presence: any;
                        colorSet: Record<string, string>;
                    }) => {
                        return (
                            <Selection
                                key={connectionId}
                                isFirstRow={isFirstRow}
                                name={presence.name}
                                colorSet={colorSet}
                                containerProps={containerProps}
                                nameProps={nameProps}
                            />
                        );
                    }
                )}
            </>
        );
    }
);

export default Selections;
