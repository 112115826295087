/** @jsxImportSource @emotion/react */

import VolumeCard from "./VolumeCard";
import { Storage as ImmutableStorage } from "components/copilot/CopilotSchemaImmutableTypes";
import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  PointerSensor,
  UniqueIdentifier,
  closestCenter,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { useCallback, useMemo, useState } from "react";
import { SortableContext, rectSortingStrategy, sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import { Framework, Storage } from "components/copilot/CopilotSchemaTypes";
import { CirclePlus } from "lucide-react";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setVolumeState } from "store/reducers/draft/volumeReducer";
import EmptyFramework from "../empty-framework";
import tw from "twin.macro";
import TemplateSelector from "../../TemplateSelector";
import DraftExtractionCard from "./DraftExtractionCard";
import { useMutation, useStorage } from "YJSProvider/createYJSContext";
import { findIndex, moveItem } from "YJSProvider/LiveObjects";

const Volumes = () => {
  const volumes = useStorage((storage) => (storage.framework as ImmutableStorage["framework"]).volumes);
  const [activeId, setActiveId] = useState<UniqueIdentifier | null>(null);
  const { addNewVolume, addNewSection } = useFrameworkOperations();
  const dispatch = useAppDispatch();
  const actionsMenuOpen = useAppSelector((store) => store.copilotDrawer.open);
  const sidebarVisible = useAppSelector((root) => root.copilot.sidebarVisible);
  const { template_document_tasks, template_text_tasks } = useAppSelector((state) => state.autopilotHealthCheck);

  const templateExtractionInProgress = useMemo(
    () => !![...template_document_tasks, ...template_text_tasks]?.filter(({ is_started }) => is_started)?.length,
    [template_document_tasks, template_text_tasks]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = useCallback((event: DragStartEvent) => {
    setActiveId(event.active.id);
  }, []);

  const handleDragCancel = useCallback(() => {
    setActiveId(null);
  }, []);

  const handleDragEnd = useMutation(({ storage }, event: DragEndEvent) => {
    const { active, over } = event;

    if (over?.id && active.id !== over.id) {
      const volumeList = (storage.get("framework") as Storage["framework"]).get("volumes") as Framework["volumes"];
      if (!volumeList) return;

      const sourceIndex = findIndex(volumeList, (row) => row.get("id") === active.id);
      const destinationIndex = findIndex(volumeList, (row) => row.get("id") === over.id);

      if (sourceIndex === -1 || destinationIndex === -1) return;

      moveItem(volumeList, sourceIndex, destinationIndex);
    }

    setActiveId(null);
  }, []);

  const activeVolume = useMemo(() => volumes.find(({ id }) => id === activeId) || {}, [activeId, volumes]);

  if (!volumes.length && !templateExtractionInProgress) return <EmptyFramework />;

  return (
    <div className="flex flex-col gap-3">
      <div className="z-[2] pr-5 ml-auto">
        <TemplateSelector />
      </div>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        onDragCancel={handleDragCancel}
      >
        <div
          className="grid grid-cols-3 gap-4 px-5 pb-5 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6"
          css={[(actionsMenuOpen || sidebarVisible) && tw`xl:grid-cols-2 2xl:grid-cols-4 3xl:grid-cols-5`]}
        >
          <SortableContext id="TEMPLATE_VOLUMES" items={volumes} strategy={rectSortingStrategy}>
            {volumes?.map((volume) => (
              <SortableItem key={volume.id} id={volume.id} volume={volume} />
            ))}
            <DragOverlay adjustScale style={{ transformOrigin: "0 0 " }}>
              {!!activeId && <VolumeCard volume={activeVolume} isDragging />}
            </DragOverlay>
          </SortableContext>
          {templateExtractionInProgress && <DraftExtractionCard />}
          <button
            onClick={() => {
              const createdVolume = addNewVolume();
              addNewSection(createdVolume.id);
              dispatch(setVolumeState({ volumeEditable: createdVolume.id }));
            }}
            className="flex flex-col gap-3 justify-center items-center h-[184px] bg-white border border-gray-light rounded-md p-4 duration-150 shadow-md hover:bg-gray-100"
          >
            <CirclePlus size={32} className="text-gray-400" />
            <div className="text-action text-sm font-medium">New Draft</div>
          </button>
        </div>
      </DndContext>
    </div>
  );
};

export default Volumes;
