import { ComponentProps } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useAppSelector } from "store/storeTypes";
import SubsectionRow from "./SubsectionRow";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";

const SortableItem = (props: ComponentProps<typeof SubsectionRow>) => {
    const isEditing =
        useAppSelector((store) => store.currentExtractionState.editableTemplateRowState.id) === props.section.id;
    const isCompleted =
        useAppSelector((store) => store.currentExtractionState.currentExtraction?.status) ===
        ExtractionStatus.Completed;
    const isDisabled = isEditing || isCompleted;

    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.section.id || "",
        disabled: isDisabled,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition: transition || undefined,
    };

    return (
        <SubsectionRow
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            dragProps={{ attributes, listeners }}
        />
    );
};

export default SortableItem;
