import axios from "axios";
import { useNotification } from "context/notificationContext";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const usePublishVolume = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id");
    const [isPublishing, setIsPublishing] = useState(false);
    const { setToast } = useNotification();

    const publishVolume = useCallback(
        async (body: { volume_id: string }) => {
            if (isPublishing || !projectId) return;

            setIsPublishing(true);
            try {
                const { data } = await axios.post<{ proposal_document_id: string }>(
                    `proposal_generation/${projectId}/publish`,
                    body
                );

                return data?.proposal_document_id;
            } catch {
                setToast.error({
                    title: "Unable to publish",
                    msg: "We were unable to publish due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            } finally {
                setIsPublishing(false);
            }
        },
        [isPublishing, projectId, setToast]
    );

    return { publishVolume, isPublishing };
};
