/** @jsxImportSource @emotion/react */

import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { CircleCheck, ExternalLink, SquareMinus } from "lucide-react";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import "../styles.css";
import { StepValue } from "../../types";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { setActiveDocument, setHighlightedElementId } from "store/reducers/extract/CurrentExtractionReducer";
import useExtractionOperations from "hook/useExtractionOperations";
import { highlightAndScrollToElement } from "../../utils";

type Props = {
  isSubsection?: boolean;
  requirements: ToImmutable<Extraction["compliance_matrix"]>;
};

const RequirementList = ({ requirements, isSubsection }: Props) => {
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const documents = useAppSelector((store) => store.currentExtractionState.documents);
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);
  const isComplete = extraction?.status === ExtractionStatus.Completed;
  const isImportStep = extraction?.step === StepValue.Import;
  const isReadOnly = isComplete || isImportStep;
  const dispatch = useAppDispatch();
  const { assignExtractionRequirement } = useExtractionOperations();

  return (
    <div
      className="flex flex-col gap-1 ml-[48px] pb-1.5 pt-1"
      css={[isSubsection && tw`ml-[72px]`, isComplete && tw`ml-[26px]`, isComplete && isSubsection && tw`ml-[50px]`]}
    >
      {requirements.map((row) => (
        <div key={row.requirement.id} className="group relative">
          {activeDocument?.id !== row.document?.id ? (
            <button
              onClick={() => {
                if (isReadOnly) return;
                const doc = documents.find(({ id }) => id === row.document?.id);
                if (doc) dispatch(setActiveDocument(doc));
              }}
              className="text-xs z-[1] select-none flex items-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[175px] left shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-300 text-slate-900  group-hover:opacity-100 hover:bg-gray-300 font-medium"
            >
              <span className="truncate">{row.document?.name}</span>
              <ExternalLink size={14} className="shrink-0" />
            </button>
          ) : (
            <button
              onClick={() => {
                if (extraction?.id && row?.proposal_reference?.section_id) {
                  assignExtractionRequirement(extraction?.id, row.requirement.id, row.proposal_reference.section_id);
                }
              }}
              className="text-xs z-[1] select-none flex items-center justify-center gap-2 ml-auto absolute p-1.5 top-2 right-2 left-2 max-w-[90px] left shadow-sharp-thin rounded transition-opacity opacity-0 bg-gray-darkest text-gray-200 group-hover:opacity-100 hover:bg-zinc-700"
            >
              Unassign
              <SquareMinus size={14} />
            </button>
          )}
          <div
            className="flex gap-2 rounded-md items-center cursor-pointer p-1 group-hover:bg-gray-100"
            onClick={() => {
              if (!row.requirement.element_id || activeDocument?.id !== row.document?.id) return;

              if (highlightedElementId === row.requirement.element_id) {
                highlightAndScrollToElement(highlightedElementId);
              } else {
                dispatch(setHighlightedElementId(row.requirement.element_id));
              }
            }}
            css={[
              activeDocument?.id !== row.document?.id && !isReadOnly && tw`pointer-events-none group-hover:opacity-50`,
            ]}
          >
            <CircleCheck size={12} className="text-gray-darkest" />
            <div className="text-xs text-gray-600 whitespace-pre-line">{row.requirement?.content}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default RequirementList;
