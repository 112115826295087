/** @jsxImportSource @emotion/react */

import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import { HIGHLIGHT_BUFFER, REQUIREMENT_CLASSNAMES } from "./constants";
import { Page } from "react-pdf";
import { Section } from "components/copilot/CopilotSchemaTypes";
import { ScreenSpinner } from "utils/icons";
import { setTemplateOpenState } from "store/reducers/extract/CurrentExtractionReducer";
import { GroupedBlock, StepValue } from "../types";
import { Minus, Plus, SearchCheck, Split } from "lucide-react";
import { MouseEvent, memo, useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMemo } from "react";
import tw from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { useSelection } from "./SelectionContext";
import { useDocViewNotification } from "../../context/doc-view-notification-context/docViewNotificationContext";
import { Requirement } from "types/Requirement";
import { useUnmergeRequirement } from "./hooks";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { useTrackUserMetric } from "utils/metrics";

type Props = {
  page: number;
  scale: number;
  pageBlocks: GroupedBlock[];
  isReadOnly: boolean;
  isAssignStep: boolean;
  dimensions: { width: number; height: number };
  onBlockSelected: (reqId: string) => void;
  sectionsMap: Record<string, ToImmutable<Section>>;
  keysOfSectionMap: string[];
  groupedFilteredRequirements: Record<string, Pick<Requirement, "id">>;
};

const PageWrapper = ({
  keysOfSectionMap,
  onBlockSelected,
  isAssignStep,
  page,
  dimensions = { height: 0, width: 0 },
  scale,
  pageBlocks,
  isReadOnly,
  sectionsMap,
  groupedFilteredRequirements,
}: Props) => {
  return (
    <div className="relative">
      <Page
        className="mx-auto w-fit"
        pageNumber={page}
        renderAnnotationLayer={false}
        scale={scale}
        loading={
          <div className="absolute inset-0 flex items-center justify-center">
            <ScreenSpinner />
          </div>
        }
      >
        {pageBlocks?.map((block) => (
          <PageBlock
            isActiveInFilter={!!groupedFilteredRequirements[block.requirement.requirement.id]}
            sectionsMap={sectionsMap}
            pageBlocks={pageBlocks}
            dimensions={dimensions}
            onBlockSelected={onBlockSelected}
            isReadOnly={isReadOnly}
            block={block}
            isAssignStep={isAssignStep}
            scale={scale}
            keysOfSectionMap={keysOfSectionMap}
            key={block.id}
          />
        ))}
      </Page>
    </div>
  );
};

interface PageBlockProps extends Omit<Props, "page" | "groupedFilteredRequirements"> {
  block: GroupedBlock;
  isActiveInFilter: boolean;
}

const PageBlock = memo(
  ({
    block,
    keysOfSectionMap,
    isAssignStep,
    dimensions,
    scale,
    onBlockSelected,
    isReadOnly,
    sectionsMap,
    isActiveInFilter,
  }: PageBlockProps) => {
    const { setToast } = useDocViewNotification();
    const ref = useRef<HTMLDivElement | null>(null);
    const selection = useSelection();
    const dispatch = useAppDispatch();
    const activeSection = useAppSelector((store) => store.currentExtractionState.activeSection);
    const openState = useAppSelector((store) => store.currentExtractionState.templateOpenState);
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const { setExtractionRequirementSkipped, assignExtractionRequirement } = useExtractionOperations();
    const trackUserEvent = useTrackUserMetric();
    const { handleUnmergeRequirement, isLoading: isUnmerging } = useUnmergeRequirement();
    const isSelected = useMemo(
      () => selection.selectedBlocks?.some((selectedBlock) => selectedBlock.id === block.id),
      [block.id, selection.selectedBlocks]
    );
    const { requirement, bounds } = block;
    const existsInTemplate = keysOfSectionMap.includes(requirement.proposal_reference.section_id || "");

    const isRequirementsStep = extraction?.step === StepValue.Requirements;
    const isExtractedRequirement = extraction?.step === StepValue.Requirements && requirement?.requirement?.skipped;
    const isSelectedRequirement = !requirement?.requirement?.skipped;
    const isSelectedAndHighlighted =
      !isReadOnly &&
      !requirement?.requirement?.skipped &&
      (extraction?.step === StepValue.Requirements || activeSection?.id);
    const isAssigned = isAssignStep && existsInTemplate && !!requirement.proposal_reference.section_id;
    const isAssignedAndHighlighted = isAssignStep && !isReadOnly && existsInTemplate && activeSection?.id;

    const requirementStateToClassName = isExtractedRequirement
      ? REQUIREMENT_CLASSNAMES.extracted
      : isAssigned
      ? REQUIREMENT_CLASSNAMES.assigned
      : isSelectedRequirement
      ? REQUIREMENT_CLASSNAMES.selected
      : "";
    const mouseLeaveHandler = useCallback(
      (e: MouseEvent<HTMLDivElement>) => {
        const allSimilarBlocks = document.querySelectorAll(`[data-element='${requirement.requirement.element_id}']`);

        const className = isAssignedAndHighlighted
          ? "hovered-assigned-requirement"
          : isExtractedRequirement
          ? "hovered-extracted-requirement"
          : isSelectedAndHighlighted
          ? "hovered-selected-requirement"
          : "";

        allSimilarBlocks?.forEach((node) => {
          className && node.classList.remove(className);
        });

        e.stopPropagation();
      },
      [isAssignedAndHighlighted, isExtractedRequirement, isSelectedAndHighlighted, requirement.requirement.element_id]
    );

    if (!requirement || !bounds || (!!requirement?.requirement?.skipped && isAssignStep)) return null;
    const { top_left: topLeft, top_right: topRight, bottom_right: bottomRight } = bounds;

    const top = topRight.Y * dimensions.height * scale - HIGHLIGHT_BUFFER;
    const left = topLeft.X * dimensions.width * scale - HIGHLIGHT_BUFFER;
    const bottom = bottomRight.Y * dimensions.height * scale + HIGHLIGHT_BUFFER;
    const right = topRight.X * dimensions.width * scale + HIGHLIGHT_BUFFER;
    const width = right - left;
    const height = bottom - top;

    return (
      <div
        ref={ref}
        data-element={requirement.requirement.element_id}
        className={`${requirementStateToClassName} !z-[2] outline-0 group absolute border border-gray-400 bg-[rgba(0,0,0,0.15)] ds-selectable ${
          isSelected ? "highlighted-dragged-selected-requirement" : ""
        }`}
        style={{
          top,
          left,
          width,
          height,
        }}
        onClick={() => {
          if (extraction?.id && !isReadOnly && isRequirementsStep) {
            setExtractionRequirementSkipped(
              extraction.id,
              requirement.requirement.id,
              !requirement?.requirement?.skipped
            );

            if (requirement?.requirement?.skipped) onBlockSelected(requirement.requirement.id);
          }

          if (extraction?.step === StepValue.Assign && activeSection?.id) {
            trackUserEvent("Generate: Requirement Assigned", {
              analysis_id: extraction?.id,
              solicitation_type: extraction?.solicitation_type,
            });
            assignExtractionRequirement(extraction?.id, requirement.requirement.id, activeSection?.id);
          } else if (extraction?.step === StepValue.Assign && !activeSection?.id) {
            setToast.warning({ msg: "Please select a section to assign the requirement." });
          }
        }}
        css={[
          !isReadOnly &&
            ((extraction?.step === StepValue.Assign && activeSection?.id) ||
              extraction?.step === StepValue.Requirements) &&
            tw`cursor-pointer`,
          isExtractedRequirement && tw`outline-gray-500`, // Extracted Requirement (Grey)
          isSelectedRequirement && tw`border-[#2a46ab] outline-[#2a46ab] bg-[rgba(42, 70, 171, .25)]`, // Selected Requirement (Blue)
          isAssigned && tw`bg-[rgba(14, 120, 8, .25)] outline-[rgb(14, 120, 8)] border-[rgb(14, 120, 8)]`, // Assigned Requirement (Green)
        ]}
        onMouseMove={
          !isReadOnly
            ? () => {
                const allSimilarBlocks = document.querySelectorAll(
                  `[data-element='${requirement.requirement.element_id}']`
                );

                const className = isAssignedAndHighlighted
                  ? "hovered-assigned-requirement"
                  : isExtractedRequirement
                  ? "hovered-extracted-requirement"
                  : isSelectedAndHighlighted
                  ? "hovered-selected-requirement"
                  : "";
                allSimilarBlocks?.forEach((node) => {
                  className && node.classList.add(className);
                });
              }
            : undefined
        }
        onMouseLeave={!isReadOnly ? mouseLeaveHandler : undefined}
      >
        {!isReadOnly && isAssignStep && !!activeSection?.id && (
          <>
            {!!requirement.proposal_reference.section_id && existsInTemplate ? (
              <div
                title={sectionsMap[requirement.proposal_reference.section_id]?.title}
                className="cursor-pointer text-xxs select-none flex items-center gap-1.5 absolute p-0.5 shadow-sharp-thin rounded transition-opacity bg-gray-darkest text-gray-200 disable-drag-select"
                style={{
                  top: 0,
                  left: "calc(100% + 6px)",
                }}
              >
                <button
                  onClick={(e) => {
                    if (requirement.proposal_reference.section_id && requirement.proposal_reference.volume_id) {
                      dispatch(
                        setTemplateOpenState([
                          ...openState,
                          requirement.proposal_reference.section_id,
                          requirement.proposal_reference.volume_id,
                        ])
                      );

                      const blockNode = document.getElementById(requirement.proposal_reference.volume_id);

                      blockNode?.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                      });
                    }

                    e.stopPropagation();
                  }}
                  className="max-w-[160px] truncate pl-1"
                >
                  {sectionsMap[requirement.proposal_reference.section_id]?.title || "No section title"}
                </button>
                <div className="w-px bg-gray-500 h-3.5" />
                <div className="p-[3px] -ml-1 text-xs rounded hover:bg-zinc-700 hover:text-white">
                  {requirement.proposal_reference.section_id === activeSection.id ? (
                    <Minus size={12} />
                  ) : (
                    <Plus size={12} />
                  )}
                </div>
              </div>
            ) : (
              <div
                className="absolute text-xs whitespace-nowrap p-1 shadow-sharp-thin rounded transition-opacity bg-gray-darkest text-gray-200 hover:text-white hover:bg-zinc-700"
                style={{
                  top: 0,
                  left: "calc(100% + 6px)",
                }}
              >
                <Plus size={14} />
              </div>
            )}
          </>
        )}
        {!isReadOnly && isRequirementsStep && block.isMergedRequirement && (
          <div
            className="absolute pl-1.5 -top-0.5 left-full opacity-0 group-hover:opacity-100"
            css={[isUnmerging && tw`opacity-100`, isActiveInFilter && tw`right-[calc(100%+26px)]`]}
            onMouseMove={!isReadOnly ? mouseLeaveHandler : undefined}
          >
            <button
              onClick={(e) => {
                if (isUnmerging) return;
                handleUnmergeRequirement(requirement.requirement.id);
                e.stopPropagation();
              }}
              className="flex items-center gap-1.5 text-xs whitespace-nowrap py-1 px-2 bg-gray-darkest text-gray-200 shadow-sharp-thin rounded hover:bg-zinc-700 hover:text-white disabled:text-gray-300 disabled:cursor-not-allowed disabled:border-gray-500 disabled:bg-gray-400 disable-child-hover"
              disabled={isUnmerging}
            >
              {isUnmerging ? <SpinnerCircle className="h-3 w-3" /> : <Split size={12} />} Unmerge
            </button>
          </div>
        )}
        {isActiveInFilter && (
          <div className="absolute pointer-events-none -top-0.5 -left-0.5 -right-0.5 -bottom-0.5 border-2 border-orange-500">
            <div className="bg-orange-500 px-1 flex rounded-l text-white items-center absolute -top-0.5 -bottom-0.5 right-full">
              <SearchCheck size={14} />
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default memo(PageWrapper);
