/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import { EllipsisVertical, RefreshCw } from "lucide-react";

const DraftExtractionCard = () => {
  return (
    <div className="relative overflow-hidden backdrop-blur-0 shadow-md flex flex-col justify-between h-[184px] bg-white border border-gray-light rounded-md p-4 hover:bg-slate-100">
      <div className="backdrop-blur absolute z-[1] top-0 bottom-0 left-0 right-0 flex flex-col gap-3 justify-center items-center">
        <RefreshCw
          size={20}
          className="text-action"
          css={[
            {
              animation: "rotateAnimation 0.8s infinite linear",
            },
          ]}
        />
        <div className="text-action text-sm font-medium">
          Template Extracting
          <span className="loading-ellipsis" />
        </div>
      </div>
      <div className="flex-1 p-1 text-sm font-semibold text-gray-darkest bg-transparent outline-none resize-none w-full">
        Template loader card
      </div>
      <div className="flex flex-row gap-4 justify-between items-end">
        <div className="flex flex-col gap-1.5 items-start">
          <button className="h-7 max-w-full flex items-center text-gray-700 py-1 px-1.5 rounded bg-slate-100">
            <span className="text-xs text-slate-500">
              <Avatar size={18} empty />
            </span>
          </button>
          <div className="text-xs text-slate-500 flex items-center gap-1.5">1 Section | 0 Done</div>
        </div>
        <div className="p-1 text-slate-600">
          <EllipsisVertical size={16} />
        </div>
      </div>
    </div>
  );
};

export default DraftExtractionCard;
