import { Modal } from "components/organisms/modal";
import { Button } from "components/editor/components";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
    INITIAL_TEMPLATE,
    appendVolume,
    resetTemplateExtractionState,
    toggleExtractError,
    toggleLoading,
    updateTemplate,
} from "store/reducers/templates/templateExtractionReducer";
import DraftTemplate from "../DraftTemplate";
import { useSubmitTemplate } from "../hooks";
import { toggleTemplateExtractionFromRequirementsModal } from "store/reducers/modalsSlice";
import { useGenerateTemplate } from "./hooks";
import { EventStreamContentType } from "@microsoft/fetch-event-source";
import { useNotification } from "context/notificationContext";
import { useEffect } from "react";
import { useObserveSseController } from "hook/useObserveSseController";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { v4 } from "uuid";
import { Volume } from "types/Templates";
import { useMutation } from "YJSProvider/createYJSContext";
import { filter } from "YJSProvider/LiveObjects";

export type TemplateExtractionFromRequirementsModalProps = {
    open: boolean;
};

const TemplateExtractionFromRequirementsModal = ({ open }: TemplateExtractionFromRequirementsModalProps) => {
    const { setToast, clearToast } = useNotification();
    const { isSubmitting, submitTemplate } = useSubmitTemplate();
    const { isExtractError, isExtractLoading, template } = useAppSelector((root) => root.templateExtraction);
    const dispatch = useAppDispatch();
    const canSubmit = !isExtractLoading && !isExtractError && !isSubmitting && !!template.volumes?.length;

    const { generateTemplate, abortConnection } = useGenerateTemplate({
        onmessage(msg) {
            if (msg.event === "FatalError") {
            }

            if (!!msg.data?.length) {
                try {
                    const parsedVolume: Volume = JSON.parse(msg.data);
                    const isEmptyVolume = !Object.keys(parsedVolume).length;
                    if (isEmptyVolume) {
                        dispatch(toggleExtractError(true));
                    } else {
                        const templateVolume = {
                            ...parsedVolume,
                            id: v4(),
                            sections: parsedVolume.sections.map((sec) => ({ ...sec, id: v4(), subsections: [] })),
                        };
                        dispatch(appendVolume(templateVolume));
                    }
                } catch {
                    dispatch(toggleExtractError(true));
                }
            }
        },
        async onopen(response) {
            dispatch(updateTemplate(INITIAL_TEMPLATE));
            if (response.ok && response.headers.get("content-type") === EventStreamContentType) {
                return; // everything's good
            } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                setToast.error({
                    title: "Unable to generate template",
                    msg: (
                        <div>
                            We could not generate the template due to a technical issue on our end,{" "}
                            <Button
                                onClick={() => generateAction()}
                                variant="link"
                                size="sm"
                                className="inline !min-h-0"
                            >
                                retry
                            </Button>
                            . If the issue persists,{" "}
                            <a
                                className="font-medium text-action duration-150 hover:text-action-hover"
                                href="mailto:support@vultron.ai"
                            >
                                contact us
                            </a>{" "}
                            for assistance.
                        </div>
                    ),
                });
                dispatch(toggleLoading(false));
            } else {
            }
        },
        onclose() {
            dispatch(toggleLoading(false));
        },
        onerror(err) {
            setToast.error({
                title: "Unable to generate template",
                msg: (
                    <div>
                        We could not generate the template due to a technical issue on our end,{" "}
                        <Button onClick={() => generateAction()} variant="link" size="sm" className="inline !min-h-0">
                            retry
                        </Button>
                        . If the issue persists,{" "}
                        <a
                            className="font-medium text-action duration-150 hover:text-action-hover"
                            href="mailto:support@vultron.ai"
                        >
                            contact us
                        </a>{" "}
                        for assistance.
                    </div>
                ),
            });
            dispatch(toggleLoading(false));
            if (err instanceof Error) {
                throw err; // rethrow to stop the operation
            } else {
            }
        },
    });

    useObserveSseController(abortConnection, () => dispatch(toggleLoading(false)));

    const generateAction = useMutation(
        ({ storage }) => {
            if (isExtractLoading) return;
            dispatch(toggleLoading(true));
            dispatch(toggleExtractError(false));

            const matrix = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
            if (!matrix) return;
            const filteredMatrix = filter(
                matrix,
                (row) =>
                    !!row.get("requirement")?.get("id") &&
                    !!(row.get("requirement")?.get("content") || row.get("requirement")?.get("summarized_content"))
            );

            if (!filteredMatrix.length) return;

            const matrixPayload = filteredMatrix.map((row) => ({
                id: row.get("requirement")?.get("id"),
                content:
                    row.get("requirement")?.get("content") || row.get("requirement")?.get("summarized_content") || "",
                response: row.get("written_content"),
            }));

            generateTemplate({ requirements: matrixPayload });
        },
        [isExtractLoading, canSubmit, generateTemplate]
    );

    useEffect(() => {
        if (open) generateAction();
        if (!open) clearToast();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Modal
            contentProps={{ css: tw`h-[90vh] max-h-[1600px] w-[90vw] max-w-[800px]` }}
            footerProps={{ css: tw`mt-0 pt-4` }}
            header="Generate Template"
            description="Generate a customized template based on the requirements. This can take up to one minute."
            body={
                <div className="flex flex-1 pt-5 pr-4 pl-4 gap-3 min-h-0">
                    <DraftTemplate onRetry={() => generateAction()} />
                </div>
            }
            footer={
                <div className="flex gap-2 justify-end">
                    <Button
                        onClick={() => {
                            dispatch(toggleTemplateExtractionFromRequirementsModal({ open: false }));
                            dispatch(resetTemplateExtractionState());
                            abortConnection();
                        }}
                        className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                        variant="outline"
                        size="md"
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={isSubmitting}
                        onClick={() => {
                            submitTemplate();
                        }}
                        disabled={!canSubmit}
                        variant="primary"
                        size="md"
                    >
                        Import
                    </Button>
                </div>
            }
            open={open}
            onOpenChange={(o) => {
                if (!o) {
                    dispatch(resetTemplateExtractionState());
                    abortConnection();
                }
                dispatch(toggleTemplateExtractionFromRequirementsModal({ open: o }));
            }}
        />
    );
};

export default TemplateExtractionFromRequirementsModal;
