/** @jsxImportSource @emotion/react */

import { PropsWithChildren } from "react";
import { ChevronRight } from "lucide-react";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import "twin.macro";
import { useBulkUpdateOperation } from "./hooks";
import { styles } from "components/molecules/dropdown-menu/styles";
import { useNotification } from "context/notificationContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlagSet } from "types/FeatureFlagSet";
import { GenerationModePopoverContent } from "components/molecules/generation-mode-popover-content";
import { ResponseTolerancePopoverContent } from "components/molecules/response-tolerance-popover-content";
import { SPEED_TO_META } from "components/molecules/response-quality-dropdown/constants";
import { PromptMode } from "./RequirementsBulkUpdate";

export type RequirementsMenubarPopoverProps = {
  contentProps?: DropdownMenu.DropdownMenuContentProps;
  requirementCount: number;
  setPromptMode: (promptMode: PromptMode) => void;
};

const GenerationSettingsMenu = ({
  children,
  contentProps,
  requirementCount,
  setPromptMode,
}: PropsWithChildren<RequirementsMenubarPopoverProps>) => {
  const { setResponseSpeed, setResponseTolerance } = useBulkUpdateOperation();
  const { setToast } = useNotification();
  const flags = useFlags<FeatureFlagSet>();

  return (
    <DropdownMenu.Root modal={false}>
      <DropdownMenu.Trigger asChild>{children}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <DropdownMenu.Content className={styles.content} align="start" sideOffset={4} {...contentProps}>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="outline-none" onClick={() => setPromptMode(PromptMode.WritingPrompt)}>
              <div className={styles.itemTrigger}>
                <div className="flex items-center gap-2">Add writing prompt</div>
              </div>
            </DropdownMenu.SubTrigger>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="outline-none" onClick={() => setPromptMode(PromptMode.Guideline)}>
              <div className={styles.itemTrigger}>
                <div className="flex items-center gap-2">Add guideline</div>
              </div>
            </DropdownMenu.SubTrigger>
          </DropdownMenu.Sub>
          <DropdownMenu.Sub>
            <DropdownMenu.SubTrigger className="outline-none">
              <div className={styles.itemTrigger}>
                <div className="flex items-center gap-2">Set sensitivity</div>
                <div className={styles.chevronRight}>
                  <ChevronRight size={14} />
                </div>
              </div>
            </DropdownMenu.SubTrigger>
            <DropdownMenu.Portal>
              <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                <ResponseTolerancePopoverContent
                  onSensitivitySelect={(responseTolerance) => {
                    setResponseTolerance(responseTolerance);
                    setToast.success({
                      msg: `Changed the sensitivity of ${requirementCount} ${
                        requirementCount > 1 ? "requirements" : "requirement"
                      } to ${responseTolerance}.`,
                    });
                  }}
                  tw="w-full text-sm"
                />
              </DropdownMenu.SubContent>
            </DropdownMenu.Portal>
          </DropdownMenu.Sub>
          {flags.qualityResponse && (
            <DropdownMenu.Sub>
              <DropdownMenu.SubTrigger className="outline-none">
                <div className={styles.itemTrigger}>
                  <div className="flex items-center gap-2">Set generation mode</div>
                  <div className={styles.chevronRight}>
                    <ChevronRight size={14} />
                  </div>
                </div>
              </DropdownMenu.SubTrigger>
              <DropdownMenu.Portal>
                <DropdownMenu.SubContent className={styles.subcontent} sideOffset={8}>
                  <GenerationModePopoverContent
                    onGenerationModeSelect={(generationMode) => {
                      setResponseSpeed(generationMode);
                      setToast.success({
                        msg: `Changed the generation mode of ${requirementCount} ${
                          requirementCount > 1 ? "requirements" : "requirement"
                        } to ${SPEED_TO_META[generationMode].label}.`,
                      });
                    }}
                    tw="w-full text-sm"
                  />
                </DropdownMenu.SubContent>
              </DropdownMenu.Portal>
            </DropdownMenu.Sub>
          )}
        </DropdownMenu.Content>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
};

export default GenerationSettingsMenu;
