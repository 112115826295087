/** @jsxImportSource @emotion/react */

import { FileMention, removeAssistantFiles } from "store/reducers/ai-assistant/aiAssistantReducer";
import { useAppDispatch } from "store/storeTypes";
import { getFileIcon } from "utils/getFileIcon";
import tw from "twin.macro";
import { X } from "lucide-react";

type Props = {
  file: FileMention;
  idx: number;
  fileMenuOpen: boolean;
  isMultiple?: boolean;
};

const FileChip = ({ file, idx, fileMenuOpen, isMultiple, ...props }: Props) => {
  const { id, name, fileExtensionType } = file;
  const dispatch = useAppDispatch();

  return (
    <div
      className="bg-white cursor-default overflow-hidden flex items-center min-w-fit h-7 min-h-[28px] text-xs rounded border border-light shadow-expanded text-gray-600"
      css={[
        isMultiple && {
          opacity: 0,
          animation: "fade-in 0.25s ease-in-out forwards",
          animationDelay: `${idx * 20}ms`,
          minHeight: 32,
        },
      ]}
      {...props}
    >
      <div className="pl-1.5 pr-2 py-1 flex items-center gap-1.5">
        <img src={getFileIcon(fileExtensionType)} alt="" className="w-[14px] h-full" />
        <div className="max-w-[130px] font-medium truncate" css={[isMultiple && tw`min-w-[100px] max-w-[250px]`]}>
          {name}
        </div>
      </div>
      <button
        onClick={() => dispatch(removeAssistantFiles([id]))}
        className="text-sm shrink-0 ml-auto h-full border border-dashed border-transparent border-l-gray-light text-gray-500 w-[26px] justify-center rounded-r-[3px] flex items-center duration-150 hover:border-gray-darkest hover:text-gray-darkest"
        css={[isMultiple && tw`w-[28px]`]}
      >
        <X size={14} />
      </button>
    </div>
  );
};

export default FileChip;
