import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";
import { SourceType } from "components/copilot/CopilotSchemaTypes";

export type WritingPromptIdeaContentBody = {
    content: string;
    reference_id?: string | null;
    source_name: string;
    source_type: SourceType;
    source_extension_type?: string;
    source_content: string;
};

type State = {
    complianceMatrixRow: ComplianceMatrixRow | null;
    isIdeationLoading: boolean;
    isSubmitting: boolean;
};

const initialState: State = {
    complianceMatrixRow: null,
    isIdeationLoading: false,
    isSubmitting: false,
};

const writingPromptIdeationReducer = createSlice({
    name: "writingPromptIdeationReducer",
    initialState,
    reducers: {
        setWritingPromptIdeationState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
        openWritingPromptIdeation: (state: State, action: PayloadAction<State["complianceMatrixRow"]>) => {
            const complianceMatrixRow = action.payload;
            return { ...state, complianceMatrixRow };
        },
        closeWritingPromptIdeation: () => {
            return initialState;
        },
    },
});

export const { openWritingPromptIdeation, closeWritingPromptIdeation, setWritingPromptIdeationState } =
    writingPromptIdeationReducer.actions;

export default writingPromptIdeationReducer.reducer;
