const MemberProgress = ({ name, completedTasks, totalTasks }) => {
    let percentage = (completedTasks / totalTasks) * 100;

    const styles = {
        background:
            totalTasks === 0
                ? "#5B6B78"
                : percentage < 50
                ? "#DC2626"
                : percentage >= 50 && percentage < 75
                ? "#E99C26"
                : percentage >= 75
                ? "#2CA87F"
                : "gray",
    };

    return (
        <div className="w-full flex h-[40px] items-center justify-between">
            <div className="w-[70%] flex gap-3 items-center">
                <div className="w-2 h-2 bg-[#F43030] rounded" style={{ ...styles }} />
                <span className="text-[14px] font-medium text-[#5B6B79]">{name}</span>
            </div>
            <MemberProgressBadge completedTasks={completedTasks} totalTasks={totalTasks} />
        </div>
    );
};

const MemberProgressBadge = ({ completedTasks, totalTasks }) => {
    return (
        <div className="w-[50px] flex justify-center border border-[#D8DDE2] rounded-md text-[12px] text-[#8A95A0]">
            {completedTasks}/{totalTasks}
        </div>
    );
};
export default MemberProgress;
