import { Section, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import SectionProposal from "components/copilot/Framework/section-proposal";
import { useOutletContext } from "react-router-dom";

const DraftSectionProposal = () => {
    const { section, volume } = useOutletContext<{ section?: Section; volume?: Volume }>();

    return (
        <div className="relative flex-1 h-full w-full">
            <div className="pr-3 pl-4 pb-5 absolute top-0 bottom-0 left-0 right-0 overflow-y-auto">
                {!!volume && !!section && <SectionProposal volume={volume} section={section} />}
            </div>
        </div>
    );
};

export default DraftSectionProposal;
