import { Navigate, Outlet, useLocation } from "react-router-dom";
import logo from "Assets/svgs/vultron-logo.svg";
import { useSelector } from "react-redux";
import Loader from "utils/Spinner";
import { useFlags } from "launchdarkly-react-client-sdk";
import { TOP_BANNER } from "const-values/Banner";
import TopBanner from "components/atoms/top-banner";

const WelcomePage = () => {
  const { isAuthenticated, isLoading } = useSelector((store) => store.auth);
  let location = useLocation();
  const { from } = location.state || { from: { pathname: "/select-workspace" } };

  const flags = useFlags();
  //
  if (isLoading)
    return (
      <div className="h-screen mx-auto flex content-center items-center justify-center py-5 px-2 text-center sm:px-4">
        <Loader />
      </div>
    );
  if (isAuthenticated) return <Navigate to={from?.pathname} replace />;
  return (
    <div>
      {!!flags.topBannerV2?.bannerCopy && <TopBanner copy={flags.topBannerV2?.bannerCopy} />}
      <div css={[!!flags.topBannerV2?.bannerCopy && { height: `calc(100vh - ${TOP_BANNER.HEIGHT}px)` }]}></div>
      <div className="flex w-full min-h-[100vh]">
        {/* left */}
        <div className="bg-white flex-1 flex flex-col">
          {/* logo */}
          <div className="absolute top-10 left-14 z-[2]">
            <img width="150px" height="139px" src={logo} alt="logo" />
          </div>
          {/* content-wrapper */}
          <div className="flex flex-col items-center w-full h-full justify-center mt-9">
            <Outlet />
          </div>
        </div>
        {/* right */}
        <div className="bg-header-img w-1/2 max-w-[1200px]" />
      </div>
    </div>
  );
};

export default WelcomePage;
