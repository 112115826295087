import { CopilotPresencePage } from "types/Presence";
import { ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { getSectionsMap } from "utils/Liveblocks/Framework";
import { motion } from "framer-motion";
import { useAppSelector } from "store/storeTypes";
import { useTrackUserMetric } from "utils/metrics";
import { Link, useSearchParams } from "react-router-dom";
import { useEffect, useMemo } from "react";

import CompleteGif from "./complete.gif";
import CountUp from "react-countup";
import TemplateManager from "../document-sidebar/template-manager";

const Import = () => {
  const trackUserEvent = useTrackUserMetric();

  const viewSummary = useAppSelector((store) => store.currentExtractionState.viewSummary);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
  const matrix = useMemo(
    () => extraction?.compliance_matrix.filter((row) => !row.requirement.skipped && !row.requirement.soft_deleted),
    [extraction?.compliance_matrix]
  );
  const sectionsMap = useMemo(() => getSectionsMap(extraction?.framework?.volumes), [extraction?.framework?.volumes]);

  const matrixAssigned = useMemo(
    () =>
      extraction?.compliance_matrix.filter(
        (row) => !!row.proposal_reference.section_id && !!sectionsMap[row.proposal_reference.section_id]
      ),
    [extraction?.compliance_matrix, sectionsMap]
  );
  const outlineLengths = useMemo(
    () =>
      extraction?.framework.volumes.reduce<{ drafts: number; sections: number; subsections: number }>(
        (acc, draft) => {
          const parentSections = draft.sections.filter((sec) => !sec.parent_id);
          const subsections = draft.sections.filter((sec) => !!sec.parent_id);
          return {
            drafts: acc.drafts + 1,
            sections: acc.sections + parentSections.length,
            subsections: acc.subsections + subsections.length,
          };
        },
        { drafts: 0, sections: 0, subsections: 0 }
      ),
    [extraction?.framework.volumes]
  );

  useEffect(() => {
    if (!viewSummary) return;

    const requirement_count = extraction?.compliance_matrix.filter(
      (row) => !row.requirement.skipped && !row.requirement.soft_deleted
    ).length;
    const assigned_requirement_count = extraction?.compliance_matrix.filter(
      (row) => !!row.proposal_reference.section_id
    ).length;
    const draft_count = extraction?.framework.volumes.length;
    const section_count = extraction?.framework.volumes.reduce((acc, draft) => {
      return acc + draft.sections.length;
    }, 0);
    const subsection_count = extraction?.framework.volumes.reduce((acc, draft) => {
      return acc + draft.sections.filter((sec) => !!sec.parent_id).length;
    }, 0);
    trackUserEvent("Generate: View Summary", {
      requirement_count,
      assigned_requirement_count,
      draft_count,
      section_count,
      subsection_count,
      analysis_id: extraction?.id,
      solicitation_type: extraction?.solicitation_type,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewSummary]);

  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")?.toLocaleLowerCase();

  if (extraction?.status === ExtractionStatus.Completed && !viewSummary)
    return (
      <div className="h-full w-full flex flex-col items-center justify-center">
        <img src={CompleteGif} alt="complete animation" className="w-[300px]" />
        <div className="text-sm text-gray-500">
          You have successfully generated the compliance matrix and proposal outline.
        </div>
        <div className="text-sm text-gray-500">
          You can find the results in the{" "}
          <Link
            className="font-semibold text-action"
            to={`/dashboard/contracts/details?tab=${CopilotPresencePage.ComplianceMatrix}&id=${id}`}
          >
            Requirements
          </Link>{" "}
          and{" "}
          <Link
            className="font-semibold text-action"
            to={`/dashboard/contracts/details?tab=${CopilotPresencePage.Template}&id=${id}`}
          >
            Draft
          </Link>{" "}
          tabs. Head over to the{" "}
          <Link
            className="font-semibold text-action"
            to={`/dashboard/contracts/details?tab=${CopilotPresencePage.Template}&id=${id}`}
          >
            Draft
          </Link>{" "}
          tab to start generating proposal content.
        </div>
      </div>
    );

  return (
    <div className="flex flex-col p-6 gap-6 h-full">
      <div className="flex justify-center">
        <div className="flex flex-row gap-6 justify-center w-full p-4 bg-layout-gray-light border border-gray-lightest rounded-md">
          <div className="max-w-64 flex flex-col justify-center items-center gap-1 flex-1 bg-white rounded-md shadow-md border border-gray-lightest h-[85px]">
            <CountUp duration={4} className="font-bold text-xl" end={matrix?.length || 0} />
            <span className="font-light text-xs text-gray-500">Total Requirements</span>
          </div>
          <div className="max-w-64 flex flex-col justify-center items-center gap-1 flex-1 bg-white rounded-md shadow-md border border-gray-lightest h-[85px]">
            <CountUp duration={4} className="font-bold text-xl" end={matrixAssigned?.length || 0} />
            <span className="font-light text-xs text-gray-500">Assigned Requirements</span>
          </div>
          <div className="max-w-64 flex flex-col justify-center items-center gap-1 flex-1 bg-white rounded-md shadow-md border border-gray-lightest h-[85px]">
            <CountUp duration={4} className="font-bold text-xl" end={outlineLengths?.drafts || 0} />
            <span className="font-light text-xs text-gray-500">Drafts</span>
          </div>
          <div className="max-w-64 flex flex-col justify-center items-center gap-1 flex-1 bg-white rounded-md shadow-md border border-gray-lightest h-[85px]">
            <CountUp duration={4} className="font-bold text-xl" end={outlineLengths?.sections || 0} />
            <span className="font-light text-xs text-gray-500">Sections</span>
          </div>
          <div className="max-w-64 flex flex-col justify-center items-center gap-1 flex-1 bg-white rounded-md shadow-md border border-gray-lightest h-[85px]">
            <CountUp duration={4} className="font-bold text-xl" end={outlineLengths?.subsections || 0} />
            <span className="font-light text-xs text-gray-500">Subsections</span>
          </div>
        </div>
      </div>
      <div className="relative flex flex-col justify-center overflow-hidden flex-1 items-center gap-6 bg-layout-gray-light rounded-md border border-gray-lightest">
        <div className="flex gap-2 flex-col items-center pt-10 pb-[48px] w-full h-full overflow-y-auto">
          <motion.div
            key={String(viewSummary)}
            className="flex flex-col gap-1 text-center"
            transition={{ duration: 0.6 }}
            variants={{
              hidden: {
                opacity: 0,
                y: "0.85rem",
              },
              visible: {
                opacity: 1,
                y: "0rem",
              },
            }}
            initial="hidden"
            animate="visible"
          >
            {viewSummary ? (
              <>
                <span className="text-2xl text-slate-800 font-semibold">Generation Summary</span>
                <span className="text-slate-500 text-base">Here is what you generated.</span>
              </>
            ) : (
              <>
                <span className="text-2xl text-slate-800 font-semibold">Review the Outline</span>
                <span className="text-slate-500 text-base">Please take a moment to ensure the outline is correct.</span>
              </>
            )}
          </motion.div>
          <div className="flex flex-col gap-4 flex-1 w-1/2 max-w-[800px]">
            <TemplateManager />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Import;
