import { CopilotPresencePage } from "types/Presence";
import { Sheet } from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { useAppSelector } from "store/storeTypes";
import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";

export const useDocumentSubItems = (id: string | undefined) => {
  const { pathname } = useLocation();
  const isProjectSpecific = pathname.includes("project-specific");
  const isContentLibrary = pathname.includes("content-library");

  return [
    {
      label: "Project Specific",
      to: `/dashboard/contracts/details/documents/project-specific?tab=${CopilotPresencePage.Documents}&id=${id}`,
      isActive: isProjectSpecific,
    },
    {
      label: "Content Library",
      to: `/dashboard/contracts/details/documents/content-library?tab=${CopilotPresencePage.Documents}&id=${id}`,
      isActive: isContentLibrary,
    },
  ];
};

export const useExtractionSubItems = (
  extractions: NonNullable<ToImmutable<Storage>>["extractions"] | undefined,
  id: string | undefined
) => {
  const { extractionId } = useParams();

  return useMemo(
    () =>
      extractions?.map((extraction) => ({
        label: extraction?.name,
        to: `/dashboard/contracts/details/extractions/${extraction?.id}?tab=${CopilotPresencePage.Extract}&id=${id}`,
        isActive: extractionId === extraction?.id,
      })) || [],
    [extractionId, id, extractions]
  );
};

export const useSheetSubItems = (sheets: Sheet[] | undefined, id: string | undefined) => {
  const activeSheet = useAppSelector((root) => root.requirements.activeSheet);

  return useMemo(
    () =>
      sheets?.map((sheet) => ({
        label: sheet?.name,
        to: `/dashboard/contracts/details?tab=${CopilotPresencePage.ComplianceMatrix}&id=${id}`,
        value: sheet,
        isActive: sheet?.id === activeSheet?.id,
      })),
    [sheets, activeSheet, id]
  );
};

export const useVolumeSubItems = (
  volumes: NonNullable<ToImmutable<Storage>>["framework"] | undefined,
  id: string | undefined
) => {
  const { volumeId } = useParams();

  return useMemo(
    () =>
      volumes?.volumes?.map((volume) => ({
        label: volume.title,
        to: `/dashboard/contracts/details/volumes/${volume?.id}/sections/${volume.sections[0]?.id}/requirements?tab=${CopilotPresencePage.Template}&id=${id}`,
        isActive: volumeId === volume?.id,
      })) || [],
    [id, volumeId, volumes?.volumes]
  );
};
