import { theme } from "twin.macro";
import { ExtractionStatus } from "../CopilotSchemaTypes";

export const MAX_FILE_SIZE = 20000000;

export const EXTRACTION_STATUS_TO_META: Record<
    ExtractionStatus,
    { label: string; colors: { primary: string; secondary: string } }
> = {
    [ExtractionStatus.Extracting]: {
        label: "Extracting",
        colors: {
            primary: theme`colors.action.secondary`,
            secondary: theme`colors.action.DEFAULT`,
        },
    },
    [ExtractionStatus.InProgress]: {
        label: "In Progress",
        colors: {
            primary: theme`colors.yellow.100`,
            secondary: theme`colors.yellow.500`,
        },
    },
    [ExtractionStatus.Ready]: {
        label: "Ready",
        colors: {
            primary: theme`colors.green.100`,
            secondary: theme`colors.green.700`,
        },
    },
    [ExtractionStatus.Completed]: {
        label: "Completed",
        colors: {
            primary: theme`colors.gray.200`,
            secondary: theme`colors.gray.700`,
        },
    },
    [ExtractionStatus.Failed]: {
        label: "Failed",
        colors: {
            primary: theme`colors.gray.200`,
            secondary: theme`colors.red.700`,
        },
    },
    [ExtractionStatus.Archived]: {
        label: "Archived",
        colors: {
            primary: theme``,
            secondary: theme``,
        },
    },
};
