/** @jsxImportSource @emotion/react */

import { VultronBlock as ImmutableVultronBlock } from "../CopilotSchemaImmutableTypes";
import IconButton from "components/atoms/icon-button";
import { useNotification } from "context/notificationContext";
import { useEffect, useState, useMemo } from "react";
import { INITIAL_LOADING_MSG, INITIAL_LOADING_STATE, SECOND_LOADING_MSG, THIRD_LOADING_MSG } from "./constants";
import copyText from "utils/copyText";
import { useTrackUserMetric } from "utils/metrics";
import { useDeleteAssistantBlock } from "./hooks";
import SourceWrapper from "./SourceWrapper";
import { VultronAvatar } from "components/molecules/avatar";
import { RotateCw, Upload, Trash2 } from "lucide-react";
import { useAppSelector } from "store/storeTypes";
import TypingEffect from "components/TypingEffect";
import InternetAnimation from "Assets/gifs/internet-loading.gif";
import { useAssistant } from "./writing-assistant-input/hooks";
import tw from "twin.macro";
import { getWordCount } from "utils/getWordCount";
import LiveDate from "components/molecules/live-date";
import FileCheckIcon from "Assets/ai-assistant/file-check.svg";
import FileXIcon from "Assets/ai-assistant/file-x.svg";
import Tooltip from "components/atoms/tooltip/Tooltip";
import GlobeX from "Assets/globe-x.svg";
import Icon from "components/atoms/icons/Icon";

const VultronBlock = ({ block }: { block: ImmutableVultronBlock }) => {
  const { setToast } = useNotification();
  const deleteBlock = useDeleteAssistantBlock();
  const { streamState } = useAppSelector((root) => root.writingAssistant);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const { refreshMessage } = useAssistant();
  const trackUserEvent = useTrackUserMetric();

  const isStreaming = isStreamingInProgress && blockId === block.id;
  const [internetLoadingState, setInternetLoadingState] = useState(INITIAL_LOADING_STATE);
  const tiptapEditor = useAppSelector((state: any) => state.yjsEditor.storeEditorOfYJSTipTap);

  const determineBlockType = useMemo(() => {
    if (block.enableInternet) {
      return "internet";
    } else if (block.promptSources?.length) {
      return "content search";
    } else {
      return "other";
    }
  }, [block]);

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    let nextTimeout: NodeJS.Timeout;
    if (isStreaming) {
      setInternetLoadingState((prev) => ({ ...prev, msg: INITIAL_LOADING_MSG }));
      timeout = setTimeout(() => setInternetLoadingState((prev) => ({ ...prev, msg: SECOND_LOADING_MSG })), 4000);
      nextTimeout = setTimeout(() => setInternetLoadingState((prev) => ({ ...prev, msg: THIRD_LOADING_MSG })), 8000);
    }
    return () => {
      clearTimeout(timeout);
      clearTimeout(nextTimeout);
    };
  }, [isStreaming]);

  return (
    <div
      className="flex flex-row gap-2 w-full"
      css={[isStreaming && { scrollMarginTop: 8 }]}
      id={isStreaming ? `assistant-block-${blockId}` : undefined}
    >
      <VultronAvatar size={28} />
      <div className="flex flex-col gap-3 pt-1 flex-1 min-w-0">
        <div className="text-gray-darkest text-sm whitespace-pre-wrap">
          {isStreaming && streamCopy}
          {isStreaming && !streamCopy && !block.enableInternet && <TypingEffect style={{ padding: 0, height: 20 }} />}
          {isStreaming && !streamCopy && block.enableInternet && (
            <div className="flex items-center -mt-1">
              <img src={InternetAnimation} alt="" className="w-[33px] h-[33px]" />
              <div className="text-action ml-1">{internetLoadingState.msg}</div>
              <span className="loading-ellipsis" />
            </div>
          )}
          {!isStreaming && !block.error && block.body}
          {!isStreaming && block.error && (
            <div className="text-red-500 px-1.5 py-1 bg-red-50 rounded-md border border-red-500">
              Something went wrong. If the issue persists, please contact us at support@vultron.ai.
            </div>
          )}
        </div>
        {!!block.sources?.length && !isStreaming && <SourceWrapper sources={block.sources} />}
        <div
          className="flex flex-row justify-between items-center"
          css={[isStreaming && tw`opacity-0 pointer-events-none`]}
        >
          <div className="flex flex-row gap-2 items-center text-gray-400">
            <IconButton
              name="Copy"
              className="w-4 h-4 duration-150 hover:text-slate-600"
              onClick={() => {
                copyText(block.body, () => {
                  setToast.success({
                    msg: "Copied",
                  });
                });
                trackUserEvent("Chat: Copy Button Clicked", {
                  word_count: getWordCount(block.body),
                  type: determineBlockType,
                });
              }}
            />
            <div className="h-4 w-px bg-gray-400" />
            <button onClick={() => deleteBlock(block.id)} className="duration-150 hover:text-slate-600">
              <Trash2 size={14} className="stroke-[1.6]" />
            </button>
            {!!block.prompt?.trim() && !isStreamingInProgress && (
              <>
                <div className="h-4 w-px bg-gray-400" />
                <button onClick={() => refreshMessage(block.id)} className="duration-150 hover:text-slate-600">
                  <RotateCw size={14} className="stroke-[1.7]" />
                </button>
              </>
            )}
            {tiptapEditor && (
              <>
                <div className="h-4 w-px bg-gray-400" />
                <button
                  onClick={() => {
                    tiptapEditor.chain().focus().insertContentAt(tiptapEditor.state.selection, block.body).run();
                  }}
                  className="duration-150 hover:text-slate-600"
                >
                  <Upload size={14} className="stroke-[1.6]" />
                </button>
              </>
            )}
            <div className="h-4 w-px bg-gray-400" />
            {block.enableInternet && !block.sources?.length && (
              <Tooltip content="No external sources were used in this response.">
                <div className="relative flex items-center justify-center h-[15px] w-[15px]">
                  <img src={GlobeX} alt="No external sources were used" className="w-4 h-4 text-black" />
                </div>
              </Tooltip>
            )}
            {block.enableInternet && !!block.sources?.length && (
              <Tooltip content="The external sources above were used in this response. Please confirm important information from these sources.">
                <div className="relative flex items-center justify-center h-[15px] w-[15px]">
                  <Icon name="Internet" className="w-4 h-4 text-black" />
                </div>
              </Tooltip>
            )}
            {!block.enableInternet && !!block.sources?.length && (
              <Tooltip content="The sources above were used in this response. Please use the provided sources to confirm important information.">
                <img src={FileCheckIcon} alt="Sources available" className="w-4 h-4" />
              </Tooltip>
            )}
            {!block.enableInternet &&
              !block.sources?.length &&
              (block.promptSources?.length ? (
                <Tooltip content="No relevant sources were found in the selected files for this response. This response was generated using no direct sources.">
                  <img src={FileXIcon} alt="No prompt sources available" className="w-4 h-4" />
                </Tooltip>
              ) : (
                <Tooltip content="No sources have been selected for this response. Please validate and confirm the content. You can select files or enable the internet mode to use direct sources.">
                  <img src={FileXIcon} alt="No sources available" className="w-4 h-4" />
                </Tooltip>
              ))}
          </div>
          <LiveDate date={block.updated_at} duration={30000} />
        </div>
      </div>
    </div>
  );
};
export default VultronBlock;
