/** @jsxImportSource @emotion/react */
import {
  Storage as ImmutableStorage,
  RequirementCompliance,
  RequirementStatus,
} from "components/copilot/CopilotSchemaImmutableTypes";
import { AvatarGroup } from "components/molecules/avatar-group";
import { useEffect, useState } from "react";
import { useAppSelector } from "store/storeTypes";
import AssigneesPopoverContent from "../assignees-popover-content";
import Popover from "components/atoms/popover";
import "twin.macro";
import tw from "twin.macro";
import {} from "components/copilot/ComplianceMatrix/constants";
import { getRequirementFeedback } from "api/api";
import { EMPTY_VALUE } from "const-values/Copilot";
import { VolumesDropdown } from "components/molecules/volumes-dropdown";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { SectionsDropdown } from "components/molecules/sections-dropdown";
import useRequirementOperations from "hook/useRequirementOperations";
import { isEqual, xor } from "lodash";
import { FlexibleTextarea } from "components/molecules/flexible-textarea";
import useGetAvatarGroup from "hook/draft/useAvatarGroup";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import RequirementStatusPopoverContent from "../requirement-status-popover-content";
import CompliancePopoverContent from "../compliance-popover-content";
import EditableContent from "components/molecules/editable-content";
import { useBuildRequirementLink } from "hook/Requirements/useBuildRequirementLink";
import { useNavigate } from "react-router-dom";
import { Button } from "components/editor/components";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import { useStorage } from "YJSProvider/createYJSContext";

const RequirementDetails = () => {
  const frameworkState = useStorage((storage) => storage.framework as ImmutableStorage["framework"]);
  const { activeProject } = useAppSelector((root) => root.project);
  const { activeRequirementId } = useAppSelector((store) => store.requirements);
  const [content, setContent] = useState("");
  const navigate = useNavigate();
  const [isEditing, setIsEditing] = useState(false);
  const { generateRequirementHeading } = useGenerateRequirementHeading();
  const { setComplianceStatus, setNotes, setRequirementStatus, assignToSection, setAssignees, setRequirementContent } =
    useRequirementOperations();
  const { getFrameworkVolumeTitle, getFrameworkVolumeSectionTitle } = useFrameworkOperations(frameworkState);
  const complianceMatrixRow = useStorage(
    (storage) =>
      (storage.compliance_matrix as ImmutableStorage["compliance_matrix"]).find(
        (row) => row.requirement.id === activeRequirementId
      ),
    isEqual
  );
  const {
    requirement,
    notes,
    proposal_reference,
    assigned_user_ids,
    compliance_status,
    requirement_status,
    document,
    written_content,
  } = complianceMatrixRow || {};
  const requirementLink = useBuildRequirementLink(
    proposal_reference?.volume_id,
    proposal_reference?.section_id,
    requirement?.id
  );
  const requirementDeleted = !!activeRequirementId && !requirement;

  const [evaluation, setEvaluation] = useState({
    loadingEvaluation: true,
    needsEvaluation: true,
    createdBy: "",
    categories: [],
    totalGrade: "",
    createdAt: "",
  });

  const fetchEvaluation = async () => {
    try {
      setEvaluation({
        loadingEvaluation: true,
        needsEvaluation: true,
        createdBy: "",
        categories: [],
        totalGrade: "",
        createdAt: "",
      });
      const response = await getRequirementFeedback(activeProject?.internal_contract?.id, activeRequirementId);

      if (response && response.status !== 204) {
        setEvaluation({
          loadingEvaluation: false,
          needsEvaluation: false,
          createdAt: response.data.created_at,
          createdBy: response.data.created_by,
          categories: response.data.categories,
          totalGrade: response.data.total_score,
        });
      }
      if (response && response.status === 204) {
        setEvaluation({
          loadingEvaluation: false,
          needsEvaluation: true,
          createdBy: "",
          categories: [],
          totalGrade: "",
          createdAt: "",
        });
      }
    } catch (error) {
      // add toast
    }
  };

  useEffect(() => {
    setIsEditing(false);
    setContent(requirement?.content || "");
    fetchEvaluation();
  }, [activeRequirementId, requirement?.content]);

  const avatars = useGetAvatarGroup(assigned_user_ids, { size: 24, className: "!text-xs" });

  if (requirementDeleted) return <div className="m-auto text-gray-400">Requirement deleted</div>;
  if (!requirement) return <div className="m-auto text-gray-400">No requirement selected</div>;

  return (
    <div className="flex-1 z-[1] absolute top-0 bottom-0 right-0 left-0 bg-white flex flex-col w-full overflow-y-auto">
      <div className="whitespace-pre-wrap text-sm p-2">
        <button
          onClick={() => setIsEditing(true)}
          className="flex cursor-text rounded-lg p-2 w-full hover:bg-slate-100"
          css={[isEditing && tw`!bg-transparent`]}
        >
          <FlexibleTextarea
            flexible
            readOnly={!isEditing}
            autoFocus={isEditing}
            value={content || ""}
            className="text-gray-600 text-sm flex-1 bg-transparent outline-none resize-none w-full"
            placeholder="Add content..."
            onBlur={() => {
              setRequirementContent(requirement.id, content);
              setIsEditing(false);
            }}
            onChange={(e) => setContent(e.currentTarget.value)}
          />
        </button>
      </div>
      <hr className="border-gray-light" />
      <div className="flex flex-col gap-3 p-4">
        <div className="flex items-center justify-between">
          <div className="text-gray-500 font-medium text-[15px]">Properties</div>
          {!!proposal_reference?.section_id && (
            <Button
              variant="link"
              size="sm"
              className="!min-h-0"
              onClick={() => {
                navigate(requirementLink.path);
              }}
            >
              View in section
            </Button>
          )}
        </div>
        <div className="flex flex-col gap-2.5">
          <div className="flex items-center w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Assignees</label>
            <Popover
              content={
                <AssigneesPopoverContent
                  selectedUsers={assigned_user_ids || []}
                  onUserSelect={(userId) => {
                    const newAssignees = xor(assigned_user_ids || [], [userId]);
                    setAssignees(requirement.id, newAssignees);
                  }}
                  onClearAll={() => setAssignees(requirement.id, [])}
                  tw="p-1"
                />
              }
            >
              <button
                className="text-gray-700 py-1 px-1.5 rounded-md bg-transparent duration-150 hover:bg-gray-100"
                css={[!avatars.length && tw`text-start min-w-[120px] bg-gray-100 hover:bg-gray-200`]}
              >
                {!!avatars.length ? (
                  <div className="flex text-xs items-center gap-2 text-gray-500">
                    <AvatarGroup maxCount={4} size={24} avatars={avatars} />
                    <span>{avatars.length} assigned</span>
                  </div>
                ) : (
                  <span className="text-sm text-slate-500">{EMPTY_VALUE}</span>
                )}
              </button>
            </Popover>
          </div>
          <div className="flex items-center w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Status</label>
            <Popover
              contentProps={{ align: "start", css: tw`mx-0` }}
              content={
                <RequirementStatusPopoverContent
                  selectedStatus={requirement_status || RequirementStatus.Todo}
                  onStatusSelect={(newStatus) => {
                    setRequirementStatus(requirement.id, newStatus);
                  }}
                  tw="p-1"
                />
              }
            >
              <button className="py-1 px-1.5 rounded-md bg-transparent duration-150 hover:bg-gray-100">
                <div className="flex text-sm items-center gap-1.5 truncate">
                  {REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo].icon}
                  {REQUIREMENT_STATUS_TO_META[requirement_status || RequirementStatus.Todo].label}
                </div>
              </button>
            </Popover>
          </div>
          <div className="flex items-center w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Compliance</label>
            <Popover
              contentProps={{ align: "start", css: tw`mx-0` }}
              content={
                <CompliancePopoverContent
                  selectedStatus={compliance_status || RequirementCompliance.Empty}
                  onStatusSelect={(newStatus) => {
                    setComplianceStatus(requirement.id, newStatus);
                  }}
                  tw="p-1"
                />
              }
            >
              <button className="py-1 px-1.5 rounded-md bg-transparent duration-150 hover:bg-gray-100">
                <div className="flex text-sm items-center gap-1.5 truncate">
                  {COMPLIANCE_TO_META[compliance_status || RequirementCompliance.Empty].icon}
                  {COMPLIANCE_TO_META[compliance_status || RequirementCompliance.Empty].label}
                </div>
              </button>
            </Popover>
          </div>
          <div className="flex items-center w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Proposal Volume</label>
            <VolumesDropdown
              contentProps={{ css: tw`min-w-[284px]`, sideOffset: 4, align: "end" }}
              volumes={frameworkState?.volumes}
              includeEmpty
              onSelect={(vol) => {
                assignToSection(requirement.id, vol?.sections[0]?.id || null);
                if (
                  vol?.sections[0] &&
                  !!(written_content || requirement?.content || requirement?.summarized_content)
                ) {
                  generateRequirementHeading({
                    requirement_ids: [requirement.id],
                  });
                }
              }}
              sectionsMustExist
            >
              <div
                className="py-1 px-1.5 rounded-md bg-transparent duration-150 hover:bg-gray-100"
                css={[
                  !proposal_reference?.volume_id &&
                    tw`text-slate-500 text-start flex items-center bg-gray-100 min-h-[32px] min-w-[120px] hover:bg-gray-200`,
                ]}
              >
                <span className="text-sm text-left line-clamp-3" style={{ wordBreak: "break-word" }}>
                  {getFrameworkVolumeTitle(proposal_reference?.volume_id || "") || EMPTY_VALUE}
                </span>
              </div>
            </VolumesDropdown>
          </div>
          <div className="flex items-center w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Proposal Section</label>
            <SectionsDropdown
              activeVolumeId={proposal_reference?.volume_id}
              contentProps={{ css: tw`min-w-[284px]`, sideOffset: 4, align: "end" }}
              volumes={frameworkState?.volumes}
              includeEmpty
              onSelect={(sec) => {
                assignToSection(requirement.id, sec?.id);
                if (!!(written_content || requirement?.content || requirement?.summarized_content)) {
                  generateRequirementHeading({
                    requirement_ids: [requirement.id],
                  });
                }
              }}
            >
              <div
                className="py-1 px-1.5 rounded-md bg-transparent duration-150 hover:bg-gray-100"
                css={[
                  !proposal_reference?.volume_id &&
                    tw`text-slate-500 flex items-center text-start bg-gray-100 min-h-[32px] min-w-[120px] hover:bg-gray-200`,
                ]}
              >
                <span className="text-sm text-left line-clamp-3" style={{ wordBreak: "break-word" }}>
                  {getFrameworkVolumeSectionTitle(
                    proposal_reference?.volume_id || "",
                    proposal_reference?.section_id || ""
                  ) || EMPTY_VALUE}
                </span>
              </div>
            </SectionsDropdown>
          </div>
          <div className="flex items-start w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Source</label>
            <div className="flex flex-col" style={{ wordBreak: "break-word" }}>
              <div className="flex flex-wrap gap-1 px-1.5 items-center">
                <div className="text-sm">Document: </div>
                <div className="text-xs text-gray-500">{document?.name || EMPTY_VALUE}</div>
              </div>
            </div>
          </div>
          <label className="text-gray-500 text-[13px] min-w-[116px]">Notes</label>
          <EditableContent
            submitOnEnter
            content={notes || ""}
            onContentUpdate={(val) => setNotes(requirement?.id, val)}
            css={[tw`text-sm p-1.5 flex-1 rounded-md bg-transparent duration-150 hover:bg-gray-100`]}
            textareaProps={{ placeholder: "Add Notes" }}
          />
          <div className="flex items-start flex-col w-full gap-4 min-h-[32px]">
            <label className="text-gray-500 text-[13px] min-w-[116px]">Response</label>
            <div className="flex flex-col">
              <div className="text-xs text-gray-500 whitespace-pre-wrap">{written_content || EMPTY_VALUE}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RequirementDetails;
