/** @jsxImportSource @emotion/react */

import { AIAssistantSession } from "utils/yjs-configs/ai-assistant/schema";
import { Link } from "react-router-dom";
import tw from "twin.macro";
import { motion } from "framer-motion";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Archive, Ellipsis } from "lucide-react";
import { useRowActions } from "./hooks";
import Tooltip from "components/atoms/tooltip";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";
import { ToImmutable } from "YJSProvider/LiveObjects";

type Props = {
  isActive: boolean;
  session: ToImmutable<AIAssistantSession>;
};

const AIAssistantSidebarRow = ({ session, isActive }: Props) => {
  const [localSessionName, setLocalSessionName] = useState(session.name);
  const actionItems = useRowActions(session);
  const { archiveSession, updateSession } = useAIAssistantOperations();
  const [optionsOpen, setOptionsOpen] = useState(false);
  const sessionEditable = useAppSelector((store) => store.aiAssistantState.sessionEditable);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setLocalSessionName(session.name);
  }, [session.name]);

  const isEditing = sessionEditable === session.id;

  return (
    <motion.div
      key={session.id}
      layout
      layoutId={session.id}
      animate="visible"
      title={localSessionName}
      css={[
        isActive && tw`relative z-[1] !bg-layout-gray-light-active`,
        optionsOpen && tw`bg-layout-gray-light-hover`,
        !isActive && isEditing && tw`hover:bg-transparent`,
      ]}
      className="relative group overflow-hidden rounded-lg hover:bg-layout-gray-light-hover"
    >
      {isEditing ? (
        <div className="px-2.5 py-2 w-full">
          <input
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                if (localSessionName.trim() !== session.name.trim())
                  updateSession(session.id, { name: localSessionName.trim() });
                dispatch(setAiAssistantState({ sessionEditable: "" }));
              }
            }}
            autoFocus
            onBlur={() => {
              if (localSessionName.trim() !== session.name.trim())
                updateSession(session.id, { name: localSessionName.trim() });
              dispatch(setAiAssistantState({ sessionEditable: "" }));
            }}
            className="text-sm outline-none text-slate-700 font-medium w-full bg-transparent"
            value={localSessionName}
            onChange={(e) => setLocalSessionName(e.target.value)}
          />
        </div>
      ) : (
        <Link
          to={`/dashboard/ai-assistant/${session.id}`}
          className="relative flex text-sm font-medium overflow-hidden w-full justify-between flex-row items-center px-2.5 py-2"
        >
          <div className="relative grow overflow-hidden whitespace-nowrap text-sm text-slate-700">
            {localSessionName}
            {!isEditing && (
              <div
                className="absolute bottom-0 right-0 top-0 to-transparent from-layout-gray-light bg-gradient-to-l w-10 group-hover:w-20 group-hover:from-50% group-hover:from-layout-gray-light-hover"
                css={[
                  optionsOpen && tw`w-20 from-50% from-layout-gray-light-hover`,
                  isActive && tw`w-20 from-50% !from-layout-gray-light-active`,
                ]}
              />
            )}
          </div>
        </Link>
      )}
      {!isEditing && (
        <div
          className="opacity-0 text-slate-700 flex items-center gap-1 absolute bottom-0 right-0 top-0 pl-1 pr-3 bg-layout-gray-light-hover group-hover:opacity-100"
          css={[
            isActive && tw`opacity-100 !bg-layout-gray-light-active`,
            optionsOpen && tw`opacity-100 from-layout-gray-light-hover`,
          ]}
        >
          <DropdownMenu
            open={optionsOpen}
            onOpenChange={setOptionsOpen}
            contentProps={{ align: "start", side: "bottom" }}
            items={actionItems}
            triggerProps={{ css: tw`duration-150 hover:opacity-50` }}
          >
            <div>
              <Ellipsis size={18} />
            </div>
          </DropdownMenu>
          <Tooltip content="Archive">
            <button onClick={() => archiveSession(session.id)} className="duration-150 hover:opacity-50">
              <Archive size={18} />
            </button>
          </Tooltip>
        </div>
      )}
    </motion.div>
  );
};

export default AIAssistantSidebarRow;
