import { Props as AvatarProps } from "components/molecules/avatar/Avatar";
import { useGetUsernameById } from "hook/user/useGetUsernameById";
import { useMemo } from "react";

const useGetAvatarGroup = (assignees?: string[], avatarProps?: Partial<AvatarProps>) => {
    const getUsernameById = useGetUsernameById();

    const avatars = useMemo(
        () =>
            assignees?.reduce<AvatarProps[]>((acc, userId) => {
                const username = getUsernameById(userId);
                if (!username) return acc;
                return [
                    ...acc,
                    {
                        key: userId,
                        id: username,
                        name: username,
                        size: 24,
                        ...avatarProps,
                    },
                ];
            }, []) || [],
        [assignees, avatarProps, getUsernameById]
    );

    return avatars;
};

export default useGetAvatarGroup;
