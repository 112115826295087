const CInputWithLabel = ({
    label,
    onChange,
    name,
    placeholder,
    type,
    className,
    required,
    disabled,
    defaultValue,
    value,
}) => {
    return (
        <div className={`flex flex-col w-full ${className && className}`}>
            <label className="pb-[8px] text-[#5B6B79] text-[12.5px] font-[500] items-center flex gap-1">{label}</label>
            <input
                type={type}
                placeholder={placeholder || "Enter Milestone Title"}
                className="border-gray-200 border rounded-md px-2 h-[40px] py-[5px] w-full text-[#5b6b79] focus:border-sky-600 focus:outline-none text-[12.5px]"
                name={name}
                value={value}
                disabled={disabled}
                defaultValue={defaultValue}
                onChange={onChange}
                required={required}
            />
        </div>
    );
};

export default CInputWithLabel;
