import axios from "axios";
import { useNotification } from "context/notificationContext";
import { useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { DisplayOption } from "store/reducers/draft/previewProposalReducer";

type PublishVolumeVariables = {
    volume_id: string;
    include_source_documents: boolean;
    section_formats: {
        id: string;
        display_option: DisplayOption;
        heading_style: {
            bold: boolean;
            underline: boolean;
            italic: boolean;
        };
    }[];
};

export const usePublishVolume = () => {
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id");
    const [isPublishing, setIsPublishing] = useState(false);
    const { setToast } = useNotification();

    const publishVolume = useCallback(
        async (body: PublishVolumeVariables) => {
            if (isPublishing || !projectId) return;

            setIsPublishing(true);
            try {
                const { data } = await axios.post<{ proposal_document_id: string }>(
                    `proposal_generation/${projectId}/publish/formatted`,
                    body
                );

                return data?.proposal_document_id;
            } catch {
                setToast.error({
                    title: "Unable to publish",
                    msg: "We were unable to publish due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            } finally {
                setIsPublishing(false);
            }
        },
        [isPublishing, projectId, setToast]
    );

    return { publishVolume, isPublishing };
};
