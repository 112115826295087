import ContractRightContent from "../../components/Contract-details/ContractRightContent";
import { ScreenSpinner } from "utils/icons";
import Timeline from "../../components/Contract-details/components/Timeline";
import Progress from "../../components/Contract-details/components/Progress";
import PointOfContact from "../../components/Contract-details/components/PointOfContact";
import LinksSection from "../../components/Contract-details/LinksSection";

// ------- Detail component for contract -------
const ProjectContent = ({
  contractDetails,
  id,
  setContractDetails,
  internalContractId,
  internalContractDetails,
  setInternalContractDetails,
  setForceRefresh,
  isLoading,
}) => {
  return (
    <div className="pt-3 flex px-5 pb-3">
      {isLoading && (
        <div className="p-7 top-0 left-0 w-full h-full absolute bg-[#5f55550e] shadow-md z-[2] backdrop-blur-[2px] my-auto flex items-center justify-center">
          <ScreenSpinner />
        </div>
      )}
      <div className="rounded-lg w-full flex-grow">
        <div className="flex gap-4 w-full">
          {/* left */}
          <div className="max-w-[calc(40%-8px)] w-full block">
            <div className="border border-[#D8DDE2] p-4 shadow-sm rounded-lg mb-4 bg-gray-lightest w-full">
              <h3 className="text-base font-medium">Information</h3>
            </div>
            <Timeline
              internalContractDetails={internalContractDetails}
              internalContractId={internalContractId}
              setInternalContractDetails={setInternalContractDetails}
            />
            <PointOfContact internalContractDetails={internalContractDetails} initialState={false} />
            <LinksSection
              links={internalContractDetails?.links || contractDetails?.links || []}
              searchPage={false}
              id={id}
              internalContractId={internalContractId}
              setForceRefresh={setForceRefresh}
            />
            <Progress internalContractId={internalContractId} />
          </div>
          {/* right */}
          <div className="max-w-[calc(60%-8px)] w-full block">
            <ContractRightContent
              contractDetails={contractDetails}
              id={id}
              setContractDetails={setContractDetails}
              internalContractId={internalContractId}
              internalContractDetails={internalContractDetails}
              setForceRefresh={setForceRefresh}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectContent;
