import { RESPONSE_TOLERANCE_OPTIONS } from "components/copilot/ComplianceMatrix/constants";
import { ResponseTolerance } from "types/Requirement";

type Props = {
  onSensitivitySelect: (tolerance: ResponseTolerance) => void;
};

const ResponseTolerancePopoverContent = ({ onSensitivitySelect, ...props }: Props) => {
  return (
    <div className="w-max max-w-[425px] overflow-hidden bg-white" {...props}>
      <div className="flex flex-col max-h-[180px] overflow-y-auto">
        {RESPONSE_TOLERANCE_OPTIONS.map(({ icon: ResponseIcon, value, name }) => (
          <button
            key={name}
            className="rounded-md px-2 py-3 text-left duration-100 hover:bg-action-lightest"
            onClick={() => onSensitivitySelect(value)}
          >
            <div className="flex gap-2 items-center">
              <ResponseIcon />
              {name}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ResponseTolerancePopoverContent;
