export type ActiveFilter = {
  sections: (RequirementSection | string)[];
  // types: RequirementType[];
  query: string;
};

export enum RequirementSection {
  StatementOfWork = "statement_of_work",
  //   SpecialRequirements = "special_requirements",
  Instructions = "instructions",
  EvaluationCriteria = "evaluation_criteria",
}

// export enum RequirementType {
//   TermsAndConditions = "terms_and_conditions",
//   Technical = "technical",
//   Management = "management",
//   FinanceAndPricing = "finance_and_pricing",
//   Submission = "submission",
//   Formatting = "formatting",
// }

export const REQUIREMENT_SECTION_TO_META: Record<RequirementSection, { label: string }> = {
  [RequirementSection.StatementOfWork]: {
    label: "SOW/PWS",
  },
  //   [RequirementSection.SpecialRequirements]: {
  //     label: "H: Special Requirements",
  //   },
  [RequirementSection.Instructions]: {
    label: "Instructions",
  },
  [RequirementSection.EvaluationCriteria]: {
    label: "Evaluation Criteria",
  },
};

// export const REQUIREMENT_TYPE_TO_META = {
//   [RequirementType.TermsAndConditions]: {
//     label: "Terms & Conditions",
//   },
//   [RequirementType.Technical]: {
//     label: "Technical",
//   },
//   [RequirementType.Management]: {
//     label: "Management",
//   },
//   [RequirementType.FinanceAndPricing]: {
//     label: "Finance & Pricing",
//   },
//   [RequirementType.Submission]: {
//     label: "Submission",
//   },
//   [RequirementType.Formatting]: {
//     label: "Formatting",
//   },
// }

export const DEFAULT_FILTERS = {
  sections: [],
  //   types: [],
  query: "",
};
