import Popover from "components/atoms/popover";
import DatePicker from "components/molecules/date-picker";
import { FormItem, FormItemStatus } from "types/Capture";
import AssigneesPopoverContent from "../assignees-popover-content";
import tw from "twin.macro";
import Avatar from "components/molecules/avatar";
import { useFormResponse, useUpdateItemDetails } from "./hooks";
import { useMemo } from "react";
import { useGetUsernameById } from "hook/user/useGetUsernameById";
import { LucideCalendarClock, LucideX } from "lucide-react";
import { FORM_ITEM_STATUS_TO_META } from "const-values/Capture";
import FormItemStatusDropdown from "../form-item-status-dropdown";

type Props = {
  formItem: FormItem;
  formId: string;
};

const FormItemRow = ({ formItem, formId }: Props) => {
  const { mutate: updateItem } = useUpdateItemDetails(formId, formItem.id);
  const { question, response, task_details: taskDetails } = formItem;
  const { localResponse, setLocalResponse } = useFormResponse(response?.value || "", formId, formItem.id);
  const getUsernameById = useGetUsernameById();
  const assignee = useMemo(
    () => getUsernameById(taskDetails?.assignee || ""),
    [taskDetails?.assignee, getUsernameById]
  );
  const currentStatusMeta = FORM_ITEM_STATUS_TO_META[taskDetails?.status || FormItemStatus.NotStarted];
  const StatusIcon = currentStatusMeta.icon;

  return (
    <div className="flex flex-col rounded border border-gray-light overflow-hidden">
      <div className="bg-gray-lightest p-5 border-b border-gray-light flex justify-between items-center gap-6">
        <div className="text-xs font-semibold truncate">{question}</div>
        <div className="flex items-center gap-2 flex-shrink-0">
          <DatePicker
            calendarPosition="bottom-end"
            value={taskDetails?.deadline ? new Date(taskDetails.deadline) : undefined}
            onChange={(date) => {
              const dateValue = date?.valueOf();
              if (typeof dateValue !== "number") return;
              updateItem({
                assignee: null,
                status: FormItemStatus.NotStarted,
                ...taskDetails,
                deadline: new Date(dateValue)?.toISOString(),
              });
            }}
            format="MM/DD/YYYY"
            onOpenPickNewDate={false}
            render={(value, openCalendar) => {
              return (
                <button
                  onClick={() => openCalendar()}
                  className="flex items-center gap-1.5 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200"
                >
                  <LucideCalendarClock size={14} />
                  {value || "No Deadline"}
                  {!!taskDetails?.deadline && (
                    <>
                      <div className="w-px h-3.5 mx-0.5 bg-slate-400" />
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          updateItem({
                            assignee: taskDetails.assignee || null,
                            status: taskDetails.status || FormItemStatus.NotStarted,
                            deadline: null,
                          });
                        }}
                        className="rounded-full bg-slate-600 text-slate-200 w-3.5 h-3.5 flex items-center justify-center hover:bg-gray-darkest"
                      >
                        <LucideX size={10} />
                      </div>
                    </>
                  )}
                </button>
              );
            }}
          />
          <FormItemStatusDropdown
            onItemSelect={(status) =>
              updateItem({
                assignee: null,
                deadline: null,
                ...taskDetails,
                status: status || FormItemStatus.NotStarted,
              })
            }
            selectedStatus={taskDetails?.status}
          >
            <div className="flex items-center gap-1.5 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200">
              <StatusIcon className="h-3.5 w-3.5" style={{ color: currentStatusMeta.color }} />
              {currentStatusMeta.label}
            </div>
          </FormItemStatusDropdown>
          <Popover
            contentProps={{ align: "end", css: tw`mx-0 overflow-hidden` }}
            content={
              <AssigneesPopoverContent
                singleSelect
                selectedUsers={[formItem.task_details?.assignee || ""]}
                onUserSelect={(userId) =>
                  updateItem({ deadline: null, status: FormItemStatus.NotStarted, ...taskDetails, assignee: userId })
                }
                tw="p-1"
              />
            }
          >
            <div className="flex items-center cursor-pointer gap-1.5 bg-slate-200 text-slate-600 rounded shadow-sm text-xs px-2 py-1 duration-100 hover:text-slate-900 hover:bg-slate-300 disabled:opacity-50 disabled:hover:text-slate-600 disabled:hover:bg-slate-200">
              <span className="text-xs text-slate-500">
                <Avatar id={assignee} name={assignee} size={16} empty={!assignee} />
              </span>
              {assignee || "None"}
            </div>
          </Popover>
        </div>
      </div>
      <div className="bg-white flex flex-col gap-6">
        <div className="bg-white flex flex-col">
          <textarea
            value={localResponse}
            onChange={(e) => setLocalResponse(e.target.value)}
            className="resize-none outline-none text-xs p-5"
            rows={10}
            placeholder="Enter a response..."
          />
        </div>
      </div>
    </div>
  );
};

export default FormItemRow;
