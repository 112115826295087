/** @jsxImportSource @emotion/react */

import Icon from "components/atoms/icons/Icon";
import { Button } from "components/editor/components";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import tw from "twin.macro";
import { TemplateSectionMode } from "./constants";
import { useTemplateSection } from "./hooks";
import SheetsDropdown from "components/molecules/sheets-dropdown";
import { updateDraftExtractionState } from "store/reducers/draft/DraftsExtractionReducer";
import { ArrowRight, X } from "lucide-react";

const DraftExtractionBar = () => {
  const { volumeId = "" } = useParams();
  const draftExtractionState = useAppSelector((store) => store.draftsExtractionState.drafts[volumeId]);
  const { isOpen, isLoading } = draftExtractionState || {};
  const { templateSectionState, setTemplateSectionState, generateTemplateSection, extractTemplateSection } =
    useTemplateSection(isOpen);
  const dispatch = useAppDispatch();

  return (
    <div
      className="absolute bottom-[38px] z-[6] hidden justify-center duration-200 left-8 right-8 pointer-events-none"
      css={[{ animation: "contentShow 100ms ease-in" }, isOpen && tw`flex`]}
    >
      <div className="items-start flex shadow-lg w-full flex-col rounded-lg gap-2 pointer-events-auto bg-dark p-2.5 max-w-[1100px]">
        <div className="flex justify-start w-full">
          <div className="flex items-center justify-between gap-8 w-full">
            <div className="flex items-center flex-row gap-1 flex-1">
              {/* <DropdownMenu
                                contentProps={{ align: "start" }}
                                items={[
                                    {
                                        key: 1,
                                        label: MODE_TO_LABEL[TemplateSectionMode.Extract],
                                        selected: templateSectionState.mode === TemplateSectionMode.Extract,
                                        onSelect: () =>
                                            setTemplateSectionState((prev) => ({
                                                ...prev,
                                                mode: TemplateSectionMode.Extract,
                                            })),
                                    },
                                    {
                                        key: 2,
                                        label: MODE_TO_LABEL[TemplateSectionMode.Generate],
                                        selected: templateSectionState.mode === TemplateSectionMode.Generate,
                                        onSelect: () =>
                                            setTemplateSectionState((prev) => ({
                                                ...prev,
                                                mode: TemplateSectionMode.Generate,
                                            })),
                                    },
                                ]}
                            >
                                <div className="h-7 font-medium flex gap-2 items-center text-xs rounded-md pl-2 pr-1.5 py-0.5 text-zinc-200 border border-zinc-300 duration-150 hover:text-white hover:bg-zinc-800">
                                    {MODE_TO_LABEL[templateSectionState.mode]}
                                    <Icon name="CarrotDown" />
                                </div>
                            </DropdownMenu> */}
              <input
                placeholder={
                  templateSectionState.mode === TemplateSectionMode.Extract
                    ? "Provide template text to extract..."
                    : "Provide context to generate new sections..."
                }
                value={templateSectionState.prompt}
                onChange={(e) => setTemplateSectionState((prev) => ({ ...prev, prompt: e.target.value }))}
                className="w-full max-w-[680px] h-7 flex-1 outline-none bg-zinc-800 text-zinc-100 px-2 py-1.5 text-xs resize-none border border-zinc-300 rounded-md"
              />
              <SheetsDropdown
                selectedSheet={templateSectionState.sheet?.id}
                onSelect={(sheet) => setTemplateSectionState((prev) => ({ ...prev, sheet: sheet }))}
              >
                <div className="h-7 max-w-[140px] font-medium flex gap-2 items-center text-xs rounded-md pl-2 pr-1.5 py-0.5 text-zinc-200 border border-zinc-300 duration-150 hover:text-white hover:bg-zinc-800">
                  <span className="truncate"> {templateSectionState.sheet?.name || "Select sheet"}</span>
                  <Icon name="CarrotDown" />
                </div>
              </SheetsDropdown>
              <Button
                loading={isLoading}
                disabled={isLoading || !templateSectionState.sheet?.id || !templateSectionState.prompt.trim()}
                onClick={() => {
                  if (!templateSectionState.sheet?.id || isLoading) return;
                  if (templateSectionState.mode === TemplateSectionMode.Generate)
                    generateTemplateSection({ text: templateSectionState.prompt }, templateSectionState.sheet.id);
                  if (templateSectionState.mode === TemplateSectionMode.Extract)
                    extractTemplateSection({ text: templateSectionState.prompt }, templateSectionState.sheet.id);
                }}
                variant="primary"
                size="sm"
                className="!bg-white w-7 h-7 !px-1.5 !text-black hover:!bg-zinc-300 disabled:pointer-events-none disabled:!text-zinc-500 disabled:opacity-50"
              >
                {!isLoading && <ArrowRight size={14} className="text-base" />}
              </Button>
            </div>
            <div className="gap-1 flex flex-row items-center">
              <Button
                size="sm"
                variant="ghost"
                className="!text-zinc-200 !px-1 w-7 h-7 hover:!bg-zinc-800 hover:!text-white"
                onClick={() => dispatch(updateDraftExtractionState({ [volumeId]: { id: volumeId, isOpen: false } }))}
              >
                <X size={16} />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraftExtractionBar;
