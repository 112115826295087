import { highlightAndScrollToElement } from "./utils";
import { useSearchParams } from "react-router-dom";
import {
  setIsExtractingDocument,
  setTimeRemaining,
  getRequirementGroups,
  getExtractionDocuments,
} from "store/reducers/extract/CurrentExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useCallback, useEffect, useMemo } from "react";

export const useGetRequirementGroups = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");

  useEffect(() => {
    if (projectId) dispatch(getRequirementGroups({ projectId, isInitialFetch: true }));
  }, [dispatch, projectId]);
};

export const useValidateExtractionStatus = () => {
  const dispatch = useAppDispatch();
  const create_document_view_tasks = useAppSelector((state) => state.autopilotHealthCheck.create_document_view_tasks);
  const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);

  const extractingTask = useMemo(() => {
    return create_document_view_tasks.find((task) => {
      return task?.reference_id && extraction?.file_ids.includes(task.reference_id);
    });
  }, [create_document_view_tasks, extraction?.file_ids]);
  const isExtractingDocument = !!extractingTask;

  const timeRemaining = useMemo(() => {
    const tasks = create_document_view_tasks.filter(
      (task) => task?.reference_id && extraction?.file_ids.includes(task.reference_id)
    );
    return tasks.length > 0
      ? Math.max(...tasks.map((task) => (task?.minutes_time_remaining ? task.minutes_time_remaining : 0)))
      : 0;
  }, [extraction?.file_ids, create_document_view_tasks]);

  useEffect(() => {
    dispatch(setIsExtractingDocument(isExtractingDocument));
  }, [dispatch, isExtractingDocument]);

  useEffect(() => {
    dispatch(setTimeRemaining(timeRemaining));
  }, [dispatch, timeRemaining]);

  useEffect(() => {
    if (extractingTask?.is_started && extraction?.file_ids) dispatch(getExtractionDocuments(extraction.file_ids));
  }, [dispatch, extractingTask?.is_started, extraction?.file_ids]);
};

export const useScrollToNodes = () => {
  const highlightedElementId = useAppSelector((root) => root.currentExtractionState.highlightedElementId);

  const scrollToNodes = useCallback(() => {
    highlightAndScrollToElement(highlightedElementId);
  }, [highlightedElementId]);

  useEffect(() => {
    scrollToNodes();
  }, [scrollToNodes]);

  return scrollToNodes;
};
