import { nanoid } from "nanoid";
import { AssistantBlockType, CoreBlock, VultronBlock } from "./yjs-configs/ai-assistant/schema";
import { LiveList, LiveObject } from "YJSProvider/LiveObjects";

export const createVultronBlock = (properties?: Partial<VultronBlock>): LiveObject<VultronBlock> => {
    return new LiveObject({
        type: AssistantBlockType.VultronBlock,
        id: nanoid(),
        updated_at: new Date().toISOString(),
        body: "",
        sources: new LiveList([]),
        ...properties,
    });
};

export const createCoreBlock = (properties?: Partial<CoreBlock>): LiveObject<CoreBlock> => {
    return new LiveObject({
        type: AssistantBlockType.CoreBlock,
        id: nanoid(),
        updated_at: new Date().toISOString(),
        body: "",
        ...properties,
    });
};
