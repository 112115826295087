/** @jsxImportSource @emotion/react */

import IconButton from "components/atoms/icon-button";
import { useNotification } from "context/notificationContext";
import { useEffect, useMemo, useState } from "react";
import copyText from "utils/copyText";
import { useTrackUserMetric } from "utils/metrics";
import { VultronAvatar } from "components/molecules/avatar";
import { RotateCw, Trash2 } from "lucide-react";
import { useAppSelector } from "store/storeTypes";
import TypingEffect from "components/TypingEffect";
import tw from "twin.macro";
import { VultronBlock as LiveVultronBlock } from "utils/yjs-configs/ai-assistant/schema";
import SourceWrapper from "./SourceWrapper";
import { useAssistant } from "pages/ai-assistant/ai-assistant-input/hooks";
import useAIAssistantOperations from "hook/useAIAssistantOperations";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { INTERNET_LOADING_MSGS } from "./constants";
import InternetAnimation from "Assets/gifs/black-internet-loading.gif";
import LiveDate from "components/molecules/live-date";
import { getWordCount } from "utils/getWordCount";
import { ToImmutable } from "YJSProvider/LiveObjects";
import Tooltip from "components/atoms/tooltip/Tooltip";
import GlobeX from "Assets/globe-x.svg";
import Icon from "components/atoms/icons/Icon";
import FileCheckIcon from "Assets/ai-assistant/file-check.svg";
import FileXIcon from "Assets/ai-assistant/file-x.svg";

const VultronBlock = ({ block }: { block: ToImmutable<LiveVultronBlock> }) => {
  const { setToast } = useNotification();
  const { deleteMessageBlock } = useAIAssistantOperations();
  const { streamState, activeSession } = useAppSelector((root) => root.aiAssistantState);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const { refreshMessage } = useAssistant();
  const trackUserEvent = useTrackUserMetric();
  const [loadingMsg, setLoadingMsg] = useState(INTERNET_LOADING_MSGS[0]);

  const isStreaming = isStreamingInProgress && blockId === block.id;

  useEffect(() => {
    setLoadingMsg(INTERNET_LOADING_MSGS[0]);
  }, [isStreamingInProgress]);

  useAnimateLoadingMsg(isStreaming && !!block.enableInternet, 4000, INTERNET_LOADING_MSGS, (msg) => setLoadingMsg(msg));

  const determineBlockType = useMemo(() => {
    if (block.enableInternet) {
      return "internet";
    } else if (block.promptSources?.length) {
      return "content search";
    } else {
      return "other";
    }
  }, [block]);

  return (
    <div
      className="flex flex-row gap-2.5 w-full"
      css={[isStreaming && { scrollMarginTop: 8 }]}
      id={isStreaming ? `assistant-block-${blockId}` : undefined}
    >
      <VultronAvatar size={26} />
      <div className="flex flex-col gap-3 pt-1 flex-1 min-w-0">
        <div className="text-gray-darkest text-sm whitespace-pre-wrap">
          {isStreaming && streamCopy}
          {isStreaming && !streamCopy && !block.enableInternet && <TypingEffect style={{ padding: 0, height: 20 }} />}
          {isStreaming && !streamCopy && block.enableInternet && (
            <div className="flex items-center text-gray-darkest -mt-1.5">
              <img src={InternetAnimation} alt="" className="w-[33px] h-[33px]" />
              <div className="ml-1">{loadingMsg}</div>
              <span className="loading-ellipsis" />
            </div>
          )}
          {!isStreaming && !block.error && block.body}
          {!isStreaming && block.error && (
            <div className="text-red-500 px-1.5 py-1 bg-red-50 rounded-md border border-red-500">
              Something went wrong. If the issue persists, please contact us at support@vultron.ai.
            </div>
          )}
        </div>
        {!!block.sources?.length && !isStreaming && <SourceWrapper sources={block.sources} />}
        <div
          className="flex flex-row justify-between items-center"
          css={[isStreaming && tw`opacity-0 pointer-events-none`]}
        >
          <div className="flex flex-row gap-2 items-center text-slate-500">
            <IconButton
              name="Copy"
              className="w-4 h-4 duration-150 hover:text-black"
              onClick={() => {
                copyText(block.body, () => {
                  setToast.success({
                    msg: "Copied",
                  });
                });
                trackUserEvent("AI Assistant: Copy Button Clicked", {
                  word_count: getWordCount(block.body),
                  type: determineBlockType,
                });
              }}
            />
            <div className="h-4 w-px bg-slate-400" />
            <button
              onClick={() => activeSession?.id && deleteMessageBlock(activeSession?.id, block.id)}
              className="duration-150 hover:text-black"
            >
              <Trash2 size={14} className="stroke-[1.6]" />
            </button>
            {!!block.prompt?.trim() && !isStreamingInProgress && (
              <>
                <div className="h-4 w-px bg-slate-400" />
                <button onClick={() => refreshMessage(block.id)} className="duration-150 hover:text-black">
                  <RotateCw size={14} className="stroke-[1.7]" />
                </button>
              </>
            )}
            <div className="h-4 w-px bg-gray-400" />
            {block.enableInternet && !block.sources?.length && (
              <Tooltip content="No external sources were used in this response.">
                <div className="relative flex items-center justify-center h-[15px] w-[15px]">
                  <img src={GlobeX} alt="No external sources were used" className="w-4 h-4" />
                </div>
              </Tooltip>
            )}
            {block.enableInternet && !!block.sources?.length && (
              <Tooltip content="The external sources above were used in this response. Please confirm important information from these sources.">
                <div className="relative flex items-center justify-center h-[15px] w-[15px]">
                  <Icon name="Internet" className="w-4 h-4 text-black" />
                </div>
              </Tooltip>
            )}
            {!block.enableInternet && !!block.sources?.length && (
              <Tooltip content="The sources above were used in this response. Please use the provided sources to confirm important information.">
                <img src={FileCheckIcon} alt="Sources available" className="w-4 h-4" />
              </Tooltip>
            )}
            {!block.enableInternet &&
              !block.sources?.length &&
              (block.promptSources?.length ? (
                <Tooltip content="No relevant sources were found in the selected files for this response. This response was generated using no direct sources.">
                  <img src={FileXIcon} alt="No prompt sources available" className="w-4 h-4" />
                </Tooltip>
              ) : (
                <Tooltip content="No sources have been selected for this response. Please validate and confirm the content. You can select files or enable the internet mode to use direct sources.">
                  <img src={FileXIcon} alt="No sources available" className="w-4 h-4" />
                </Tooltip>
              ))}
          </div>
          <LiveDate date={block.updated_at} duration={30000} />
        </div>
      </div>
    </div>
  );
};

export default VultronBlock;
