import {
    DragEndEvent,
    DragStartEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";
import { Section, Storage } from "components/copilot/CopilotSchemaTypes";
import { useCallback, useEffect, useState } from "react";
import { move } from "utils/array";
import { useMutation } from "YJSProvider/createYJSContext";
import { find, findIndex, LiveList, LiveObject, moveItem, ToImmutable } from "YJSProvider/LiveObjects";

export const useDrag = (
    sections: ToImmutable<LiveList<LiveObject<Section>>>,
    volumeId: string,
    extractionId?: string
) => {
    const [activeDragId, setActiveDragId] = useState<UniqueIdentifier | null>(null);
    const [dragSections, setDragSections] = useState<ToImmutable<LiveList<LiveObject<Section>>>>([]);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    useEffect(() => {
        setDragSections(sections);
    }, [sections]);

    const handleDragStart = useCallback(
        (event: DragStartEvent) => {
            setDragSections(sections);
            setActiveDragId(event.active.id);
        },
        [sections]
    );

    const handleDragCancel = useCallback(() => {
        setDragSections(sections);
        setActiveDragId(null);
    }, [sections]);

    const handleDragEnd = useMutation(
        ({ storage }, event: DragEndEvent) => {
            const { active, over } = event;

            if (over?.id && active.id !== over.id) {
                const extractions = storage.get("extractions") as Storage["extractions"] | undefined;
                if (!extractions) return;
                const liveExtraction = find(extractions, (extraction) => extraction.get("id") === extractionId);

                const volumes = liveExtraction?.get("framework")?.get("volumes") as LiveList<LiveObject<Section>>;
                if (!volumes) return;

                const liveVolume = find(volumes, (volume) => volume.get("id") === volumeId);
                const liveSections = liveVolume?.get("sections") as LiveList<LiveObject<Section>> | undefined;

                if (!liveSections?.length) return;

                const sourceIndex = sections.findIndex((section) => section.id === active.id);
                const destinationIndex = sections?.findIndex((section) => section.id === over.id);

                const sourceSection = sections[sourceIndex];
                const destinationSection = sections[destinationIndex];
                const sourceSectionIndex = findIndex(
                    liveSections,
                    (section) => section.get("id") === sourceSection?.id
                );
                const destinationSectionIndex = findIndex(
                    liveSections,
                    (section) => section.get("id") === destinationSection?.id
                );

                if (sourceSectionIndex === -1 || destinationSectionIndex === -1) return;
                const moved = move([...dragSections], sourceIndex, destinationIndex);
                setDragSections(moved);
                moveItem(liveSections, sourceSectionIndex, destinationSectionIndex);
            } else {
                setDragSections(sections);
            }

            setActiveDragId(null);
        },
        [extractionId, volumeId, dragSections, sections]
    );

    return { sensors, dragSections, handleDragStart, handleDragEnd, handleDragCancel, activeDragId };
};
