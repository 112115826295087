/** @jsxImportSource @emotion/react */
import { Interpolation, Theme } from "@emotion/react";
import IconButton from "components/atoms/icon-button/IconButton";
import { PropsWithChildren, ReactNode } from "react";
import tw from "twin.macro";

export type Props = {
    fullScreen?: boolean;
    overlay?: boolean;
    opened: boolean;
    onClose?: () => void;
    header?: ReactNode;
    drawerActions?: ReactNode;
    styles?: Interpolation<Theme>;
    overlayStyles?: Interpolation<Theme>;
    headerStyles?: Interpolation<Theme>;
};

const Drawer = ({
    opened,
    onClose,
    overlay,
    header,
    children,
    styles,
    fullScreen,
    drawerActions,
    overlayStyles,
    headerStyles,
    ...props
}: PropsWithChildren<Props>) => {
    return (
        <>
            <div
                className="z-[99] overflow-hidden flex flex-col bg-white border-l border-zinc-200 shadow absolute top-0 bottom-0 right-0 duration-150"
                css={[
                    { transform: "translateX(100%)" },
                    opened && { transform: "translateX(0%)" },
                    fullScreen && tw`fixed`,
                    styles,
                ]}
                {...props}
            >
                {(header || !!onClose) && (
                    <div className="flex gap-3 justify-between p-4 items-start pb-2" css={headerStyles}>
                        {header}
                        <div className="flex items-center gap-0.5">
                            {!!header && drawerActions}
                            <IconButton
                                className="p-1 duration-150 rounded-md hover:bg-zinc-100"
                                name="ChevronsRight"
                                onClick={onClose}
                            />
                            {!header && drawerActions}
                        </div>
                    </div>
                )}
                {children}
            </div>
            {overlay && (
                <div
                    tw="z-[98] hidden absolute duration-200 top-0 bottom-0 right-0 left-0 bg-black opacity-0"
                    css={[opened && tw`block opacity-30`, overlayStyles]}
                    onClick={onClose}
                />
            )}
        </>
    );
};

export default Drawer;
