import { ReactComponent as Disconnected } from "Assets/svgs/disconnected.svg";
import { ReactComponent as VultronLogoNoTitle } from "Assets/svgs/vultron-logo-no-title.svg";

const FallbackComponent = () => {
  return (
    <div className="flex flex-col gap-8 bg-neutral-100 p-14 justify-center items-center h-screen w-screen">
      <div className="flex flex-col items-center gap-6">
        <div className="relative w-96 h-48">
          <Disconnected className="absolute inset-x-0 h-96 w-full -top-1/2" />
        </div>
        <div className="text-slate-500">There was a problem serving the requested page.</div>
        <div className="text-slate-500">
          Sorry about that. Please try{" "}
          <button
            className="text-gray-darkest underline duration-100 hover:opacity-70"
            onClick={() => window.location.reload()}
          >
            refreshing
          </button>{" "}
          and contact our support team at{" "}
          <a
            className="text-gray-darkest underline duration-100 hover:opacity-70"
            href="mailto:support@vultron.ai?subject=Page%20Error"
          >
            support@vultron.ai
          </a>{" "}
          if the problem persists.
        </div>
      </div>
      <div className="pl-6 text-slate-600 text-sm flex items-center flex-row gap-3">
        <button className="hover:underline" onClick={() => window.location.reload()}>
          Refresh page
        </button>
        <div className="w-4 h-px bg-slate-300" />
        <a className="hover:underline" href="mailto:support@vultron.ai?subject=Page%20Error">
          Contact Support
        </a>
      </div>
      <VultronLogoNoTitle className="opacity-40 w-11 h-11" />
    </div>
  );
};

export default FallbackComponent;
