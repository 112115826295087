/** @jsxImportSource @emotion/react */

import { useState, useEffect, useCallback, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import CopilotTable from "./CopilotTable";
import tw from "twin.macro";
import { Spinner } from "utils/icons";
import { getInternalContractDetails } from "api/api";
import "react-circular-progressbar/dist/styles.css";
import { useLocalStorage } from "hook/useLocalStorage";
import { isEqual, omit } from "lodash";
import { filterOut, getAllSections } from "./utils";
import Icon from "components/atoms/icons/Icon";
import { useCopilotSearch, useExportToSheet, useInsertRequirementRow } from "./hooks";
import { ColumnOption, Direction, SortKey } from "./constants";
import LiveNameCursors from "components/molecules/live-name-cursors";
import { COLUMN_OPTIONS, COLUMN_OPTION_TO_FILTER_KEY, DEFAULT_DOC, SORT_KEY_TO_TEXT } from "./constants";
import { useSortable } from "./hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Checkbox } from "components/atoms/checkbox";
import Tooltip from "components/atoms/tooltip/Tooltip";
import { CopilotPresencePage } from "types/Presence";
import IconButton from "components/atoms/icon-button/IconButton";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import { setCheckedState } from "store/reducers/copilot/requirementsReducer";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { EMPTY_SHEET } from "const-values/Sheets";
import { useKey } from "react-use";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useStorage } from "YJSProvider/createYJSContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import useCreateAtlasRequirement from "hook/Requirements/useCreateRequirement";
import { ArrowDown, ArrowUp, Search } from "lucide-react";

const ComplianceMatrix = ({ frameworkState }) => {
  const { activeSheet } = useAppSelector((root) => root.requirements);
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id")?.toLocaleLowerCase();
  const { createRequirement: createAtlasRequirement, isCreatingRequirement } = useCreateAtlasRequirement();
  const complianceMatrixState = useStorage(
    (storage) =>
      storage.compliance_matrix.filter((row) => activeSheet?.id === (row.requirement.extraction_id || EMPTY_SHEET.id)),
    isEqual
  );
  const flags = useFlags();
  const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
  const { setLocalVal: setSelectedColumns, localValue: selectedColumns } = useLocalStorage(
    `complianceMatrixColumns_${workspace_id}`,
    COLUMN_OPTIONS
  );
  const [requirementsOnly] = usePersistedStorage(`requirementsOnlyFilter-${id}`, false);
  const [showInfoTab, setShowInfoTab] = useState(false);
  const [sortActive, setSortActive] = useState(false);
  const [documents, setDocuments] = useState([{ id: null, name: "No Document" }]);
  const [contractDetails, setContractDetails] = useState({});
  const [requirements, setRequirements] = useState([]);

  const [searchFilters, setSearchFilters] = useState({
    // column filters
    assignees: "",
    documentName: "",
    complianceType: "",
    requirementStatusType: "",
    proposalVolume: "",
    proposalSection: "",
  });

  const documentsRef = useRef();
  const searchRef = useRef();
  useEffect(() => {
    const fetchInternalContract = () => {
      getInternalContractDetails(id)
        .then((res) => {
          const contract = res?.data?.internal_contract?.contract || {};
          if (!isEqual(contract, contractDetails)) setContractDetails(contract);
          const govDocs = res?.data?.government_source;
          const internalDocs = res?.data?.internal_documents;
          const allDocs = [...DEFAULT_DOC, ...internalDocs, ...govDocs];
          const allDocsCopy = allDocs.map((doc) => omit(doc, ["download_url", "secure_preview_url"]));
          const stripDocumentKeys = documentsRef.current?.map((doc) =>
            omit(doc, ["download_url", "secure_preview_url"])
          );

          // causes children to rerender
          // keys "download_url" and "secure_preview_url" always change
          if (!isEqual(allDocsCopy, stripDocumentKeys)) {
            documentsRef.current = allDocs;
            setDocuments(allDocs);
          }
        })
        .catch((err) => {
          documentsRef.current = DEFAULT_DOC;
          setDocuments(DEFAULT_DOC);
        });
    };
    fetchInternalContract();
    const intervalId = setInterval(() => fetchInternalContract(), 3000);
    return () => {
      clearInterval(intervalId);
      dispatch(setCheckedState({}));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedColumn = useCallback(
    (checked, value) => {
      const prevList = [...selectedColumns];
      if (!checked) {
        const columnToFilterKey = COLUMN_OPTION_TO_FILTER_KEY[value];
        setSelectedColumns(prevList?.filter((p) => p !== value));
        setSearchFilters((prev) => ({ ...prev, [columnToFilterKey]: "" }));
      } else {
        setSelectedColumns([value, ...prevList]);
      }
    },
    [selectedColumns, setSelectedColumns]
  );

  const hasColumnFilters = useMemo(() => {
    const { requirementsOnly, ...rest } = searchFilters;
    return Object.values(rest).some((filterValue) => !!filterValue);
  }, [searchFilters]);
  const hasFilters = hasColumnFilters || !!requirementsOnly;
  // sorted data for table rows
  const allSections = useMemo(() => getAllSections(frameworkState?.volumes), [frameworkState?.volumes]);

  const updatedRequirements = useMemo(
    () =>
      requirements?.map((v) => {
        let volId = v?.proposal_reference?.volume_id;
        let secId = v?.proposal_reference?.section_id;
        let volumeIndex = frameworkState?.volumes?.findIndex((vl) => vl?.id === volId);
        return {
          ...v,
          requirementText: v?.requirement?.content || v?.requirement?.summarized_content || "",
          proposalVolume: volumeIndex,
          proposalSection: allSections?.findIndex((sc) => sc?.id === secId) || null,
        };
      }),
    [allSections, frameworkState?.volumes, requirements]
  );
  const { items: requirementItems, sortConfig, handleSorting } = useSortable(updatedRequirements, sortActive);
  const { exporting, exportRequirements } = useExportToSheet({
    internalContractId: id,
    requirementsOnly,
    searchFilters,
    frameworkState,
    selectedColumns,
    sortConfig,
    sortActive,
  });

  const filteredComplianceMatrix = useMemo(() => {
    if (!hasFilters) return complianceMatrixState;
    return complianceMatrixState.filter((row) => filterOut({ row, searchFilters, frameworkState, requirementsOnly }));
  }, [hasFilters, complianceMatrixState, searchFilters, frameworkState, requirementsOnly]);

  const { query, setQuery, filteredResults } = useCopilotSearch(filteredComplianceMatrix);

  useEffect(() => setRequirements(filteredResults), [filteredResults]);

  const sortOptions = useMemo(
    () =>
      Object.values(SortKey).map((option) => ({
        key: option,
        label: SORT_KEY_TO_TEXT[option],
        onSelect: () => handleSorting({ key: option }),
      })),
    [handleSorting]
  );

  const cursorPanel = useRef();

  const columnItems = useMemo(
    () =>
      COLUMN_OPTIONS.filter((option) => {
        if (flags.instantDraft && ColumnOption.Source === option) return false;
        return !flags.instantDraft &&
          [ColumnOption.Response, ColumnOption.Sources, ColumnOption.Relevance, ColumnOption.Score].includes(option)
          ? false
          : true;
      }).map((option) => ({
        key: option,
        label: option,
        value: option,
        checked: selectedColumns.includes(option),
        onCheck: (checked, value) => handleSelectedColumn(checked, value),
      })),
    [handleSelectedColumn, flags.instantDraft, selectedColumns]
  );

  const dispatch = useAppDispatch();

  const canAddRequirement = !sortActive && !hasColumnFilters;
  const insertRequirementRow = useInsertRequirementRow();
  const addRequirementRow = useCallback(async () => {
    if (!canAddRequirement) return;

    try {
      const atlasRequirement = await createAtlasRequirement({ content: "" });

      const newReq = createComplianceMatrixRowRequirement({
        id: atlasRequirement.id,
        ...(activeSheet?.id !== EMPTY_SHEET.id && { extraction_id: activeSheet?.id }),
      });
      insertRequirementRow(0, { requirement: newReq });
    } catch {}
  }, [activeSheet?.id, canAddRequirement, createAtlasRequirement, insertRequirementRow]);

  useKey(
    (e) => e.metaKey && e.code === "KeyF",
    (e) => {
      searchRef.current?.focus();
      e.stopPropagation();
      e.preventDefault();
    }
  );

  return (
    <div ref={cursorPanel} className="pt-3 flex flex-col flex-1 overflow-x-hidden overflow-y-hidden relative">
      <LiveNameCursors cursorPanel={cursorPanel} activePage={CopilotPresencePage.ComplianceMatrix} newYjsProvider />
      <div className="z-10 bg-white pb-3">
        <div className="px-5">
          <div className="flex justify-between gap-4 items-center">
            <div className="flex gap-4 flex-1 max-w-[300px]">
              <div className="flex py-1 px-2 h-8 w-full items-center gap-2 text-gray-darkest font-normal text-xs rounded-md border border-gray-light focus-within:border-slate-600">
                <Search size={12} className="text-gray-mid" />
                <input
                  ref={searchRef}
                  value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  placeholder="Type to search..."
                  className="!outline-none w-full truncate"
                />
              </div>
            </div>
            <div className="flex gap-2 items-center">
              <div className="flex gap-2 items-center whitespace-nowrap">
                <label className="text-gray-500 text-xs font-normal">Sort by:</label>
                <div className="cursor-default overflow-hidden border h-8 border-[#dbe0e5] flex items-center rounded-lg">
                  <Tooltip
                    disabled={!complianceMatrixState?.length}
                    content={`${!sortActive ? "Enable" : "Disable"} sorting`}
                  >
                    <div
                      className="h-full px-2 flex items-center"
                      css={[!complianceMatrixState?.length && tw`bg-gray-200`]}
                    >
                      <Checkbox
                        onCheck={(checked) => setSortActive(checked)}
                        checked={sortActive}
                        disabled={!complianceMatrixState?.length}
                        className={!complianceMatrixState?.length ? "!bg-gray-300" : undefined}
                        size="xs"
                      />
                    </div>
                  </Tooltip>
                  <button
                    disabled={!sortActive}
                    onClick={() =>
                      handleSorting({
                        direction:
                          sortConfig.direction === Direction.Descending ? Direction.Ascending : Direction.Descending,
                      })
                    }
                    className="group peer border-l border-[#dbe0e5] h-full flex items-center px-2 duration-0 hover:bg-gray-100 hover:duration-100 disabled:bg-gray-200 disabled:pointer-events-none"
                  >
                    {sortConfig.direction === Direction.Ascending && (
                      <ArrowUp size={12} className="text-action stroke-1 group-disabled:text-slate-400" />
                    )}
                    {sortConfig.direction === Direction.Descending && (
                      <ArrowDown size={12} className="text-action stroke-1 group-disabled:text-slate-400" />
                    )}
                  </button>
                  <DropdownMenu
                    disabled={!sortActive}
                    contentProps={{ css: tw`text-xs` }}
                    triggerProps={{ css: tw`h-full` }}
                    items={sortOptions}
                  >
                    <div className="px-2 h-full border-l border-[#dbe0e5] duration-100 hover:bg-gray-100 flex items-center text-xs font-medium text-black group-disabled:bg-gray-200 group-disabled:text-slate-400 group-disabled:duration-0">
                      {SORT_KEY_TO_TEXT[sortConfig.key]}
                    </div>
                  </DropdownMenu>
                </div>
              </div>

              <DropdownMenu modal={false} multiselect items={columnItems}>
                <Tooltip content="Show or hide columns">
                  <IconButton
                    name="Columns"
                    className="shadow-expanded w-8 !h-8 border border-[#dbe0e5] rounded-lg duration-100 hover:bg-gray-50"
                    iconProps={{ className: "w-[15px] h-[15px]" }}
                  />
                </Tooltip>
              </DropdownMenu>
              <Tooltip content="Export requirements">
                <button
                  className="shadow-expanded flex justify-center text-action w-8 !h-8 items-center gap-2 text-xs border border-[#dbe0e5] rounded-lg duration-100 hover:bg-gray-50"
                  onClick={exportRequirements}
                >
                  <>
                    {exporting ? (
                      <Spinner classes="!text-black" width="18" height="18" />
                    ) : (
                      <Icon name="Export" className="w-[15px] h-[15px]" />
                    )}
                  </>
                </button>
              </Tooltip>
              <DropdownMenu
                modal={false}
                triggerProps={{ className: "rounded-lg" }}
                items={[
                  {
                    key: 1,
                    label: "New Entry",
                    disabled: isCreatingRequirement,
                    onSelect: () => addRequirementRow(),
                  },
                  {
                    key: 2,
                    label: "From Text",
                    onSelect: () => dispatch(toggleRequirementExtractionModal({ open: true })),
                  },
                ]}
                disabled={!canAddRequirement}
              >
                <div
                  className="shadow-expanded min-h-[26px] min-w-[40px] px-4 !h-8 border text-xs border-[#dbe0e5] rounded-lg flex items-center justify-center duration-100 hover:bg-action-hover text-white bg-action"
                  css={[!canAddRequirement && tw`shadow-none bg-gray-200 text-slate-400`]}
                >
                  <Icon name="PlusCircle" className="w-[15px] h-[15px] mr-1.5" /> Add
                </div>
              </DropdownMenu>
            </div>
          </div>
        </div>
      </div>
      <div className="px-5 pb-4 flex-1">
        <CopilotTable
          searchActive={!!query.length}
          sortConfig={sortConfig}
          handleSorting={handleSorting}
          sortActive={sortActive}
          complianceMatrixState={complianceMatrixState}
          requirements={requirementItems}
          setRequirements={setRequirements}
          setSearchFilters={setSearchFilters}
          searchFilters={searchFilters}
          showInfoTab={showInfoTab}
          setShowInfoTab={setShowInfoTab}
          documents={documents}
          frameworkState={frameworkState}
          selectedColumns={selectedColumns}
        />
      </div>
    </div>
  );
};

export default ComplianceMatrix;
