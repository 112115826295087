import { getAllChatSessionsAPI } from "api/api";
import { formatDate } from "helpers/helper";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import ChatHistory from "./ChatHistory";
import ChatPanel from "./ChatPanel";
import { useNotification } from "context/notificationContext";

const AskAi = ({ internalContractId }) => {
    const { setToast } = useNotification();

    const [loadingSessions, setLoadingSessions] = useState(true);
    const [chatSessions, setChatSessions] = useState([]);
    const [searchParams, setSearchParams] = useSearchParams();
    const [creatingSession, setCreatingSession] = useState(false);

    useEffect(() => {
        getAllSessions();
    }, []);

    const getAllSessions = () => {
        getAllChatSessionsAPI(internalContractId)
            .then((res) => {
                let data = res.data.map((s) => ({
                    ...s,
                    name: s.name || `Chat Session ${formatDate(s.created_at)}`,
                }));
                setChatSessions([...data]);
                if (data?.length !== 0) {
                    setSearchParameters({ chatId: data[0].id });
                }
            })
            .catch((err) => {
                setToast.error({
                    title: "Oops!",
                    msg: "There was an error fetching chat sessions. Please refresh and try again.",
                });
            })
            .finally(() => {
                setLoadingSessions(false);
                setCreatingSession(false);
            });
    };

    const setSearchParameters = (newParams) => {
        let newSearchParams = new URLSearchParams(searchParams.toString());
        Object.keys(newParams).forEach((key) => {
            if (newParams[key]) {
                newSearchParams.set(key, newParams[key]);
            }
        });

        setSearchParams(newSearchParams);
    };

    return (
        <div className="flex flex-grow px-3 pb-3 overflow-auto relative">
            {loadingSessions ? (
                <div
                    className="absolute   top-0 left-0 w-full  flex items-center justify-center overflow-hidden rounded-md  rounded-xl box-border"
                    style={styles.loadingOverlay}
                >
                    <span className="loaderr"></span>
                </div>
            ) : (
                <div className="flex flex-grow  pb-3 overflow-auto">
                    <div className="border overflow-y-scroll min-w-[343px] w-[343px] min-h-full border-gray-300 shadow-lg rounded-lg bg-white sessions">
                        <ChatHistory
                            internalContractId={internalContractId}
                            chatSessions={chatSessions}
                            setChatSessions={setChatSessions}
                            searchParams={searchParams}
                            getAllSessions={getAllSessions}
                            creatingSession={creatingSession}
                            setCreatingSession={setCreatingSession}
                        />
                    </div>
                    <div className="flex-grow flex">
                        <ChatPanel />
                    </div>
                </div>
            )}
        </div>
    );
};

const styles = {
    loadingOverlay: {
        background: "transparent",
        height: "100%",
        zIndex: 5,
    },
};

export default AskAi;
