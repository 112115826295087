import { MutationFunction, MutationOptions, QueryKey, useMutation } from "@tanstack/react-query";
import { queryClient } from "./queryClient";
import { AxiosError } from "axios";

const updateListItem =
  () =>
  <TVariables, TData extends {}>(
    queryKey: QueryKey,
    mutationFn: MutationFunction<TData, TVariables> | undefined,
    {
      onMutate,
      onError,
      ...options
    }: Omit<MutationOptions<TData, AxiosError, TVariables>, "mutationFn" | "onMutate"> & {
      onMutate?: (variables: TVariables, previousValue?: TData) => { previousValue?: TData; newValue: TData };
    } = {}
  ) =>
    useMutation({
      mutationFn,
      onMutate: async (variables) => {
        await queryClient.cancelQueries({ queryKey });

        const oldCachedValue = queryClient.getQueryData<TData>(queryKey);
        const defaultNewValue = { ...oldCachedValue, ...variables };
        const { previousValue = oldCachedValue, newValue = defaultNewValue } =
          (await onMutate?.(variables, oldCachedValue)) || {};
        queryClient.setQueryData(queryKey, newValue);

        return { previousValue, newValue };
      },
      onError(...args) {
        queryClient.invalidateQueries({ queryKey });
        onError?.(...args);
      },
      ...options,
    });

export const listItemMutation = {
  update: updateListItem(),
};
