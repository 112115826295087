import { Modal } from "components/organisms/modal";
import CopyPasteExtraction from "./CopyPasteExtraction";
import { Button } from "components/editor/components";
import tw from "twin.macro";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { resetTemplateExtractionState } from "store/reducers/templates/templateExtractionReducer";
import DraftTemplate from "../DraftTemplate";
import { useSubmitTemplate } from "../hooks";
import { toggleTemplateExtractionModal } from "store/reducers/modalsSlice";

export type TemplateExtractionModalProps = {
    open: boolean;
};

const TemplateExtractionModal = ({ open }: TemplateExtractionModalProps) => {
    const { isSubmitting, submitTemplate } = useSubmitTemplate();
    const { isExtractError, isExtractLoading, template } = useAppSelector((root) => root.templateExtraction);
    const dispatch = useAppDispatch();
    const canSubmit = !isExtractLoading && !isExtractError && !isSubmitting && !!template.volumes?.length;

    return (
        <Modal
            contentProps={{ css: tw`h-[90vh] max-h-[1600px] w-[90vw] max-w-[1800px]` }}
            footerProps={{ css: tw`mt-0 pt-4` }}
            header="Extract Template"
            description={
                <>
                    Build a structured template from a <b>specified or internal outline</b>. Please be sure to{" "}
                    <b>include only the required text</b>.
                </>
            }
            body={
                <div className="flex flex-1 pt-6 pr-4 pl-4 gap-3 min-h-0">
                    <CopyPasteExtraction />
                    <DraftTemplate />
                </div>
            }
            footer={
                <div className="flex gap-2 justify-end">
                    <Button
                        onClick={() => {
                            dispatch(toggleTemplateExtractionModal({ open: false }));
                            dispatch(resetTemplateExtractionState());
                        }}
                        className="!border-neutral-300 !text-stone-900 hover:!bg-neutral-100"
                        variant="outline"
                        size="md"
                    >
                        Cancel
                    </Button>
                    <Button
                        loading={isSubmitting}
                        onClick={() => {
                            submitTemplate();
                        }}
                        disabled={!canSubmit}
                        variant="primary"
                        size="md"
                    >
                        Import
                    </Button>
                </div>
            }
            open={open}
            onOpenChange={(o) => {
                if (!o) dispatch(resetTemplateExtractionState());
                dispatch(toggleTemplateExtractionModal({ open: o }));
            }}
        />
    );
};

export default TemplateExtractionModal;
