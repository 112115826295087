import HardBreak from "@tiptap/extension-hard-break";

export const CustomHardBreak = HardBreak.extend({
  renderHTML() {
    return ["br"];
  },
  renderText() {
    return "\n";
  },
  addKeyboardShortcuts() {
    return {
      Enter: () => this.editor.chain().focus().insertContent("<br>").run(),
    };
  },
});
