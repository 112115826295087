import { ContentLibraryImage } from "../ContentLibrary/ContentLibraryImage";
import { CustomListItem } from "./CustomListItem";
import { CustomTableCell } from "../CustomTableCell";
import { CustomTaskItem } from "../CustomTaskItem";
import { Document } from "@tiptap/extension-document";
import { DocumentMarginNode } from "./document-margin/DocumentMargin";
import { ExtensionFontSize } from "components/yjs-editor/components/extensions/ExtensionFontSize";
import { YJSProvider } from "YJSProvider/YJSProvider";

import Collaboration from "@tiptap/extension-collaboration";
import CollaborationCursor from "@tiptap/extension-collaboration-cursor";
import FontFamily from "@tiptap/extension-font-family";
import Highlight from "@tiptap/extension-highlight";
import Image from "@tiptap/extension-image";
import PasteExtension from "./PasteExtension";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Table from "@tiptap/extension-table";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import TaskList from "@tiptap/extension-task-list";
import TextAlign from "@tiptap/extension-text-align";
import TextStyle from "@tiptap/extension-text-style";
import Typography from "@tiptap/extension-typography";
import Underline from "@tiptap/extension-underline";
import UnfocusedSelectionExtension from "./UnfocusedSelection";
import * as Y from "yjs";
import { CommentsMark } from "components/Comments";

export const getExtensions = ({
  provider,
  doc,
  user,
  removeThread,
  restoreThread,
}: {
  provider?: YJSProvider;
  doc: Y.Doc;
  user: Record<string, any>;
  removeThread: (threadId: string) => void;
  restoreThread: (threadId: string) => void;
}) => [
  StarterKit.configure({
    document: false,
    blockquote: {
      HTMLAttributes: {
        class: "tiptap-blockquote",
      },
    },
    heading: {
      levels: [1, 2, 3, 4, 5],
      HTMLAttributes: {
        class: "tiptap-heading",
      },
    },
    // The Collaboration extension comes with its own history handling
    history: false,
    horizontalRule: {
      HTMLAttributes: {
        class: "tiptap-hr",
      },
    },
    listItem: false,
    orderedList: {
      HTMLAttributes: {
        class: "tiptap-ordered-list",
      },
    },
    paragraph: {
      HTMLAttributes: {
        class: "tiptap-paragraph",
      },
    },
  }),
  Document.extend({
    content: "documentmargin",
  }),
  DocumentMarginNode,
  Highlight.configure({
    HTMLAttributes: {
      class: "tiptap-highlight",
    },
  }),
  ContentLibraryImage,
  Image.configure({
    HTMLAttributes: {
      class: "tiptap-image",
    },
  }),
  Table.configure({
    HTMLAttributes: {
      class: "tiptap-table",
    },
  }),
  TableRow.configure({
    HTMLAttributes: {
      class: "tiptap-table-row",
    },
  }),
  TableHeader.configure({
    HTMLAttributes: {
      class: "tiptap-table-header",
    },
  }),
  CustomTableCell,
  Placeholder.configure({
    placeholder: "Start writing…",
    emptyEditorClass: "tiptap-empty",
  }),
  CustomTaskItem,
  CommentsMark.configure({
    provider,
    removeThread,
    restoreThread,
  }),
  TaskList.configure({
    HTMLAttributes: {
      class: "tiptap-task-list",
    },
  }),
  TextAlign.configure({
    types: ["heading", "paragraph"],
  }),
  Typography,
  Underline,
  Collaboration.configure({
    document: doc,
  }),
  ...(provider
    ? [
        CollaborationCursor.configure({
          provider: provider,
          user,
        }),
      ]
    : []),
  TextStyle,
  ExtensionFontSize,
  FontFamily.configure({
    types: ["textStyle"],
  }),
  PasteExtension,
  UnfocusedSelectionExtension,
  CustomListItem.configure({
    HTMLAttributes: {
      class: "tiptap-list-item",
    },
  }),
];
