export const VOICE_ASSIST_STOPPED = "Stopped";
export const VOICE_ASSIST_READY = "Ready";
export const VOICE_ASSIST_RECORDING = "Recording";
export const VOICE_ASSIST_PROCESSING = "Processing";
export const VOICE_ASSIST_PLAYING = "Playing";

export const INITIAL_STATE = {
    voiceAssistState: VOICE_ASSIST_STOPPED,
    mediaRecorder: null,
    conversationId: null,
    audioUrl: null,
};

export const VOICE_CHAT_CONV_API = "/voice_assistant/conversation";
export const VOICE_CHAT_MESSAGE_API = "/voice_assistant/message";
export const VOICE_TRANSLATE_API = "/voice_assistant/translate";
