export enum ResponseTolerance {
  Strict = "strict",
  Moderate = "moderate",
  Flexible = "flexible",
}

export enum TotalScore {
  Adequate = "Adequate",
  NeedsImprovement = "Needs Improvement",
}

export enum RelevanceScore {
  Low = 1,
  Medium,
  High,
}

export const RELEVANCE_SCORE_TO_RESPONSE_TOLERANCE = {
  [RelevanceScore.Low]: ResponseTolerance.Flexible,
  [RelevanceScore.Medium]: ResponseTolerance.Moderate,
  [RelevanceScore.High]: ResponseTolerance.Strict,
};

export const RESPONSE_TOLERANCE_TO_RELEVANCE_SCORE = {
  [ResponseTolerance.Flexible]: RelevanceScore.Low,
  [ResponseTolerance.Moderate]: RelevanceScore.Medium,
  [ResponseTolerance.Strict]: RelevanceScore.High,
};

type RequirementResponse = {
  content: string;
  id: string;
  relevance_score: RelevanceScore;
  sources: [];
  total_score: TotalScore | null;
};

export type Requirement = {
  analysis_id: string | null;
  content: string;
  document_id: string | null;
  element_id: string | null;
  group_id: string | null;
  id: string;
  page: number | null;
  recommended: boolean;
  response: RequirementResponse | null;
  section_id: string | null;
  skipped: boolean;
  volume_id: string | null;
  section_header: string | null;
};
