import { CopilotPresencePage } from "types/Presence";
import {
  BookOpen,
  CircleCheck,
  CircleHelp,
  FileEdit,
  FileText,
  FileUp,
  FolderClosed,
  House,
  Target,
} from "lucide-react";

export const guideLinkSubItems = [
  {
    label: "Projects",
    to: "https://intercom.help/vultron-ai/en/articles/8893852-getting-started-projects",
  },
  {
    label: "Documents",
    to: "https://intercom.help/vultron-ai/en/articles/8896158-projects-import-documents",
  },
  {
    label: "Generate",
    to: "https://intercom.help/vultron-ai/en/articles/9312141-projects-generate",
  },
  {
    label: "Drafts",
    to: "https://intercom.help/vultron-ai/en/articles/8931970-projects-drafts",
  },
  {
    label: "Proposals",
    to: "https://intercom.help/vultron-ai/en/articles/8902376-project-proposals",
  },
];

export const sideNavigationTabs = {
  Dashboard: { name: "Dashboard", slug: CopilotPresencePage.Project, tabIcon: House },
  Documents: { name: "Documents", slug: CopilotPresencePage.Documents, tabIcon: FolderClosed },
  Capture: { name: "Capture", slug: CopilotPresencePage.Capture, tabIcon: Target },
  Generate: { name: "Generate", slug: CopilotPresencePage.Extract, tabIcon: FileUp },
  Requirements: { name: "Requirements", slug: CopilotPresencePage.ComplianceMatrix, tabIcon: CircleCheck },
  Draft: { name: "Draft", slug: CopilotPresencePage.Template, tabIcon: FileEdit },
  Proposals: { name: "Proposals", slug: CopilotPresencePage.Proposal, tabIcon: FileText },
  SupportCenter: { name: "Support Center", tabIcon: CircleHelp, openInNewTab: true },
  Guides: { name: "Guides", tabIcon: BookOpen, openInNewTab: true },
};
