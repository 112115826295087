import { SectionStatus, Volume } from "components/copilot/CopilotSchemaImmutableTypes";
import { useGetUsernameById } from "hook/user/useGetUsernameById";
import { useCallback, useMemo } from "react";
import { Download, Link, Trash2 } from "lucide-react";
import copyText from "utils/copyText";
import { useNotification } from "context/notificationContext";
import { usePublishVolume } from "hook/draft/usePublishVolume";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CopilotPresencePage } from "types/Presence";
import useGetAvatarGroup from "hook/draft/useAvatarGroup";
import { DisplayOption } from "store/reducers/draft/previewProposalReducer";
import { useTrackUserMetric } from "utils/metrics";
import { triggerConfirm } from "components/organisms/confirm-modal/utils";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import Icon from "components/atoms/icons/Icon";
import { useAppDispatch } from "store/storeTypes";
import { toggleSaveTemplateModal } from "store/reducers/modalsSlice";

export const useVolumeMeta = (volume?: Partial<Volume>) => {
  const { sections, assignees } = volume || {};
  const avatars = useGetAvatarGroup(assignees, { size: 20, className: "!text-xxs" });

  const getUsernameById = useGetUsernameById();

  const statuses = useMemo(() => {
    return (
      sections?.reduce<{ [key in SectionStatus]?: number }>(
        (acc, sec) => ({
          ...acc,
          [sec.status || SectionStatus.Todo]: (acc[sec.status || SectionStatus.Todo] || 0) + 1,
        }),
        {}
      ) || {}
    );
  }, [sections]);

  const assignedUser = useMemo(() => {
    if (assignees?.length) return getUsernameById(assignees[0]);
  }, [assignees, getUsernameById]);

  return { avatars, assignedUser, statuses };
};

export const useDropdownItems = (directionPath: string, volume?: Partial<Volume>) => {
  const { setToast } = useNotification();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const link = `${window.location.origin}/dashboard/contracts/details/${directionPath}`;
  const { publishVolume, isPublishing } = usePublishVolume();
  const navigate = useNavigate();
  const trackUserEvent = useTrackUserMetric();
  const { deleteVolume } = useFrameworkOperations();

  const handlePublish = useCallback(async () => {
    if (volume?.id && !isPublishing) {
      const initialSectionFormats =
        volume?.sections?.map((section) => ({
          id: section.id,
          display_option: DisplayOption.FullRequirement,
          heading_style: {
            bold: true,
            underline: false,
            italic: false,
          },
        })) || [];

      const docId = await publishVolume({
        volume_id: volume.id,
        section_formats: initialSectionFormats,
        include_source_documents: false,
      });
      if (!docId) return;

      const newParams = new URLSearchParams(searchParams);
      newParams.set("tab", CopilotPresencePage.Proposal);
      setToast.success({
        title: "Draft Published",
        msg: (
          <div className="text-xs flex flex-col mt-1">
            <div className="text-slate-900 font-medium">{volume.title}</div>
            <button
              className="mt-1 w-fit font-medium text-action duration-150 hover:text-action-hover"
              onClick={() =>
                navigate(`/dashboard/contracts/details?${newParams.toString()}`, {
                  state: { docId },
                })
              }
            >
              View proposal
            </button>
          </div>
        ),
      });
    }
  }, [isPublishing, navigate, publishVolume, searchParams, setToast, volume?.id, volume?.sections, volume?.title]);

  const items = useMemo(
    () => [
      {
        key: 1,
        label: (
          <div className="flex text-xs items-center">
            <Download size={16} className="mr-2 w-4" />
            {isPublishing ? "Publishing" : "Publish"}
            {isPublishing && <span className="loading-ellipsis" />}
          </div>
        ),
        disabled: isPublishing,
        onSelect: () => handlePublish(),
      },
      {
        key: 2,
        label: (
          <div className="flex text-xs items-center">
            <Icon name="Save" className="mr-2 w-4 h-4" />
            {isPublishing ? "Publishing" : "Save new template"}
            {isPublishing && <span className="loading-ellipsis" />}
          </div>
        ),
        onSelect: () => {
          if (volume?.id) dispatch(toggleSaveTemplateModal({ open: true, initialProps: { volumeId: volume.id } }));
        },
      },
      {
        key: 3,
        label: (
          <div className="flex items-center gap-2 text-xs">
            <Link size={16} />
            Copy link
          </div>
        ),
        onSelect: () =>
          copyText(link, () => {
            setToast.success({
              msg: "Copied",
            });
            trackUserEvent("Drafts: Draft Link Copied", {
              volume_id: String(volume?.id),
            });
          }),
      },
      {
        key: 4,
        label: (
          <div className="flex gap-2 text-xs items-center text-red-500">
            <Trash2 size={16} /> Delete
          </div>
        ),
        onSelect: () =>
          triggerConfirm({
            proceedLabel: "Delete",
            header: `Are you sure you want to delete "${volume?.title}"?`,
            body: "This action is irreversible and will delete all progress associated with the draft.",
          }).then((proceed) => {
            if (proceed) {
              volume?.id && deleteVolume(volume.id);
            }
          }),
      },
    ],
    [deleteVolume, dispatch, handlePublish, isPublishing, link, setToast, trackUserEvent, volume?.id, volume?.title]
  );

  return { items, isPublishing };
};
