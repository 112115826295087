/** @jsxImportSource @emotion/react */

import { Extraction, ExtractionStatus } from "components/copilot/CopilotSchemaTypes";
import { FormattedSection } from "pages/draft-volume/draft-volume-sidebar/DraftVolumeSidebar";
import { ArrowUpDown, ChevronRight, CirclePlus, EllipsisVertical } from "lucide-react";
import Icon from "components/atoms/icons/Icon";
import tw, { theme } from "twin.macro";
import useExtractionOperations from "hook/useExtractionOperations";
import * as Accordion from "@radix-ui/react-accordion";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { DndContext, DragOverlay, DraggableAttributes } from "@dnd-kit/core";
import { SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import {
  setActiveSection,
  setEditableTemplateRowState,
  setSectionToMove,
  setTemplateOpenState,
} from "store/reducers/extract/CurrentExtractionReducer";
import { StepValue } from "../../../types";
import Chip from "components/atoms/chip";
import { HTMLAttributes, forwardRef, memo, useMemo, useState } from "react";
import { useSectionDropdownItems } from "../hooks";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { SyntheticListenerMap } from "@dnd-kit/core/dist/hooks/utilities";
import RequirementList from "../RequirementList";
import { useDrag } from "./hooks";
import { verticalSortableListCollisionDetection } from "pages/draft-volume/draft-volume-sidebar/utils";
import SortableItem from "../subsection-row/SortableItem";
import SubsectionRow from "../subsection-row";
import { ToImmutable } from "YJSProvider/LiveObjects";
import Tooltip from "components/atoms/tooltip";

interface Props extends HTMLAttributes<HTMLDivElement> {
  volumeId: string;
  groupedComplianceMatrix: Record<string, ToImmutable<Extraction["compliance_matrix"]>>;
  section: FormattedSection;
  withOpacity?: boolean;
  isDragging?: boolean;
  dragProps?: { attributes?: DraggableAttributes; listeners?: SyntheticListenerMap };
}

const SectionRow = forwardRef<HTMLDivElement, Props>(
  ({ dragProps, isDragging, withOpacity, style, volumeId, groupedComplianceMatrix, section }, ref) => {
    const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);
    const requirements = groupedComplianceMatrix[section.id] || [];
    const hasRequirements = !!requirements.length;
    const hasSubsections = !!section.subsections?.length;
    const { setSectionName, addNewSection } = useExtractionOperations();
    const dispatch = useAppDispatch();
    const editableTemplateRowState = useAppSelector((store) => store.currentExtractionState.editableTemplateRowState);
    const activeSection = useAppSelector((store) => store.currentExtractionState.activeSection);
    const openState = useAppSelector((store) => store.currentExtractionState.templateOpenState);
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const isReadOnly = extraction?.status === ExtractionStatus.Completed;
    const isImportStep = extraction?.step === StepValue.Import;
    const isAssignStep = extraction?.step === StepValue.Assign;
    const shouldShowRequirementsAccordion = (isAssignStep || isImportStep) && hasRequirements;
    const { items } = useSectionDropdownItems(section, volumeId, extraction?.id);
    const isEditing = editableTemplateRowState.id === section.id;
    const isActive = activeSection?.id === section.id;
    const { sensors, handleDragEnd, dragSections, handleDragStart, handleDragCancel, activeDragId } = useDrag(
      section.subsections || [],
      volumeId,
      extraction?.id
    );

    const activeDragSection = useMemo(
      () => section.subsections?.find((subsection) => subsection.id === activeDragId),
      [activeDragId, section.subsections]
    );
    return (
      <div
        ref={ref}
        className="ml-[34px] rounded-md"
        css={[
          isDragging && tw`bg-white`,
          isDragging && isImportStep && tw`bg-layout-gray-light`,
          (shouldShowRequirementsAccordion || hasSubsections) && tw`ml-3`,
          isDragging && tw`!m-0`,
          {
            boxShadow: isDragging ? theme`boxShadow.expanded` : "none",
            margin: isDragging ? theme`boxShadow.expanded` : "none",
            zIndex: isDragging ? "4" : "auto",
            opacity: withOpacity ? "0.3" : "1",
            pointerEvents: isEditing ? "none" : "auto",
            ...style,
          },
        ]}
        {...dragProps?.attributes}
      >
        <Accordion.Item value={section.id}>
          <Accordion.Header>
            <div ref={setPortalRef} className="flex flex-col gap-1.5 select-none">
              <div
                className="cursor-default relative overflow-hidden group text-gray-darkest rounded-md"
                css={[
                  isAssignStep && !isReadOnly && tw`cursor-pointer`,
                  !isEditing && !isReadOnly && tw`hover:bg-gray-200`,
                  isActive && tw`!bg-gray-darkest !text-white`,
                ]}
              >
                <div className="flex flex-row pr-4 pl-2 gap-0.5 items-center">
                  {!isReadOnly && (
                    <div
                      className="z-[1] bg-transparent rounded p-1 opacity-0 text-slate-500 hover:text-slate-900 hover:bg-gray-300 group-hover:opacity-100"
                      css={[
                        { cursor: isDragging ? "grabbing" : "grab" },
                        isActive && tw`text-white hover:text-white hover:bg-zinc-700`,
                        isDragging && tw`opacity-100`,
                      ]}
                      {...dragProps?.listeners}
                    >
                      <Icon name="Draggable" className="w-3 h-3" />
                    </div>
                  )}
                  <div
                    className="text-xs flex flex-row gap-1.5 items-center w-[calc(100%-22px)]"
                    onClick={() => {
                      if (!isReadOnly) {
                        if (!isActive) dispatch(setActiveSection(section));
                        if (isActive) dispatch(setActiveSection(undefined));
                        if (shouldShowRequirementsAccordion || hasSubsections)
                          dispatch(setTemplateOpenState([...openState, section.id]));
                      }
                    }}
                  >
                    {(shouldShowRequirementsAccordion || hasSubsections) && (
                      <Accordion.Trigger
                        title={section.title}
                        className="rounded group duration-100"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <ChevronRight
                          size={14}
                          className="flex-shrink-0 transition-transform mr-0.5 duration-200 group-data-[state=open]:rotate-90"
                        />
                      </Accordion.Trigger>
                    )}
                    {isEditing ? (
                      <>
                        <Icon name="Section" className="min-w-3" />
                        <input
                          placeholder="Section title..."
                          autoFocus
                          onChange={(e) => {
                            dispatch(setEditableTemplateRowState({ localValue: e.target.value }));
                          }}
                          onKeyDown={(e) => {
                            if (e.code === "Enter") {
                              if (extraction?.id) {
                                setSectionName(
                                  extraction.id,
                                  volumeId,
                                  section.id,
                                  editableTemplateRowState.localValue
                                );
                              }
                              setTimeout(
                                () =>
                                  dispatch(
                                    setEditableTemplateRowState({
                                      id: "",
                                      localValue: "",
                                    })
                                  ),
                                100
                              );
                            }
                          }}
                          onBlur={() => {
                            if (extraction?.id) {
                              setSectionName(extraction.id, volumeId, section.id, editableTemplateRowState.localValue);
                            }
                            setTimeout(
                              () =>
                                dispatch(
                                  setEditableTemplateRowState({
                                    id: "",
                                    localValue: "",
                                  })
                                ),
                              100
                            );
                          }}
                          value={editableTemplateRowState.localValue}
                          className="text-[13px] font-semibold py-2 outline-none text-gray-darkest w-full bg-transparent"
                          css={[isActive && tw`bg-gray-darkest text-white`]}
                        />
                      </>
                    ) : (
                      <>
                        <Icon name="Section" className="min-w-3" />
                        <div className="text-[13px] py-2 w-full min-w-0">
                          <div className="flex flex-row justify-between items-center">
                            <span
                              className="whitespace-nowrap font-semibold"
                              css={[
                                shouldShowRequirementsAccordion && tw`truncate`,
                                !section.title.trim() && tw`text-gray-400`,
                              ]}
                            >
                              {section.title || "Section title..."}
                            </span>
                            {hasRequirements && (
                              <Chip
                                variant="primary"
                                className="ml-1 !h-[16px] min-w-[16px] !px-1"
                                colors={{
                                  primaryColor: isActive ? theme`colors.gray.100` : theme`colors.gray.700`,
                                  secondaryColor: isActive ? theme`colors.gray.700` : theme`colors.gray.100`,
                                }}
                              >
                                <div className="text-[0.5rem]">{requirements.length}</div>
                              </Chip>
                            )}
                          </div>
                          {!isEditing && !isReadOnly && (
                            <div
                              className="absolute bottom-0 right-0 top-0 to-transparent bg-gradient-to-l w-14 from-white group-hover:w-32 group-hover:from-50% group-hover:from-gray-200"
                              css={[
                                shouldShowRequirementsAccordion && tw`from-transparent`,
                                isActive && tw`!from-gray-darkest from-50% w-24`,
                                isImportStep && tw`from-layout-gray-light group-hover:w-24`,
                              ]}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
                {!isReadOnly && (
                  <div
                    className="opacity-0 text-slate-700 text-sm flex items-center gap-1.5 absolute bottom-0 right-0 top-0 pl-2 pr-2 bg-gray-200 group-hover:opacity-100"
                    css={[isEditing && tw`hidden`, isActive && tw`!bg-gray-darkest !text-white opacity-100`]}
                  >
                    <Tooltip content="Move">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          dispatch(setSectionToMove(section));
                        }}
                      >
                        <ArrowUpDown size={16} />
                      </button>
                    </Tooltip>
                    {!isImportStep && (
                      <Tooltip content="Add subsection">
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            if (extraction?.id) {
                              const createdSection = addNewSection(extraction.id, volumeId, {
                                parent_id: section.id,
                              });
                              if (!openState.includes(section.id)) {
                                dispatch(setTemplateOpenState([...openState, section.id]));
                              }
                              setTimeout(
                                () =>
                                  dispatch(
                                    setEditableTemplateRowState({
                                      localValue: createdSection.title,
                                      id: createdSection.id,
                                    })
                                  ),
                                80
                              );
                            }
                          }}
                        >
                          <CirclePlus size={16} />
                        </button>
                      </Tooltip>
                    )}
                    <DropdownMenu
                      portalProps={{ container: portalRef }}
                      contentProps={{ align: "end", css: tw`min-w-[120px]` }}
                      items={items}
                    >
                      <div className="">
                        <EllipsisVertical size={14} />
                      </div>
                    </DropdownMenu>
                  </div>
                )}
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Content className="collapsibleContent cursor-default">
            {shouldShowRequirementsAccordion && <RequirementList requirements={requirements} />}
            <DndContext
              sensors={sensors}
              collisionDetection={verticalSortableListCollisionDetection}
              onDragEnd={(event) => handleDragEnd(event)}
              onDragStart={handleDragStart}
              onDragCancel={handleDragCancel}
            >
              <SortableContext
                id="EXTRACTION_TEMPLATE_SUBSECTIONS"
                // @ts-ignore
                items={dragSections || []}
                strategy={verticalListSortingStrategy}
              >
                {dragSections?.map((subsection) => (
                  <SortableItem
                    key={subsection.id}
                    volumeId={volumeId}
                    section={subsection}
                    groupedComplianceMatrix={groupedComplianceMatrix}
                  />
                ))}
                <DragOverlay style={{ transformOrigin: "0 0 " }}>
                  {!!activeDragId && activeDragSection && (
                    <SubsectionRow
                      volumeId={volumeId}
                      section={activeDragSection}
                      groupedComplianceMatrix={groupedComplianceMatrix}
                      isDragging
                    />
                  )}
                </DragOverlay>
              </SortableContext>
            </DndContext>
          </Accordion.Content>
        </Accordion.Item>
      </div>
    );
  }
);

export default memo(SectionRow);
