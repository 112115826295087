import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllPromptFiles } from "api/api";

export type ProjectAttachment = {
    id: string;
    name: string;
    file_extension_type: string;
    download_url: string;
    secure_preview_url: string;
};

export type State = {
    projectAttachments: ProjectAttachment[];
    isProjectAttachmentsLoading: boolean;
};

const initialState: State = {
    projectAttachments: [],
    isProjectAttachmentsLoading: false,
};

export const getProjectAttachments = createAsyncThunk<State["projectAttachments"], string>(
    "projectAttachmentsState/projectAttachments",
    async (internalContractId: string) => {
        const response = await getAllPromptFiles(internalContractId, "&bucket_only=True");
        return response.data;
    }
);

const projectAttachmentsReducer = createSlice({
    name: "projectAttachmentsState",
    initialState,
    reducers: {
        resetProjectAttachmentsState: () => {
            return initialState;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getProjectAttachments.pending, (state) => {
            state.isProjectAttachmentsLoading = true;
        });
        builder.addCase(getProjectAttachments.fulfilled, (state, action) => {
            state.projectAttachments = action.payload;
            state.isProjectAttachmentsLoading = false;
        });
        builder.addCase(getProjectAttachments.rejected, (state) => {
            state.isProjectAttachmentsLoading = false;
        });
    },
});

export const { resetProjectAttachmentsState } = projectAttachmentsReducer.actions;

export default projectAttachmentsReducer.reducer;
