/** @jsxImportSource @emotion/react */

import { Document } from "react-pdf";
import { DocumentCallback } from "react-pdf/dist/cjs/shared/types";
import { GroupedBlock, StepValue } from "../types";
import { CircleCheck, Merge, X } from "lucide-react";
import { ScreenSpinner } from "utils/icons";
import { Section } from "components/copilot/CopilotSchemaTypes";
import { setRequirementsConfig } from "store/reducers/extract/CurrentExtractionReducer";
import { useDragSelectOperation, useMergeRequirements } from "./hooks";
import { forwardRef, useCallback, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import PageWrapper from "./PageWrapper";
import useExtractionOperations from "hook/useExtractionOperations";
import { ToImmutable } from "YJSProvider/LiveObjects";
import { SelectionArea } from "./SelectionContext";
import { useDocViewNotification } from "../../context/doc-view-notification-context/docViewNotificationContext";
import { keyBy } from "lodash";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { useTrackUserMetric } from "utils/metrics";

type PageDimensionsMap = Record<number, { width: number; height: number }>;

type Props = {
  scale: number;
  groupedBlocks: {
    allFilteredBlocks: GroupedBlock[];
    pageGroups: Record<number, GroupedBlock[]>;
  };
  isReadOnly: boolean;
  sectionsMap: Record<string, ToImmutable<Section>>;
  keysOfSectionMap: string[];
};

const DocumentContainer = forwardRef<HTMLDivElement, Props>(
  ({ scale, keysOfSectionMap, sectionsMap, isReadOnly, groupedBlocks }, ref) => {
    const dispatch = useAppDispatch();
    const { setToast } = useDocViewNotification();
    const trackUserEvent = useTrackUserMetric();
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const filteredRequirements = useAppSelector((store) => store.currentExtractionState.filteredRequirements);
    const mergedRequirements = useAppSelector((store) => store.currentExtractionState.mergedRequirements);
    const groupedFilteredRequirements = useMemo(
      () =>
        keyBy(
          filteredRequirements.map((filteredRequirement) => {
            return filteredRequirement.group_id
              ? mergedRequirements.find((mergedRequirement) => mergedRequirement.id === filteredRequirement.group_id) ||
                  filteredRequirement
              : filteredRequirement;
          }),
          "id"
        ),
      [filteredRequirements, mergedRequirements]
    );
    const isAssignStep = extraction?.step === StepValue.Assign;
    const isRequirementsStep = extraction?.step === StepValue.Requirements;
    const { mergeRequirements, isLoading: isMergingRequirements } = useMergeRequirements();

    const {
      bulkUnassignExtractionRequirements,
      setBulkExtractionRequirementsSkipped,
      bulkAssignExtractionRequirements,
    } = useExtractionOperations();
    const [numPages, setNumPages] = useState(0);
    const [dimensions, setDimensions] = useState<PageDimensionsMap>({});
    const { selectedBlocks, clearSelection, onMove, onStop, onBeforeStart } = useDragSelectOperation(
      groupedBlocks.allFilteredBlocks,
      extraction
    );
    const activeSection = useAppSelector((store) => store.currentExtractionState.activeSection);
    const isLoading = useAppSelector((store) => {
      const { isLoadingCoordinates, isLoadingDocuments, isLoadingTemplate, isLoadingRequirementGroups } =
        store.currentExtractionState;
      return isLoadingCoordinates || isLoadingDocuments || isLoadingTemplate || isLoadingRequirementGroups;
    });
    const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);

    const pageList = useMemo(() => Array.from({ length: numPages }, (_, i) => i + 1), [numPages]);

    const file = useMemo(
      () => ({ url: activeDocument?.secure_preview_url || "" }),
      [activeDocument?.secure_preview_url]
    );

    const onBlockSelected = useCallback(
      (reqId: string) => {
        setToast.success({
          msg: (
            <div className="flex flex-row gap-2 items-center text-sm text-white">
              <CircleCheck size={14} />
              <span>Requirement added.</span>
            </div>
          ),
        });
        trackUserEvent("Generate: Requirement Added", {
          analysis_id: extraction?.id,
          solicitation_type: extraction?.solicitation_type,
        });
        dispatch(setRequirementsConfig({ currentRequirementIdAdded: reqId }));
      },
      [dispatch, extraction?.id, extraction?.solicitation_type, setToast, trackUserEvent]
    );

    const onDocumentLoadSuccess = useCallback(async (pdf: DocumentCallback) => {
      setNumPages(pdf.numPages);

      try {
        const pageDimensionsMap: PageDimensionsMap = {};
        // If you want to get the dimension for page 1 (1-indexed)
        const pageList = Array.from({ length: pdf.numPages }, (_, i) => i + 1);
        const requestList = await Promise.all(pageList.map((page) => pdf.getPage(page)));

        for (let i = 0; i < requestList.length; i++) {
          const page = requestList[i];
          const width = page.view[2];
          const height = page.view[3];
          pageDimensionsMap[pageList[i]] = { width, height };
        }
        setDimensions(pageDimensionsMap);
      } catch {}
    }, []);

    const onDocumentLoadError = useCallback((error: Error) => {
      console.log("Load error", error);
    }, []);

    const DisplayChild = useMemo(
      () => (
        <div className="px-8 pt-14 w-full">
          {file.url && !isLoading ? (
            <Document
              renderMode="canvas"
              file={file}
              onLoadError={onDocumentLoadError}
              onLoadSuccess={onDocumentLoadSuccess}
              className="w-full h-full"
              loading={
                <div className="absolute inset-0 top-14 flex items-center justify-center">
                  <ScreenSpinner />
                </div>
              }
            >
              {window === undefined ? (
                <div />
              ) : (
                pageList.map((page) => {
                  const pageBlocks = groupedBlocks.pageGroups[page] || [];

                  return (
                    <PageWrapper
                      groupedFilteredRequirements={groupedFilteredRequirements}
                      page={page}
                      isAssignStep={isAssignStep}
                      dimensions={dimensions[page]}
                      key={page}
                      isReadOnly={isReadOnly}
                      pageBlocks={pageBlocks}
                      scale={scale}
                      onBlockSelected={onBlockSelected}
                      sectionsMap={sectionsMap}
                      keysOfSectionMap={keysOfSectionMap}
                    />
                  );
                })
              )}
            </Document>
          ) : (
            <div className="flex items-center justify-center h-full">
              <ScreenSpinner />
            </div>
          )}
        </div>
      ),
      [
        dimensions,
        file,
        groupedBlocks.pageGroups,
        groupedFilteredRequirements,
        isAssignStep,
        isLoading,
        isReadOnly,
        keysOfSectionMap,
        onBlockSelected,
        onDocumentLoadError,
        onDocumentLoadSuccess,
        pageList,
        scale,
        sectionsMap,
      ]
    );

    return (
      <>
        <SelectionArea
          onStop={onStop}
          className="doc-view-selection-container flex justify-center h-full overflow-y-auto"
          onMove={onMove}
          selectables=".ds-selectable"
          container=".doc-view-selection-container"
          onBeforeStart={onBeforeStart}
          selectedBlocks={selectedBlocks}
        >
          {DisplayChild}
        </SelectionArea>

        {!!selectedBlocks?.length && !isReadOnly && (
          <div className="absolute select-none shadow-lg shadow-black/40 z-[10] rounded-md p-2 bg-black/85 backdrop-blur-lg bottom-10 left-1/2 -translate-x-1/2 flex flex-row items-center gap-2 animate-fadeIn duration-100">
            <div className="shrink-0 flex h-7 items-center">
              <div className="text-gray-100 h-[inherit] flex items-center px-2 text-xs border border-r-0 border-dashed border-gray-500 rounded-l">
                {selectedBlocks.length} chosen
              </div>
              <button
                onClick={clearSelection}
                className="h-[inherit] text-gray-200 text-base px-1.5 border border-dashed border-gray-500 rounded-r duration-100 hover:text-white hover:border-gray-100"
              >
                <X size={16} />
              </button>
            </div>
            {isRequirementsStep && selectedBlocks.length > 1 && (
              <>
                <div className="w-px bg-gray-500 h-[24px]" />

                <button
                  onClick={async () => {
                    if (!extraction?.id || isMergingRequirements) return;
                    const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

                    try {
                      await mergeRequirements({ requirement_ids: selectedReqIds });
                      clearSelection();
                    } catch {}
                  }}
                  className="text-xs flex items-center gap-2 max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black disabled:text-gray-500 disabled:cursor-not-allowed disabled:bg-transparent disabled:border-gray-500"
                  disabled={isMergingRequirements}
                >
                  {isMergingRequirements ? <SpinnerCircle className="h-3.5 w-3.5" /> : <Merge size={14} />}

                  <div className="text-xs">Merge</div>
                </button>
              </>
            )}
            <div className="w-px bg-gray-500 h-[24px]" />
            <button
              onClick={() => {
                if (!extraction?.id) return;
                const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

                if (!isAssignStep) {
                  setBulkExtractionRequirementsSkipped(extraction.id, selectedReqIds, true);
                }

                if (isAssignStep) {
                  bulkUnassignExtractionRequirements(extraction?.id, selectedReqIds);
                }
                clearSelection();
              }}
              className="text-xs max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black"
            >
              {isAssignStep ? "Unassign" : "Deselect"}
            </button>
            {(!isAssignStep || (isAssignStep && !!activeSection)) && (
              <>
                <div className="w-px bg-gray-500 h-[24px]" />
                <button
                  onClick={() => {
                    if (!extraction?.id) return;
                    const selectedReqIds = selectedBlocks.map((block) => block.requirement.requirement.id);

                    if (!isAssignStep) {
                      setBulkExtractionRequirementsSkipped(extraction.id, selectedReqIds, false);
                      setToast.success({
                        msg: (
                          <div className="flex flex-row gap-2 items-center text-sm text-white">
                            <CircleCheck size={14} />
                            <span>
                              {selectedReqIds.length} requirement{selectedReqIds.length !== 1 && "s"} added.
                            </span>
                          </div>
                        ),
                      });
                    }
                    if (isAssignStep && activeSection?.id)
                      bulkAssignExtractionRequirements(extraction.id, selectedReqIds, activeSection.id);
                    clearSelection();
                  }}
                  className="text-xs truncate max-w-80 font-medium border px-2.5 py-1.5 border-gray-400 rounded duration-100 text-gray-100 hover:text-white hover:border-gray-100 hover:bg-black"
                >
                  {isAssignStep ? `Assign to "${activeSection?.title}"` : "Select"}
                </button>
              </>
            )}
          </div>
        )}
      </>
    );
  }
);

export default DocumentContainer;
