/** @jsxImportSource @emotion/react */

import tw from "twin.macro";
import Icon from "components/atoms/icons/Icon";
import { SectionStatus } from "components/copilot/CopilotSchemaImmutableTypes";
import Avatar from "components/molecules/avatar";
import { SECTION_STATUS_TO_META } from "const-values/Draft";
import EditableContent from "components/molecules/editable-content";
import { RefreshCw } from "lucide-react";
import { useLayoutEffect, useRef } from "react";

const SectionNavRowLoading = () => {
  const ref = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    ref.current?.scrollIntoView();
  }, []);

  return (
    <div ref={ref} className="relative pointer-events-none">
      <div className="backdrop-blur-sm rounded absolute z-[2] top-0 bottom-0 left-0 right-0 flex flex-col gap-3 justify-center items-center">
        <RefreshCw
          className="text-action"
          size={16}
          css={[
            {
              animation: "rotateAnimation 0.8s infinite linear",
            },
          ]}
        />
        <div className="text-action text-sm font-medium">
          Section Generating
          <span className="loading-ellipsis" />
        </div>
      </div>
      <div className="group flex flex-col rounded relative gap-2 p-2.5 pl-1 overflow-hidden bg-slate-100">
        <div className="flex flex-row items-center justify-between">
          <div className="flex flex-row items-start gap-1 flex-1">
            <div className="z-[1] mt-0.5 bg-transparent rounded p-1 opacity-0 text-slate-500 hover:text-slate-900 hover:bg-slate-200 group-hover:opacity-100">
              <Icon name="Draggable" className="w-3 h-3" />
            </div>
            <EditableContent
              disabled
              content=""
              onContentUpdate={(val) => {}}
              css={[tw`font-medium p-0 text-gray-mid text-sm z-[1] -ml-1 pl-1 py-0.5 flex-1`]}
              textareaProps={{ placeholder: "Add section title" }}
            />
          </div>
        </div>
        <div className="pl-6 flex gap-1.5">
          <button className="z-[1] h-7 max-w-full flex items-center text-gray-700 py-1 px-1.5 rounded bg-slate-200 duration-150 hover:bg-slate-200">
            <span className="text-xs text-slate-500">
              <Avatar size={18} empty />
            </span>
          </button>

          <button className="z-[1] h-7 flex items-center max-w-full text-gray-700 py-1 px-1.5 rounded bg-slate-200 duration-150 hover:bg-slate-200">
            <div className="flex text-xs items-center gap-1.5 text-gray-500 truncate">
              {SECTION_STATUS_TO_META[SectionStatus.Todo].icon}
              {SECTION_STATUS_TO_META[SectionStatus.Todo].label}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionNavRowLoading;
