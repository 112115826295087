/** @jsxImportSource @emotion/react */

import classNames from "classnames";
import { Button } from "components/editor/components";
import HighlightWords from "components/organisms/HighlightWords";
import { FormEvent, HTMLProps, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { useDebounce } from "react-use";
import { toggleRequirementDetails } from "store/reducers/copilot/copilotDrawerReducer";
import { setActiveRequirementId } from "store/reducers/copilot/requirementsReducer";
import { useAppDispatch } from "store/storeTypes";
import tw from "twin.macro";
import { useTrackUserMetric } from "utils/metrics";
import { getWordCount } from "utils/getWordCount";

interface Props extends HTMLProps<HTMLDivElement> {
    editable?: boolean;
    isEditActive?: boolean;
    clamp?: boolean;
    reqId: string;
    title: string;
    onOpenEdit?: () => void;
    onCloseEdit?: (e?: FormEvent<HTMLFormElement>) => void;
    onEdit?: (title: string) => void;
    footer?: ReactNode;
    actionsButtons?: ReactNode;
    rowActions?: ReactNode;
    searchTerm?: string;
}

const RequirementRow = ({
    reqId,
    editable,
    onEdit,
    onCloseEdit,
    onOpenEdit,
    title,
    isEditActive,
    footer,
    clamp,
    rowActions,
    className,
    searchTerm,
    actionsButtons,
    ...props
}: Props) => {
    const contentRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const [showMore, setShowMore] = useState({
        visible: false,
        open: false,
    });
    const [rowTitle, setRowTitle] = useState(title);
    const trackUserEvent = useTrackUserMetric();

    useLayoutEffect(() => {
        if (clamp && contentRef.current) {
            if ((contentRef.current?.clientHeight || 0) >= 160) setShowMore({ visible: true, open: false });
        }
    }, [clamp]);

    const firstUpdate = useRef(true);
    useDebounce(
        () => {
            if (rowTitle !== title && !firstUpdate.current && editable) {
                onEdit?.(rowTitle);
            }
            firstUpdate.current = false;
        },
        200,
        [rowTitle]
    );

    return (
        <div
            className={classNames(["!outline-0 flex flex-col w-full pb-2 !cursor-default px-2", className])}
            {...props}
        >
            <div className="flex items-center gap-2.5 w-full cursor-default">
                <div className="flex items-center gap-2 w-full cursor-default">
                    {actionsButtons}
                    <div
                        className="relative group flex flex-col justify-center flex-1 min-h-[46px] bg-slate-50 text-stone-900 outline-none text-sm font-normal rounded-lg overflow-hidden border border-slate-200 hover:bg-slate-100 hover:border-slate-500"
                        css={[isEditActive && tw`cursor-text`]}
                    >
                        <button
                            className="absolute top-0 left-0 right-0 bottom-0"
                            onClick={() => {
                                dispatch(setActiveRequirementId(reqId));
                                dispatch(toggleRequirementDetails(true));
                            }}
                        />
                        <div className="flex items-center justify-between gap-4 p-2.5 pr-9">
                            {isEditActive && editable ? (
                                <input
                                    type="text"
                                    className="z-[1] bg-transparent focus:outline-none border-0 rounded-0 focus:ring-0 ring-0 w-full pr-2"
                                    placeholder=""
                                    value={rowTitle}
                                    onChange={(e) => setRowTitle(e.target.value)}
                                    autoFocus={isEditActive}
                                    onBlur={() => {
                                        onCloseEdit?.();
                                        trackUserEvent("Requirements: Requirement Updated", {
                                            requirement_id: String(reqId),
                                            word_count: getWordCount(rowTitle),
                                        });
                                    }}
                                    onKeyDown={(e) => {
                                        if (e.code === "Enter") {
                                            onCloseEdit?.();
                                            trackUserEvent("Requirements: Requirement Updated", {
                                                requirement_id: String(reqId),
                                                word_count: getWordCount(rowTitle),
                                            });
                                        }
                                    }}
                                />
                            ) : (
                                <div className="flex items-center gap-2">
                                    <div className="">
                                        <div
                                            ref={contentRef}
                                            className="flex items-center gap-2 z-[1]"
                                            css={[
                                                clamp && !showMore.open && tw`line-clamp-8`,
                                                { wordBreak: "break-word" },
                                            ]}
                                        >
                                            {searchTerm?.trim() ? (
                                                <HighlightWords text={title} highlight={searchTerm} />
                                            ) : (
                                                title
                                            )}
                                            <div className="flex items-center gap-0.5">
                                                {editable && (
                                                    <div
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            onOpenEdit?.();
                                                        }}
                                                        className="text-action font-medium align-middle relative z-[1] inline-flex justify-center items-center cursor-pointer select-none hover:text-action-hover"
                                                        role="button"
                                                    >
                                                        Fill in requirement...
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {showMore.visible && (
                                            <>
                                                {!showMore.open && (
                                                    <Button
                                                        variant="link"
                                                        onClick={() =>
                                                            setShowMore((prev) => ({
                                                                ...prev,
                                                                open: true,
                                                            }))
                                                        }
                                                        className="font-semibold relative text-xs"
                                                    >
                                                        Show more...
                                                    </Button>
                                                )}
                                                {showMore.open && (
                                                    <Button
                                                        variant="link"
                                                        onClick={() =>
                                                            setShowMore((prev) => ({
                                                                ...prev,
                                                                open: false,
                                                            }))
                                                        }
                                                        className="font-semibold relative text-xs"
                                                    >
                                                        Show less...
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {rowActions}
                        {footer}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RequirementRow;
