/** @jsxImportSource @emotion/react */

import { useState, useMemo } from "react";
import { Download, ExternalLink } from "lucide-react";
import { ListItemProps } from "./types";
import useDocumentPreview from "hook/useDocumentPreview";
import { Spinner } from "utils/icons";
import { Button } from "components/editor/components";
import { getFileIcon } from "utils/getFileIcon";
import folderIcon from "Assets/folderIcon.svg";
import { toggleDocumentViewerModal } from "store/reducers/modalsSlice";
import { useAppDispatch } from "store/storeTypes";
import Tooltip from "components/atoms/tooltip";

const ListItem = ({ item, type, addType, allSelectedDocuments, allNestedFiles, onRowSelected }: ListItemProps) => {
  const [isAddingItems, setIsAddingItems] = useState(false);
  const { downloadFile } = useDocumentPreview();
  const dispatch = useAppDispatch();

  const isItemAdded = useMemo(() => {
    if (type === "directory") {
      return !!allNestedFiles?.every((file) => allSelectedDocuments?.find(({ file_id }) => file_id === file.id));
    }

    return allSelectedDocuments.find((doc) => doc.file_id === item.id);
  }, [allNestedFiles, allSelectedDocuments, item.id, type]);

  return (
    <div
      className="group w-full pl-4 pr-6 flex gap-6 items-center justify-between hover:bg-action-lightest"
      onClick={() => onRowSelected?.()}
    >
      <div className="group/name relative flex flex-1 py-4 cursor-pointer overflow-hidden items-center gap-1">
        <img
          className="h-[24px] w-[24px] object-contain"
          src={"file_extension_type" in item ? getFileIcon(item.file_extension_type) : folderIcon}
          alt="file-icon"
        />

        <p className="text-sm pl-2 text-gray-700 pt-[1px] truncate max-w-full w-max text-left">{item?.name}</p>
        {type === "directory" && (
          <p className="text-[10px] pt-[3px] text-[#5B6B79] whitespace-nowrap">({allNestedFiles?.length} Documents)</p>
        )}

        {type === "document" && (
          <div className="flex pl-1 flex-row items-center gap-2 opacity-0 transition-opacity duration-150 group-hover/name:opacity-100">
            <button
              onClick={() => {
                if ("download_url" in item) downloadFile(item.download_url);
              }}
              className="text-[16px] pb-0.5 text-action duration-150 hover:text-action-hover"
            >
              <Download size={16} />
            </button>
            {"file_extension_type" in item && item.file_extension_type === "pdf" && (
              <Tooltip content="Open">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    dispatch(toggleDocumentViewerModal({ open: true, initialProps: { url: item.download_url } }));
                  }}
                  className="text-xs pb-0.5 text-action duration-150 hover:text-action-hover"
                >
                  <ExternalLink size={16} className="shrink-0" />
                </button>
              </Tooltip>
            )}
          </div>
        )}
      </div>
      <Button
        variant="link"
        size="md"
        className="flex items-center gap-2 text-[14px] font-medium rounded opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto"
        onClick={(event) => {
          event?.stopPropagation();
          setIsAddingItems(true);
          addType(item, () => setIsAddingItems(false));
        }}
        disabled={isItemAdded}
      >
        {isAddingItems ? (
          <Spinner classes="!text-black" />
        ) : (
          <>
            {isItemAdded ? (
              <>Added</>
            ) : (
              <>
                <span className="text-[18px]">+</span> Add
              </>
            )}
          </>
        )}
      </Button>
    </div>
  );
};

export default ListItem;
