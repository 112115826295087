import { Section } from "components/copilot/CopilotSchemaImmutableTypes";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { useEffect, useLayoutEffect } from "react";
import { setRequirementsState } from "store/reducers/draft/sectionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMutation } from "YJSProvider/createYJSContext";
import { filter, some } from "YJSProvider/LiveObjects";

export const useRequirementHighlight = () => {
    const dispatch = useAppDispatch();
    const { requirementHighlighted, requirementRouted } = useAppSelector(
        (store) => store.sectionState.requirementsState
    );

    useLayoutEffect(() => {
        const observeClick = () => !requirementRouted && dispatch(setRequirementsState({ requirementHighlighted: "" }));
        if (requirementHighlighted) {
            const requirementCard = document.getElementById(`section-requirement-${requirementHighlighted}`);
            requirementCard?.scrollIntoView({
                block: "center",
                inline: "center",
                behavior: "smooth",
            });

            document.addEventListener("mousedown", observeClick);
        }
        return () => {
            document.removeEventListener("mousedown", observeClick);
        };
    }, [dispatch, requirementHighlighted, requirementRouted]);
};

export const useValidateRequirementOrder = (section?: Section) => {
    const validateRequirementOrder = useMutation(({ storage }, sectionId: string) => {
        const complianceMatrixRows = storage.get("compliance_matrix") as Storage["compliance_matrix"] | undefined;
        if (!complianceMatrixRows) return;
        const rowsInSection = filter(
            complianceMatrixRows,
            (row) => row?.get("proposal_reference").get("section_id") === sectionId
        );
        const doesNotHaveFullOrdering = rowsInSection.some(
            (row) => typeof row.get("requirement")?.get("section_order") !== "number"
        );
        if (doesNotHaveFullOrdering) {
            rowsInSection.forEach((row, idx) => {
                const requirement = row?.get("requirement");
                requirement?.set("section_order", idx);
            });
        }
    }, []);

    useEffect(() => {
        if (section?.id) validateRequirementOrder(section.id);
    }, [section?.id, validateRequirementOrder]);
};
