/** @jsxImportSource @emotion/react */

import { ComponentProps, forwardRef, HTMLAttributes, useEffect, useState } from "react";
import tw from "twin.macro";
import { FlexibleTextarea } from "../flexible-textarea";
import { Interpolation, Theme } from "@emotion/react";

interface Props extends HTMLAttributes<HTMLDivElement> {
  submitOnEnter?: boolean;
  autoFocus?: boolean;
  content: string;
  onContentUpdate: (content: string) => void;
  heightProps?: Interpolation<Theme>;
  textareaProps?: ComponentProps<typeof FlexibleTextarea>;
  disabled?: boolean;
  onHeightSet?: (height: number) => void;
}

const EditableContent = forwardRef<HTMLDivElement, Props>(
  (
    { submitOnEnter, textareaProps, content, onContentUpdate, autoFocus, heightProps, disabled, onHeightSet, ...props },
    ref
  ) => {
    const [isEditing, setIsEditing] = useState(autoFocus || false);
    const [localContent, setLocalContent] = useState(content || "");

    useEffect(() => {
      setLocalContent(content);
    }, [content]);

    useEffect(() => {
      if (autoFocus) setIsEditing(true);
    }, [autoFocus]);

    return (
      <div
        onClick={() => setIsEditing(true)}
        tw="text-base flex items-center rounded p-1 pointer-events-none"
        css={[
          !isEditing && tw`hover:bg-slate-100 cursor-default pointer-events-auto`,
          isEditing && tw`!bg-transparent`,
          disabled && tw`!pointer-events-none`,
        ]}
        ref={ref}
        {...props}
      >
        <FlexibleTextarea
          flexible
          value={localContent}
          className="block outline-none resize-none bg-transparent border-none pointer-events-auto w-full"
          onChange={(e) => isEditing && setLocalContent(e.currentTarget.value)}
          onBlur={() => {
            onContentUpdate(localContent);
            setIsEditing(false);
          }}
          onHeightSet={onHeightSet}
          onKeyDown={
            submitOnEnter
              ? (e) => {
                  if (e.code === "Enter" && !e.shiftKey) {
                    onContentUpdate(localContent);
                    setIsEditing(false);
                  }
                }
              : undefined
          }
          autoFocus={autoFocus || isEditing}
          readOnly={!isEditing}
          css={[!isEditing && heightProps, disabled && tw`!pointer-events-none`]}
          {...textareaProps}
        />
      </div>
    );
  }
);

export default EditableContent;
