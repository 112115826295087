import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import { useNotification } from "context/notificationContext";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setContentSearchState } from "store/reducers/copilot/contentSearchReducer";

type SearchContentVariables = {
    text: string;
    file_id_filters: string[];
};

const LOADING_MSGS = ["Searching", "Analyzing", "Organizing"];

export const useSearchContent = () => {
    const { setToast } = useNotification();
    const [isResponseEmpty, setIsResponseEmpty] = useState(false);
    const [loadingMsg, setLoadingMsg] = useState(LOADING_MSGS[0]);
    const { isLoading, results } = useAppSelector((root) => root.contentSearch);
    const { contentSearchOpen } = useAppSelector((root) => root.copilotDrawer);
    const dispatch = useAppDispatch();
    useAnimateLoadingMsg(isLoading, 3500, LOADING_MSGS, (msg) => setLoadingMsg(msg));

    const reset = useCallback(() => {
        setIsResponseEmpty(false);
        setLoadingMsg(LOADING_MSGS[0]);
    }, []);

    useEffect(() => {
        if (!contentSearchOpen) {
            reset();
        }
    }, [contentSearchOpen, reset]);

    const searchContent = useCallback(
        async (variables: SearchContentVariables) => {
            dispatch(setContentSearchState({ isLoading: true }));
            reset();
            try {
                const { data } = await axios.post("/writing/search/full_text", variables);
                setIsResponseEmpty(data && !data?.length);
                if (data?.length) dispatch(setContentSearchState({ results: data }));
            } catch {
                setToast.error({
                    title: "Unable to search content",
                    msg: "We were unable to search content due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance.",
                });
            } finally {
                dispatch(setContentSearchState({ isLoading: false }));
            }
        },
        [dispatch, reset, setToast]
    );

    return { searchContent, isLoading, isResponseEmpty, loadingMsg, results };
};
