/** @jsxImportSource @emotion/react */

import { Button } from "components/editor/components";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { StepValue } from "./doc-viewer";
import { setExtractState } from "store/reducers/extract/CurrentExtractionReducer";
import tw from "twin.macro";

const GenerationBanner = () => {
    const extraction = useAppSelector((store) => store.currentExtractionState.currentExtraction);
    const shouldShowAssignBanner = useAppSelector((store) => store.currentExtractionState.shouldShowAssignBanner);
    const shouldShowRequirementsBanner = useAppSelector(
        (store) => store.currentExtractionState.shouldShowRequirementsBanner
    );

    const dispatch = useAppDispatch();

    const handleBannerClose = () => {
        extraction?.step === StepValue.Assign
            ? dispatch(setExtractState({ shouldShowAssignBanner: false }))
            : dispatch(setExtractState({ shouldShowRequirementsBanner: false }));
    };

    const shouldShowBanner =
        (shouldShowAssignBanner && extraction?.step === StepValue.Assign) ||
        (shouldShowRequirementsBanner && extraction?.step === StepValue.Requirements);

    if (!shouldShowBanner) {
        return null;
    }

    return (
        <div
            className="bg-gray-darkest/80 backdrop-blur-md p-4 rounded-lg pointer-events-auto  text-white text-[15px] flex flex-col"
            css={[extraction?.step === StepValue.Assign ? tw`!w-[400px]` : tw`!w-[320px]`]}
        >
            <div className="flex flex-col justify-between">
                {extraction?.step === StepValue.Assign ? (
                    <>
                        <div className="font-semibold mb-2">Assign Requirements</div>
                        <div className="text-[13px]">
                            Select a section in the right panel and then click requirements to assign them to the
                            selected section.
                        </div>
                    </>
                ) : (
                    <>
                        <div className="font-semibold mb-2">Select Requirements</div>
                        <div className="text-[13px]">
                            Click the text to select or unselect each text block as a requirement.
                        </div>
                    </>
                )}
            </div>
            <div className="flex w-full justify-end mt-3">
                <Button
                    className="!text-white flex items-center justify-end !p-0"
                    onClick={handleBannerClose}
                    variant="ghost"
                    size="md"
                >
                    <div className="flex justify-center items-center p-1 px-2 rounded-md border border-gray-light duration-150 hover:bg-gray-darkest mt-2">
                        Got it
                    </div>
                </Button>
            </div>
        </div>
    );
};

export default GenerationBanner;
