/** @jsxImportSource @emotion/react */

import { useCallback, useEffect, useState } from "react";
import listLoading from "Assets/lotties/list-loading-lottie.json";
import Lottie from "lottie-react";
import { ReactComponent as EmptyList } from "Assets/svgs/draggableListItems.svg";
import { ReactComponent as ErrorEmptyList } from "Assets/svgs/errorDraggableListItems.svg";
import TemplateRow, { RowType } from "components/molecules/template-row/TemplateRow";
import { DragDropContext, DropResult, Droppable } from "react-beautiful-dnd";
import Icon from "components/atoms/icons/Icon";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { ExtractionMode, INITIAL_TEMPLATE, updateTemplate } from "store/reducers/templates/templateExtractionReducer";
import { insert, move } from "utils/array";
import { v4 } from "uuid";
import { useAnimateLoadingMsg } from "hook/useAnimateLoadingMsg";
import loadingDots from "Assets/lotties/loading-dots.json";
import { Button } from "components/editor/components";

const LOADING_MSGS_COPY_PASTE = ["Generating template", "This can take up to one minute"];
const LOADING_MSGS_REQUIREMENTS = ["Generating template from requirements", "This can take up to one minute"];

type Props = {
    onRetry?: () => void;
};

const DraftTemplate = ({ onRetry }: Props) => {
    const dispatch = useAppDispatch();
    const { isExtractLoading, isExtractError, template, activeExtractionMode } = useAppSelector(
        (root) => root.templateExtraction
    );
    const { volumes } = template;
    const [editActive, setEditActive] = useState("");
    const loadingMsgs =
        activeExtractionMode === ExtractionMode.CopyPaste ? LOADING_MSGS_COPY_PASTE : LOADING_MSGS_REQUIREMENTS;
    const [loadingMsg, setLoadingMsg] = useState(loadingMsgs[0]);

    useEffect(() => {
        if (isExtractLoading) setLoadingMsg(loadingMsgs[0]);
    }, [isExtractLoading, loadingMsgs]);

    useAnimateLoadingMsg(isExtractLoading, 5000, loadingMsgs, (msg) => setLoadingMsg(msg));

    const dragEnd = useCallback(
        ({ source, destination, type, draggableId }: DropResult) => {
            if (!destination) return;
            if (source?.index === destination?.index && source?.droppableId === destination?.droppableId) return;

            const sourceIndex = source.index;
            const destinationIndex = destination.index;

            if (type === "DRAFT_TEMPLATE_VOLUMES") {
                const moved = move(volumes, sourceIndex, destinationIndex);
                dispatch(updateTemplate({ volumes: moved }));
            } else if (type === "DRAFT_TEMPLATE_SECTIONS") {
                const activeSectionId = draggableId;
                const volumeSourceId = source?.droppableId;
                const volumeDestinationId = destination?.droppableId;
                const destinationVolumeIndex = volumes.findIndex((vol) => vol.id === volumeDestinationId);
                const destinationVolume = volumes[destinationVolumeIndex];

                if (volumeSourceId === volumeDestinationId) {
                    if (destinationVolumeIndex === -1) return;
                    const moved = move(destinationVolume.sections, sourceIndex, destinationIndex);
                    const updatedVolume = { ...destinationVolume, sections: moved };
                    const newVolumes = [...volumes];
                    newVolumes[destinationVolumeIndex] = updatedVolume;
                    dispatch(updateTemplate({ volumes: newVolumes }));
                } else {
                    const sourceVolumeIndex = volumes.findIndex((vol) => vol.id === volumeSourceId);
                    const sourceVolume = volumes[sourceVolumeIndex];
                    const foundSection = sourceVolume.sections[sourceIndex];
                    if (!foundSection) return;
                    const newVolumes = volumes.map((prevVol) => {
                        if (volumeSourceId === prevVol.id) {
                            return {
                                ...prevVol,
                                sections: prevVol.sections.filter((prevSec) => prevSec.id !== activeSectionId),
                            };
                        }
                        if (volumeDestinationId === prevVol.id) {
                            return {
                                ...prevVol,
                                sections: insert(prevVol.sections, foundSection, destinationIndex),
                            };
                        }

                        return prevVol;
                    });

                    dispatch(updateTemplate({ volumes: newVolumes }));
                }
            }
        },
        [dispatch, volumes]
    );

    return (
        <div className="flex flex-col gap-2.5 flex-1 relative">
            <button
                disabled={isExtractLoading || !volumes.length}
                onClick={() => dispatch(updateTemplate(INITIAL_TEMPLATE))}
                className="absolute -top-[20px] right-2.5 text-xs text-red-500 duration-150 hover:text-red-400 disabled:!text-[#C6CBD0]"
            >
                Clear
            </button>
            <div className="min-h-0 relative flex-1 justify-between gap-2 flex flex-col p-2 pt-3 border-1.5 border-gray-lightest rounded-md shadow-soft overflow-y-auto">
                {isExtractError ? (
                    <div className="text-center flex flex-col gap-3 w-full h-full justify-center items-center text-xs text-[#96A4AF] p-3">
                        <ErrorEmptyList className="w-[180px]" />
                        <div>
                            <div className="font-medium text-sm mb-1.5">We failed to generate the template</div>
                            {activeExtractionMode === ExtractionMode.CopyPaste && (
                                <>
                                    We did not find any volumes, sections, or subsections in the provided text. <br />
                                    Please double check the content provided and try again.
                                </>
                            )}
                            {activeExtractionMode === ExtractionMode.Requirements && (
                                <>
                                    We did not find any relevant content from the provided requirements. <br />
                                    Please double check your requirements and{" "}
                                    <Button
                                        onClick={() => onRetry?.()}
                                        variant="link"
                                        size="sm"
                                        className="inline !min-h-0"
                                    >
                                        try again.
                                    </Button>
                                </>
                            )}
                        </div>
                    </div>
                ) : (
                    <>
                        {isExtractLoading && !volumes.length && (
                            <div className="text-center flex flex-col gap-3 w-full h-full justify-center items-center text-xs text-[#96A4AF] p-3">
                                <Lottie
                                    animationData={listLoading}
                                    style={{
                                        height: "80%",
                                        position: "absolute",
                                        top: 31,
                                        width: "100%",
                                    }}
                                />
                                <div className="absolute top-[61%]">
                                    {loadingMsg}
                                    <span className="loading-ellipsis" />
                                </div>
                            </div>
                        )}
                        {!isExtractLoading && !volumes.length && (
                            <div className="text-center flex flex-col gap-4 w-full h-full justify-center items-center text-sm text-[#96A4AF] p-3">
                                <EmptyList className="w-[180px]" />
                                No template generated.
                            </div>
                        )}
                        {!!volumes?.length && (
                            <DragDropContext onDragEnd={dragEnd}>
                                <Droppable droppableId="VOLUMES" type="DRAFT_TEMPLATE_VOLUMES">
                                    {(provided) => (
                                        <div
                                            {...provided.droppableProps}
                                            className="flex flex-col"
                                            ref={provided.innerRef}
                                        >
                                            {volumes.map((item, ind) => (
                                                <TemplateRow
                                                    className="pb-4"
                                                    isDraft
                                                    withinPortal
                                                    editable
                                                    collapsible
                                                    key={item.id}
                                                    draggableId={item.id}
                                                    index={ind}
                                                    isDragDisabled={isExtractLoading || !!editActive}
                                                    type={RowType.Volume}
                                                    isEditActive={editActive === item.id}
                                                    onEdit={(newTitle) => {
                                                        const updatedVolumes = [...volumes];
                                                        const updatedVolume = { ...item };
                                                        updatedVolume.name = newTitle;
                                                        updatedVolumes[ind] = updatedVolume;
                                                        dispatch(updateTemplate({ volumes: updatedVolumes }));
                                                    }}
                                                    onCloseEdit={(e) => setEditActive("")}
                                                    onOpenEdit={() => setEditActive(item.id)}
                                                    tooltip={{ props: { disabled: isExtractLoading || !!editActive } }}
                                                    title={item.name}
                                                    onDelete={() =>
                                                        dispatch(
                                                            updateTemplate({
                                                                volumes: volumes.filter((vol) => vol.id !== item.id),
                                                            })
                                                        )
                                                    }
                                                >
                                                    <Droppable droppableId={item?.id} type="DRAFT_TEMPLATE_SECTIONS">
                                                        {(provided1) => (
                                                            <div
                                                                className="flex flex-col mt-2"
                                                                {...provided1.droppableProps}
                                                                ref={provided1.innerRef}
                                                            >
                                                                {item?.sections?.map((subItem, index) => (
                                                                    <TemplateRow
                                                                        className="pl-8"
                                                                        isDraft
                                                                        withinPortal
                                                                        editable
                                                                        draggableId={subItem.id}
                                                                        index={index}
                                                                        key={subItem.id}
                                                                        type={RowType.Section}
                                                                        isDragDisabled={
                                                                            isExtractLoading || !!editActive
                                                                        }
                                                                        tooltip={{
                                                                            props: {
                                                                                disabled:
                                                                                    isExtractLoading || !!editActive,
                                                                            },
                                                                        }}
                                                                        isEditActive={subItem.id === editActive}
                                                                        onEdit={(newTitle) => {
                                                                            const updatedVolumes = [...volumes];
                                                                            const updatedSections = [...item.sections];
                                                                            const updatedVolume = { ...item };
                                                                            const updatedSection = { ...subItem };
                                                                            updatedSection.name = newTitle;
                                                                            updatedSections[index] = updatedSection;
                                                                            updatedVolume.sections = updatedSections;
                                                                            updatedVolumes[ind] = updatedVolume;
                                                                            dispatch(
                                                                                updateTemplate({
                                                                                    volumes: updatedVolumes,
                                                                                })
                                                                            );
                                                                        }}
                                                                        onOpenEdit={() => setEditActive(subItem.id)}
                                                                        onCloseEdit={(e) => setEditActive("")}
                                                                        title={subItem.name}
                                                                        onDelete={() =>
                                                                            dispatch(
                                                                                updateTemplate({
                                                                                    volumes: volumes.map((vol) => {
                                                                                        if (vol.id === item.id) {
                                                                                            return {
                                                                                                ...vol,
                                                                                                sections:
                                                                                                    vol.sections.filter(
                                                                                                        (sec) =>
                                                                                                            sec.id !==
                                                                                                            subItem.id
                                                                                                    ),
                                                                                            };
                                                                                        }
                                                                                        return vol;
                                                                                    }),
                                                                                })
                                                                            )
                                                                        }
                                                                    />
                                                                ))}
                                                                {provided1.placeholder}
                                                            </div>
                                                        )}
                                                    </Droppable>
                                                    <div className="w-full pr-10 pl-8 cursor-default">
                                                        <div
                                                            className="w-full flex items-center select-none gap-2.5 cursor-default"
                                                            role="button"
                                                            onClick={() => {
                                                                const id = v4();
                                                                setEditActive(id);
                                                                dispatch(
                                                                    updateTemplate({
                                                                        volumes: volumes.map((vol) => {
                                                                            if (vol.id === item.id) {
                                                                                return {
                                                                                    ...vol,
                                                                                    sections: [
                                                                                        ...vol.sections,
                                                                                        {
                                                                                            id,
                                                                                            name: "",
                                                                                            subsections: [],
                                                                                        },
                                                                                    ],
                                                                                };
                                                                            }
                                                                            return vol;
                                                                        }),
                                                                    })
                                                                );
                                                            }}
                                                        >
                                                            <div className="min-w-[26px] w-[26px] h-[26px] flex justify-center items-center cursor-pointer select-none duration-150 bg-transparent rounded-full hover:bg-slate-100">
                                                                <Icon name="Plus" className="text-[#9FA2AA]" />
                                                            </div>
                                                            <div className="cursor-pointer h-[54px] flex-1 p-4 flex items-center rounded-md border-2 bg-white border-slate-100 text-slate-400 text-base font-normal duration-200 bg-transparent hover:bg-slate-50">
                                                                Add Section
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TemplateRow>
                                            ))}

                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                                {isExtractLoading && !!volumes.length && (
                                    <div className="text-center flex flex-col gap-3 w-full pb-4 justify-center items-center text-xs text-[#96A4AF]">
                                        <Lottie
                                            animationData={loadingDots}
                                            style={{
                                                height: "80px",
                                                position: "absolute",
                                            }}
                                        />
                                    </div>
                                )}
                                <div className="w-full pr-10 cursor-default">
                                    <div
                                        className="w-full flex items-center select-none gap-2.5 cursor-default"
                                        role="button"
                                        onClick={() => {
                                            const id = v4();
                                            setEditActive(id);
                                            dispatch(
                                                updateTemplate({
                                                    volumes: [
                                                        ...volumes,
                                                        {
                                                            id,
                                                            name: "",
                                                            sections: [],
                                                        },
                                                    ],
                                                })
                                            );
                                        }}
                                    >
                                        <div className="min-w-[26px] w-[26px] h-[26px] flex justify-center items-center cursor-pointer select-none duration-150 bg-transparent rounded-full hover:bg-slate-100">
                                            <Icon name="Plus" className="text-[#9FA2AA]" />
                                        </div>
                                        <div className="text-stone-900 p-2 pl-4 flex flex-1 items-center text-lg font-medium cursor-pointer h-[54px] bg-slate-50 rounded-md border border-sky-100 duration-200 hover:bg-slate-100">
                                            Add Volume
                                        </div>
                                    </div>
                                </div>
                            </DragDropContext>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

export default DraftTemplate;
