/** @jsxImportSource @emotion/react */

import { useTriggerExtraction, useUpdateExtractionStatuses } from "./hooks";
import { Button } from "components/editor/components";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { Outlet, useParams } from "react-router-dom";
import UploadDocuments from "./upload-documents";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setCurrentExtraction } from "store/reducers/extract/CurrentExtractionReducer";
import { ExtractionCarousel } from "./extraction-carousel/ExtractionCarousel";
import ProjectSelection from "./project-selection/ProjectSelection";
import { useStorage } from "YJSProvider/createYJSContext";
import { LiveObject, ToImmutable } from "YJSProvider/LiveObjects";
import { isEqual } from "lodash";

const ExtractContainer = () => {
  const extractions = useStorage<NonNullable<ToImmutable<LiveObject<Storage>>["extractions"]>>(
    (root) => (root as ToImmutable<LiveObject<Storage>>).extractions || []
  );
  useUpdateExtractionStatuses(extractions);
  const { selectedDocuments, selectedSolicitationType } = useAppSelector((state) => state.extractReducerV2);

  const { triggerExtraction, isLoading } = useTriggerExtraction();

  return (
    <div className="pt-3">
      <ExtractionOutlet />
      <div className="flex flex-col gap-6 px-5 pb-4 pt-1">
        <div className="flex flex-col gap-4 border border-gray-lightest shadow rounded-lg p-4 bg-white">
          <h2 className="text-lg font-medium">Active Generations</h2>
          {!!extractions.length && <ExtractionCarousel extractions={extractions} />}
          {!extractions.length && <div className="text-gray-500 text-sm">No generations have been started</div>}
        </div>
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-8 border border-gray-lightest shadow rounded-lg p-4 bg-white">
            <div className="flex flex-col gap-4">
              <h2 className="text-lg font-medium">Create a New Generation</h2>
              <ProjectSelection selectedSolicitationType={selectedSolicitationType} />
            </div>
            {selectedSolicitationType && (
              <div className="flex flex-col gap-4">
                <div className="flex flex-col">
                  <div className="font-normal text-xs text-gray-500">Step 2</div>
                  <div className="font-medium text-base">Select Documents</div>
                  <div className="text-sm text-gray-500">
                    Upload and select all relevant documents required for the generation.
                  </div>
                </div>
                <UploadDocuments />
                <div className="self-end">
                  <Button
                    disabled={isLoading || !selectedDocuments.length}
                    loading={isLoading}
                    onClick={() => triggerExtraction(selectedSolicitationType)}
                    variant="primary"
                    size="sm/md"
                  >
                    Start Generation
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtractContainer;

const ExtractionOutlet = () => {
  const { extractionId } = useParams();
  const extraction = useStorage(
    (root) => (root as ToImmutable<Storage>).extractions?.find(({ id }) => id === extractionId),
    isEqual
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setCurrentExtraction(extraction));
  }, [dispatch, extraction]);

  return <Outlet />;
};
