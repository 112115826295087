import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { Empty } from "components/molecules/empty";
import TypingEffect from "components/TypingEffect";
import tw from "twin.macro";
import { useLayoutEffect, useRef } from "react";
import { ArrowDown } from "lucide-react";
import { renderBlock } from "./utils";
import { setAiAssistantState } from "store/reducers/ai-assistant/aiAssistantReducer";

const AIAssistantConversation = () => {
  const { streamState, autoscroll, activeSession } = useAppSelector((store) => store.aiAssistantState);
  const { isStreamingInProgress, streamCopy, blockId } = streamState;
  const scrollRef = useRef<HTMLDivElement>(null);
  const myConversation = activeSession?.conversation;
  const dispatch = useAppDispatch();
  const isEmpty = !myConversation?.length;

  useLayoutEffect(() => {
    if (autoscroll) {
      setTimeout(() => {
        scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
        dispatch(setAiAssistantState({ autoscroll: true }));
      }, 0);
    }
  }, [autoscroll, dispatch, isStreamingInProgress, streamCopy, blockId]);

  useLayoutEffect(() => {
    setTimeout(() => {
      scrollRef.current?.scrollTo(0, scrollRef.current?.scrollHeight + 21);
      dispatch(setAiAssistantState({ autoscroll: true }));
    }, 0);
  }, [activeSession?.id, dispatch]);

  return (
    <div
      className="flex w-full items-center justify-center h-full overflow-y-auto"
      onScroll={(e) => {
        const shouldAutoScroll =
          e.currentTarget.scrollTop + 5 >= e.currentTarget.scrollHeight - e.currentTarget.clientHeight;

        if (shouldAutoScroll !== autoscroll) dispatch(setAiAssistantState({ autoscroll: shouldAutoScroll }));
      }}
      ref={scrollRef}
    >
      {!autoscroll && (
        <button
          onClick={() => {
            if (blockId && myConversation?.[myConversation?.length - 1]?.id !== blockId) {
              document
                .getElementById(`assistant-block-${blockId}`)
                ?.scrollIntoView({ block: "start", behavior: "smooth" });
            } else {
              scrollRef.current?.scrollTo({
                top: scrollRef.current?.scrollHeight + 5,
                behavior: "smooth",
              });
            }
          }}
          className="absolute flex justify-center items-center w-8 h-8 z-[1] rounded-full right-1/2 bottom-6 shadow-sharp-thin text-black text-[20px] bg-layout-gray-light-active backdrop-blur-[2px]"
        >
          {isStreamingInProgress ? (
            <TypingEffect
              css={[
                {
                  paddingLeft: 3,
                  paddingRight: 0,
                  paddingBottom: 6,
                  "& > div": tw`bg-black h-1 w-1 mr-[3px]`,
                },
              ]}
            />
          ) : (
            <ArrowDown size={14} />
          )}
        </button>
      )}
      {isEmpty && !isStreamingInProgress ? (
        <Empty heading="How can I assist you today?" title="Send a message to get started" />
      ) : (
        <div className="relative w-full h-full mx-4 lg:px-2 lg:mx-auto lg:max-w-2xl xl:max-w-3xl 2xl:max-w-[900px] 3xl:max-w-[1100px]">
          <div className="flex flex-col w-full items-start flex-1 gap-8 px-4 pt-5 pb-12">
            {myConversation?.map(renderBlock)}
          </div>
        </div>
      )}
    </div>
  );
};

export default AIAssistantConversation;
