/** @jsxImportSource @emotion/react */

import { Avatar } from "components/molecules/avatar";
import { Checkbox } from "components/atoms/checkbox";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { Eye } from "lucide-react";
import { ListFilterMenu } from "components/molecules/list-filter-menu";
import { NoteIcon } from "utils/icons";
import { useAppSelector } from "store/storeTypes";
import { User } from "types/User";
import { useTrackUserMetric } from "utils/metrics";
import { ActiveFilter, FilterOption } from "./utils";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { ComplianceMatrixRow, RequirementCompliance, RequirementStatus, Storage } from "../CopilotSchemaImmutableTypes";
import { useMemo, SetStateAction, Dispatch } from "react";
import { RiFilterLine, RiFilterFill } from "@remixicon/react";
import Icon from "components/atoms/icons/Icon";
import Tooltip from "components/atoms/tooltip/Tooltip";
import tw from "twin.macro";
import usePersistedStorage from "hook/persisted-storage/usePersistedStorage";
import { useStorage } from "YJSProvider/createYJSContext";
import { useFlags } from "launchdarkly-react-client-sdk";
import { FeatureFlagSet } from "types/FeatureFlagSet";

export interface UpdatedRequirement extends ComplianceMatrixRow {
  requirementText: string;
  proposalVolume: number;
  proposalSection: number;
}

type Props = {
  volumeAndSectionNames: { volumes: string[]; sections: string[] };
  setSearchFilters: Dispatch<SetStateAction<ActiveFilter>>;
  searchFilters: ActiveFilter;
  documents: { id: string; name: string }[];
  requirements: UpdatedRequirement[];
  handleToggleCheckAll: (checked: boolean) => void;
  selectedColumns: string[];
  isAllSelected: boolean;
  projectId?: string;
};

const TableHeader = ({
  volumeAndSectionNames,
  setSearchFilters,
  searchFilters,
  selectedColumns,
  requirements,
  handleToggleCheckAll,
  documents,
  isAllSelected,
  projectId,
}: Props) => {
  const { workspaceMembers } = useAppSelector((store) => store.auth);
  const [requirementsOnly, setRequirementsOnly] = usePersistedStorage<boolean>(
    `requirementsOnlyFilter-${projectId}`,
    true
  );
  const complianceMatrixRowCount = useStorage(
    (root) => (root.compliance_matrix as Storage["compliance_matrix"]).length
  );
  const flags = useFlags<FeatureFlagSet>();
  const trackUserEvent = useTrackUserMetric();

  const skippedFilterOptions = useMemo(
    () => [
      {
        key: 1,
        label: "Included",
        value: true,
        selected: requirementsOnly,
        onSelect: () => {
          setRequirementsOnly(true);

          trackUserEvent("Requirements: View Changed", {
            view: "Active",
          });
        },
      },
      {
        key: 2,
        label: "All",
        value: false,
        selected: !requirementsOnly,
        onSelect: () => {
          setRequirementsOnly(false);

          trackUserEvent("Requirements: View Changed", {
            view: "All",
          });
        },
      },
    ],
    [requirementsOnly, setRequirementsOnly, trackUserEvent]
  );

  const filterDropdownOptions = useMemo(
    () => ({
      [FilterOption.Assignees]: workspaceMembers.map(({ id, username }: User) => ({
        key: id,
        label: (
          <div className="flex items-center gap-2">
            <Avatar size={24} id={username} name={username} className="!text-[13px]" /> {username}
          </div>
        ),
        value: id,
        selected: id === searchFilters.assignees,
        onSelect: () =>
          setSearchFilters((prev: ActiveFilter) => ({
            ...prev,
            assignees: id === searchFilters.assignees ? "" : id,
          })),
      })),
      [FilterOption.Status]: Object.values(RequirementStatus).map((status) => ({
        key: status,
        label: (
          <div className="flex items-center gap-2">
            {REQUIREMENT_STATUS_TO_META[status].icon}
            {REQUIREMENT_STATUS_TO_META[status].label}
          </div>
        ),
        value: status,
        selected: status === searchFilters.requirementStatusType,
        onSelect: () =>
          setSearchFilters((prev) => ({
            ...prev,
            requirementStatusType: status === searchFilters.requirementStatusType ? "" : status,
          })),
      })),
      [FilterOption.Compliance]: Object.values(RequirementCompliance).map((status) => ({
        key: status,
        label: (
          <div className="flex items-center gap-2">
            {COMPLIANCE_TO_META[status].icon}
            {COMPLIANCE_TO_META[status].label}
          </div>
        ),
        value: status,
        selected: status === searchFilters.complianceType,
        onSelect: () =>
          setSearchFilters((prev) => ({
            ...prev,
            complianceType: status === searchFilters.complianceType ? "" : status,
          })),
      })),
      [FilterOption.Document]: documents.map(({ id, name }) => {
        return {
          key: id,
          label: name,
          value: name,
          selected: name === searchFilters.documentName,
          onSelect: () =>
            setSearchFilters((prev) => ({
              ...prev,
              documentName: name === searchFilters.documentName ? "" : name,
            })),
        };
      }),
      [FilterOption.ProposalVolume]: volumeAndSectionNames.volumes.map((name) => ({
        key: name,
        label: name,
        value: name,
        selected: name === searchFilters.proposalVolume,
        onSelect: () =>
          setSearchFilters((prev: any) => ({
            ...prev,
            proposalVolume: name === searchFilters.proposalVolume ? "" : name,
            proposalSection: "",
          })),
      })),
      [FilterOption.ProposalSection]: volumeAndSectionNames.sections.map((name) => ({
        key: name,
        label: name,
        value: name,
        selected: name === searchFilters.proposalSection,
        onSelect: () =>
          setSearchFilters((prev: any) => ({
            ...prev,
            proposalSection: name === searchFilters.proposalSection ? "" : name,
          })),
      })),
    }),
    [
      workspaceMembers,
      documents,
      volumeAndSectionNames.volumes,
      volumeAndSectionNames.sections,
      searchFilters.assignees,
      searchFilters.requirementStatusType,
      searchFilters.complianceType,
      searchFilters.documentName,
      searchFilters.proposalVolume,
      searchFilters.proposalSection,
      setSearchFilters,
    ]
  );

  return (
    <div
      className="sticky top-[0px] z-[9] overflow-hidden flex bg-[#fafafa] h-[40px] border-b border-gray-200"
      css={[!complianceMatrixRowCount && tw`pointer-events-none`]}
    >
      <div className="font-medium text-xs min-w-[440px] max-w-[440px] w-[27%]">
        <div className="pr-4 pl-2 py-2 flex items-center gap-1">
          <div className="w-[54px] flex items-center justify-center">
            {!!requirements?.length && (
              <Checkbox size="lg" onCheck={(checked) => handleToggleCheckAll(checked)} checked={isAllSelected} />
            )}
          </div>
          <div className="flex items-center gap-2 select-none">
            <div className="flex items-center gap-1">
              <NoteIcon width="13" height="13" />
              Requirement ({requirements.length})
            </div>
            <DropdownMenu items={skippedFilterOptions}>
              <button className="bg-slate-200 border border-slate-400 shadow-sm flex gap-2 items-center text-slate-600 rounded text-xs py-0.5 pl-2 pr-1 duration-100 hover:bg-slate-200 hover:text-slate-800">
                {requirementsOnly ? "Included" : "All"}
                <Icon name="CarrotDown" />
              </button>
            </DropdownMenu>
          </div>
        </div>
      </div>
      <Tooltip disableHoverableContent content="Indicate if the row should be included as a requirement.">
        <div className="flex items-center justify-center select-none font-medium min-w-[100px] max-w-[100px] w-[5%]">
          <Eye size={12} />
        </div>
      </Tooltip>
      {selectedColumns.includes("Assignees") && (
        <ListFilterMenu items={filterDropdownOptions[FilterOption.Assignees]} selectedColor="#EDEDED">
          <Tooltip
            disableHoverableContent
            content={
              <div>
                Assign dedicated team members responsible for <span className="font-semibold">drafting a response</span>{" "}
                to the requirement.
              </div>
            }
          >
            <div className="font-medium text-xs min-w-[120px] max-w-[120px] w-[8%] flex justify-center items-center">
              <div
                className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
                css={[!!searchFilters.assignees && tw`text-action`]}
              >
                <div className="flex items-center gap-1">Assignees</div>
                {!!searchFilters.assignees ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
              </div>
            </div>
          </Tooltip>
        </ListFilterMenu>
      )}
      {selectedColumns.includes("Status") && (
        <ListFilterMenu items={filterDropdownOptions[FilterOption.Status]} selectedColor="#EDEDED">
          <Tooltip
            disableHoverableContent
            content={
              <div>
                Monitor the <span className="font-semibold">status of the draft response</span> to the requirement.
              </div>
            }
          >
            <div className="font-medium text-xs min-w-[90px] max-w-[90px] flex justify-center items-center w-[9%]">
              <div
                className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
                css={[!!searchFilters.requirementStatusType && tw`text-action`]}
              >
                <div className="flex items-center gap-1">Status</div>
                {!!searchFilters.requirementStatusType ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
              </div>
            </div>
          </Tooltip>
        </ListFilterMenu>
      )}
      {selectedColumns.includes("Compliance") && (
        <ListFilterMenu items={filterDropdownOptions[FilterOption.Compliance]} selectedColor="#EDEDED">
          <Tooltip
            disableHoverableContent
            content={
              <div>
                Monitor the <span className="font-semibold">organization's compliance status</span> with the
                requirement. This is an <span className="font-semibold">optional field</span> used for internal tracking
                purposes only.
              </div>
            }
          >
            <div className="font-medium text-xs min-w-[110px] max-w-[110px] flex justify-center items-center w-[9%]">
              <div
                className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
                css={[!!searchFilters.complianceType && tw`text-action`]}
              >
                <div className="flex items-center gap-1">Compliance</div>
                {!!searchFilters.complianceType ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
              </div>
            </div>
          </Tooltip>
        </ListFilterMenu>
      )}
      {selectedColumns.includes("Proposal Volume") && (
        <ListFilterMenu items={filterDropdownOptions[FilterOption.ProposalVolume]} selectedColor="#EDEDED">
          <Tooltip
            disableHoverableContent
            content={
              <div>
                The proposal volume where the requirement is{" "}
                <span className="font-semibold">addressed in the proposal</span>. Use the{" "}
                <span className="font-semibold">drag-and-drop tool</span> in the
                <span className="font-semibold"> draft tab</span> to handle assigning requirements.
              </div>
            }
          >
            <div className="font-medium text-xs min-w-[260px] max-w-[260px] w-[16%]">
              <div
                className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
                css={[!!searchFilters.proposalVolume && tw`text-action`]}
              >
                <div className="flex items-center gap-1">Proposal Volume</div>
                {!!searchFilters.proposalVolume ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
              </div>
            </div>
          </Tooltip>
        </ListFilterMenu>
      )}
      {selectedColumns.includes("Proposal Section") && (
        <ListFilterMenu items={filterDropdownOptions[FilterOption.ProposalSection]} selectedColor="#EDEDED">
          <Tooltip
            disableHoverableContent
            content={
              <div>
                The proposal section where the requirement is{" "}
                <span className="font-semibold">addressed in the proposal</span>. Use the{" "}
                <span className="font-semibold">drag-and-drop tool</span> in the
                <span className="font-semibold"> draft tab</span> to handle assigning requirements.
              </div>
            }
          >
            <div className="font-medium text-xs min-w-[260px] max-w-[260px] w-[16%]">
              <div
                className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
                css={[!!searchFilters.proposalSection && tw`text-action`]}
              >
                <div className="flex items-center gap-1">Proposal Section</div>
                {!!searchFilters.proposalSection ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
              </div>
            </div>
          </Tooltip>
        </ListFilterMenu>
      )}
      {flags.instantDraft && (
        <>
          {selectedColumns.includes("Response") && (
            <div className="flex items-center p-2 select-none font-medium text-xs min-w-[350px] max-w-[350px] w-[10%]">
              Response
            </div>
          )}
          {selectedColumns.includes("Sources") && (
            <div className="flex items-center justify-center p-2 select-none font-medium text-xs min-w-[150px] max-w-[150px] w-[4%]">
              Sources
            </div>
          )}
          {selectedColumns.includes("Relevancy") && (
            <div className="flex items-center justify-center p-2 select-none font-medium text-xs min-w-[100px] max-w-[100px] w-[3%]">
              Relevancy
            </div>
          )}
          {selectedColumns.includes("Score") && (
            <div className="flex items-center justify-center p-2 select-none font-medium text-xs min-w-[100px] max-w-[100px] w-[3%]">
              Score
            </div>
          )}
        </>
      )}
      {!flags.instantDraft && selectedColumns.includes("Source") && (
        <ListFilterMenu items={filterDropdownOptions[FilterOption.Document]} selectedColor="#EDEDED">
          <Tooltip
            disableHoverableContent
            content={
              <div>
                Presents the requirement's <span className="font-semibold">source document</span> and
                <span className="font-semibold"> associated metadata</span>.
              </div>
            }
          >
            <div className="font-medium text-xs min-w-[110px] max-w-[110px] w-[6%] flex justify-center items-center">
              <div
                className="flex items-center gap-1.5 select-none px-3 py-2 border-gray-200"
                css={[!!searchFilters.documentName && tw`text-action`]}
              >
                <div className="flex items-center gap-1">Source</div>
                {!!searchFilters.documentName ? <RiFilterFill size={12} /> : <RiFilterLine size={12} />}
              </div>
            </div>
          </Tooltip>
        </ListFilterMenu>
      )}
    </div>
  );
};

export default TableHeader;
