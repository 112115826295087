import BasicSidebarItem from "components/atoms/basic-sidebar-item";
import { Skeleton } from "components/molecules/skeleton";
import useGetSchemas from "hook/capture/useGetSchemas";
import { useFlags } from "launchdarkly-react-client-sdk";
import { memo, useEffect, useMemo } from "react";
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FeatureFlagSet } from "types/FeatureFlagSet";
import { CopilotPresencePage } from "types/Presence";
import CaptureSchemaItem from "./CaptureSchemaItem";
import useGetForms from "hook/capture/useGetForms";
import { CAPTURE_SCHEMA_TYPE_PREFIX, CAPTURE_STATIC_PAGES_META } from "const-values/Capture";
import { CAPTURE_BASE_PATH } from "./constants";

import { useCreateInitialForms } from "./hooks";

const Capture = () => {
  const [searchParams] = useSearchParams();
  const { data: schemas, isLoading } = useGetSchemas();
  useGetForms();

  useCreateInitialForms();
  const flags = useFlags<FeatureFlagSet>();
  const navigate = useNavigate();
  const { schemaType } = useParams();

  const { pathname } = useLocation();
  const staticPages = useMemo(() => Object.values(CAPTURE_STATIC_PAGES_META), []);
  const isStaticRoute = useMemo(
    () => staticPages.some(({ slug }) => pathname === `${CAPTURE_BASE_PATH}/${slug}`),
    [pathname, staticPages]
  );

  useEffect(() => {
    if (!flags.capture) {
      navigate({
        pathname: "/dashboard/contracts/details",
        search: new URLSearchParams({
          ...Object.fromEntries(searchParams.entries()),
          tab: CopilotPresencePage.Project,
        }).toString(),
      });
    }
  }, [flags.capture, navigate, searchParams]);

  useEffect(() => {
    if (!schemaType && !isStaticRoute && schemas?.length) {
      navigate({
        pathname: schemas[0].type.replace(`${CAPTURE_SCHEMA_TYPE_PREFIX}:`, ""),
        search: searchParams.toString(),
      });
    }
  }, [flags.capture, isStaticRoute, navigate, schemaType, schemas, searchParams, staticPages]);

  return (
    <div className="flex h-full">
      <div className="p-5 flex-col flex gap-y-1 border-r border-gray-300 text-[#757575]">
        {isLoading && new Array(4).fill(0).map((_, i) => <Skeleton key={i} borderRadius={4} height={44} width={160} />)}
        {!isLoading &&
          !!schemas?.length &&
          schemas.map((schema) => <CaptureSchemaItem key={schema.id} schema={schema} />)}
        {staticPages.map(({ slug, label }) => (
          <BasicSidebarItem key={slug} to={{ pathname: slug, search: searchParams.toString() }}>
            {label}
          </BasicSidebarItem>
        ))}
      </div>
      <div className="flex-1 p-6 overflow-y-auto">
        <Outlet />
      </div>
    </div>
  );
};

export default memo(Capture);
