import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type State = {
    volumeEditable: string;
};

const initialState: State = {
    volumeEditable: "",
};

const volumeReducer = createSlice({
    name: "volume",
    initialState,
    reducers: {
        setVolumeState: (state: State, action: PayloadAction<Partial<State>>) => {
            return { ...state, ...action.payload };
        },
    },
});

export const { setVolumeState } = volumeReducer.actions;

export default volumeReducer.reducer;
