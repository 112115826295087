import { Outlet } from "react-router-dom";

interface DocumentsProps {
  internalContractDetails: any;
  internalContractId: string;
}

const Documents = ({ internalContractDetails, internalContractId }: DocumentsProps) => {
  return (
    <Outlet
      context={{
        internalContractDetails,
        internalContractId,
      }}
    />
  );
};

export default Documents;
