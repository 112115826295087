import { queryClient } from "api/queryClient";
import { CAPTURE_CREATE_INITIAL_FORMS_KEY } from "const-values/Capture";
import useCreateForm from "hook/capture/useCreateForm";
import { CAPTURE_FORMS_QUERY_KEY } from "hook/capture/useGetForms";
import { SCHEMAS_QUERY_KEY } from "hook/capture/useGetSchemas";
import groupBy from "lodash/groupBy";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CaptureSchema, Form } from "types/Capture";

export const useCreateInitialForms = () => {
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id");
  const schemas = queryClient.getQueryData<CaptureSchema[]>([SCHEMAS_QUERY_KEY]);
  const forms = queryClient.getQueryData<Form[]>([CAPTURE_FORMS_QUERY_KEY, { project_id: projectId }]);
  const [isCreatingForms, setIsCreatingForms] = useState(false);
  const { mutateAsync: createForm } = useCreateForm({ mutationKey: [CAPTURE_CREATE_INITIAL_FORMS_KEY] });
  const groupedFormsBySchema = useMemo(() => groupBy(forms, "schema.type"), [forms]);
  const schemasToInitialize = useMemo(
    () => (schemas && forms ? schemas.filter(({ type }) => !groupedFormsBySchema[type]?.length) : []),
    [forms, groupedFormsBySchema, schemas]
  );

  const initializeForms = useCallback(async () => {
    if (isCreatingForms) return;
    setIsCreatingForms(true);

    try {
      await Promise.all(schemasToInitialize.map(({ type }) => createForm({ schema_type: type })));
    } catch {
    } finally {
      setIsCreatingForms(false);
    }
  }, [isCreatingForms, createForm, schemasToInitialize]);

  useEffect(() => {
    if (schemasToInitialize.length) initializeForms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schemasToInitialize]);
};
