import driveWhite from "Assets/drive-white.svg";
import folderOpenSm from "Assets/folder-open-white.svg";
import lod from "Assets/loader.svg";
import searchIcon from "Assets/search-normal.png";
import {
  createDirectory,
  createFile,
  deleteDirectory,
  deleteFile,
  moveDirectory,
  moveFile,
  updateDirectory,
  updateFile,
} from "api/api";
import { Plus } from "lucide-react";
import CustomModal from "components/CustomModal";
import Document from "components/Drive/Document";
import Folder from "components/Drive/Folder";
import UploadDriveDocModal from "components/Drive/UploadDriveDocModal";
import ExtractingDocumentToast from "components/LoadingToast";
import { useNotification } from "context/notificationContext";
import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { fetchFileStorage, updateAllDocs, updateAllFolders } from "store/reducers/driveReducerSlice";
import { formatBytes, validateField } from "utils/helpers";
import { CloseIconBlack } from "utils/icons";
import MoveModal from "../MoveModal";
import { useDirectoryFileSearch } from "./hooks";
import NotFoundItems from "components/NotFoundItems";
import { DropdownMenu } from "components/molecules/dropdown-menu";
import { xor } from "lodash";
import Icon from "components/atoms/icons/Icon";
import { useContentLibraryFilterItems } from "components/molecules/content-drive-popover-content/hooks";
import { LEGACY_DOCUMENT_TYPES } from "pages/drive/documents/constants";

const DocumentsContent = () => {
  const dispatch = useDispatch();
  const {
    rootFiles: allDocs,
    rootSubdirectories: allFolders,
    isInitialFetch,
  } = useSelector((state) => state.drive.fileStorage);
  const { setToast } = useNotification();
  const {
    isSearching,
    searchResults,
    isSearchActive,
    setDocumentTypes,
    setSearchQuery,
    searchQuery,
    documentTypes,
    setUploaded,
    uploaded,
    folderSearchResults,
    throttleSearch,
  } = useDirectoryFileSearch();
  const [folderName, setFolderName] = useState("");
  const [selectedFolderId, setSelectedFolderId] = useState(false);
  const [isFolderAddModalOpen, setIsFolderAddModalOpen] = useState(false);
  const [showUploadModal, setShowUploadModal] = useState(false);
  const [currentFolders, setCurrentFolders] = useState([]);
  const [currentDocs, setCurrentDocs] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingToast, setLoadingToast] = useState({ loading: false, files: 1 });
  const [draggingFolderId, setDraggingFolderId] = useState("");
  const [draggingDocId, setDraggingDocId] = useState("");
  const [selectedFolderHeading, setSelectedFolderHeading] = useState("");
  const [moveModalOpen, setIsMoveModalOpen] = useState({
    item: null,
    open: false,
    type: undefined,
  });
  const [stateHistory, setStateHistory] = useState({
    type: null,
    data: [],
    itemInfo: {},
  });
  const [ctrlKeyPressed, setCtrlKeyPressed] = useState(false);
  const { typeItems, publishDateItemOptions } = useContentLibraryFilterItems({
    publishDatesConfig: { uploaded, onSelect: (val) => setUploaded(val === uploaded ? "" : val) },
    typesConfig: {
      documentTypes,
      onCheck: (val) => {
        if (Array.isArray(val)) {
          setDocumentTypes((prev) => xor(prev, val));
        } else {
          setDocumentTypes((prev) => xor(prev, [val]));
        }
      },
    },
  });
  useEffect(() => {
    // Check if allDocs and allFolders are empty before dispatching the action
    if (!allDocs.length && !allFolders.length) {
      dispatch(fetchFileStorage({ isInitialFetch: true }));
    }
  }, [dispatch, allDocs.length, allFolders.length]);

  useEffect(() => {
    if (isSearchActive) {
      setCurrentDocs(searchResults);
      setCurrentFolders(folderSearchResults);
    } else {
      setCurrentDocs(allDocs);
      setCurrentFolders(allFolders);
    }
  }, [allDocs, allFolders, folderSearchResults, isSearchActive, searchResults]);

  /////////////////////////// APIs Integration //////////////////////////
  //////////////// function to create new folder ////////////////////////
  async function createNewDirectory() {
    if (validateField(folderName)) {
      setIsFolderAddModalOpen(false);

      try {
        const { data: newFolder } = await createDirectory({ name: folderName, parent_directory_id: null });
        dispatch(updateAllFolders([...allFolders, newFolder]));
        setFolderName("");
      } catch (err) {
        handleApiError(
          err,
          "We were unable to create a new folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    }
  }

  ///////////////////// update directory function ////////////////////
  async function updateSelectedDirectory(folderId) {
    if (validateField(folderName)) {
      setIsFolderAddModalOpen(false);

      try {
        await updateDirectory(folderId, { name: folderName });

        const updatedFolders = allFolders.map((folder) =>
          folder.id === folderId ? { ...folder, name: folderName } : folder
        );

        dispatch(updateAllFolders([...updatedFolders]));

        handleApiSuccess("Folder updated");
        setFolderName("");
      } catch (err) {
        handleApiError(
          err,
          "We were unable to update folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    }
  }

  ///////////////////// delete directory function ////////////////////
  async function deleteSelectedDirectory(folderId) {
    // setIsDataLoading(true);

    try {
      await deleteDirectory(folderId);

      const updatedFolders = allFolders.filter((folder) => folder.id !== folderId);

      // setIsDataLoading(false);
      dispatch(updateAllFolders(updatedFolders));
      refreshResults();
    } catch (err) {
      handleApiError(
        err,
        "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    }
  }

  ///////////////////// move directory function ////////////////////
  async function moveSelectedDirectory(dropTargetFolderId, selectedFolderId, preventHistory) {
    if ((draggingFolderId && dropTargetFolderId && draggingFolderId !== dropTargetFolderId) || selectedFolderId) {
      try {
        let folderId = selectedFolderId || draggingFolderId;
        await moveDirectory(folderId, { parent_directory_id: dropTargetFolderId });
        if (!preventHistory) {
          setStateHistory({
            type: "folder",
            data: allFolders,
            itemInfo: allFolders?.find((folder) => folder?.id === folderId),
          });
        }
        const updatedFolders = allFolders.filter((folder) => folder?.id !== folderId);
        dispatch(updateAllFolders(updatedFolders));
        refreshResults();
        handleApiSuccess("Folder moved");
      } catch (err) {
        handleApiError(
          err,
          "We were unable to delete the folder due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
        );
      }
    }
  }

  ///////////////////// create new file function ////////////////////
  async function createDocument(files, id = null) {
    if (!files.length) {
      setLoadingToast({
        loading: false,
        files: 1,
      });
      return;
    }

    setLoadingToast({
      loading: true,
      files: files?.length,
    });

    const headers = {
      "Content-Type": "multipart/form-data",
    };

    try {
      const uploadPromises = files.map(async (file) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append(id === null ? "parent_directory" : "parent_directory_id", id);
        const response = await createFile(formData, "upload", headers);
        return response.data;
      });

      const receivedData = await Promise.all(uploadPromises);

      if (id === null) {
        dispatch(updateAllDocs([...allDocs, ...receivedData]));
      }

      handleApiSuccess("Files uploaded");
      setShowUploadModal(false);
      setLoadingToast({
        loading: false,
        files: 1,
      });
      refreshResults();
    } catch (err) {
      handleApiError(
        err,
        "We were unable to create document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
      setLoadingToast({
        loading: false,
        files: 1,
      });
    }
  }

  ///////////////////// function to update a document ////////////////////
  async function updateDocument(documentId, data) {
    try {
      const { data: updatedDoc } = await updateFile(documentId, data);

      const updatedDocs = allDocs.map((doc) => (doc.id === documentId ? updatedDoc : doc));
      dispatch(updateAllDocs(updatedDocs));

      handleApiSuccess("File updated");
    } catch (err) {
      handleApiError(
        err,
        "We were unable to update document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    }
  }

  ///////////////////// function to delete document ////////////////////
  async function deleteDocument(documentId) {
    // setIsDataLoading(true);

    try {
      await deleteFile(documentId);

      const updatedDocs = allDocs.filter((doc) => doc.id !== documentId);
      dispatch(updateAllDocs(updatedDocs));
      // setIsDataLoading(false);
    } catch (err) {
      handleApiError(
        err,
        "We were unable to delete document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    }
  }

  const refreshResults = useCallback(() => {
    if (isSearchActive) {
      throttleSearch(true);
    } else {
      dispatch(fetchFileStorage());
    }
  }, [dispatch, isSearchActive, throttleSearch]);

  ///////////////////// function to move document ////////////////////
  async function moveDocument(dropTargetFolderId, selectedDocId, preventHistory) {
    try {
      let docId = selectedDocId || draggingDocId;
      await moveFile(docId, { parent_directory_id: dropTargetFolderId });
      if (!preventHistory) {
        setStateHistory({
          type: "doc",
          data: allDocs,
          itemInfo: allDocs?.find((doc) => doc?.id === docId),
        });
      }
      const updatedDocs = allDocs.filter((doc) => doc.id !== docId);
      dispatch(updateAllDocs(updatedDocs));

      refreshResults();
      handleApiSuccess("File moved");
    } catch (err) {
      handleApiError(
        err,
        "We were unable to move the document due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    }
  }

  // Generic api success handling function
  function handleApiSuccess(message) {
    setToast.success({
      msg: message,
    });

    setIsLoading(false);
  }

  // Generic api error handling function
  function handleApiError(error, errorMessage) {
    setToast.error({
      msg: errorMessage,
    });
    setIsLoading(false);
  }

  ///////////////////////////DRAG AND DROP FROM COMPUTER FUNCTIONALITY ////////////////////////////
  const [isDragOverDrive, setIsDragOverDrive] = useState(false);

  ////////////////// function to check validity of file
  function isValidFile(file) {
    const maxFileSize = 200000000; // 200 MB in bytes

    const acceptedFormats = {
      "text/plain": [".txt"],
      "application/pdf": [".pdf"],
      "application/doc": [".doc", ".docx", ".dot"],
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
      "application/pptx": [".pptx"],
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    };

    const allowedExtensions = Object.values(acceptedFormats).flat();

    if (file.size > maxFileSize) {
      handleInvalidFileMsg(
        `Document exceeds the maximum size limit. We do not support document sizes larger than ${formatBytes(
          maxFileSize
        )}. Please try again with a smaller document or contact support@vultron.ai for assistance.`
      );
      return false;
    }

    const fileExtension = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
    const fileType = file.type;

    if (!allowedExtensions.includes(fileExtension) || !acceptedFormats[fileType]?.includes(fileExtension)) {
      handleInvalidFileMsg("Document format is not supported.");
      return false;
    }

    return true;
  }

  ////////// function to handle file msg /////////////
  function handleInvalidFileMsg(errorMessage) {
    setToast.error({
      msg: errorMessage,
    });
  }

  ///// function to handle files dropped from computer into drive
  function onMainDrop(acceptedFiles) {
    if (showUploadModal) return;
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);
    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    createDocument(validFiles);
  }

  ///// function to handle files dropped from computer into any folder
  function onFolderDrop(acceptedFiles, targetFolderId) {
    setSelectedFolderHeading("");
    setIsDragOverDrive(false);

    const validFiles = acceptedFiles.filter((file) => isValidFile(file));
    createDocument(validFiles, targetFolderId);
  }

  const { getRootProps: getMainRootProps, getInputProps: getMainInputProps } = useDropzone({
    onDrop: onMainDrop,
    onDragOver: (e) => {
      e.preventDefault();
      if (showUploadModal) return;
      setIsDragOverDrive(true);
    },
    onDragLeave: (e) => {
      const relatedTarget = e.relatedTarget;
      if (!relatedTarget || (relatedTarget !== e.currentTarget && !e.currentTarget.contains(relatedTarget))) {
        setIsDragOverDrive(false);
      }
    },
    noClick: true,
  });

  // handle move modal closing event
  const handleCloseMoveModal = () => {
    setIsMoveModalOpen({ item: null, open: false, type: undefined });
  };

  // ////////// Move folder/document ////////////////////
  async function onMoveManually(selectedDestination) {
    let parent_id = selectedDestination === "drive" ? null : selectedDestination;
    try {
      if (moveModalOpen?.type === "folder") {
        await moveSelectedDirectory(parent_id, moveModalOpen?.item?.id);
      } else {
        await moveDocument(parent_id, moveModalOpen?.item?.id);
      }
      handleCloseMoveModal();
    } catch (err) {
      handleApiError(
        err,
        "We were unable to move due to a technical issue on our end. Please refresh and try again. If the issue persists, contact support@vultron.ai for assistance."
      );
    }
  }

  // ////////////// setting up ctrl + z //////////
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlKeyPressed(true);
      }
      if (event.key === "z" && ctrlKeyPressed) {
        undo();
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === "Control" || event.key === "Meta") {
        setCtrlKeyPressed(false);
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [ctrlKeyPressed]);

  // /////// perform undo method for docs & folders ///////////////////
  const undo = async () => {
    if (!stateHistory?.type) return;
    if (stateHistory?.type === "folder") {
      await moveSelectedDirectory(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
      dispatch(updateAllFolders(stateHistory?.data || []));
      setStateHistory({ type: null, data: [], itemInfo: {} });
    } else {
      await moveDocument(stateHistory?.itemInfo?.parent_directory, stateHistory?.itemInfo?.id, "prevent");
      dispatch(updateAllDocs(stateHistory?.data));
      refreshResults();
      setStateHistory({ type: null, data: [], itemInfo: {} });
    }
  };

  return (
    <div className="relative flex-grow" {...getMainRootProps()}>
      <input multiple {...getMainInputProps()} />
      {/* --------- DOTTED LINE APPEARS ON DRAG AND DROP ----------- */}
      <div
        className={`${
          (isDragOverDrive || selectedFolderHeading) && !showUploadModal && "border-[3px] border-dashed border-action"
        } absolute w-full h-full pointer-events-none z-50`}
      />

      <div className="relative">
        {/* ---------------------------------- SECTION SEARCH -------------------------------- */}
        <section className="pt-2 pb-6 px-1">
          <div className="flex gap-3">
            <div className="flex-1 relative">
              <img src={searchIcon} alt="search icon" loading="lazy" className="absolute top-2.5 left-1.5 w-5" />

              <input
                placeholder="Search..."
                className="bg-white focus:outline-none block focus:ring-0 focus:ring-gray-200 text-sm text-gray-500 w-full h-10 py-3 pl-9 pr-3 rounded-md border border-gray-light"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>

            <div className="flex items-center gap-3 flex-wrap">
              <DropdownMenu multiselect items={typeItems}>
                <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                  <div className="truncate">
                    {documentTypes.length
                      ? documentTypes.filter((document) => !LEGACY_DOCUMENT_TYPES.includes(document)).join(", ")
                      : "Type"}
                  </div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
              <DropdownMenu items={publishDateItemOptions}>
                <div className="rounded-md min-w-[140px] text-sm h-10 w-[140px] bg-white flex justify-between px-2 py-1 items-center gap-2 border border-gray-light text-slate-600">
                  <div className="truncate">{uploaded || "Uploaded"}</div>
                  <Icon name="CarrotDown" className="min-w-3" />
                </div>
              </DropdownMenu>
            </div>
          </div>
        </section>
        {/* ---------------------------- FOLDERS SECTION ------------------------------ */}
        <section className="folders-section min-h-[150px] px-1 pt-1">
          <div className="flex justify-between items-center mb-6">
            <h5 className="font-medium text-md">Folders</h5>
            <div
              className="flex items-center gap-1 cursor-pointer hover:bg-hover-default px-1.5 py-1 rounded-md bg-gray-100 hover:border-gray-300 border border-gray-200"
              onClick={() => {
                setIsFolderAddModalOpen(true);
                setSelectedFolderId("");
              }}
            >
              <span className="text-sm font-semibold">Folder</span>
              <button onClick={() => setFolderName("")}>
                <Plus size={14} />
              </button>
            </div>
          </div>

          {isInitialFetch && !currentFolders.length ? (
            <div className="w-full flex justify-center flex-col py-4 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : currentFolders.length ? (
            <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap gap-x-[20px] gap-y-5 mb-8">
              {currentFolders.map((folder) => (
                <Folder
                  key={folder.id}
                  {...folder}
                  setIsFolderAddModalOpen={setIsFolderAddModalOpen}
                  setSelectedFolderId={setSelectedFolderId}
                  draggingFolderId={draggingFolderId}
                  setDraggingFolderId={setDraggingFolderId}
                  draggingDocId={draggingDocId}
                  setDraggingDocId={setDraggingDocId}
                  moveSelectedDirectory={moveSelectedDirectory}
                  setFolderName={setFolderName}
                  isLoading={isLoading}
                  handleDelete={deleteSelectedDirectory}
                  moveDocument={moveDocument}
                  createDocument={createDocument}
                  setSelectedFolderHeading={setSelectedFolderHeading}
                  setIsDragOverDrive={setIsDragOverDrive}
                  selectedFolderHeading={selectedFolderHeading}
                  onFolderDrop={onFolderDrop}
                  handleMoveModalOpen={() => {
                    setIsMoveModalOpen({ item: folder, open: true, type: "folder" });
                  }}
                />
              ))}
            </div>
          ) : (
            !isSearchActive && (
              <div className="pb-10">
                <h5 className="text-base text-center font-semibold text-[#1D2630]">No Folders</h5>
                <p className="text-[#353535] text-center pt-[5px]">
                  Create a folder by clicking on the “Folder +” button.
                </p>
              </div>
            )
          )}

          <CustomModal isOpen={isFolderAddModalOpen} onClose={() => setIsFolderAddModalOpen(false)}>
            <div className="p-5 w-[444px] ">
              <div className="flex justify-between items-center mb-5">
                <h3 className="font-semibold text-xl">{selectedFolderId ? "Update Folder" : "New Folder"}</h3>
                <div className="w-[15.56px] h-[15.56px]">
                  <span className="cursor-pointer">
                    <CloseIconBlack
                      onClick={() => {
                        setIsFolderAddModalOpen(false);
                      }}
                    />
                  </span>
                </div>
              </div>

              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  selectedFolderId ? updateSelectedDirectory(selectedFolderId) : createNewDirectory();
                }}
              >
                <label className="mb-2 block text-sm text-[#5B6B79]">Name</label>
                <input
                  type="text"
                  className="w-full rounded-md py-2 px-3 h-10 border-[1px] border-[#CFD1D4] focus:ring-gray-darkest focus:border-gray-darkest outline-none mb-7"
                  value={folderName}
                  onChange={(e) => setFolderName(e.target.value)}
                  autoFocus
                  required
                />

                <div className="flex justify-end gap-4">
                  <button
                    type="button"
                    className="border-[1px] border-[#DBE0E5] rounded-lg py-[9px] px-4 text-sm font-medium text-[#1D2630]"
                    onClick={(e) => {
                      setIsFolderAddModalOpen(false);
                      setFolderName("");
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className="border-0 bg-gray-darkest rounded-lg py-[9px] px-4 text-sm font-medium text-[#ffffff] flex items-center gap-2"
                  >
                    {selectedFolderId ? "Update" : "Add"}
                  </button>
                </div>
              </form>
            </div>
          </CustomModal>
        </section>

        {/* -------------------------- SECTION DOCUMENT -------------------------------- */}
        <section className="document-section px-1 relative mb-9">
          <div className="flex items-center justify-between mb-6">
            <h5 className="font-medium text-md">Documents</h5>
            {!isSearchActive && (
              <div
                onClick={() => setShowUploadModal(true)}
                className="flex items-center gap-1 rounded-md cursor-pointer px-1.5 py-1  bg-gray-100 hover:border-gray-300 border border-gray-200"
              >
                <span className="text-sm font-semibold">Document</span>
                <span className="relative block">
                  <Plus size={14} />
                </span>
              </div>
            )}
          </div>

          {isInitialFetch || isSearching ? (
            <div className="w-full flex justify-center flex-col py-14 px-2">
              <img className="mx-auto max-w-full" src={lod} alt="Loading..." />
              <div className="flex-grow" />
            </div>
          ) : (
            <>
              {!!currentDocs.length && (
                <div className="cards grid grid-cols-4 2xl:flex 2xl:flex-wrap round-lg gap-x-[20px] gap-y-5">
                  {currentDocs.map((doc, i) => (
                    <Document
                      key={i + doc?.id}
                      docData={doc}
                      updateDocument={updateDocument}
                      deleteDocument={deleteDocument}
                      setDraggingFolderId={setDraggingFolderId}
                      setDraggingDocId={setDraggingDocId}
                      isLoading={isLoading}
                      handleMoveModalOpen={() => {
                        setIsMoveModalOpen({ item: doc, open: true, type: "doc" });
                      }}
                    />
                  ))}
                </div>
              )}

              {isSearchActive && !currentDocs.length && (
                <NotFoundItems
                  title="No documents found"
                  subTitle="No matching results. Try another search."
                  className={"w-full flex mt-24 justify-center items-center bg-inherit"}
                />
              )}
              {!isSearchActive && (
                <div className="mb-5">
                  <UploadDriveDocModal isDrive={!currentDocs.length} createDocument={createDocument} />
                </div>
              )}
            </>
          )}

          {/* ------------ UPLOAD files MODAL --------------- */}
          <UploadDriveDocModal
            showUploadModal={showUploadModal}
            setShowUploadModal={setShowUploadModal}
            createDocument={createDocument}
          />
        </section>
      </div>

      {/* ------------- Drag and Drop Toast -------------------- */}
      {(isDragOverDrive || selectedFolderHeading) && !showUploadModal && (
        <div className="absolute bottom-24 w-full pointer-events-none z-50">
          <div className="absolute left-1/2 transform -translate-x-1/2 bg-gray-darkest py-3.5 px-16 rounded-full text-center">
            <span className="text-lg opacity-90 text-[#ffffff] block mb-1.5">Drop files to upload them to</span>

            {selectedFolderHeading && (
              <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2">
                <img src={folderOpenSm} alt="icon" loading="lazy" className="w-[18px] h-[18px] bg-[#fffffff]" />{" "}
                {selectedFolderHeading}
              </span>
            )}

            {isDragOverDrive && (
              <span className="text-lg font-semibold text-[#ffffff] flex justify-center items-center gap-2 pb-2">
                <img src={driveWhite} alt="icon" loading="lazy" className="w-[18px] h-[18px] bg-[#fffffff]" /> Content
                Library
              </span>
            )}
          </div>
        </div>
      )}

      {/* ---------- Loading toast new ---------- */}
      {loadingToast?.loading && (
        <ExtractingDocumentToast
          loadingText={`Uploading ${loadingToast?.files > 1 ? "Documents" : "Document"}`}
          handleClose={() => setLoadingToast({ loading: false, files: 1 })}
        />
      )}

      {/* ------------ Move Modal --------------- */}
      <MoveModal
        selectedItem={moveModalOpen}
        handleCloseMoveModal={handleCloseMoveModal}
        onMove={onMoveManually}
        currentFolders={currentFolders}
        isMain={true}
      />
    </div>
  );
};

export default DocumentsContent;
