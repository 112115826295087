import { Storage } from "components/copilot/CopilotSchemaTypes";
import { transformImmutableVolumesToYJS } from "components/copilot/Framework/utils";
import { useState } from "react";
import {
    toggleTemplateExtractionFromRequirementsModal,
    toggleTemplateExtractionModal,
} from "store/reducers/modalsSlice";
import { resetTemplateExtractionState } from "store/reducers/templates/templateExtractionReducer";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { useMutation } from "YJSProvider/createYJSContext";

export const useSubmitTemplate = () => {
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { template } = useAppSelector((root) => root.templateExtraction);
    const dispatch = useAppDispatch();

    const submitTemplate = useMutation(
        ({ storage }) => {
            setIsSubmitting(true);
            const volumes = template.volumes.map(({ id, ...vol }) => ({
                ...vol,
                sections: vol.sections.map(({ id: secId, ...sec }) => sec),
            }));
            const framework = storage?.get("framework") as Storage["framework"] | undefined;
            const liveVolumes = framework?.get("volumes");
            const transformedLiveVolumes = transformImmutableVolumesToYJS(volumes);
            transformedLiveVolumes?.forEach((vol) => liveVolumes?.push(vol));

            setTimeout(() => {
                setIsSubmitting(false);
                dispatch(toggleTemplateExtractionFromRequirementsModal({ open: false }));
                dispatch(toggleTemplateExtractionModal({ open: false }));
                dispatch(resetTemplateExtractionState());
            }, 2000);
        },
        [dispatch, template.volumes]
    );

    return { isSubmitting, submitTemplate };
};
