/** @jsxImportSource @emotion/react */

import { Interpolation, Theme } from "@emotion/react";
import * as RadixSwitch from "@radix-ui/react-switch";
import { ComponentProps } from "react";
import tw from "twin.macro";

type Size = "sm" | "md" | "lg";

interface Props extends ComponentProps<typeof RadixSwitch.Root> {
    styles?: Interpolation<Theme>;
    size?: Size;
}

const Switch = ({ size = "md", styles, ...props }: Props) => {
    const sizeStyles = {
        sm: {
            root: tw`w-[28px] h-[16px]`,
            thumb: tw`w-[12px] h-[12px] data-[state=checked]:translate-x-[14px]`,
        },
        md: {
            root: tw`w-[36px] h-[20px]`,
            thumb: tw`w-[16px] h-[16px] data-[state=checked]:translate-x-[18px]`,
        },
        lg: {
            root: tw`w-[42px] h-[25px]`,
            thumb: tw`w-[21px] h-[21px] data-[state=checked]:translate-x-[19px]`,
        },
    };

    return (
        <RadixSwitch.Root
            className="bg-gray-500 rounded-full relative data-[state=checked]:bg-action outline-none"
            style={{ WebkitTapHighlightColor: "rgba(0, 0, 0, 0)" }}
            css={[sizeStyles[size].root, styles]}
            {...props}
        >
            <RadixSwitch.Thumb
                className="block bg-white rounded-full transition-transform duration-100 translate-x-0.5 will-change-transform"
                css={sizeStyles[size].thumb}
            />
        </RadixSwitch.Root>
    );
};

export default Switch;
