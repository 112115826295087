/** @jsxImportSource @emotion/react */

import { useMemo } from "react";
import { useAppSelector } from "store/storeTypes";
import { getFileIcon } from "utils/getFileIcon";
import { Skeleton } from "components/molecules/skeleton";
import { WorkspaceFile } from "types/FileStorage";
import AttachmentRow from "./AttachmentRow";

type Props = {
  query: string;
  handleSelectedFiles: (checked: boolean, files: Pick<WorkspaceFile, "id" | "name" | "file_extension_type">[]) => void;
};

const AttachmentsPopover = ({ query, handleSelectedFiles }: Props) => {
  const { selectedFiles } = useAppSelector((root) => root.writingAssistant);
  const { projectAttachments, isProjectAttachmentsLoading } = useAppSelector((root) => root.projectAttachmentsState);

  const filteredDocs = useMemo(() => {
    if (!query.trim()) return projectAttachments;
    return projectAttachments.filter(({ name }) =>
      name?.trim()?.toLowerCase()?.startsWith(query?.trim()?.toLowerCase())
    );
  }, [projectAttachments, query]);

  return (
    <div className="text-stone-800 text-sm w-full max-h-[300px] overflow-y-auto flex flex-col gap-1 p-1">
      {isProjectAttachmentsLoading &&
        new Array(4).fill(0).map((_, idx) => (
          <div key={idx} className="pl-2 pr-3 py-1.5 flex items-center justify-between">
            <Skeleton borderRadius={4} height={20} width={174} />
            <Skeleton borderRadius={4} height={16} width={16} />
          </div>
        ))}
      {!isProjectAttachmentsLoading &&
        filteredDocs.map(({ id, name, file_extension_type, download_url }) => (
          <AttachmentRow
            key={id}
            id={id}
            name={name}
            file_extension_type={file_extension_type}
            getFileIcon={getFileIcon}
            handleSelectedFiles={handleSelectedFiles}
            selectedFiles={selectedFiles}
            download_url={download_url}
          />
        ))}
      {!isProjectAttachmentsLoading && !filteredDocs.length && (
        <div className="min-h-[150px] text-xs text-gray-400 w-full flex justify-center items-center">
          No documents found
        </div>
      )}
    </div>
  );
};

export default AttachmentsPopover;
