/** @jsxImportSource @emotion/react */

import { ReactNode } from "react";
import { motion, LayoutGroup } from "framer-motion";
import styles from "./segmentedControl.module.css";
import tw from "twin.macro";
import genericMemo from "utils/genericMemo";

type Variant = "light" | "dark";
type Size = "sm" | "md" | "lg";

export type ControlItem<T> = {
    label: ReactNode;
    value: T;
};

type SegmentedControlProps<T> = {
    variant?: Variant;
    size?: Size;
    layoutId: string;
    items: ControlItem<T>[];
    activeItem: ControlItem<T>;
    onItemSelect: (value: ControlItem<T>) => void;
};

const sizeStyles = {
    sm: {
        button: tw`py-[3px] px-2.5 text-xs`,
    },
    md: {
        button: tw`py-1.5 px-3.5 text-base`,
    },
    lg: {
        button: tw`py-2 px-4 text-lg`,
    },
};

const variantStyles = {
    light: {
        container: tw`bg-slate-200`,
        copy: tw`text-slate-700`,
        activeCopy: tw`text-gray-darkest`,
        activeButton: tw`bg-white`,
    },
    dark: {
        container: tw`bg-zinc-600`,
        copy: tw`text-zinc-200`,
        activeCopy: tw`text-white`,
        activeButton: tw`bg-dark`,
    },
};

const SegmentedControl = <T,>({
    size = "md",
    layoutId,
    variant = "light",
    items,
    activeItem,
    onItemSelect,
    ...props
}: SegmentedControlProps<T>): JSX.Element => {
    return (
        <LayoutGroup>
            <ol tw="inline-flex max-w-fit p-[3px] rounded-md" css={[variantStyles[variant].container]} {...props}>
                {items.map((item) => {
                    const isActive = item.value === activeItem.value;

                    return (
                        <motion.li
                            className={styles.item}
                            whileTap={isActive ? { scale: 0.95 } : { opacity: 0.6 }}
                            key={`${item.value}`}
                        >
                            <button
                                onClick={() => onItemSelect(item)}
                                className={styles.button}
                                css={[
                                    tw`transition-colors duration-300`,
                                    sizeStyles[size].button,
                                    variantStyles[variant].copy,
                                    isActive && variantStyles[variant].activeCopy,
                                ]}
                            >
                                {isActive && (
                                    <motion.div
                                        transition={{ duration: 0.2 }}
                                        layoutId={layoutId}
                                        className="rounded-[5px] shadow-expanded z-[1] absolute top-0 right-0 bottom-0 left-0"
                                        css={[variantStyles[variant].activeButton]}
                                    />
                                )}
                                <span className="z-[2] relative">{item.label}</span>
                            </button>
                        </motion.li>
                    );
                })}
            </ol>
        </LayoutGroup>
    );
};

export default genericMemo(SegmentedControl);
