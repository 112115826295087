import { StepValue } from "./types";
import { ReactComponent as Requirements } from "Assets/extract/requirements.svg";
import { ReactComponent as Template } from "Assets/extract/template.svg";
import { ReactComponent as Assign } from "Assets/extract/assign.svg";
import { ReactComponent as Generate } from "Assets/extract/generate.svg";
import { Step } from "components/organisms/stepper";

export const GROUPED_STEPS: Record<StepValue, Step<StepValue>> = {
    [StepValue.Template]: {
        label: "Select templates",
        icon: Template,
        step: 1,
        value: StepValue.Template,
    },
    [StepValue.Requirements]: {
        label: "Select requirements",
        icon: Requirements,
        step: 2,
        value: StepValue.Requirements,
    },
    [StepValue.Assign]: {
        label: "Assign requirements",
        icon: Assign,
        step: 3,
        value: StepValue.Assign,
    },
    [StepValue.Import]: {
        label: "Review results",
        icon: Generate,
        step: 4,
        value: StepValue.Import,
    },
};

export const STEP_TO_META: Record<
    StepValue,
    { label: string; prevStep: StepValue | null; nextStep: StepValue | null }
> = {
    [StepValue.Template]: {
        label: "Imported Templates",
        prevStep: null,
        nextStep: StepValue.Requirements,
    },
    [StepValue.Requirements]: {
        label: "Selected Requirements",
        prevStep: StepValue.Template,
        nextStep: StepValue.Assign,
    },
    [StepValue.Assign]: {
        label: "Created Outline",
        prevStep: StepValue.Requirements,
        nextStep: StepValue.Import,
    },
    [StepValue.Import]: {
        label: "Import",
        prevStep: StepValue.Assign,
        nextStep: null,
    },
};
