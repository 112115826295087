/** @jsxImportSource @emotion/react */

import { useState, useEffect, useRef, useCallback, useMemo } from "react";
import { useSelector } from "react-redux";
import doc from "../../../Assets/document-text.svg";
import { Virtuoso } from "react-virtuoso";
import "./styles.css";
import { sendAssignedRequirementNotificationApi } from "api/api";
import { useSearchParams } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Icon from "components/atoms/icons/Icon";
import { EMPTY_VALUE } from "const-values/Copilot";
import tw from "twin.macro";
import { AvatarGroup } from "components/molecules/avatar-group";
import { move } from "utils/array";
import SkipButton from "./SkipButton";
import TableHeader from "./TableHeader";
import { compact, uniq, uniqBy } from "lodash";
import { Empty } from "components/molecules/empty";
import { Checkbox } from "components/atoms/checkbox";
import Tooltip from "components/atoms/tooltip/Tooltip";
import Popover from "components/atoms/popover/Popover";
import { blueScrollStyles } from "styles/accessibility";
import { Avatar } from "components/molecules/avatar";
import { Button } from "components/editor/components";
import { useGetAtlasRequirements, useInsertRequirementRow } from "./hooks";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import { setActiveRequirementId, updateCheckedState } from "store/reducers/copilot/requirementsReducer";
import { toggleRequirementDetails } from "store/reducers/copilot/copilotDrawerReducer";
import { useFrameworkOperations } from "hook/useFrameworkOperations";
import { toggleRequirementExtractionModal } from "store/reducers/modalsSlice";
import { COMPLIANCE_TO_META, REQUIREMENT_STATUS_TO_META } from "const-values/Draft";
import { RequirementCompliance, RequirementStatus } from "../CopilotSchemaImmutableTypes";
import RequirementStatusPopoverContent from "components/organisms/requirement-status-popover-content";
import useRequirementOperations from "hook/useRequirementOperations";
import CompliancePopoverContent from "components/organisms/compliance-popover-content/CompliancePopoverContent";
import SheetsTabs from "./sheets-tabs";
import { createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { EMPTY_SHEET } from "const-values/Sheets";
import { SectionsDropdown } from "components/molecules/sections-dropdown";
import { VolumesDropdown } from "components/molecules/volumes-dropdown";
import { useGenerateRequirementHeading } from "hook/draft/useGenerateRequirementHeading";
import { useTrackUserMetric } from "utils/metrics";
import { useMutation } from "YJSProvider/createYJSContext";
import { findIndex, moveItem } from "YJSProvider/LiveObjects";
import { useFlags } from "launchdarkly-react-client-sdk";
import { RELEVANCE_TO_META, SCORE_TO_META } from "./constants";
import { ReactComponent as EmptyRelevance } from "Assets/requirements/empty-relevance.svg";
import { ReactComponent as EmptyScore } from "Assets/requirements/empty-score.svg";
import SpinnerCircle from "utils/Spinner/SpinnerCircle";
import { getFileIcon } from "utils/getFileIcon";
import useCreateAtlasRequirement from "hook/Requirements/useCreateRequirement";

// ----------------
const CopilotTable = ({
  sortActive,
  setSearchFilters,
  searchActive,
  complianceMatrixState,
  requirements,
  setRequirements,
  searchFilters,
  frameworkState,
  selectedColumns,
}) => {
  const checkedState = useAppSelector((root) => root.requirements.checkedState);
  const activeSheet = useAppSelector((root) => root.requirements.activeSheet);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const internalContractId = searchParams.get("id")?.toLocaleLowerCase();
  const insertRequirementRow = useInsertRequirementRow();
  const { createRequirement: createAtlasRequirement, isCreatingRequirement } = useCreateAtlasRequirement();
  const boxRef = useRef();
  const trackUserEvent = useTrackUserMetric();
  const { groupedAtlasRequirements, isLoading: isLoadingAtlasRequirements } =
    useGetAtlasRequirements(internalContractId);

  const existingDocuments = useMemo(
    () =>
      uniqBy(
        complianceMatrixState.filter(({ document }) => !!document.name).map(({ document }) => document),
        "name"
      ),
    [complianceMatrixState]
  );

  const updateComplianceMatrixRow = useMutation(({ storage }, reqId, field, subfield, val) => {
    const complianceMatrix = storage.get("compliance_matrix");
    if (!complianceMatrix) return;
    const rowIndex = findIndex(complianceMatrix, (row) => row.get("requirement")?.get("id") === reqId);
    complianceMatrix.get(rowIndex)?.get(field)?.set(subfield, val);
  }, []);

  const setAssignMemberField = useMutation(({ storage }, reqId, assigned_user_id, clearAll, customList) => {
    const complianceMatrix = storage.get("compliance_matrix");
    if (!complianceMatrix) return;
    const rowIndex = findIndex(complianceMatrix, (row) => row.get("requirement")?.get("id") === reqId);
    const row = complianceMatrix.get(rowIndex);

    if (clearAll) {
      row.set("assigned_user_ids", []);
      return;
    }
    if (typeof customList === "object") {
      row.set("assigned_user_ids", customList || []);
      return;
    }

    const requirementId = row.get("requirement").get("id");
    const assignedMembers = row.get("assigned_user_ids") || [];
    let prev = assignedMembers?.find((b) => b === assigned_user_id);

    trackUserEvent("Requirements: Assignee Updated", {
      requirement_id: reqId,
      new_assignees_count: assignedMembers?.length ?? 0,
    });

    if (prev) {
      row.set(
        "assigned_user_ids",
        assignedMembers?.filter((m) => m !== assigned_user_id)
      );
    } else {
      row.set("assigned_user_ids", [...assignedMembers, assigned_user_id]);

      sendAssignedRequirementNotificationApi({
        requirement_id: requirementId,
        internal_contract_id: internalContractId,
        assigned_user_id: assigned_user_id,
      }).catch((error) => {
        console.error("Failed to send notification", error);
      });
    }
  }, []);

  const volumeAndSectionNames = useMemo(
    () => ({
      volumes: uniq(
        frameworkState?.volumes
          .filter((vol) => !!vol.sections?.length)
          ?.map((v) => {
            return v?.title;
          }) || []
      ),
      sections: compact(
        uniq(
          frameworkState?.volumes
            ?.filter(({ title }) => !searchFilters.proposalVolume || title === searchFilters.proposalVolume)
            ?.reduce((acc, vol) => [...acc, ...vol.sections?.map((sec) => sec.title)], [])
        )
      ),
    }),
    [frameworkState?.volumes, searchFilters.proposalVolume]
  );

  const handleToggleCheckAll = useCallback(
    (checked) => {
      const checkedReqs = requirements.reduce((acc, row) => ({ ...acc, [row?.requirement?.id]: checked }), {});
      dispatch(updateCheckedState(checkedReqs));
    },
    [dispatch, requirements]
  );

  const getCheckedRows = useMemo(() => {
    const allCheckedReqIds = Object.keys(checkedState).filter((id) => checkedState[id]);
    return requirements?.filter((v) => allCheckedReqIds.includes(v?.requirement?.id));
  }, [checkedState, requirements]);
  const hasCheckedRows = useMemo(() => {
    return Object.keys(checkedState).some((id) => checkedState[id]);
  }, [checkedState]);
  const isAllSelected = useMemo(() => {
    return !!requirements?.length && requirements?.length === getCheckedRows?.length;
  }, [getCheckedRows?.length, requirements?.length]);

  // on drag end
  const onDragEnd = useMutation(
    ({ storage }, { destination, source, type }) => {
      if (!destination || !source || type !== "REQUIREMENTS_TABLE") return;
      const complianceMatrix = storage.get("compliance_matrix");
      if (!complianceMatrix) return;
      const destinationReqRow = requirements[destination.index];
      const destinationRowIndex = findIndex(
        complianceMatrix,
        (row) => row.get("requirement")?.get("id") === destinationReqRow.requirement?.id
      );
      const sourceReqRow = requirements[source.index];
      const sourceRowIndex = findIndex(
        complianceMatrix,
        (row) => row.get("requirement")?.get("id") === sourceReqRow.requirement?.id
      );
      setRequirements((prev) => move(prev, source.index, destination.index));
      setTimeout(() => moveItem(complianceMatrix, sourceRowIndex, destinationRowIndex), 0);
    },
    [requirements, setRequirements]
  );

  const handleToggleRowSelection = useCallback(
    (checked, data) => {
      const reqId = data?.requirement?.id;

      dispatch(updateCheckedState({ [reqId]: checked }));
    },
    [dispatch]
  );

  const addRequirementRow = useCallback(async () => {
    try {
      const atlasRequirement = await createAtlasRequirement({ content: "" });
      const newReq = createComplianceMatrixRowRequirement({
        id: atlasRequirement.id,
        ...(activeSheet?.id !== EMPTY_SHEET.id && { extraction_id: activeSheet?.id }),
      });
      insertRequirementRow(0, { requirement: newReq });
    } catch {}
  }, [activeSheet?.id, createAtlasRequirement, insertRequirementRow]);

  return (
    <section className="rounded-lg h-full flex flex-col relative">
      <div
        className="border border-slate-200 rounded-t-lg flex-1 overflow-x-auto overflow-y-hidden"
        ref={boxRef}
        css={blueScrollStyles}
      >
        <div className="w-max min-w-full z-[0] rounded-lg bg-white h-full">
          <TableHeader
            projectId={internalContractId}
            isAllSelected={isAllSelected}
            volumeAndSectionNames={volumeAndSectionNames}
            searchFilters={searchFilters}
            setSearchFilters={setSearchFilters}
            requirements={requirements}
            handleToggleCheckAll={handleToggleCheckAll}
            selectedColumns={selectedColumns}
            documents={existingDocuments}
          />
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable
              droppableId="table"
              type="REQUIREMENTS_TABLE"
              mode="virtual"
              renderClone={(provided, snapshot, rubric) => (
                <Item
                  isLoadingAtlasRequirements={isLoadingAtlasRequirements}
                  atlasResponse={
                    groupedAtlasRequirements?.[requirements[rubric.source.index]?.requirement?.id]?.response
                  }
                  hasCheckedRows={hasCheckedRows}
                  checked={checkedState[requirements[rubric.source.index]?.requirement?.id]}
                  internalContractId={internalContractId}
                  isDragDisabled={sortActive}
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  data={requirements[rubric.source.index]}
                  handleToggleRowSelection={handleToggleRowSelection}
                  updateComplianceMatrixRow={updateComplianceMatrixRow}
                  frameworkState={frameworkState}
                  selectedColumns={selectedColumns}
                  setAssignMemberField={setAssignMemberField}
                />
              )}
            >
              {(provided) =>
                !!requirements.length ? (
                  <Virtuoso
                    components={{
                      Item: HeightPreservingItem,
                    }}
                    scrollerRef={provided.innerRef}
                    style={{ height: "calc(100% - 40px)", width: "100%", overflowX: "hidden" }}
                    data={requirements}
                    itemContent={(index, data) => {
                      return (
                        <Draggable
                          isDragDisabled={sortActive}
                          draggableId={data.requirement.id}
                          index={index}
                          key={data.requirement.id}
                        >
                          {(provided, snapshot) => (
                            <Item
                              isLoadingAtlasRequirements={isLoadingAtlasRequirements}
                              atlasResponse={groupedAtlasRequirements?.[data?.requirement?.id]?.response}
                              hasCheckedRows={hasCheckedRows}
                              checked={checkedState[data?.requirement?.id]}
                              internalContractId={internalContractId}
                              isDragDisabled={sortActive}
                              provided={provided}
                              isDragging={snapshot.isDragging}
                              handleToggleRowSelection={handleToggleRowSelection}
                              data={data}
                              updateComplianceMatrixRow={updateComplianceMatrixRow}
                              frameworkState={frameworkState}
                              selectedColumns={selectedColumns}
                              setAssignMemberField={setAssignMemberField}
                            />
                          )}
                        </Draggable>
                      );
                    }}
                  />
                ) : (
                  <div ref={provided.innerRef} className="absolute top-[90px] bottom-2.5 w-full py-2">
                    {!!complianceMatrixState?.length && (
                      <Empty
                        className={!searchActive ? "h-[190px]" : undefined}
                        heading="No matching requirements"
                        title="Try changing your search filters or adding a new requirement"
                        containerProps={searchActive ? { css: tw`pb-8` } : undefined}
                      />
                    )}
                    {!complianceMatrixState?.length && (
                      <Empty
                        className="h-[130px]"
                        heading="No requirements"
                        title={
                          <div className="text-center">
                            Get started by{" "}
                            <Button
                              disabled={isCreatingRequirement}
                              onClick={() => addRequirementRow()}
                              variant="link"
                              size="sm"
                              className="inline !min-h-0 !text-sm !font-semibold"
                            >
                              adding a requirement
                            </Button>{" "}
                            or
                            <br />
                            <Button
                              onClick={() => dispatch(toggleRequirementExtractionModal({ open: true }))}
                              variant="link"
                              size="sm"
                              className="inline !min-h-0 !text-sm !font-semibold"
                            >
                              extracting requirements
                            </Button>{" "}
                            from a document
                          </div>
                        }
                      />
                    )}
                  </div>
                )
              }
            </Droppable>
          </DragDropContext>
        </div>
      </div>
      <SheetsTabs />
    </section>
  );
};

export const AssignedDropDown = ({ data, setAssignMemberField }) => {
  const { workspaceMembers } = useSelector((store) => store.auth);
  const [searchVal, setSearchVal] = useState("");

  const handleMemberSelect = (v) => {
    setAssignMemberField(data.requirement?.id, v);
  };

  const getUsernameById = useCallback(
    (userId) => {
      const result = workspaceMembers?.find((member) => member?.id === userId);
      return result?.username;
    },
    [workspaceMembers]
  );

  const sortedMembers = useMemo(
    () =>
      [...workspaceMembers].sort((a, b) => {
        const aSelected = data.assigned_user_ids?.includes(a.id);
        const bSelected = data.assigned_user_ids?.includes(b.id);

        if (aSelected && !bSelected) {
          return -1; // a is selected but b is not, move a to the top
        } else if (!aSelected && bSelected) {
          return 1; // b is selected but a is not, move b to the top
        } else {
          // both a and b are selected or both are not selected, sort alphabetically
          return a.username.localeCompare(b.username);
        }
      }),
    [data.assigned_user_ids, workspaceMembers]
  );

  const filteredUsers = useMemo(() => {
    return sortedMembers?.filter((me) => me?.username?.toLocaleLowerCase()?.includes(searchVal?.toLocaleLowerCase()));
  }, [searchVal, sortedMembers]);

  const avatars = useMemo(
    () =>
      data?.assigned_user_ids?.reduce((acc, userId) => {
        const username = getUsernameById(userId);
        if (!username) return acc;
        return [
          ...acc,
          {
            id: username,
            name: username,
            size: 32,
          },
        ];
      }, []),
    [data?.assigned_user_ids, getUsernameById]
  );

  return (
    <Popover
      modal
      content={
        <div className="w-max max-w-[425px] rounded-[6px] overflow-hidden bg-white shadow-[0_0_15px_rgba(0,0,0,0.1)] z-20">
          <div className="max-h-[250px] overflow-y-auto">
            <div className="sticky top-0 left-0 bg-white z-[2] p-[0.15rem]">
              <input
                autoFocus
                onChange={(e) => setSearchVal(e.target.value)}
                value={searchVal}
                type="text"
                placeholder={"Search Members"}
                className={`w-[100%] h-[25px] py-5 px-2 text-sm rounded border !outline-0 border-gray-200`}
                style={{ outlineStyle: "solid" }}
              />
            </div>
            <div className="px-3 py-3">
              {filteredUsers?.length > 0 ? (
                filteredUsers?.map((v, i) => (
                  <div
                    key={i}
                    className={`flex items-center min-w-[120px] max-w-[425px] py-[2px] ${
                      i + 1 === workspaceMembers?.length ? "" : "mb-1"
                    }`}
                  >
                    <label
                      htmlFor={`checkbox-item-${data?.requirement?.id + i}`}
                      className="flex items-center gap-2 text-sm select-none cursor-pointer w-full text-gray-900 max-w-full  overflow-hidden line-clamp-1"
                    >
                      <Checkbox
                        id={`checkbox-item-${data?.requirement?.id + i}`}
                        onCheck={() => handleMemberSelect(v?.id)}
                        checked={!!data?.assigned_user_ids?.find((n) => n === v?.id)}
                      />
                      <Avatar id={v?.username} size={24} name={v?.username} className="!text-[13px]" />
                      {v?.username}
                    </label>
                  </div>
                ))
              ) : (
                <div className="min-w-[160px] text-gray-400 text-center px-2 py-3 text-xs">No members found.</div>
              )}
            </div>
          </div>
        </div>
      }
    >
      <div className="cursor-pointer py-1 pl-2 w-full">
        {workspaceMembers?.length > 0 ? (
          <div className="flex items-center justify-center w-full p-1 min-w-[105px]  min-h-[32px] rounded-md bg-transparent duration-150 hover:bg-slate-200">
            <div className="flex items-center justify-center">
              {avatars?.length > 0 ? (
                <AvatarGroup maxCount={2} avatars={avatars} size={26} />
              ) : (
                <p className="text-gray-400 text-xs">Add Members</p>
              )}
              {workspaceMembers?.length > 2 && <span className="text-xs text-gray-400 ml-1">+</span>}
            </div>
          </div>
        ) : null}
      </div>
    </Popover>
  );
};

const HeightPreservingItem = ({ children, item, ...props }) => {
  const [size, setSize] = useState(0);
  const knownSize = props["data-known-size"];
  useEffect(() => {
    setSize((prevSize) => {
      return knownSize === 0 ? prevSize : knownSize;
    });
  }, [knownSize]);
  // check style.css for the height-preserving-container rule
  return (
    <div
      {...props}
      className="height-preserving-container"
      style={{
        "--child-height": `${size}px`,
      }}
    >
      {children}
    </div>
  );
};

function splitStringByKeywords(s, keywords) {
  if (!keywords || !s) {
    return [s];
  }

  // Sort keywords by length in descending order to ensure longer keywords are matched first
  const sortedKeywords = [...keywords].sort((a, b) => b.length - a.length);

  // Escape special characters in keywords and create a regular expression pattern
  const escapedKeywords = sortedKeywords.map((keyword) => keyword.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"));
  const pattern = new RegExp(`\\b(${escapedKeywords.join("|")})\\b`, "g");

  // Split the string using the regular expression pattern and filter out any empty strings
  return s.split(pattern).filter((part) => part !== "");
}

const Item = ({
  hasCheckedRows,
  checked = false,
  isDragging,
  data,
  provided,
  handleToggleRowSelection,
  updateComplianceMatrixRow,
  frameworkState,
  selectedColumns,
  setAssignMemberField,
  isDragDisabled,
  //   atlasResponse - optional
  atlasResponse,
  isLoadingAtlasRequirements,
}) => {
  const flags = useFlags();
  const { requirement, document, written_content, response_sources } = data;
  const { getFrameworkVolumeTitle, getFrameworkVolumeSectionTitle } = useFrameworkOperations(frameworkState);
  const { setRequirementStatus, setComplianceStatus } = useRequirementOperations();
  const { generateRequirementHeading } = useGenerateRequirementHeading();
  const activeRequirementId = useAppSelector((root) => root.requirements.activeRequirementId);
  const reqId = requirement?.id;
  const isShowingDetails = activeRequirementId === reqId;
  const dispatch = useAppDispatch();
  const trackUserEvent = useTrackUserMetric();
  const memoizedContent = useMemo(
    () => splitStringByKeywords(requirement?.content || requirement?.summarized_content, requirement?.matched_keywords),
    [requirement?.content, requirement?.summarized_content, requirement?.matched_keywords]
  );
  const RelevancyIcon = RELEVANCE_TO_META[atlasResponse?.relevance_score]?.icon || EmptyRelevance;
  const ScoreIcon = SCORE_TO_META[atlasResponse?.total_score]?.icon || EmptyScore;
  return (
    <div
      {...provided.draggableProps}
      ref={provided.innerRef}
      style={provided.draggableProps.style}
      className="border-b bg-white"
      css={[
        isDragging && tw`shadow max-w-[440px] rounded overflow-hidden`,
        (checked || (isShowingDetails && !hasCheckedRows)) && tw`bg-blue-50`,
        requirement.skipped && tw`opacity-40`,
      ]}
    >
      <div className="text-gray-darkest w-full flex items-center relative z-[1] cursor-pointer hover:bg-blue-50">
        <div
          className="absolute right-0 left-0 top-0 bottom-0"
          onClick={() => {
            dispatch(setActiveRequirementId(reqId));
            dispatch(toggleRequirementDetails(true));
          }}
        />
        <div className="pr-2 pl-2 py-2 min-w-[440px] max-w-[440px] w-[27%] cursor-pointer relative group">
          <div className="flex gap-2">
            <label
              htmlFor={checked ? "" : requirement?.id}
              className={`z-[1] ${
                checked ? "" : "opacity-0 group-hover:opacity-100 "
              } flex gap-1  items-center justify-center`}
            >
              <div className="w-[65%]">
                <Tooltip
                  content={
                    isDragDisabled ? (
                      "You cannot drag until you disable sorting."
                    ) : (
                      <div className="text-center flex flex-col gap-1">
                        <div>
                          <span className="text-gray-800 text-xs font-semibold">Drag </span>
                          <span className="text-gray-800 text-xs font-normal">to move</span>
                        </div>
                      </div>
                    )
                  }
                >
                  <div
                    {...provided.dragHandleProps}
                    className="flex cursor-grab justify-center items-center p-1 rounded-md bg-transparent duration-150 active:bg-slate-200 hover:bg-slate-200 active:cursor-grabbing"
                    css={[isDragDisabled && tw`!bg-slate-300 opacity-50 cursor-not-allowed`]}
                  >
                    <Icon name="Draggable" className="text-gray-lightest" />
                  </div>
                </Tooltip>
              </div>
              <Checkbox
                id={requirement?.id}
                checked={checked || false}
                onCheck={(checked) => handleToggleRowSelection(checked, data)}
              />
            </label>
            <div
              onClick={() => {
                dispatch(setActiveRequirementId(reqId));
                dispatch(toggleRequirementDetails(true));
              }}
              className="flex items-center w-full text-xs text-gray-darkest"
            >
              <div className="line-clamp-[8] whitespace-pre-wrap">
                {requirement?.content || requirement?.summarized_content ? (
                  memoizedContent.map((part, index) => (
                    <span
                      key={index}
                      className={
                        requirement?.matched_keywords && requirement?.matched_keywords.includes(part)
                          ? "font-bold text-action"
                          : "font-normal"
                      }
                    >
                      {part}
                    </span>
                  ))
                ) : (
                  <Button variant="link" size="md">
                    Fill in requirement...
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
        <SkipButton requirement={requirement} />
        {selectedColumns.includes("Assignees") && (
          <div className="z-[1] pr-4 pl-[0.20rem] py-2 min-w-[120px] max-w-[120px] w-[8%]">
            <AssignedDropDown data={data} setAssignMemberField={setAssignMemberField} />
          </div>
        )}
        {selectedColumns.includes("Status") && (
          <Popover
            modal
            contentProps={{ css: tw`mx-0` }}
            content={
              <RequirementStatusPopoverContent
                selectedStatus={data?.requirement_status || RequirementStatus.Todo}
                onStatusSelect={(newStatus) => {
                  setRequirementStatus(requirement.id, newStatus);
                }}
                tw="p-1"
              />
            }
          >
            <div className="z-[1] p-4 min-w-[90px] max-w-[90px] flex justify-center w-[9%]">
              <button className="p-2 rounded-md bg-transparent duration-150 hover:bg-slate-200">
                <div className="flex text-xs items-center gap-1.5 truncate">
                  {REQUIREMENT_STATUS_TO_META[data?.requirement_status || RequirementStatus.Todo].icon}
                </div>
              </button>
            </div>
          </Popover>
        )}
        {selectedColumns.includes("Compliance") && (
          <Popover
            modal
            contentProps={{ css: tw`mx-0` }}
            content={
              <CompliancePopoverContent
                selectedStatus={data?.compliance_status || RequirementCompliance.Empty}
                onStatusSelect={(newStatus) => {
                  setComplianceStatus(requirement.id, newStatus);
                }}
                tw="p-1"
              />
            }
          >
            <div className="z-[1] p-4 min-w-[110px] max-w-[110px] flex justify-center w-[9%]">
              <button className="p-2 rounded-md bg-transparent duration-150 hover:bg-slate-200">
                <div className="flex text-xs items-center gap-1.5 truncate">
                  {COMPLIANCE_TO_META[data?.compliance_status || RequirementCompliance.Empty].icon}
                </div>
              </button>
            </div>
          </Popover>
        )}
        {selectedColumns.includes("Proposal Volume") && (
          <VolumesDropdown
            contentProps={{ css: tw`min-w-[284px]`, sideOffset: -8, align: "start" }}
            volumes={frameworkState?.volumes}
            includeEmpty
            onSelect={(vol) => {
              updateComplianceMatrixRow(reqId, "proposal_reference", "volume_id", vol?.id || "");

              updateComplianceMatrixRow(reqId, "proposal_reference", "section_id", vol?.sections[0]?.id || "");

              if (
                vol?.sections[0]?.id &&
                !!(written_content || requirement?.content || requirement?.summarized_content)
              )
                generateRequirementHeading({ requirement_ids: [reqId] });
            }}
            sectionsMustExist
          >
            <div className="z-[1] gap-2 flex items-center justify-between px-2 relative py-2 min-w-[260px] max-w-[260px] w-[16%]">
              <span className="text-xs text-start max-w-[200px]">
                {getFrameworkVolumeTitle(data?.proposal_reference?.volume_id) || EMPTY_VALUE}
              </span>
              <Icon name="CarrotDown" className="w-4 h-4 text-[#5B6B79]" />
            </div>
          </VolumesDropdown>
        )}
        {selectedColumns.includes("Proposal Section") && (
          <SectionsDropdown
            activeVolumeId={data?.proposal_reference?.volume_id}
            contentProps={{ css: tw`min-w-[284px]`, sideOffset: -8, align: "center" }}
            volumes={frameworkState?.volumes}
            includeEmpty
            onSelect={(sec) => {
              const volume = frameworkState?.volumes?.find((vol) => vol?.sections?.find(({ id }) => id === sec?.id));

              updateComplianceMatrixRow(reqId, "proposal_reference", "volume_id", volume?.id || "");
              updateComplianceMatrixRow(reqId, "proposal_reference", "section_id", sec?.id || "");

              trackUserEvent("Requirements: Requirement Added to Section", {
                requirement_id: String(reqId),
                section_id: String(sec?.id || ""),
              });

              if (sec?.id && !!(written_content || requirement?.content || requirement?.summarized_content))
                generateRequirementHeading({ requirement_ids: [reqId] });
            }}
          >
            <div className="z-[1] gap-2 flex items-center justify-between px-2 relative py-2 min-w-[260px] max-w-[260px] w-[16%]">
              <span className="text-xs text-start max-w-[200px]">
                {getFrameworkVolumeSectionTitle(
                  data?.proposal_reference?.volume_id,
                  data?.proposal_reference?.section_id
                ) || EMPTY_VALUE}
              </span>
              <Icon name="CarrotDown" className="w-4 h-4 text-[#5B6B79]" />
            </div>
          </SectionsDropdown>
        )}
        {flags.instantDraft && (
          <>
            {selectedColumns.includes("Response") && (
              <div title={written_content} className="z-[1] p-2 min-w-[350px] max-w-[350px] w-[10%]">
                <div
                  onClick={() => {
                    dispatch(setActiveRequirementId(reqId));
                    dispatch(toggleRequirementDetails(true));
                  }}
                  className="w-full text-xs text-gray-darkest"
                >
                  <div className="line-clamp-[8] whitespace-pre-wrap">
                    {written_content || <div className="text-gray-500">No response</div>}
                  </div>
                </div>
              </div>
            )}
            {selectedColumns.includes("Sources") && (
              <div className="flex justify-center items-center text-gray-darkest flex-col gap-1 p-2 min-w-[150px] max-w-[150px] w-[4%]">
                <Popover
                  content={
                    <div className="flex flex-col">
                      {response_sources?.map((source, idx) => (
                        <>
                          <div className="pl-2.5 pr-2.5 py-1.5 flex items-center gap-1.5">
                            <img src={getFileIcon(source.extension_type)} alt="" className="w-[14px] h-full" />
                            <div className="max-w-[200px] text-sm truncate">{source.name}</div>
                          </div>
                          {idx !== response_sources?.length - 1 && <hr />}
                        </>
                      ))}
                    </div>
                  }
                  disabled={!response_sources?.length}
                >
                  <div
                    className="select-none cursor-default z-[1] px-2 flex gap-1 items-center bg-gray-200 text-xs rounded-md text-gray-darkest"
                    css={[response_sources?.length && tw`cursor-pointer hover:bg-gray-300`]}
                  >
                    <Icon name="Paperclip" className="w-3.5" />
                    {response_sources?.length || 0} Documents
                  </div>
                </Popover>
              </div>
            )}
            {selectedColumns.includes("Relevancy") && (
              <div className="flex justify-center items-center text-gray-darkest flex-col gap-1 p-2 min-w-[100px] max-w-[100px] w-[3%]">
                {isLoadingAtlasRequirements && <SpinnerCircle />}
                {!isLoadingAtlasRequirements && (
                  <>
                    <div className="h-[22px] flex items-center">
                      <RelevancyIcon />
                    </div>
                    <span className="text-gray-500 text-xs">
                      {RELEVANCE_TO_META[atlasResponse?.relevance_score]?.label || EMPTY_VALUE}
                    </span>
                  </>
                )}
              </div>
            )}
            {selectedColumns.includes("Score") && (
              <div className="flex justify-center items-center text-gray-darkest flex-col gap-1 p-2 min-w-[100px] max-w-[100px] w-[3%]">
                {isLoadingAtlasRequirements && <SpinnerCircle />}
                {!isLoadingAtlasRequirements && (
                  <>
                    <div className="h-[22px] flex items-center">
                      <ScoreIcon />
                    </div>
                    <span className="text-gray-500 text-xs">
                      {SCORE_TO_META[atlasResponse?.total_score]?.label || EMPTY_VALUE}
                    </span>
                  </>
                )}
              </div>
            )}
          </>
        )}
        {!flags.instantDraft && selectedColumns.includes("Source") && (
          <div className="z-[1] p-4 min-w-[110px] max-w-[110px] w-[6%]">
            <Tooltip
              delayDuration={300}
              contentProps={{ align: "end" }}
              content={
                <div className="text-gray-primary font-normal pr-1 max-w-[300px] break-words">
                  <p className="my-2">
                    <b>Document:</b> {document?.name || EMPTY_VALUE}
                  </p>
                  {requirement?.page_number && (
                    <p className="my-1">
                      <b>Page Number:</b> {requirement.page_number}
                    </p>
                  )}
                </div>
              }
            >
              <div className="m-auto w-fit cursor-default">
                <img className="mx-auto w-6 h-6" src={doc} alt="" />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
    </div>
  );
};

export default CopilotTable;
