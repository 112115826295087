import Popover from "components/atoms/popover";
import { Props as PopoverProps } from "components/atoms/popover/Popover";
import { PropsWithChildren } from "react";
import { BaseFile } from "types/FileStorage";
import ContentDrivePopoverContent from "./ContentDrivePopoverContent";

interface Props extends Omit<PopoverProps, "content"> {
    handleSelectedFiles: (checked: boolean, files: BaseFile[]) => void;
    selectedFiles: string[];
    onClear: (files: BaseFile[]) => void;
}

const ContentDrivePopover = ({ handleSelectedFiles, onClear, selectedFiles, ...props }: PropsWithChildren<Props>) => {
    return (
        <Popover
            contentProps={{ side: "bottom" }}
            content={
                <ContentDrivePopoverContent
                    handleSelectedFiles={handleSelectedFiles}
                    onClear={onClear}
                    selectedFiles={selectedFiles}
                />
            }
            {...props}
        />
    );
};

export default ContentDrivePopover;
