/** @jsxImportSource @emotion/react */

import { GroupedBlock } from "../../types";
import { Info } from "lucide-react";
import { useState } from "react";

import DocumentJumper from "./DocumentJumper";
import Popover from "components/atoms/popover";
import tw from "twin.macro";

type Props = {
  isAssignStep: boolean;
  allFilteredBlocks: GroupedBlock[];
};

const BottomRightActions = ({ isAssignStep, allFilteredBlocks }: Props) => {
  const [open, setOpen] = useState(true);
  const [portalRef, setPortalRef] = useState<HTMLDivElement | null>(null);

  return (
    <div ref={setPortalRef} className="flex flex-row items-center gap-2 absolute bottom-2 right-2 z-[3]">
      <Popover
        open={open}
        onOpenChange={setOpen}
        contentProps={{
          align: "end",
          css: tw`mx-0 bg-white/80 backdrop-blur-lg border-1.5 border-gray-300`,
          onInteractOutside: (e) => e.preventDefault(),
        }}
        defaultOpen
        portalProps={{ container: portalRef }}
        content={
          <div className="flex flex-col gap-2.5 p-3 min-w-[150px] m-1">
            <div className="text-xs font-medium text-gray-500 mb-1.5">Legend</div>
            <div className="flex items-center gap-4 justify-between flex-row">
              <div className="bg-white">
                <div
                  className="border-2 border-action bg-action/25 w-3 h-3"
                  css={[isAssignStep && tw`bg-[rgba(14, 120, 8, .25)] border-[rgb(14, 120, 8)]`]}
                />
              </div>
              <span className="text-xs font-medium">{isAssignStep ? "Assigned" : "Selected"}</span>
            </div>
            <div className="flex items-center gap-4 justify-between flex-row">
              <div className="bg-white">
                <div
                  className="border-2 border-gray-400 bg-black/15 w-3 h-3"
                  css={[isAssignStep && tw`border-action bg-action/25`]}
                />
              </div>
              <span className="text-xs font-medium">{isAssignStep ? "Unassigned" : "Unselected"}</span>
            </div>
          </div>
        }
      >
        <div
          className="px-2 backdrop-blur-lg shadow py-1.5 flex flex-row gap-2 rounded-md items-center cursor-pointer text-white bg-black/70 duration-100 hover:bg-black"
          css={[open && tw`bg-black`]}
        >
          <Info size={14} />
          <span className="text-xs">Legend</span>
        </div>
      </Popover>
      <DocumentJumper currentBlocks={allFilteredBlocks} />
    </div>
  );
};

export default BottomRightActions;
