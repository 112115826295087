import { FetchEventSourceInit, fetchEventSource } from "@microsoft/fetch-event-source";
import { useLocalStorage } from "hook/useLocalStorage";
import { PreviewRequirement } from "./SimpleRequirementExtraction";
import { Storage } from "components/copilot/CopilotSchemaTypes";
import { Tab } from "../tabs/Tabs";
import { useCallback, useMemo, useRef, useState } from "react";
import Icon from "components/atoms/icons/Icon";
import { TextExtractData } from "components/copilot/Extract/hooks";
import { useSearchParams } from "react-router-dom";
import { createComplianceMatrixRow, createComplianceMatrixRowRequirement } from "utils/complianceMatrix";
import { useMutation } from "YJSProvider/createYJSContext";

export const useSseReqExtraction = (setIsExtracting: (loading: boolean) => void, options?: FetchEventSourceInit) => {
    const { localValue } = useLocalStorage("vultron_user_token", "");
    const { localValue: workspace_id } = useLocalStorage("vultron_workspace_id", "");
    const { localValue: use_auth0 } = useLocalStorage("vultron_user_use_auth0");
    const useAuth0Header = use_auth0 === true;
    const controllerRef = useRef(new AbortController());
    const { current: controller } = controllerRef;
    const [searchParams] = useSearchParams();
    const projectId = searchParams.get("id")?.toLocaleLowerCase();

    const extractRequirements = (body: TextExtractData) => {
        setIsExtracting(true);
        fetchEventSource(`${process.env.REACT_APP_BASE_URL}/autopilot/${projectId}/extract/requirements/stream/text`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Workspace: `Workspace ${workspace_id}`,
                Authorization: `Bearer ${localValue}`,
                "X-Authorization-Auth0": JSON.stringify(useAuth0Header),
                Accept: "application/json",
            },
            body: JSON.stringify(body),
            signal: controller.signal,
            openWhenHidden: true,
            ...options,
        });
    };

    const abortConnection = useCallback(() => {
        controllerRef.current.abort();
        controllerRef.current = new AbortController();
    }, []);

    return { extractRequirements, abortConnection };
};

export const useAppendRequirements = () => {
    const appendRequirements = useMutation(({ storage }, requirements: PreviewRequirement[], onSuccess: () => void) => {
        const liveRequirements = requirements.map(({ content, extractionId }) => {
            const createdRequirement = createComplianceMatrixRowRequirement({
                extraction_id: extractionId,
                content,
            });
            return createComplianceMatrixRow({ requirement: createdRequirement });
        });

        const complianceMatrix = storage.get("compliance_matrix") as Storage["compliance_matrix"];
        liveRequirements.forEach((liveReq) => complianceMatrix.push([liveReq]));

        onSuccess();
    }, []);

    return appendRequirements;
};

export enum TabSlug {
    Document,
    CopyPaste,
}

export const useTabs = () => {
    const extractTabs: Tab<TabSlug>[] = useMemo(
        () => [
            {
                name: "Copy + Paste",
                icon: <Icon name="Copy" className="w-[18px] h-[18px]" />,
                slug: TabSlug.CopyPaste,
                empty: "No requirements have been assigned.",
            },
        ],
        []
    );

    const activeTabState = useState<Tab<TabSlug>>(extractTabs[0]);

    return { activeTabState, extractTabs };
};
