import React, { useState } from "react";
import hide from "../../Assets/hide.svg";
import show from "../../Assets/show.svg";
import rocket from "../../Assets/rocket.svg";
import trophy from "../../Assets/trophy.svg";
import puzzle from "../../Assets/puzzle.svg";
import rubrik from "../../Assets/scoring-rubrik-icon.svg";
import * as Collapsible from "@radix-ui/react-collapsible";

interface AccordionItemProps {
    title: string;
    subtitleContent: string;
    grade: string;
    gradeColor: string;
    defaultOpen?: boolean;
    content?: React.ReactNode;
    buttonClassName?: string;
    containerClassName?: string;
    optional?: boolean;
    bgColor?: string;
}

const FeedbackAccordion: React.FC<AccordionItemProps> = ({
    title,
    subtitleContent,
    grade,
    gradeColor,
    content,
    buttonClassName,
    containerClassName,
    bgColor,
    defaultOpen = false,
    optional = false,
}) => {
    const [isOpen, setIsOpen] = useState(defaultOpen);

    type IconKey = "Comprehensiveness" | "Quality" | "Responsiveness" | "Scoring Framework";

    const ICON_MAP: Record<IconKey, string> = {
        Comprehensiveness: rocket,
        Quality: trophy,
        Responsiveness: puzzle,
        "Scoring Framework": rubrik,
    };

    bgColor = bgColor ? bgColor : "bg-white";
    return (
        <Collapsible.Root open={isOpen} onOpenChange={setIsOpen}>
            <div className={containerClassName}>
                <div className="flex items-center">
                    <Collapsible.Trigger asChild onClick={(e) => e.stopPropagation()}>
                        <button
                            className={`group text-xl relative flex w-full items-center justify-between border-0 ${bgColor} box-border px-5 py-4 text-left transition [overflow-anchor:none] hover:z-[2] focus:z-[3] focus:outline-none rounded-lg ${
                                isOpen ? "" : "shadow-inset_0_-1px_0_rgba(229,231,235) hover:bg-slate-50"
                            }`}
                            type="button"
                            aria-expanded={isOpen}
                            onClick={() => setIsOpen(!isOpen)}
                        >
                            <div className="flex-grow flex items-center">
                                {title === "Scoring Framework" && (
                                    <img className="w-[16px] h-[16px]" src={ICON_MAP[title as IconKey]} alt="icon" />
                                )}
                                {title !== "Scoring Framework" && (
                                    <img className="w-4 h-4" src={ICON_MAP[title as IconKey]} alt="icon" />
                                )}
                                <div className="pl-2 text-sm font-semibold">{title}</div>
                                {subtitleContent && (
                                    <span className="text-xs text-gray-lightest ml-2 mt-1">{subtitleContent}</span>
                                )}
                                {grade && (
                                    <span className={`text-sm ${gradeColor} ml-auto font-semibold mt-1`}>{grade}</span>
                                )}
                            </div>
                            <span className="flex ml-4 items-center mt-1">
                                <img className={isOpen ? "" : "hidden"} src={hide} alt="icon" />
                                <img className={isOpen ? "hidden" : ""} src={show} alt="icon" />
                            </span>
                        </button>
                    </Collapsible.Trigger>
                </div>

                <Collapsible.Content className={`collapsibleContent pt-3 pb-4 ${bgColor}`}>
                    {content}
                </Collapsible.Content>
            </div>
        </Collapsible.Root>
    );
};

export default FeedbackAccordion;
