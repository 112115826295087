/** @jsxImportSource @emotion/react */

import { setEnableInternet } from "store/reducers/writing-assistant/writingAssistantReducer";
import { useAppDispatch } from "store/storeTypes";
import "./styles.css";
import Icon from "components/atoms/icons/Icon";
import { X } from "lucide-react";

const InternetChip = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="bg-white cursor-default overflow-hidden flex items-center min-w-fit h-7 min-h-[28px] text-xs rounded border border-light shadow-expanded text-gray-600">
      <div className="pl-1.5 pr-2 py-1 flex items-center gap-1.5">
        <Icon name="Internet" className="w-[14px] text-gray-600 h-full" />
        <div className="font-medium text-gray-600">Internet</div>
      </div>
      <button
        onClick={() => dispatch(setEnableInternet(false))}
        className="text-sm h-full border border-dashed border-transparent border-l-gray-light shrink-0 text-gray-500 w-[26px] justify-center rounded-r-[3px] flex items-center duration-150 hover:border-gray-darkest hover:text-gray-darkest"
      >
        <X size={14} />
      </button>
    </div>
  );
};

export default InternetChip;
