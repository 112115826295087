import React, { Dispatch } from "react";
import { PromptMode } from "./RequirementsBulkUpdate";
import { X } from "lucide-react";

interface RequirementsBulkUpdateInputProps {
  promptMode: PromptMode;
  promptContent: string;
  setPromptContent: Dispatch<React.SetStateAction<string>>;
  setPromptMode: Dispatch<React.SetStateAction<PromptMode | null>>;
  handleAdd: () => void;
}

const RequirementsBulkUpdateInput = ({
  promptMode,
  promptContent,
  setPromptContent,
  setPromptMode,
  handleAdd,
}: RequirementsBulkUpdateInputProps) => {
  return (
    <div className="flex items-center gap-2">
      <input
        placeholder={`Enter ${promptMode === PromptMode.WritingPrompt ? "writing prompt" : "guideline"} content`}
        value={promptContent}
        onChange={(e) => setPromptContent(e.target.value)}
        className="min-w-[500px] max-w-[500px] h-7 flex-1 outline-none bg-zinc-800 text-zinc-100 px-2 py-1.5 text-xs resize-none border border-zinc-300 rounded-md"
      />
      <button
        onClick={() => {
          handleAdd();
        }}
        className="flex items-center justify-center bg-white rounded-sm h-7 text-xs px-1.5 text-black hover:!bg-zinc-300 disabled:pointer-events-none disabled:!text-zinc-500 disabled:opacity-50"
        disabled={!promptContent.trim()}
      >
        Add {promptMode === PromptMode.WritingPrompt ? "Writing Prompt" : "Guideline"}
      </button>
      <button
        onClick={() => {
          setPromptMode(null);
          setPromptContent("");
        }}
        className="flex items-center justify-center rounded-sm h-7 text-xs px-1.5 text-white"
      >
        <X size={16} />
      </button>
    </div>
  );
};

export default RequirementsBulkUpdateInput;
