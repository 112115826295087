import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type Section = {
    id: string;
    name: string;
    subsections: { name: string }[];
};

type Volume = {
    id: string;
    name: string;
    sections: Section[];
};

export enum ExtractionMode {
    CopyPaste = 1,
    Requirements,
}

export type TemplateDraft = Record<"volumes", Volume[]>;

export const INITIAL_TEMPLATE = {
    volumes: [],
};

type State = {
    activeExtractionMode: ExtractionMode;
    isExtractLoading: boolean;
    isExtractError: boolean;
    template: TemplateDraft;
    copyPasteContent: string;
};

const initialState: State = {
    activeExtractionMode: ExtractionMode.CopyPaste,
    isExtractLoading: false,
    isExtractError: false,
    copyPasteContent: "",
    template: INITIAL_TEMPLATE,
};

const templateExtractionReducer = createSlice({
    name: "templateExtraction",
    initialState,
    reducers: {
        updateTemplate: (state: State, action: PayloadAction<Partial<TemplateDraft>>) => {
            state.template = { ...state.template, ...action.payload };
        },
        appendVolume: (state: State, action: PayloadAction<Volume>) => {
            state.template.volumes = [...state.template.volumes, action.payload];
        },
        toggleExtractError: (state: State, action: PayloadAction<boolean>) => {
            state.isExtractError = action.payload;
        },
        toggleLoading: (state: State, action: PayloadAction<boolean>) => {
            state.isExtractLoading = action.payload;
        },
        setCopyPasteContent: (state: State, action: PayloadAction<string>) => {
            state.copyPasteContent = action.payload;
        },
        setExtractionMode: (state: State, action: PayloadAction<ExtractionMode>) => {
            state.activeExtractionMode = action.payload;
        },
        resetTemplateExtractionState: () => {
            return initialState;
        },
    },
});

export const {
    updateTemplate,
    appendVolume,
    toggleLoading,
    setCopyPasteContent,
    resetTemplateExtractionState,
    toggleExtractError,
    setExtractionMode,
} = templateExtractionReducer.actions;

export default templateExtractionReducer.reducer;
