import { useCallback, useEffect, useState } from "react";
import { DEFAULT_FILTERS } from "./constants";
import { useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/storeTypes";
import {
  getFilteredRequirements,
  setFilteredRequirements,
  setRequirementFilters,
} from "store/reducers/extract/CurrentExtractionReducer";
import { compact, uniq } from "lodash";
import { useDebounce } from "react-use";
import axios from "axios";
import { Requirement } from "types/Requirement";

export const useGetDynamicSections = (isDynamicSectionsActivated: boolean, extractionId?: string) => {
  const activeDocumentId = useAppSelector((store) => store.currentExtractionState.activeDocument?.id) || "";
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const [dynamicSections, setDynamicSections] = useState<{ [key: string]: string[] }>({});
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isDynamicSectionsActivated) getDynamicSections();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDynamicSectionsActivated]);

  const getDynamicSections = useCallback(async () => {
    if (isLoading || !extractionId) return;
    setIsLoading(true);
    try {
      const { data } = await axios.get<Requirement[]>(`/autopilot/${projectId}/requirements`, {
        params: { analysis_id: extractionId },
      });

      const groupedSections = data.reduce<{ [key: string]: string[] }>((acc, req) => {
        if (!req.document_id || !req.section_header) return acc;
        if (acc[req.document_id]) {
          acc[req.document_id].push(req.section_header);
          acc[req.document_id] = uniq(acc[req.document_id]);
        } else acc[req.document_id] = [req.section_header];

        return acc;
      }, {});

      setDynamicSections(groupedSections);
    } catch {
    } finally {
      setIsLoading(false);
    }
  }, [extractionId, isLoading, projectId]);

  return { dynamicSections: dynamicSections[activeDocumentId] || [], isLoadingDynamicSections: isLoading };
};

export const useRequirementFilters = (isDynamicSectionsActivated: boolean) => {
  const filters = useAppSelector((store) => store.currentExtractionState.requirementFilters);
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get("id")?.toLocaleLowerCase();
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const isLoading = useAppSelector((store) => store.currentExtractionState.isLoadingFilteredRequirements);
  const dispatch = useAppDispatch();
  const activeDocument = useAppSelector((store) => store.currentExtractionState.activeDocument);

  useDebounce(
    () => {
      dispatch(setRequirementFilters({ ...filters, query }));
    },
    300,
    [query]
  );

  useEffect(() => {
    if (projectId) dispatch(getFilteredRequirements({ projectId }));
  }, [activeDocument?.id, dispatch, projectId]);

  useEffect(() => {
    const hasActiveFilters = Object.values(filters).some((filterVal) => !!filterVal.length);

    const keywords = compact(filters.query.toLowerCase().trim().split(/,\s*/));

    if (projectId)
      dispatch(
        getFilteredRequirements({
          projectId,
          params: {
            ...(!isDynamicSectionsActivated && { source: filters.sections }),
            ...(isDynamicSectionsActivated && { section_headers: filters.sections }),
            ...(!!keywords.length && { keyword: keywords }),
          },
        })
      );

    if (!hasActiveFilters) dispatch(setFilteredRequirements([]));
  }, [dispatch, filters, isDynamicSectionsActivated, projectId]);

  useEffect(
    () => () => {
      dispatch(setRequirementFilters(DEFAULT_FILTERS));
    },
    [dispatch]
  );

  return { isLoading, filters, isOpen, setIsOpen, query, setQuery };
};
