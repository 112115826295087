import { ResponseSpeed } from "./types";
import { LucideIcon, Wand, Zap } from "lucide-react";

export const SPEED_TO_META: Record<
  ResponseSpeed,
  { icon: LucideIcon; value: ResponseSpeed; label: string; description: string }
> = {
  [ResponseSpeed.Speed]: {
    icon: Zap,
    value: ResponseSpeed.Speed,
    label: "Speed Optimized",
    description: "This option is optimized for generation speed and will take approximately a minute to complete.",
  },
  [ResponseSpeed.Quality]: {
    icon: Wand,
    value: ResponseSpeed.Quality,
    label: "Quality Optimized",
    description:
      "This option is optimized for the quality of the generated response and will take a few minutes to complete.",
  },
};
