import { ComponentProps } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { useAppSelector } from "store/storeTypes";
import SectionNavRow from "./SectionNavRow";

const SortableItem = (props: ComponentProps<typeof SectionNavRow>) => {
    const isDisabled = useAppSelector((store) => store.sectionState.sectionEditable) === props.section.id;

    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: props.section.id || "",
        disabled: isDisabled,
    });

    const style = {
        transform: CSS.Translate.toString(transform),
        transition: transition || undefined,
    };

    return (
        <SectionNavRow
            ref={setNodeRef}
            style={style}
            withOpacity={isDragging}
            {...props}
            dragProps={{ attributes, listeners }}
        />
    );
};

export default SortableItem;
