export const CORE_CRITERIA = {
  active_voice: "Active Voice",
  explanation: "Explanation",
  outcomes_benefits: "Outcomes & Benefits",
  readability: "Readability, Spelling, & Grammar",
  risk: "Risk Identification & Mitigation",
  substantiation: "Substantiation",
};

export const SENSITIVITY_OPTIONS = [
  {
    label: "Concise",
    description: "Prioritize and focus feedback on the most critical elements that require improvement.",
    value: "concise",
  },
  {
    label: "Targeted (recommended)",
    description:
      "Prioritize and focus feedback on major issues and significant concerns to improve key and essential areas.",
    value: "targeted",
  },
  {
    label: "Exhaustive",
    description:
      "Provide extensive feedback based on a line-by-line review to ensure alignment with the highest standards.",
    value: "exhaustive",
  },
];
