import VanillaSelectionArea from "@viselect/vanilla";
import { SelectionEvents, SelectionOptions } from "@viselect/vanilla";
import React, { useEffect, createContext, useContext, useRef, useState } from "react";
import { GroupedBlock } from "../types";

export interface SelectionAreaProps extends Partial<SelectionOptions>, React.HTMLAttributes<HTMLDivElement> {
    id?: string;
    className?: string;
    onBeforeStart?: SelectionEvents["beforestart"];
    onBeforeDrag?: SelectionEvents["beforedrag"];
    onStart?: SelectionEvents["start"];
    onMove?: SelectionEvents["move"];
    onStop?: SelectionEvents["stop"];
    selectedBlocks?: GroupedBlock[];
}

const SelectionContext = createContext<{
    selectedBlocks?: GroupedBlock[];
    selectionState?: VanillaSelectionArea | undefined;
}>({});

export const useSelection = () => useContext(SelectionContext);

export const SelectionArea: React.FunctionComponent<SelectionAreaProps> = ({
    onBeforeStart,
    onBeforeDrag,
    onStart,
    onMove,
    onStop,
    boundaries,
    children,
    className,
    id,
    container,
    selectables,
    selectedBlocks,
}) => {
    const [selectionState, setSelection] = useState<VanillaSelectionArea | undefined>(undefined);
    const root = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const areaBoundaries = boundaries ? boundaries : (root.current as HTMLElement);

        const selection = new VanillaSelectionArea({
            boundaries: areaBoundaries,
            container,
            selectables,
        });

        selection.on("beforestart", (evt) => onBeforeStart?.(evt));
        selection.on("start", (evt) => onStart?.(evt));
        selection.on("move", (evt) => onMove?.(evt));
        selection.on("stop", (evt) => onStop?.(evt));

        setSelection(selection);

        return () => {
            selection.destroy();
            setSelection(undefined);
        };
    }, [boundaries, container, onBeforeStart, onMove, onStart, onStop, selectables]);

    return (
        <SelectionContext.Provider value={{ selectedBlocks, selectionState }}>
            {boundaries ? (
                children
            ) : (
                <div ref={root} className={className} id={id}>
                    {children}
                </div>
            )}
        </SelectionContext.Provider>
    );
};
