import { Editor } from "@tiptap/react";
import { useState } from "react";
import { clearDraft, commentDraftPluginKey } from "../CommentsDraftPlugin";
import { useOnEditorTransaction } from "hook/useOnEditorTransaction";

interface CommentDraft {
  domNode: Node;
  quote_text: string;
}
export const useDraft = (editor: Editor) => {
  const [draft, setDraft] = useState<CommentDraft | null>(null);

  useOnEditorTransaction(editor, (_tr, editor) => {
    const state = commentDraftPluginKey.getState(editor.state);
    const emptySelection = editor.state.selection.empty;
    if (!state || !state.draftActive || emptySelection) {
      setDraft(null);
      if (state?.draftActive) clearDraft(editor);
      return;
    }
    const node = editor.view.domAtPos(editor.state.selection.from);
    const commentNode = node.node instanceof HTMLElement && node.node.querySelector(".comment-draft");

    return setDraft({
      domNode: commentNode || node.node,
      quote_text: state.draftContent || "",
    });
  });

  return draft;
};
