import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ComplianceMatrixRow } from "components/copilot/CopilotSchemaImmutableTypes";

type State = {
    requirements: ComplianceMatrixRow[];
    activeIndex: number;
};

const initialState: State = {
    requirements: [],
    activeIndex: -1,
};

const ideationReducer = createSlice({
    name: "ideation",
    initialState,
    reducers: {
        setActiveIndex: (state: State, action: PayloadAction<State["activeIndex"]>) => {
            state.activeIndex = action.payload;
        },
        openIdeationDrawer: (state: State, action: PayloadAction<Pick<State, "requirements" | "activeIndex">>) => {
            const { requirements, activeIndex } = action.payload;
            return { ...state, requirements, activeIndex };
        },
        closeIdeationDrawer: (state: State) => {
            return initialState;
        },
    },
});

export const { setActiveIndex, openIdeationDrawer, closeIdeationDrawer } = ideationReducer.actions;

export default ideationReducer.reducer;
